import {uploadMinimumData, getMinimumWageData} from '../../api/minimumWage'; // ../../../services/api/minimumWage

export const uploadMinimumDatas = async (mappedData, orgId) => {
  try {
      const data = await uploadMinimumData(mappedData);
      // Handle success
  } catch (err) {
      if (err.response) {
          const { status, data } = err.response;
          switch (status) {
              case 400:
                  console.error("Bad Request:", data);
                  break;
              case 500:
                  console.error("Internal Server Error:", data);
                  break;
              default:
                  console.error(`Error ${status}:`, data);
          }
      } else if (err.request) {
          console.error("No response received from server:", err.request);
      } else {
          console.error("Error setting up the request:", err.message);
      }
  }
};


export const getMinimumWageDatas = async () => {
  try {
    return await getMinimumWageData();
  } catch (error) {
    // Check if error.response exists before accessing its properties
    if (error.response) {
      console.error('Error fetching work order details data:', error.response.status, error.response.data);
    } else {
      // Handle cases where error.response is undefined (e.g., network issues)
      console.error('Error fetching work order details data:', error.message);
    }
    
    // Return an empty array in case of an error
    return [];
  }
};
