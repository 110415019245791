import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

import Header from './Header';
import { getContractWorkmenByContractorCode } from '../../../../../services/auth';

const RegisterOfContractWorkMenByContractor = (props) => {
  const {
    userConfig,
    contractorList,
    locationList,
    locationwiseContractors,
  } = props;

  const [filters, setFilters] = React.useState({
    contractors: null,
    locations: null,
    dateRange: [],
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const today = dayjs();

  useEffect(() => {
    const initialFilters = {
      dateRange: [today.subtract(1, 'month').startOf('day'), today],
      locations: null
    }
    setFilters(initialFilters)
  }, [])

  const columns = [
    { title: 'Sl. No.', dataIndex: 'slNo', key: 'slNo' },
    { title: 'Workmen ID', dataIndex: 'workmenId', key: 'workmenId' },
    { title: 'Name and surname of workmen', dataIndex: 'name', key: 'name' },
    { title: 'Age and Sex', dataIndex: 'ageSex', key: 'ageSex' },
    { title: 'Father’s / Husband’s name', dataIndex: 'guardianName', key: 'guardianName' },
    { title: 'Nature of Employment/ Designation', dataIndex: 'designation', key: 'designation' },
    { title: 'Permanent Home Address of workmen (Village and Tahsil/Taluk and District)', dataIndex: 'permanentAddress', key: 'permanentAddress' },
    { title: 'Local Address', dataIndex: 'localAddress', key: 'localAddress' },
    { title: 'Date of commencement of employment', dataIndex: 'employmentStartDate', key: 'employmentStartDate' },
    { title: 'Signature or thumb-impression of workmen', dataIndex: 'signature', key: 'signature' },
    { title: 'Date of termination of employment', dataIndex: 'employmentEndDate', key: 'employmentEndDate' },
    { title: 'Reasons for termination', dataIndex: 'terminationReason', key: 'terminationReason' },
    { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
  ];


  const addNewRow = (worksheet, rowData, index) => {
    const newRow = worksheet.addRow();
    newRow.font = { size: 9, name: 'Arial' };
    newRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getCell(`A${index}`).value = rowData[0];
    worksheet.mergeCells(`B${index}:D${index}`);
    worksheet.getCell(`B${index}`).value = rowData[1];
    worksheet.mergeCells(`E${index}:G${index}`);
    worksheet.getCell(`E${index}`).value = rowData[2];
    worksheet.mergeCells(`H${index}:J${index}`);
    worksheet.getCell(`H${index}`).value = rowData[3];
    worksheet.getCell(`K${index}`).value = rowData[4];
    worksheet.getCell(`L${index}`).value = rowData[5];
    worksheet.getCell(`M${index}`).value = rowData[6];
    worksheet.getCell(`N${index}`).value = rowData[7];
    worksheet.getCell(`O${index}`).value = rowData[8];
    worksheet.mergeCells(`P${index}:R${index}`);
    worksheet.getCell(`P${index}`).value = rowData[9];
    worksheet.mergeCells(`S${index}:T${index}`);
    worksheet.getCell(`S${index}`).value = rowData[10];
    worksheet.getCell(`U${index}`).value = rowData[11];
    worksheet.getCell(`V${index}`).value = rowData[12];
  }

  const selectedLocation = userConfig?.organisation?.locations?.filter(l => l.id == filters?.locations)[0]

  const downloadCSV = async () => {
    const documents = data?.workers;

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    // Step 2: Add the image to cell A1
    worksheet.mergeCells('A1:C1');
    const base64Data = userConfig?.organisation?.logoFileData?.replace(/^data:image\/\w+;base64,/, '');
    if (base64Data) {
      // Decode Base64 to binary string using atob
      const binaryString = atob(base64Data);

      // Convert binary string to Uint8Array
      const uint8Array = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      const imageId = workbook.addImage({
        buffer: uint8Array,
        extension: 'png', // Change to 'jpeg' if your image is a JPEG
      });
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 }, // Top-left corner at column 0 (A), row 0 (1)
        br: { col: 3, row: 1 }, // Bottom-right corner at column 1 (B), row 1 (2)
      });
    }

    // Step 3: Adjust the size of cell A1 to fit the image
    const sheetHeaderRow = worksheet.getRow(1)
    sheetHeaderRow.height = 57.6; // Set row height
    sheetHeaderRow.font = { bold: true, size: 10, name: 'Arial' };

    // Step 4: Add the formatted text to a cell
    const headerText = `Contract Labour (Regulation and Abolition) Central Rules\nForm XIII [See Rule 75]\nRegister Of Workmen Employed by Contractor`;
    worksheet.mergeCells('D1:H1');
    worksheet.mergeCells('I1:P1');
    worksheet.mergeCells('R1:V1');

    // Apply formatting to the cell
    const headerCell = worksheet.getCell('I1');
    headerCell.value = headerText;
    headerCell.alignment = {
      vertical: 'middle', // Center vertically
      horizontal: 'center', // Center horizontally
      wrapText: true, // Enable text wrapping
    };

    worksheet.mergeCells('A2:P3');
    worksheet.mergeCells('R2:S2');
    worksheet.mergeCells('R3:S3');
    worksheet.mergeCells('T2:V2');
    worksheet.mergeCells('T3:V3');
    worksheet.getCell('R2').value = 'Executed on :';
    const now = new Date();
    const formattedTime = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    }).replace(/,/g, ''); // Remove commas
    //worksheet.mergeCells('P2:Q2');
    worksheet.getCell('T2').value = formattedTime
    worksheet.getCell('R3').value = 'Printed for:';
    worksheet.getCell('T3').value = userConfig?.userid;
    worksheet.getRow(2).font = { size: 7.5, name: 'Arial' }
    worksheet.getRow(3).font = { size: 7.5, name: 'Arial' }

    worksheet.mergeCells('A4:J4');
    worksheet.getCell('A4').value = 'Name and Address of the Principal Employer:';
    worksheet.mergeCells('K4:V4');
    worksheet.getCell('K4').value = `${selectedLocation?.name || ''},  ${selectedLocation?.address || ''}`;  // location name and address
    worksheet.getRow(4).font = { size: 9, name: 'Arial' }
    worksheet.getCell('A4').font = { bold: true };

    worksheet.mergeCells('A5:J5');
    worksheet.getCell('A5').value = 'Name and Address of Establishment in/under which contract is carried on:';
    worksheet.mergeCells('K5:V5');
    worksheet.getCell('K5').value = `${userConfig?.organisation?.name || ''}, ${userConfig?.organisation?.locations[0]?.address || ''}`; // org name and ho location address
    worksheet.getRow(5).font = { size: 9, name: 'Arial' }
    worksheet.getCell('A5').font = { bold: true };

    worksheet.mergeCells('A6:J6');
    worksheet.getCell('A6').value = 'Nature and Location of work:';
    worksheet.mergeCells('K6:V6');
    worksheet.getCell('K6').value = `${data?.contractorDetails?.natureOfWork || ''} ${data?.contractorDetails?.natureOfWork && ','} ${selectedLocation?.address || ''}`;
    worksheet.getRow(6).font = { size: 9, name: 'Arial' }
    worksheet.getCell('A6').font = { bold: true };

    worksheet.mergeCells('A7:J7');
    worksheet.getCell('A7').value = 'Name and Address of Contractor:';
    worksheet.mergeCells('K7:V7');
    worksheet.getCell('K7').value = data?.contractorDetails?.nameAndAddress;
    worksheet.getRow(7).font = { size: 9, name: 'Arial' }
    worksheet.getCell('A7').font = { bold: true };

    // Step 5: Define the table headers
    const tableHeaders = [
      'Sl. No.',
      'Workmen ID',
      'Name and surname of workmen',
      'Age and Sex',
      'Father’s / Husband’s name',
      'Nature of Employment/ Designation',
      'Permanent Home Address of workmen (Village and Tahsil/Taluk and District)',
      'Local Address',
      'Date of commencement of employment',
      'Signature or thumb-impression of workmen',
      'Date of termination of employment',
      'Reasons for termination',
      'Remarks'
    ];

    worksheet.addRow()

    // Add headers to the worksheet
    addNewRow(worksheet, tableHeaders, 8)
    const headerRow = worksheet.getRow(8);
    headerRow.font = { bold: true, size: 9, name: 'Arial' };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };


    // Step 6: Add table data
    documents.forEach((doc, index) => {
      const rowData = [
        doc.slNo, // Sl. No.
        doc.workmenId,
        doc.name,
        doc.ageSex,
        doc.guardianName,
        doc.designation,
        doc.permanentAddress,
        doc.localAddress,
        doc.employmentStartDate,
        doc.signature,
        doc.employmentEndDate,
        doc.terminationReason,
        doc.remarks
      ];

      addNewRow(worksheet, rowData, 9 + index)
    });

    // Step 7: Set column widths
    worksheet.columns = [
      { width: 15.33 },
      { width: 1.5 },
      { width: 3 },
      { width: 12 },
      { width: 3 },
      { width: 4 },
      { width: 6.67 },
      { width: 2.78 },
      { width: 6.00 },
      { width: 3.56 },
      { width: 13.22 },
      { width: 15.33 },
      { width: 15.33 },
      { width: 15.33 },
      { width: 15.33 },
      { width: 10.4 },
      { width: 0 },
      { width: 4.11 },
      { width: 6.33 },
      { width: 8.22 },
      { width: 13.67 },
      { width: 14.22 },
    ];

    // Step 8: Add borders to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Step 9: Write the workbook to a file and trigger
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filters?.contractors} - Register of Contract Workmen employed by Contractor.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  const applyFilters = async (filters) => {
    setLoading(true);

    try {
      const { dateRange, locations, contractors } = filters;
      const response = await getContractWorkmenByContractorCode({
        location: locations,
        contractorName: contractors,
        startDate: dateRange[0],//getLocalDate(dateRange[0]),
        endDate: dateRange[1]//getLocalDate(dateRange[1])
      });
      const data = response?.data?.data
      setData(data);
    } catch (error) {
      setData({});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        downloadCSV={downloadCSV}
        userConfig={userConfig}
        contractorList={contractorList}
        locationList={locationList}
        applyFilters={applyFilters}
        loading={loading}
        disableDownload={!data?.workers?.length || loading}
        locationwiseContractors={locationwiseContractors}
      />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={data?.workers}
          scroll={{ x: true }}
          bordered
          title={() => (<></>)}
          pagination={{ pageSize: 50 }}
        />
      </Spin>
    </>
  );
};

export default RegisterOfContractWorkMenByContractor;
