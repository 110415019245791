import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Form } from 'antd';
import { SearchOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getLaborRequisitionDetailsData, getLrSearchData } from '../services/actions/api/laborRequisitionsDetails'
import moment from 'moment';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { useParams, useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;

const LaborRequisitionList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const { userConfig } = useAuth();

  const [filters, setFilters] = useState({
    search: '',
    lrNumber: null,
    status: null,
    contractorName:null,
    validFrom: null,
    validTo: null,
  });

  // const [jsonData, setJsonData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [isWorkOrder, setIsWorkOrder] = useState(userConfig.level==="sectionHead");
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [form] = Form.useForm();

  // ✅ Fetch Data Function with Pagination
  const fetchData = async (page = 1, limit = 10) => {
    console.log(`Fetching data: Page ${page}, Limit ${limit}`);
    setLoading(true);

    try {
      const response = await getLaborRequisitionDetailsData(page, limit);
  
      // Check if response contains expected data
      if (!response || !response.data || !Array.isArray(response.data)) {
        console.warn("Invalid or missing data in API response.");
        setTableData([]); // Clear table if response is invalid
        return;
      }
  
      // Ensure total records are correctly set
      const totalRecordsCount = response?.pagination?.totalDocuments || response.data.length;
  
      const dataWithKeys = response.data.map((item, index) => ({
        ...item,
        key: item.id || `${page}-${index}`,
      }));
  
      setTableData(dataWithKeys);
      setFilteredData(dataWithKeys);
      setTotalRecords(totalRecordsCount);
  
    } catch (error) {
      console.error("Error fetching labor requisition details:", error);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Fetch Data When Component Mounts or Page Changes
  useEffect(() => {
    const storedUserId = sessionStorage.getItem("userId");
    setUserId(storedUserId);
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    // Filter data whenever search criteria changes
    handleSearch();
  }, [filters.search, filters.lrNumber, filters.status, filters.contractorName, filters.validFrom, filters.validTo]); // Trigger whenever filters.search changes

  
  const handleSearch = async () => {
    const res = await getLrSearchData()
    let data = [...res]; // Make a copy of tableData
 
    if (filters.search) {
      data = data.filter((item) =>
        [
          item.lrNumber,
          item.workOrderNumber,
          item.jobName,
          item.contractorName,
          item.sectionHead,
          item.status,
        ].some((field) =>
          field?.toString().toLowerCase().includes(filters.search.toLowerCase())
        )
      );
    }
    if (filters.lrNumber) {
      data = data.filter(item => item.lrNumber.includes(filters.lrNumber));
    }
    if (filters.status) {
      data = data.filter(item => item.status.includes(filters.status));
    }
    if (filters.contractorName) {
      data = data.filter(item => item.contractorName.includes(filters.contractorName));
    }
    if (filters.validFrom) {
      data = data.filter(item =>
        moment(item.validFrom, 'DD-MM-YYYY').isSameOrAfter(filters.validFrom)
      );
    }
    if (filters.validTo) {
      data = data.filter(item =>
        moment(item.validTo, 'DD-MM-YYYY').isSameOrBefore(filters.validTo)
      );
    }
 
    setFilteredData(data);
  };
  
  const handleReset = () => {
    setFilters({
      lrNumber:'',
      search: '',
      unit: '',
      status: '',
      contractorName: '',
      validFrom: '',
      validTo: '',
    });
    setFilteredData(tableData);

    // Manually reset form fields immediately after state update
    form.setFieldsValue({
      lrNumber:null,
      search: '',
      unit: null,
      status: null,
      contractorName: null,
      validFrom: null,
      validTo: null,
    });
  };

  const uniqueStatus = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.status))]:[];
  const uniqueLRNumber = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.lrNumber))]:[];
  const uniqueContractorName = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.contractorName))]:[];

  const columns = [
    {
      title: 'LR Number',
      dataIndex: 'lrNumber',
      key: 'lrNumber',
      render: (text, record) => {
        return !isWorkOrder ? (
        <Link 
          to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {record.lrNumber}
        </Link>
        ): 
        (
          <span>{record.lrNumber}</span>
        );
        // handleView(record)
      },
    },
    {
      title: 'Job Description',
      dataIndex: 'jobName',
      key: 'jobName',
      render: (text, record) => {
        return !isWorkOrder ? ( 
        <Link 
          to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
        {record.jobName}
        </Link>
        )
        :(
          <span>{record.jobName}</span>
        )
    },
    },
    {
      title: 'Section Head',
      dataIndex: 'sectionHead',
      key: 'sectionHead',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
        {/* {userId} */}
        {record.sectionHead}
        </Link>
         )
         :(
           <span>{record.sectionHead}</span>
         )
        },
    },
    {
      title: 'Work Order Number',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
          {record.workOrderNumber}
          </Link>
           )
           :(
             <span>{record.workOrderNumber}</span>
           )
          },
      },
    {
      title: 'LR Valid From ',
      width:'10%',
      dataIndex: 'validFrom',
    //   render: (text, record) => `${record.validFrom} - ${record.validTo}`,
      key: 'validFrom',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
          {record.validFrom}
          </Link>
          // handleView(record)
          )
          :(
            <span>{record.validFrom}</span>
          )
       },
    },
    {
      title: 'LR Valid To ',
      dataIndex: 'validTo',
      width:'10%',
      // render: (text, record) => `${record.validFrom} - ${record.validTo}`,
      key: 'validTo',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
          {record.validTo}
          </Link>
        // handleView(record)
        )
        :(
          <span>{record.validTo}</span>
        )
      }
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
          {record.contractorName}
          </Link>
        // handleView(record)
        )
        :(
          <span>{record.contractorName}</span>
        )
      }
    },
    // {
    //   title: 'Unit Name',
    //   dataIndex: 'unitName',
    //   key: 'unitName',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return !isWorkOrder ? ( 
          <Link 
            to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
          {record.status}
          </Link>
        // handleView(record)
        )
        :(
          <span>{record.status}</span>
        )
      }
    },
  ];

  const handleView = (record) => {
    console.log('Viewing record:', record);
    // Implement the desired functionality for viewing the record here
    // For example, navigate to a detailed view page:
    navigate(`/laborRequisitionList/lr/${record.lrNumber}`);
  };

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Labor Requisition List</span>
        </Col>
        <Col span={12}>
        <Form form={form}>
            <Form.Item name="search" initialValue={filters.search}>
          <Input
            placeholder="Search"
            // value={filters.search}
            onChange={e => setFilters({ ...filters, search: e.target.value })}
            style={{ width: '100%' }}
            suffix={<SearchOutlined />}
          />
          </Form.Item>
        </Form>
        </Col>
        {/* <Col span={6} style={{ textAlign: 'right' }}>
          <Upload customRequest={customRequest} beforeUpload={beforeUpload} showUploadList={false}>
            <Button type="primary" icon={<UploadOutlined />}>
              Import Work Order
            </Button>
          </Upload>
        </Col> */}
      </Row>
      <div style={{ backgroundColor: '#F4F6FF', border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
      <Form form={form}> 
        <Row gutter={16}>
            <Col span={4}>
            <Form.Item name="lrNumber" initialValue={filters.lrNumber}>
            <Select
                placeholder="LR Number"
                // value={filters.lrNumber}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, lrNumber: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
              {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                <Option key={element.lrNumber} value={element.lrNumber}>{element.lrNumber}</Option>
              ))} */}
              {uniqueLRNumber.map(lrNumber => (
                  <Option key={lrNumber} value={lrNumber}>
                    {lrNumber}
                  </Option>
                ))}
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="status" initialValue={filters.status}>
            <Select
                placeholder="Status"
                // value={filters.status}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, status: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
               {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                  <Option key={element.status} value={element.status}>{element.status}</Option>
               ))} */}
               {uniqueStatus.map(unit => (
                  <Option key={unit} value={unit}>
                    {unit}
                  </Option>
                ))}
                {/* <Option value="Status1">Status1</Option>
                <Option value="Status2">Status2</Option> */}
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="contractorName" initialValue={filters.contractorName}>
            <Select
                placeholder="Contractor Name"
                // value={filters.contractorName}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, contractorName: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
               {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                  <Option key={element.contractorName} value={element.contractorName}>{element.contractorName}</Option>
                ))} */}
                {uniqueContractorName.map(contractorName => (
                  <Option key={contractorName} value={contractorName}>
                    {contractorName}
                  </Option>
                ))}
                {/* <Option value="ADM">ADM</Option>
                <Option value="HR">HR</Option> */}
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validFrom" initialValue={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid From"
                style={{ width: '100%' }}
                // value={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}
                // onChange={date => setFilters({ ...filters, validFrom: date })}
                format="DD-MM-YYYY"
                onChange={(date, dateString) => setFilters({ ...filters, validFrom: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validTo" initialValue={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid To"
                style={{ width: '100%' }}
                // value={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}
                // onChange={date => setFilters({ ...filters, validTo: date })}
                format="DD-MM-YYYY"
                onChange={(date, dateString) => setFilters({ ...filters, validTo: dateString })}
            />
            </Form.Item>
            </Col>
            {/* <Col span={3}>
            <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
                style={{ width: '100%' }}
            >
                Search
            </Button>
            </Col> */}
            <Col span={3}>
            <Button
                icon={<ReloadOutlined />}
                onClick={handleReset}
                style={{ width: '100%' }}
            >
                Reset
            </Button>
            </Col>
        </Row>
        </Form>
      </div>
      {filteredData && (
      <Table 
        columns={columns} 
        dataSource={filteredData} 
        rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          onChange: (page) => {
            console.log("Page changed to:", page);
            setCurrentPage(page);
            fetchData(page, pageSize); // ✅ Fetch new page
          },
          onShowSizeChange: (_, size) => {
            console.log("Page size changed to:", size);
            setPageSize(size);
            setCurrentPage(1); // ✅ Reset to first page on size change
            fetchData(1, size);
          },
        }} 
      />
      )}
    </div>
  );
};

export default LaborRequisitionList;
