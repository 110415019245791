import React, { useEffect, useState } from 'react';
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  Input,
  Form,
  Modal,
  notification,
  Row,
  Radio,
  Tag,
  Select
} from 'antd';
import {
  PlusOutlined,
  DeleteFilled,
  EditFilled,
  EyeFilled,
} from '@ant-design/icons';
import {
  createSchedule,
  deleteSchedule,
  getAllSchedules,
  updateSchedule,
} from '../../services/auth';

const scheduleStatusList = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
const schedulesMasterData = [
  { label:'Update workman status pre shift', id: 'UPDATE_WORKMAN_STATUS_PRE_SHIFT', description: 'For workman status update before shift start.' },
  { label:'Update workman status post shift', id: 'UPDATE_WORKMAN_STATUS_POST_SHIFT', description: 'For workman status update after shift ends.' },
  // { label:'Permanent Workman', id: 'PERMANENT_WORKMAN', description: 'For permanent workman SFTP integration.' },
  // { label:'Contract Workman', id: 'CONTRACT_WORKMAN', description: 'For contract workman SFTP integration.' },
  // { label:'Reviewer Staff', id: 'REVIEWER_STAFF', description: 'For reviewer staff SFTP integration.' },
]
const { Option } = Select;

const SchedulesList = (props) => {
  const { setViewHistory } = props;
  const [schedulesData, setSchedulesData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAddMode, setIsAddMode] = useState(false);

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  var removeTableData = () => {}

  useEffect(() => {
    getSchedulesData();
  }, []);

  const getSchedulesData = async () => {
    const response = await getAllSchedules();
    const data = response?.data?.data
    form.setFieldsValue({
      schedules: data,
    });
    setSchedulesData(response?.data?.data);
  };

  const handleEdit = (row, isAddModeVar) => {
    setSelectedRow(row);
    modalForm.setFieldsValue(
      isAddModeVar
        ? {
            id: '',
            description: '',
            cronSchedule: '',
            status: ''
          }
        : schedulesData[row.key]
    );
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    modalForm.validateFields().then(async (values) => {
      const schedules = form.getFieldValue('schedules');
      const { label, description } = schedulesMasterData?.filter(s => s.id == values.id)[0]
      let data = {};
      const updatedSchedules = schedules.map((schedule, index) => {
        if (index === selectedRow.key) {
          data = schedule;
          return { ...schedule, ...values, label, description };
        }
        return schedule;
      });
      
      if (isAddMode) {
        let createResponse = await createSchedule({...values, label, description});
        updatedSchedules[updatedSchedules.length - 1]._id = createResponse?.data?._id;
        setIsAddMode(false);
      } else {
        await updateSchedule({ ...values, _id: data._id });
      }

      setSchedulesData(updatedSchedules)
      form.setFieldsValue({ schedules: updatedSchedules });
      setIsModalVisible(false);
      setSelectedRow(null);
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
    if(isAddMode) {
      removeTableData(schedulesData.length)
      setIsAddMode(false);
    }
  };

  const handleDelete = async (remove, record) => {
    const schedules = form.getFieldValue('schedules');
    const data = schedules[record.name];
    try {
      await deleteSchedule(data?._id);
      remove(record.name);
      setSchedulesData(form.getFieldValue('schedules'))
      notification.success({ message: 'Successfully deleted the report.' });
    } catch (error) {
      notification.error({ message: 'Error while deleting report.' });
    }
  };

  const handleView = (record) => {
    setSelectedRow(record);
    setViewHistory(schedulesData[record.name]);
  };

  const columns = (remove) => {
    removeTableData = remove;
    return [
      {
        title: 'S.No.',
        dataIndex: 'index',
        width: 70,
        render: (_, __, index) => <span>{index + 1}</span>,
      },
      {
        title: 'Schedule Name',
        dataIndex: 'label',
        width: 400,
        render: (_, record) => (
          <Form.Item name={[record.name, 'label']}>
            <Input disabled />
          </Form.Item>
        ),
      },
      {
        title: 'Cron Schedule',
        dataIndex: 'cronSchedule',
        width: 200,
        render: (_, record) => (
          <Form.Item name={[record.name, 'cronSchedule']}>
            <Input disabled />
          </Form.Item>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (_, record) => {
          // Access the status value from the form's state
          const status = schedulesData[record.key]?.status;
          const isActive = ['active', undefined].includes(status);
          return (
            <Tag style={{ marginBottom: '20px' }} color={isActive ? 'green' : 'orange'}>
              {isActive ? 'Active' : 'Inactive'}
            </Tag>
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, record) => (
          <Row style={{ marginTop: '-20px' }}>
            <Typography.Link onClick={() => handleView(record)}>
              <EyeFilled />
            </Typography.Link>
            <Typography.Link
              style={{ margin: '0px 10px 0px 10px' }}
              onClick={() => handleEdit(record, false)}>
              <EditFilled />
            </Typography.Link>
            <Popconfirm
              title='Delete'
              onConfirm={() => handleDelete(remove, record)}>
              <Typography.Link>
                <DeleteFilled />
              </Typography.Link>
            </Popconfirm>
          </Row>
        ),
      },
    ];
  }

  const renderFooter = (add) => (
    <Button
      type='dashed'
      htmlType='button'
      onClick={() => {
        add();
        setIsAddMode(true);
        handleEdit(
          {
            key: form.getFieldsValue('schedules')?.schedules?.length - 1,
          },
          true
        );
      }}
      block
      icon={<PlusOutlined />}>
      {`Add Schedule`}
    </Button>
  );

  return (
    <>
      <Form
        form={form}
        initialValues={{
          schedules: schedulesData,
        }}
        layout='vertical'>
        <Form.List name='schedules'>
          {(fields, { add, remove }) => (
            <Table
              columns={columns(remove)}
              dataSource={fields.map((field) => ({
                ...field,
                key: field.key,
              }))}
              //style={{ maxWidth: '800px' }}
              footer={() => renderFooter(add)}
              pagination={false}
              locale={{
                emptyText: `No schedule added.`,
              }}
            />
          )}
        </Form.List>
      </Form>

      {/* Edit Modal */}
      <Modal
        title={isAddMode ? 'Add Schedule' : 'Edit Schedule'}
        open={isModalVisible}
        onOk={handleModalOk}
        maskClosable={false} // Prevent closing on outside click
        onCancel={handleModalCancel}>
        <Form form={modalForm} layout='vertical'>
          <Form.Item
            name='id'
            label='Schedule Name'
            rules={[{ required: true, message: 'Please select a schedule name' }]}>
            <Select placeholder="Select a schedule name">
              {
                schedulesMasterData?.map(s =>
                  <>
                    <Option value={s.id}>{s.label}</Option>
                    <Input.TextArea rows={4} disabled  value={s.description}/>
                  </>
              )
              }
            </Select>
          </Form.Item>
          <Form.Item
            name='cronSchedule'
            label='Cron Schedule'
            rules={[{ required: true, message: 'Please enter Cron schedule' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please select one status' }]}
          >
            <Radio.Group>
              {scheduleStatusList.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SchedulesList;
