//     [2020] - [2024] Wikiworks Innovations Private Limited.
//  All Rights Reserved.

// NOTICE:  All information contained herein is, and remains
// the property of Wikiworks its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Wikiworks
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process and are protected by trade secret or copyright
// law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Wikiworks.
// """

import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Space,
  Collapse,
  notification,
  Typography,
  Modal,
  Divider,
  Image,
  message,
  Tooltip,
  Upload,
  Table,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import axios1 from '../../services/axiosInstance';
import Webcam from 'react-webcam';
import {
  SendOtp,
  callCheckAadharUniquenessApi,
  EpnRenew,
  getContractWorkmenEpnNumber,
  getContractWorkmenWorkerCodeNumber,
} from '../../services/auth';
import { VerifyOtp } from '../../services/auth';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { State } from '../../JSON/data';
import {
  Dadra,
  Delhi,
  Jharkhand,
  Karnataka,
  AndhraPradesh,
  ArunachalPradesh,
  Assam,
  Bihar,
  Chhattisgarh,
  Chandigarh,
  Goa,
  Gujarat,
  Haryana,
  HimachalPradesh,
  Jammu,
  Kerala,
  Lakshadweep,
  MadhyaPradesh,
  Maharashtra,
  Manipur,
  Meghalaya,
  Mizoram,
  Nagaland,
  Odisha,
  Punjab,
  Puducherry,
  Rajasthan,
  TamilNadu,
  Telangana,
  Tripura,
  Uttarakhand,
  UttarPradesh,
  WestBengal,
  Sikkim,
} from '../../JSON/data';
import { Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { init } from 'echarts';
import debounce, { getLocalDate, getUTCDate } from '../../utils/helperFunction';
import { setLoader } from '../../actions';
import CustomFields from '../customFields';
import { getAppStore } from '../../store';
import getLabel from '../../utils/getLabel';
import moment from 'moment';
import {
  updatePass,
  getWorkmenList,
  getWorkmenLimit,
} from '../../services/auth';
import { getSignedUrl } from '../../services/auth';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

var convert = require('xml-js');

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;
//Sachin Code
const getPassDatesForEpnRenewal = (
  SafetyDate,
  WCPdate,
  LaborLicenseDate,
  PMEdate,
  WOdate
) => {
  const currentDate = new Date();
  let sixMonthsFromToday = new Date(currentDate);
  sixMonthsFromToday.setDate(currentDate.getDate() + 180);
  sixMonthsFromToday = sixMonthsFromToday.getTime();

  const safety = SafetyDate ? new Date(SafetyDate).getTime() : null;
  const WCP = WCPdate ? new Date(WCPdate).getTime() : null;
  const LaborLicense = LaborLicenseDate
    ? new Date(LaborLicenseDate).getTime()
    : null;
  const PME = PMEdate ? new Date(PMEdate).getTime() : null;
  const WO = WOdate ? new Date(WOdate).getTime() : null;

  // Compare the dates and get the minimum
  let passExtendedUpto = Infinity;
  if (safety) passExtendedUpto = Math.min(passExtendedUpto, safety);
  if (WCP) passExtendedUpto = Math.min(passExtendedUpto, WCP);
  if (LaborLicense) passExtendedUpto = Math.min(passExtendedUpto, LaborLicense);
  if (PME) passExtendedUpto = Math.min(passExtendedUpto, PME);
  if (WO) passExtendedUpto = Math.min(passExtendedUpto, WO);

  const passValidUpto = Math.min(passExtendedUpto, sixMonthsFromToday);

  return { passExtendedUpto, passValidUpto };
};

const unitGateMapping = {
  haziraEast: 'GATE 2',
  haziraWest: 'GATE 5',
};

const Personaldetails = ({
  initialValues,
  onNext,
  setImageSrc,
  imageSrc,
  masterData,
  onUpdateFields,
  onUpdateNewData,
  epn,
  jobDetails,
  wageDetails,
  uploadDocument,
}) => {
  const { user, userConfig } = useAuth();

  const shouldDisplayEpnButton = epn === 'No';

  const { id } = useParams();

  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled =
    userConfig.role === 'supervisor' && initialValues?.approvalStage >= 0;

  // const isDisabled = initialValues?.approvalStage > 0 && userConfig.role !== "supervisor";

  const formRef = React.useRef();
  const formRef1 = React.useRef();
  const [form] = Form.useForm();
  const [location, setlocation] = React.useState({});
  const [values, setValues] = React.useState(initialValues);
  const [selected, setSelected] = React.useState('environment');
  const [livePhoto, setlivePhoto] = React.useState('');
  const [uploadProfilePhoto, setUploadProfilePhoto] = React.useState('');
  const [aadharPhoto, setaadharPhoto] = React.useState('');
  const [isFetchedFromAadhar, setISFetchedFromAadhar] = React.useState(false);
  const [Isworking, setIsworking] = React.useState(true);
  const [IsworkingBio, setIsworkingBio] = React.useState(true);
  const [updatedEpn, setUpdatedEpn] = React.useState('');
  const [ranoliEpnNumber, setRanoliEpnNumber] = React.useState('RNW1');
  const [locationChangedId, setLocationId] = useState(null);

  const [disableAddress, setDisableAddress] = useState({});
  // const [masterDataState, setmasterDataState] = React.useState(
  //   masterData || [] // Set masterData to the provided value or an empty object if not provided

  // );

  //
  const [statusOfRenewEPNButton, setStatusOfRenewEPNButton] = useState(false);
  const [IsworkingHandicaped, setIsworkingHandicaped] = React.useState(true);
  const [workingArea, setWorkingArea] = React.useState([]);
  const [gateNumber, setGateNumber] = React.useState([]);
  const [technicalDetails, settechnicalDetails] = React.useState([]);
  const [natureOfWork, setnatureOfWork] = React.useState([]);
  const [ranoliWorkmenCode, setRanoliWorkmenNumber] = React.useState('WC1');
  const [tableData, setTableData] = useState([]);
  const [blockType, setBlockType] = useState(
    initialValues?.blockType || 'permanent'
  );
  const [renewingEpn, setRenewingEpn] = useState(false);

  const { customFields = [] } = getAppStore();

  const [disabledButton, setDisabledButton] = React.useState({
    sendOtp: initialValues && initialValues.aadharNo ? false : true,
    verifyOtp: true,
  });

  const [aadharNumber, setAadharNumber] = React.useState('');
  const [isAadharValid, setIsAadharValid] = React.useState(false);
  const [isExistingEntry, setIsExistingEntry] = React.useState(
    initialValues?.aadharNo
  );
  const [selectedGateNumber, setSelectedGateNumber] = useState();
  const [modalForm] = Form.useForm();
  const [agencyEsicNo, setAgencyEsicNo] = useState();

  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const BiometricDisable = () => {
    setIsworkingBio(!IsworkingBio);
  };

  const IsworkingHandicapped = () => {
    form.setFieldValue('disabilityType', '');
    form.setFieldValue('disabilityPercentage', '');

    setIsworkingHandicaped(!IsworkingHandicaped);
  };
  const PmeCheckbox = (e) => {};

  const setExemptStatus = () => {
    let uanDisabled =
      !id || (id && initialValues?.uan)
        ? initialValues?.Uan
          ? true
          : false
        : true;
    let PfDisabled =
      !id || (id && initialValues?.pfAccountNo)
        ? initialValues?.pf
          ? true
          : false
        : true;
    let esicDisabled =
      !id || (id && initialValues?.esicno)
        ? initialValues?.esicexempt
          ? true
          : false
        : true;

    setUanDisabled(uanDisabled);
    setPfDisabled(PfDisabled);
    setEsicDisabled(esicDisabled);
  };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName == 'unitName' && ['haziraWest', 'haziraEast'].includes(value))
      setSelectedGateNumber(unitGateMapping[value]);
    else if (fieldName == 'gateNumber') setSelectedGateNumber(value);

    form.setFieldsValue({ [fieldName]: value });
    onUpdateFields?.(fieldName, value);
  };

  useEffect(() => {
    form.setFieldsValue({ gateNumber: selectedGateNumber });
  }, [selectedGateNumber]);

  useEffect(() => {
    EmployeeList();
    setExemptStatus();
  }, []);
  async function EmployeeList() {
    const Response = await axios1.get('/org/locations');

    const Response2 = Response.data.org.locations;
    setlocation({ Response2 });
  }

  const profileImagePreview = async () => {
    if (initialValues?.aadharPhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.aadharPhotoGcpPath)
      );
      setaadharPhoto(data?.data?.data);
    }
    if (initialValues?.livePhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.livePhotoGcpPath)
      );
      setlivePhoto(data?.data?.data);
    }
    if (initialValues?.uploadPhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.uploadPhotoGcpPath)
      );
      setUploadProfilePhoto(data?.data?.data);
    } else {
      return;
    }
  };

  useEffect(() => {
    profileImagePreview();
  }, [initialValues]);

  useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
    formRef1.current?.setFieldsValue(
      initialValues?.epnRenewHistory[initialValues?.epnRenewHistory.length - 1]
    );
    setExemptStatus();
    setISFetchedFromAadhar(initialValues?.isFetchedFromAadhar);
    setEmploymentStatus(initialValues?.statusToBeImposed);
    // form.setFieldsValue({statusToBeImposed: initialValues?.statusToBeImposed ? initialValues?.statusToBeImposed : initialValues?.e})
    let tempStatus = [
      'Hibernate',
      'inactive',
      'rejected',
      'terminated',
      'blocked',
      'blacklisted',
      'retired',
    ].includes(initialValues?.status)
      ? initialValues?.status?.charAt(0)?.toUpperCase() +
        initialValues?.status?.slice(1)
      : 'Active';
    console.log('339 ', tempStatus, initialValues?.status);
    form.setFieldsValue({
      statusToBeImposed: initialValues?.statusToBeImposed
        ? initialValues?.statusToBeImposed
        : tempStatus,
    });
    setBlockType(initialValues?.blockType || 'permanent');
    setSelectedGateNumber(initialValues?.GateNumber);

    if (initialValues?.isFetchedFromAadhar) {
      setDisableAddress({
        address1: true,
        address2: true,
        address3: true,
        village: true,
        district: true,
        state: true,
        city: true,
        taluka: true,
        tehsil: true,
        pincode: true,
      });
    }
    form.setFieldsValue({
      statusFromDate: getUTCDate(initialValues?.statusFromDate),
      statusToDate: getUTCDate(initialValues?.statusToDate),
    });
  }, [initialValues]);

  const [startScan, setStartScan] = React.useState(false);
  const [loadingScan, setLoadingScan] = React.useState(false);
  const [data, setData] = useState();
  const [date1, setdate1] = React.useState({});
  const [state, setstate] = React.useState({});
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [otploading, setotploading] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [isAadharChecked, setIsAadharChecked] = useState(false);
  const [ViewOnly, setViewOnly] = useState(false);
  const navigate = useNavigate();

  const handleScan = async (scanData) => {
    setViewOnly(true);
    setLoadingScan(true);
    if (scanData && scanData !== '') {
      const xmlDocument1 = new DOMParser().parseFromString(
        scanData,
        'text/xml'
      );
      var xml = scanData;
      var result1 = convert.xml2js(xml, { compact: true, spaces: 4 });
      const fullName = result1.PrintLetterBarcodeData._attributes.name;
      const parts = fullName.split(' ');
      const lastName = parts.pop();
      const firstName = parts.join(' ');
      const gender = result1.PrintLetterBarcodeData._attributes.gender;
      const yob = result1.PrintLetterBarcodeData._attributes.yob;
      const dob = dayjs(
        result1.PrintLetterBarcodeData._attributes.dob,
        'DD-MM-YYYY'
      );
      var theAge = 2023 - yob;
      const uid = result1.PrintLetterBarcodeData._attributes.uid;
      const endNum = uid.slice(-4);
      const maskNum = endNum.padStart(uid.length, '*');
      const co = result1.PrintLetterBarcodeData._attributes.co;
      const house = result1.PrintLetterBarcodeData._attributes.house;
      const street = result1.PrintLetterBarcodeData._attributes.street;
      const lm = result1.PrintLetterBarcodeData._attributes.lm;
      const loc = result1.PrintLetterBarcodeData._attributes.loc;
      const village = result1.PrintLetterBarcodeData._attributes.vtc;
      const dist = result1.PrintLetterBarcodeData._attributes.dist;
      const state = result1.PrintLetterBarcodeData._attributes.state;
      const pc = result1.PrintLetterBarcodeData._attributes.pc;
      const address =
        result1.PrintLetterBarcodeData._attributes.house +
        ', ' +
        street +
        ', ' +
        lm +
        ', ' +
        loc;
      setdate1({ dob });
      setData(xml);
      setStartScan(false);
      setLoadingScan(false);

      setstate({ state });

      form.setFieldsValue({
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        aadharNo: maskNum,
        fatherName: co,
        address: address,
        village: village,
        district: dist,
        state: state,
        pincode: pc,
        age: theAge,
        dob: dob,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  function formatDate2(date) {
    console.log(date, 'date 476');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const onFinish = (values) => {
    console.log('values391', values);
    form.setFieldValue(
      'Dob',
      values.Dob
        ? dayjs(new Date(values.Dob)).local().format('YYYY-MM-DD')
        : initialValues?.Dob
    );

    if (isFetchedFromAadhar || initialValues?.isFetchedFromAadhar) {
      form.setFieldValue(
        'dob',
        values.dob
          ? dayjs(new Date(values.Dob)).local().format('YYYY-MM-DD')
          : initialValues?.dob
      );
      // form.setFieldValue('Dob', dayjs(new Date(values.Dob)).local().format('YYYY-MM-DD')? dayjs(new Date(values.Dob)).local().format('YYYY-MM-DD'):  initialValues?.Dob)
      form.setFieldValue(
        'Dob',
        values.Dob
          ? dayjs(new Date(values.Dob)).local().format('YYYY-MM-DD')
          : initialValues?.Dob
      );
      console.log(
        'DOB dob ',
        form.getFieldValue('Dob'),
        form.getFieldValue('dob')
      );
    }

    values.aadharPhoto = uidai?.profilePic
      ? 'data:image/png;base64,' + uidai?.profilePic
      : initialValues?.aadharPhoto || '';
    values.livePhoto = imageSrc || initialValues?.livePhoto;
    // values.uploadPhoto = previewImage || initialValues?.uploadPhoto

    if (
      !(values.firstName || initialValues.firstName) ||
      !(values.locationId || initialValues.locationId)
    ) {
      message.error('Please open all the section to proceed.');
      return false;
    }

    values.isFetchedFromAadhar = isFetchedFromAadhar;
    values.aadhaarRequestID = Request?.requestId;
    values.statusFromDate = values?.statusFromDate
      ? getLocalDate(values.statusFromDate)
      : '';
    values.statusToDate = values?.statusToDate
      ? getLocalDate(values.statusToDate)
      : '';
    values.statusTerminateNoticeDate = values?.statusTerminateNoticeDate
      ? getLocalDate(values.statusTerminateNoticeDate)
      : '';

    // return 0;
    onNext(values);
  };

  const [UanDisabled, setUanDisabled] = useState();
  const [PfDisabled, setPfDisabled] = useState();
  const [FormDisabled, setFormDisabled] = useState();

  const [EsicDisabled, setEsicDisabled] = useState();

  useEffect(() => {
    form.setFieldValue('esicexempt', EsicDisabled);
    form.setFieldValue('pf', PfDisabled);
    form.setFieldValue('Uan', UanDisabled);
  }, [EsicDisabled, PfDisabled, UanDisabled]);
  const [MaritalDisabled, setMaritalDisabled] = useState(true);
  const [date, setDate] = useState(new Date());
  const [uidai, setuidai] = useState({});
  const [adhar, setadhar] = useState('');
  const [otp, setotp] = useState('');
  const [Request, setRequest] = useState({});
  const [disable, setDisable] = React.useState(false);
  const [otpdisable, setotpDisable] = React.useState(false);
  const [consent, setconsent] = React.useState(true);
  const [openedAccordian, setOpenAccordian] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [cardLost, setCardLost] = useState('');

  const onChange1 = (date) => {
    setDate(date);
  };

  //on preview Usestate
  /* eslint-disable */

  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [presentAdress, setpresentAdress] = useState({
    address: '',
    village: '',
    district: '',
    state: '',
    pincode: '',
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setpresentAdress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleFieldChange(name, value);
    // handleFieldChange('address1', e.target.value);
  };

  const agecalculated = (e) => {};

  const Uantoggle = () => {
    form.setFieldValue('uan', '');
    setUanDisabled(!UanDisabled);
  };
  const Pftoggle = (e) => {
    form.setFieldValue('pfAccountNo', '');
    setPfDisabled(!PfDisabled);
  };
  // const toggle = (e) => {
  //   form.setFieldValue('pfAccountNo', '');
  //   setPfDisabled(!PfDisabled);
  // };
  const Esictoggle = (e) => {
    form.setFieldValue('esicno', '');
    // form.setFieldValue('esicexempt', e.target.value);
    setEsicDisabled(!EsicDisabled);
  };
  const Maritaltoggle = (values) => {
    if (Option.value == values.married) {
      setMaritalDisabled(!MaritalDisabled);
    } else {
      setMaritalDisabled(MaritalDisabled);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);
  function addressFunction(e) {
    if (e.target.checked) {
      form.setFieldsValue({
        pstate: form.getFieldValue('state'),
        // paddress: form.getFieldValue('address'),
        paddress1: form.getFieldValue('address1'),
        paddress2: form.getFieldValue('address2'),
        paddress3: form.getFieldValue('address3'),
        pvillage: form.getFieldValue('village'),
        pdistrict: form.getFieldValue('district'),
        ppincode: form.getFieldValue('pincode'),
        ptaluka: form.getFieldValue('taluka'),
        ptehsil: form.getFieldValue('tehsil'),
        pcity: form.getFieldValue('city'),
        pcountry: form.getFieldValue('country'),
      });
    } else {
      form.setFieldsValue({
        pstate: form.getFieldValue(''),
        // paddress: form.getFieldValue(''),
        paddress1: form.getFieldValue(''),
        paddress2: form.getFieldValue(''),
        paddress3: form.getFieldValue(''),
        pvillage: form.getFieldValue(''),
        pdistrict: form.getFieldValue(''),
        ppincode: form.getFieldValue(''),
        ptaluka: form.getFieldValue(''),
        ptehsil: form.getFieldValue(''),
        pcity: form.getFieldValue(''),
        pcountry: form.getFieldValue(''),
      });
    }
  }

  //new send otp
  const Sendotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'http://localhost:3000/api/newSendOtp',
        `${process.env.REACT_APP_API_URL}/newSendOtp`,
        {
          // If running on different domains or ports in development, specify the full URL, e.g., "http://localhost:5000/api/getOkycOtp"
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            aadhaarNumber: adhar,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setDisabledButton({ ...disabledButton, verifyOtp: false });
        console.log('600', data);
        setDisable(true);

        const requestId = data?.responseObject?.requestId;
        setRequest({ requestId });
        if (data?.responseObject?.result) {
          notification.success({
            // message: 'OTP sent to registered mobile number',
            message: data?.responseObject?.result?.message,
          });
        } else {
          notification.error({
            message: data?.responseObject?.error,
          });
        }
      } else {
        // console.error('Failed to send OTP');
        // alert('Failed to send OTP. Please try again.');
        notification.error({
          message: 'Failed to send OTP. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
    }
  };

  const adharvalue = (event) => {
    const inputValue = event.target.value;
    setAadharNumber(inputValue);
    setadhar(event.target.value);

    // Check Aadhar length
    if (inputValue.length === 12) {
      setIsAadharValid(true); // Set Aadhar as valid if it's 12 digits
    } else {
      setIsAadharValid(false);
    }

    setIsAadharChecked(false);
    // Other logic for checking Aadhar uniqueness if needed
    handleFieldChange('aadharNo', inputValue);
  };

  React.useEffect(() => {
    setIsExistingEntry(initialValues?.aadharNo);
    // if (
    //   initialValues?.handicapped == 'Yes' ||
    //   initialValues?.handicapped == 'Y'
    // ) {
    //   setIsworkingHandicaped(!IsworkingHandicaped);
    //   form.setFieldValue('handicapped', true);
    // }
    if (
      initialValues?.handicapped == 'Yes' ||
      initialValues?.handicapped == 'Y' ||
      initialValues?.handicapped == true
    ) {
      setIsworkingHandicaped(!IsworkingHandicaped);
      form.setFieldValue('handicapped', true); // Set the form value to true (checked)
    } else {
      form.setFieldValue('handicapped', false);
    }

    if (initialValues?.policeVerificationData) {
      form.setFieldValue('policeVerification', 'Yes');
    }
  }, [initialValues]);

  const otpvalue = (event) => {
    setotp(event.target.value);
  };

  //new verify otp
  const existingValuesForCheck = {
    FirstName: initialValues?.firstName,
    LastName: initialValues?.lastName,
    Gender: initialValues?.gender,
    DateOfBirth: dayjs(initialValues?.dob).format('DD-MM-YYYY'),
    Age: initialValues?.age,
  };

  const Verifyotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'http://localhost:3000/api/verifyOtp',
        `${process.env.REACT_APP_API_URL}/verifyOtp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-karza-key': 'TrW5eNdWoKynOjsl',
          },
          body: JSON.stringify({
            requestId: Request.requestId,
            otp: otp,
            aadhaarNo: adhar,
            consent: 'Y',
            clientData: {
              caseId: '123456', // Case ID (ensure it's the correct value)
            },
          }),
        }
      );

      if (response.ok) {
        setotpDisable(true);
        const data = await response.json();
        console.log('data', data);
        const realData = data?.result?.dataFromAadhaar;
        const realDataAddress =
          data?.result?.dataFromAadhaar?.address?.splitAddress;
        const addressParts =
          data?.result?.dataFromAadhaar?.address?.combinedAddress
            .split(',')
            .map((part) => part.trim());

        console.log(
          '678',
          data?.result?.dataFromAadhaar?.address?.splitAddress
        );
        var dob = new Date(realData?.dob);
        console.log('dob688', dob);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);

        setViewOnly(true);
        const name = realData?.name;
        const parts = name?.trim().split(' ');

        const lastName = parts.pop();
        const firstName = parts.join(' ');
        const profilePic = realData?.image;

        const endNum = adhar.slice(-4);
        const maskNum = endNum.padStart(adhar.length, '*');

        setuidai({
          profilePic,
        });

        form.setFieldsValue({
          firstName: firstName,
          lastName: lastName,
          gender: realData?.gender,
          longName: realData?.name,
          LongName: firstName + ' ' + lastName,
          fatherName: realData?.fatherName,
          address1: (realDataAddress?.houseNumber || '')?.slice(0, 30),
          address2: (realDataAddress?.street || '')?.slice(0, 30),
          address3: (
            (realDataAddress?.landmark || '') +
            (realDataAddress?.location || '')
          )?.slice(0, 30),

          village: realDataAddress?.vtcName,
          district: realDataAddress?.district,
          state: realDataAddress?.state,
          taluka: realDataAddress?.subdistrict,
          city: realDataAddress?.subdistrict,
          tehsil: realDataAddress?.subdistrict,
          pincode: realDataAddress?.pincode,
          dob: dayjs(realData?.dob, 'YYYY-MM-DD'),
          Dob: dayjs(realData?.dob, 'YYYY-MM-DD'),

          age: age,
          country: realDataAddress?.country,
        });
        validateAddressFields(realDataAddress);
        setISFetchedFromAadhar(true);
        notification.success({
          message: 'Fetching Details From UIDAI Server',
        });
      }
      // }
      else {
        notification.error({
          message: 'Failed to verify OTP. Please try again.',
        });
      }
    } catch (error) {
      notification.error({
        message:
          'Error verifying OTP. Please check your network and try again.',
      });
    } finally {
      setLoader(false);
      // setOtp('');
    }
  };

  const validateAddressFields = (realDataAddress) => {
    const obj = {
      address1: false,
      address2: false,
      address3: false,
      village: false,
      district: false,
      state: false,
      city: false,
      taluka: false,
      tehsil: false,
      pincode: false,
    };
    if (
      realDataAddress?.houseNumber &&
      realDataAddress?.houseNumber?.length > 0
    )
      obj.address1 = true;
    if (realDataAddress?.street && realDataAddress?.street?.length > 0)
      obj.address2 = true;
    if (
      (realDataAddress?.landmark && realDataAddress?.landmark?.length > 0) ||
      (realDataAddress?.location && realDataAddress?.location?.length > 0)
    )
      obj.address3 = true;
    if (realDataAddress?.vtcName && realDataAddress?.vtcName?.length > 0)
      obj.village = true;
    if (realDataAddress?.district && realDataAddress?.district?.length > 0)
      obj.district = true;
    if (realDataAddress?.state && realDataAddress?.state?.length > 0)
      obj.state = true;
    if (
      realDataAddress?.subdistrict &&
      realDataAddress?.subdistrict?.length > 0
    ) {
      obj.taluka = true;
      obj.city = true;
      obj.tehsil = true;
    }
    if (realDataAddress?.pincode && realDataAddress?.pincode?.length > 0)
      obj.pincode = true;
    setDisableAddress({ ...obj });
  };

  const [dob, setDob] = useState(null);

  const handleDateChange = (date) => {
    setDob(date);
    var dob = new Date(date);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    // Calculate age based on the selected date of birth
    // const age = date ? moment().diff(date, 'years') : undefined;
    // Set the value of the age field in the form
    form.setFieldsValue({ age });
    // const { name, value } = date?.target;

    handleFieldChange('Dob', date);
  };

  // function highlightDifference(fetchedValue, existingValue, key) {
  //   const fetchedString = String(fetchedValue)?.toLowerCase();
  //   const existingString = String(existingValue)?.toLowerCase();

  //   if (key === 'FirstName' || key === 'LastName' || key === 'Gender') {
  //     if (fetchedString !== existingString) {
  //       const highlightColor = 'red';
  //       const differenceText = `(${fetchedValue})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   } else if (key === 'DateOfBirth') {
  //     const fetchedDate = new Date(fetchedValue).getTime();
  //     const existingDate = new Date(existingValue).getTime();

  //     if (fetchedDate !== existingDate) {
  //       const highlightColor = 'red';
  //       const differenceText = `(${fetchedValue})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   } else {
  //     const difference = fetchedValue.length - existingValue.length;

  //     if (difference !== 0) {
  //       const highlightColor = difference > 0 ? 'green' : 'red';
  //       const differenceText = difference > 0 ? `(+${difference})` : `(${difference})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   }

  //   return fetchedValue;
  // }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const consentreview = (event) => {
    setconsent(!consent);
  };

  //State And District
  const states = State;
  const citi = {
    'Andhra Pradesh': AndhraPradesh,
    'Andaman and Nicobar Islands': AndhraPradesh,
    'Arunachal Pradesh': ArunachalPradesh,
    Assam: Assam,
    Bihar: Bihar,
    Karnataka: Karnataka,
    Chhattisgarh: Chhattisgarh,
    Chandigarh: Chandigarh,
    Dadra: Dadra,
    Delhi: Delhi,
    Goa: Goa,
    Gujarat: Gujarat,
    Haryana: Haryana,
    'Himachal Pradesh': HimachalPradesh,
    'Jammu and Kashmir': Jammu,
    Jharkhand: Jharkhand,
    Kerala: Kerala,
    Lakshadweep: Lakshadweep,
    'Madhya Pradesh': MadhyaPradesh,
    Maharashtra: Maharashtra,
    Manipur: Manipur,
    Meghalaya: Meghalaya,
    Mizoram: Mizoram,
    Nagaland: Nagaland,
    Odisha: Odisha,
    Punjab: Punjab,
    Puducherry: Puducherry,
    Rajasthan: Rajasthan,
    'Tamil Nadu': TamilNadu,
    Telangana: Telangana,
    Tripura: Tripura,
    Uttarakhand: Uttarakhand,
    UttarPradesh: UttarPradesh,
    'West Bengal': WestBengal,
    Sikkim: Sikkim,
  };
  const [selectedState, setSelectedState] = useState('');
  const [selectedstate, setSelectedstate] = useState('');
  //Live Capture
  const [visible, setVisible] = useState(false);
  const [imageSrc1, setImageSrc1] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const webcamRef = useRef(null);

  const toggleCamera = () => {
    setVisible(!visible);
    setIsCapturing(true);
  };

  const handleOk = () => {
    console.log(webcamRef, 'webcamRef');
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setVisible(false);
    setIsCapturing(true);
  };

  const handleCancel1 = () => {
    setIsCapturing(false);
    setVisible(false);
  };
  //Marital Status
  const handleMaritalStatusChange = (value) => {
    setMaritalStatus(value);
  };

  const isMarried = maritalStatus === 'married';

  const validateage = (_, value) => {
    const age = Number(value);

    if (age < 15 || age > 58) {
      return Promise.reject('Age must be between 15 and 58');
    }

    return Promise.resolve();
  };

  const changeAccordian = (e) => {
    if (e[0] && !openedAccordian.includes(e[0])) {
      setOpenAccordian([...openedAccordian, e[0]]);
    }
  };

  //for enable and disble aadhaar details
  const [employmentStatus, setEmploymentStatus] = useState(
    initialValues?.statusToBeImposed
  ); // Default value

  const handleStatusChange = (value) => {
    console.log(value, initialValues?.statusToBeImposed , 'compare status')
    setEmploymentStatus(value);
    if(initialValues?.statusToBeImposed?.toLowerCase() !== value?.toLowerCase()){
      form.setFieldsValue({
        statusFromDate: '', // Reset statusFromDate
        statusToDate: '',   // Reset statusToDate
        blockType: '',  // Reset blockType is status is changed to blocked
        remark: '' //reset remarks
      });
    }
  };

  // useEffect(() => {
  //   const checkAadhar = setTimeout(() => {
  //     callCheckAadharUniquenessApi(adhar)
  //       .then((res) => {
  //
  //       })
  //       .catch((error) => {
  //
  //       });
  //   }, 1000);

  //   return () => clearTimeout(checkAadhar);
  // }, [adhar]);

  const checkAadharUniqueness = async (data) => {
    try {
      setLoader(true);
      const res = await callCheckAadharUniquenessApi(data);
      setLoader(false);

      if (!res.data.isAadhaarAvailable) {
        setDisabledButton({ ...disabledButton, sendOtp: false });
        return true;
        // throw Error('Aadhar already exist.');
      }
      return false;
      //
    } catch (error) {
      return false;
    }
  };
  //

  useEffect(() => {
    // workingAreaInput();
    gateAreaInput();
    technicalDetilsInput();
    natureOfWorkInput();
    //
  }, [masterData]);

  // function workingAreaInput() {
  //   if (!Array.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'Working_Area';
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   //
  //   const workingAreas = dataFromArray?.dataParsed?.map((item) => {
  //     return item?.Working_Area;
  //   });
  //   setWorkingArea(workingAreas);
  //   //

  //   if (dataFromArray) {
  //
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }

  function gateAreaInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Gate_Number';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const gateInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Gate_Number;
    });
    setGateNumber(gateInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function technicalDetilsInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Technical_Details';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const techinicalDetailsAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Technical_Details + ' ' + item?.Description;
    });
    settechnicalDetails(techinicalDetailsAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  //

  // const handleEpnRenew = () => {
  //
  //   const idepn = initialValues?.id;

  //   EpnRenew(idepn)
  //     .then((res) => {
  //
  //       setLoader(true);
  //
  //       const updateContractWorkerRs =
  //         res?.data?.responseData?.updateContractWorkerRs;

  //       setUpdatedEpn(updateContractWorkerRs?.EPNCode);

  //       setTimeout(() => {
  //         setLoader(false);
  //         form.setFieldsValue({
  //           epnNumber: updateContractWorkerRs?.EPNCode,
  //         });

  //         // Check if the status is 'success' and show notification
  //         if (updateContractWorkerRs?.status === 'Success') {
  //           notification.success({
  //             message: 'EPN Renewed Successfully',
  //             description: 'The EPN has been successfully renewed.',
  //           });
  //         }
  //       }, 8000);

  //       form.setFieldsValue({});
  //     })
  //     .catch((error) => {
  //       console.error('error is epn', error);
  //     });
  // };

  const handleModal = () => setModalVisible(true);
  const handleModalOk = () => setModalVisible(false);
  const handleModalCancel = () => setModalVisible(false);

  // const handleEpnRenew = async () => {
  //
  //   const idepn = initialValues?.id;
  //   try {
  //     // const formData = await form.validateFields();
  //     const formData = await modalForm.validateFields();
  //     // const response = await EpnRenew(idepn);
  //     const response = [];
  //
  //     setLoader(true);
  //
  //     const updateContractWorkerRs =
  //       response?.data?.responseData?.updateContractWorkerRs;
  //
  //     setUpdatedEpn(updateContractWorkerRs?.EPNCode);

  //     // if (!updateContractWorkerRs) {
  //     //   throw new Error('No data found for EPN renewal');
  //     // }

  //     // Get form values
  //     //  const formValues = await form.validateFields();
  //     const formValues = await modalForm.validateFields();
  //     //  // Update main form data with modal form data
  //     //  form.setFieldsValue(formData);

  //     // Fetch the previous EPN number from your data
  //     const oldEpnNumber = initialValues?.epnNumber;

  //
  //     // Add the newly added data to the table

  //     // if (isEmptyObject(newData)) {
  //     if (formData) {
  //       const newData = {
  //         epnNumber: updateContractWorkerRs?.epnNumber,
  //         // Add other fields as needed
  //         // oldEpnNumber: updateContractWorkerRs?.oldEpnNumber,
  //         oldEpnNumber: oldEpnNumber,
  //         epnValidityFromDate: formValues?.epnValidityFromDate,
  //         epnValidityToDate: formValues?.epnValidityToDate,
  //         cardLostType: formValues?.cardLostType,
  //         cardLostDate: formValues?.cardLostDate,
  //         cardLostRemark: formValues?.cardLostRemark,
  //         epnRenew: formValues?.epnRenew,
  //         oldEpnCancelDate: formValues?.oldEpnCancelDate,
  //       };
  //
  //       // Call the callback function to pass newData to the parent component
  //       onUpdateNewData(newData);
  //     }

  //     setTimeout(() => {
  //       setLoader(false);

  //       // Check if the status is 'success' and show notification
  //       // if (updateContractWorkerRs?.status === 'Success') {
  //       //   notification.success({
  //       //     message: 'EPN Renewed Successfully',
  //       //     description: 'The EPN has been successfully renewed.',
  //       //   });
  //       // }
  //     }, 8000);
  //     setModalVisible(false);
  //   } catch (error) {
  //     console.error('error is epn', error);
  //     // Handle error if necessary
  //   }
  // };

  //Channa/Sachin new code

  // const handleEpnRenew = async () => {
  //   const idepn = initialValues?.id;
  //   try {
  //     const formData = await modalForm.validateFields();
  //     let dateIssued = new Date();
  //     formData.IssuedDate = dateIssued.toISOString();

  //

  //     formData.cardLostDate = new Date().toISOString();
  //     formData.oldEpnCancelDate = new Date().toISOString();
  //     formData.epnRenew = new Date().toISOString();
  //     const updatedEpnRenewHistory = [
  //       ...initialValues.epnRenewHistory,
  //       formData,
  //     ];

  //

  //     const updatedEpnRenewDetails = {
  //       ...initialValues,
  //       epnRenewHistory: updatedEpnRenewHistory,
  //     };

  //     const passDetails = {
  //       personalDetails: updatedEpnRenewDetails,
  //       jobDetails: jobDetails,
  //       wageDetails: wageDetails,
  //       //locationId: locationId,
  //       livePhoto: imageSrc,
  //       uploadDocument: uploadDocument,
  //     };

  //     // passDetails.personalDetails.epnNumber = updatedEpn;

  //     await updatePass(idepn, passDetails)
  //       .then((res) => {
  //         notification.success({ message: 'Record updated Successfully' });
  //       })
  //       .catch((err) => {
  //
  //         //notification.error({ message: err.message });
  //       });

  //     const response = await EpnRenew(idepn);
  //     setLoader(true);
  //     const updateContractWorkerRs =
  //       response?.data?.responseData?.updateContractWorkerRs;
  //     setUpdatedEpn(updateContractWorkerRs?.EPNCode);

  //     const formValues = await modalForm.validateFields();

  //     const oldEpnNumber = initialValues?.epnNumber;

  //     if (formData) {
  //       const newData = {
  //         epnNumber: updateContractWorkerRs?.EPNCode,
  //         // Add other fields as needed
  //         // oldEpnNumber: updateContractWorkerRs?.oldEpnNumber,
  //         oldEpnNumber: oldEpnNumber,

  //         epnValidityFromDate: formValues?.epnValidityFromDate,

  //         epnValidityToDate: formValues?.epnValidityToDate,
  //         cardLostType: formValues?.cardLostType,
  //         cardLostDate: formValues?.cardLostDate,
  //         cardLostRemark: formValues?.cardLostRemark,
  //         epnRenew: formValues?.epnRenew,
  //         oldEpnCancelDate: formValues?.oldEpnCancelDate,
  //       };
  //
  //       // Call the callback function to pass newData to the parent component
  //       onUpdateNewData(newData);
  //     }

  //     setTimeout(() => {
  //       setLoader(false);

  //       // Check if the status is 'success' and show notification
  //       // if (updateContractWorkerRs?.status === 'Success') {
  //       //   notification.success({
  //       //     message: 'EPN Renewed Successfully',
  //       //     description: 'The EPN has been successfully renewed.',
  //       //   });
  //       // }
  //     }, 8000);
  //     setModalVisible(false);
  //   } catch (error) {
  //     console.error('error is epn', error);
  //     // Handle error if necessary
  //   }
  // };

  const handleEpnRenew = async () => {
    const idepn = initialValues?.id;
    try {
      setLoader(true);

      const formData = await modalForm.validateFields();

      let dateIssued = new Date();
      formData.IssuedDate = dateIssued.toISOString();

      formData.cardLostDate = new Date().toISOString();
      formData.oldEpnCancelDate = new Date().toISOString();
      formData.epnRenew = new Date().toISOString();
      let passValidUpto = new Date(formData.passValidUpto).getTime();
      let passValidUpto_suggested_timestamp = new Date(
        initialValues.passValidUpto_suggested_timestamp
      ).getTime();

      formData.passValidUpto = Math.min(
        passValidUpto,
        passValidUpto_suggested_timestamp
      );
      formData.passValidUpto = new Date(formData.passValidUpto).toISOString();

      const updatedEpnRenewHistory = [
        ...initialValues.epnRenewHistory,
        formData,
      ];

      const updatedEpnRenewDetails = {
        ...initialValues,
        epnRenewHistory: updatedEpnRenewHistory,
        passValidUpto: formData.passValidUpto,
      };

      const passDetails = {
        personalDetails: updatedEpnRenewDetails,
        jobDetails: jobDetails,
        wageDetails: wageDetails,
        livePhoto: imageSrc,
        uploadDocument: uploadDocument,
      };
      // Added By Viresh

      if (formData?.cardType == 'S') {
        passDetails.status = 'inactive';
        // passDetails.status = 'rehired';
      }
      if (formData?.cardType == 'R') {
        passDetails.status = 'rehired'; // Use = to assign the status
      }
      // Added by Aditya
      passDetails.Edited = 'No';
      // First update pass without the new EPN number
      await updatePass(idepn, passDetails)
        .then((res) => {
          // notification.success({ message: 'Record updated Successfully' });
        })
        .catch((err) => {});
      setRenewingEpn(true);

      // Call the EpnRenew function to get the new EPN number
      const response = await EpnRenew(idepn);
      setRenewingEpn(false);
      // setLoader(true);
      const updateContractWorkerRs =
        response?.data?.responseData?.updateContractWorkerRs;
      const newEpnCode = updateContractWorkerRs?.EPNCode;
      setUpdatedEpn(newEpnCode);

      const formValues = await modalForm.validateFields();
      const oldEpnNumber = initialValues?.epnNumber;

      if (formValues && newEpnCode) {
        const newData = {
          epnNumber: newEpnCode,
          oldEpnNumber: oldEpnNumber,
          epnValidityFromDate: formValues?.epnValidityFromDate,
          epnValidityToDate: formValues?.epnValidityToDate,
          cardLostType: formValues?.cardLostType,
          cardLostDate: formValues?.cardLostDate,
          cardLostRemark: formValues?.cardLostRemark,
          epnRenew: formValues?.epnRenew,
          oldEpnCancelDate: formValues?.oldEpnCancelDate,
        };

        onUpdateNewData(newData);
      }

      // Update passDetails with the new EPN code
      passDetails.personalDetails.epnNumber = newEpnCode;
      formData.newEpnNumber = newEpnCode;

      // Update pass with the new EPN number
      //Added By Viresh
      if (formData?.cardType == 'S') {
        passDetails.status = 'inactive'; // Use = to assign the status
      }
      if (formData?.cardType == 'R') {
        passDetails.status = 'approved'; // Use = to assign the status
      }
      // Added By Aditya
      passDetails.Edited = 'No';
      await updatePass(idepn, passDetails)
        .then((res) => {
          setLoader(false);

          notification.success({
            message:
              formData?.cardType == 'R'
                ? 'Record updated Successfully with new EPN'
                : formData?.cardType == 'S'
                ? 'Record surrendered successfully'
                : '',
          });
          setStatusOfRenewEPNButton(statusOfRenewEPNButton + 1);
        })
        .catch((err) => {});

      setTimeout(() => {
        setLoader(false);
        setModalVisible(false);
        navigate('/home/approver/list');
      }, 4000);
    } catch (error) {
      setLoader(false);
      console.error('error is epn', error);
      setRenewingEpn(false);
      notification.error({ message: 'Request to renew EPN failed' });
    }
  };

  // Function to check if an object is empty
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length !== 0 && obj.constructor === Object;
  };
  // useEffect(() => {
  //   handleEpnRenew(data);

  //  }, [handleEpnRenew]);

  // Nature of Work
  function natureOfWorkInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Type_of_Contract';
    const dataFromArray = masterData.find(
      (item) => item.sheetName === sheetName
    );

    if (!dataFromArray) {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
      return;
    }

    const natureOfWorkAreas = dataFromArray.dataParsed.map(
      (item) => item['Dropdown for Nature of Work']
    );
    setnatureOfWork(natureOfWorkAreas);
  }

  // useEffect(() => {
  //   contractWorkmenEpnNumber(),
  //   contractWorkmenCodeNumber()
  // }, []);

   useEffect(() => {
      if (initialValues){ 
        initialValues.initialStatusToBeImposed = initialValues?.statusToBeImposed;
      }
    }, [initialValues]);

  const contractWorkmenEpnNumber = async (orgId, locationId) => {
    try {
      const response = await getContractWorkmenEpnNumber(orgId, locationId);
      const responseData = response.data;

      if (responseData.message === 'New entry') {
        // If it's a new entry, setPSNumber to '0001'
        const newRNWNumber = 1;
        const updatedEPNNumber = `RNW${newRNWNumber}`;
        setRanoliEpnNumber(updatedEPNNumber);

        // You can do other processing or actions if needed
      } else {
        // If it's an existing entry, increment the existing numeric part by 1
        let existingNumericPart = responseData.lastEPNNumber;
        let numericPart = existingNumericPart?.split(/\D+/);

        numericPart = parseInt(numericPart[1]) + 1;

        // Pad the numeric part with leading zeros based on your desired length
        const paddedNumericPart = numericPart.toString().padStart(4, '0');

        // Concatenate with any prefix or other formatting you may need
        const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

        // Update the state with the new PSNumber
        setRanoliEpnNumber(updatedEPNRNWNumber);
      }
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const contractWorkmenCodeNumber = async (orgId, locationId) => {
    try {
      const response = await getContractWorkmenWorkerCodeNumber(
        orgId,
        locationId
      );
      const responseData = response.data;

      if (responseData.message === 'New entry') {
        // If it's a new entry, setPSNumber to '0001'
        const newWorkerCodeNumber = 1;
        const updatedWorkerCodeNumber = `RNW${newWorkerCodeNumber}`;
        setRanoliEpnNumber(updatedWorkerCodeNumber);

        // You can do other processing or actions if needed
      } else {
        // If it's an existing entry, increment the existing numeric part by 1
        let existingNumericPart = responseData.lastEPNNumber;
        let numericPart = existingNumericPart?.split(/\D+/);

        numericPart = parseInt(numericPart[1]) + 1;

        // Pad the numeric part with leading zeros based on your desired length
        const paddedNumericPart = numericPart.toString().padStart(4, '0');

        // Concatenate with any prefix or other formatting you may need
        const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

        // Update the state with the new PSNumber
        setRanoliEpnNumber(updatedEPNRNWNumber);
      }
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const orgId = userConfig.orgId;

  const handleFieldChangeEPN = async (changedField, allField) => {
    //
    if (changedField[0]?.name[0] === 'locationId') {
      if (changedField[0]?.value === 'ranoli') {
        try {
          const response = await getContractWorkmenEpnNumber(orgId);
          const responseData = response.data;

          if (responseData.message === 'New entry') {
            // If it's a new entry, setPSNumber to '0001'
            const newRNWNumber = 1;
            const updatedEPNNumber = `RNW${newRNWNumber}`;
            //setRanoliEpnNumber(updatedEPNNumber);

            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              epnNumber: updatedEPNNumber,
              // Add more fields as needed
            });

            // You can do other processing or actions if needed
          } else {
            // If it's an existing entry, increment the existing numeric part by 1
            let existingNumericPart = responseData.lastEPNNumber;
            let numericPart = existingNumericPart?.split(/\D+/);

            numericPart = parseInt(numericPart[1]) + 1;

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

            // Update the state with the new PSNumber
            setRanoliEpnNumber(updatedEPNRNWNumber);
            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              epnNumber: updatedEPNRNWNumber,
              // Add more fields as needed
            });
          }
        } catch (error) {
          console.error(error);
          // Handle error if necessary
        }
      } else {
        form.setFieldsValue({
          epnNumber: '', // Clear EPN number
          empCode: '', // Clear worker code number
          // Add more fields as needed
        });
      }

      if (changedField[0]?.value === 'ranoli') {
        try {
          const response = await getContractWorkmenWorkerCodeNumber(orgId);
          const responseData = response.data;

          if (responseData.message === 'New entry') {
            // If it's a new entry, setPSNumber to '0001'
            const newWorkerCodeNumber = 1;
            const updatedWorkerCodeNumber = `WC${newWorkerCodeNumber}`;
            // setRanoliWorkmenNumber(updatedWorkerCodeNumber);

            form.setFieldsValue({
              empCode: updatedWorkerCodeNumber,
            });
          } else {
            let existingNumericPart = responseData?.lastWorkmenCodeNumber;
            let numericPart = existingNumericPart?.split(/\D+/);

            numericPart = parseInt(numericPart[1]) + 1;

            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            const updatedWorkmenRNWNumber = `WC${paddedNumericPart}`;

            // Update the state with the new PSNumber
            //setRanoliWorkmenNumber(updatedWorkmenRNWNumber);
            //

            form.setFieldsValue({
              empCode: updatedWorkmenRNWNumber,
            });
          }
        } catch (error) {
          console.error(error);
          // Handle error if necessary
        }
      }
    } else if (changedField[0]?.name[0] === 'blockType') {
      setBlockType(changedField[0]?.value);
    }
  };

  const handleLocationChange = (newLocationId) => {
    setLocationId(newLocationId); // Update the state with the new location ID
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      title: 'EPN Code',
      dataIndex: 'newEpnNumber',
      width: '5%',
      editable: true,
    },
    {
      title: 'OLD EPN Number',
      dataIndex: 'epnNumber',
      width: '5%',
      editable: true,
    },
    {
      title: 'EPN Validity From Date',
      dataIndex: 'IssuedDate',
      width: '10%',
      // render: (date) => formatDate(date),
      render: (date) => (date ? formatDate(date) : ''),
      editable: true,
    },
    {
      title: 'EPN Validity To Date',
      dataIndex: 'passValidUpto',
      width: '10%',
      // render: (date) => formatDate(date),
      render: (date) => (date ? formatDate(date) : ''),
      editable: true,
    },
    {
      title: 'Card Lost Type',
      dataIndex: 'cardLostType',
      width: '2%',
      editable: true,
    },
    {
      title: 'Card Lost Date',
      dataIndex: 'cardLostDate',
      width: '10%',
      // render: (date) => formatDate(date),
      render: (date) => (date ? formatDate(date) : ''),
      editable: true,
    },
    {
      title: 'Card Lost Remark',
      dataIndex: 'LostRemark',
      width: '5%',
      editable: true,
    },
    {
      title: 'EPN Renew Date',
      dataIndex: 'epnRenew',
      width: '10%',
      // render: (date) => formatDate(date),
      render: (date) => (date ? formatDate(date) : ''),
      editable: true,
    },
    {
      title: 'Old EPN Cancel Date',
      dataIndex: 'oldEpnCancelDate',
      width: '10%',
      // render: (date) => formatDate(date),
      render: (date) => (date ? formatDate(date) : ''),
      editable: true,
    },

    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: '20%',

    //   render: (_, record) => {
    //     return (
    //       <Space>
    //         <Typography.Link
    //           disabled={
    //             userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
    //           }
    //           onClick={() => edit(record)}>
    //           <EditFilled />
    //           Edit
    //         </Typography.Link>
    //         {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
    //           <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
    //             <DeleteFilled />
    //             Delete
    //           </Typography.Link>
    //         </Popconfirm>  */}
    //       </Space>
    //     );
    //   },
    // },
  ];

  const latestEpnRenew =
    initialValues?.epnRenewHistory?.[initialValues?.epnRenewHistory.length - 1];

  const initialValuesFromHistory = {};
  if (latestEpnRenew) {
    Object.keys(latestEpnRenew).forEach((key) => {
      initialValuesFromHistory[key] = latestEpnRenew[key];
    });
  }

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
    const agency_esic = initialValues?.contractorDetails?.addJobDetails?.find(
      (agency) => agency?.jobordernumber == initialValues?.jobOrderNumber
    );
    setAgencyEsicNo(agency_esic?.esicno);
  }, [initialValues]);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        const data = res.data;
        const jobOrderNumber = initialValues?.jobOrderNumber;
        const contractorCode =
          initialValues?.contractorCode || userConfig.contractorCode;
        const contractorName = initialValues?.name || userConfig.name;

        const findMatchingObject = (data, jobOrderNumber, contractorCode) => {
          for (const item of data) {
            if (
              item &&
              item.addJobDetails &&
              Array.isArray(item.addJobDetails)
            ) {
              for (const details of item.addJobDetails) {
                if (
                  details.id == contractorCode &&
                  details.jobordernumber == jobOrderNumber
                ) {
                  return details;
                }
              }
            }
          }
        };

        const matchingObject = findMatchingObject(
          data,
          jobOrderNumber,
          contractorCode
        );

        if (matchingObject) {
          initialValues.periodContractorEndDate = dayjs(
            new Date(matchingObject.periodContractorEndDate)
          );
          initialValues.doe = dayjs(new Date(matchingObject.doe));
          initialValues.wcvt = dayjs(new Date(matchingObject.wcvt));
          initialValues.periodContractorStartDate = dayjs(
            new Date(matchingObject.periodContractorStartDate)
          );
          let passDates = getPassDatesForEpnRenewal(
            initialValues.safetyCerticationEndDate_timeStamp,
            matchingObject.wcvt, // WCP validity date
            matchingObject.doe, // Labor License validity date
            initialValues.pmeValidityDate_timeStamp, // Medical validity date
            matchingObject.periodContractorEndDate // WO validity date
          );

          initialValues.passValidUpto = dayjs(passDates?.passValidUpto);
          initialValues.passValidUpto_suggested_timestamp =
            passDates?.passValidUpto;
          initialValues.passExtendedUpto = dayjs(passDates?.passExtendedUpto);
          if (initialValues?.pmeValidityDate_timeStamp) {
            initialValues.pmeValidityDate = dayjs(
              initialValues.pmeValidityDate_timeStamp
            );
          }
          if (initialValues.safetyCerticationEndDate_timeStamp) {
            initialValues.safetyCerticationEndDate = dayjs(
              initialValues.safetyCerticationEndDate_timeStamp
            );
          }
        }
      })
      .catch((error) => {
        // setLoader(false);
      });
  };
  console.log(initialValues, 'initial values');

  // useEffect(() => {
  // let tempStatus = ['Hibernate', 'inactive', 'rejected', 'terminated', 'blocked', 'blacklisted', 'retired'].includes(initialValues?.status)
  //   ? initialValues?.status?.charAt(0)?.toUpperCase() +
  //   initialValues?.status?.slice(1)
  //   : 'Active';
  // console.log("339 ",tempStatus, initialValues?.status)
  // setEmploymentStatus(tempStatus);
  // form.setFieldsValue({statusToBeImposed: initialValues?.statusToBeImposed ? initialValues?.statusToBeImposed : tempStatus})
  // }, [initialValues?.status])

  return (
    <>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        onFieldsChange={handleFieldChangeEPN}
        ref={formRef}
        form={form}>
        <Collapse onChange={changeAccordian}>
          <Panel header='Aadhaar Details' key='1'>
            <Row>
              <Checkbox
                disabled={
                  initialValues?.approvalStage === 0 &&
                  userConfig.role === 'approver'
                }
                onClick={consentreview}>
                I hereby, at my own discretion, voluntarily submit the physical
                copy of my Aadhaar card / physical e-Aadhaar / masked Aadhaar /
                offline electronic Aadhaar / copies of my Aadhaar card in
                physical or electronic form or xml as issued by UIDAI (Aadhaar),
                for the purpose of establishing my identity / age / address
                proof and voluntarily give my consent for the purpose of
                employment-related verification. I understand that I have the
                right to withdraw this consent at any point in time and that I
                have the right to complain to the Data Protection Board of India
                for any breach of my personal identity.
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              {/* <Col
                span={12}
                style={{
                  borderRight: '2px solid black',
                  paddingRight: '26px',
                }}>
                <Form.Item hidden={consent}>
                  <div className='App' style={{ alignItems: 'start' }}>
                    <Button
                      className='glow-on-hover'
                      style={{
                        borderRadius: '10px',
                        type: 'primary',
                        color: 'white',
                        height: '40px',
                        justifyContent: 'end',
                        fontWeight: 'bolder',
                      }}
                      onClick={() => {
                        setStartScan(!startScan);
                      }}
                      type='primary'
                      disabled={isDisabled}>
                      {startScan
                        ? 'Scan QR Code Stop '
                        : 'Scan QR Code from Aadhaar '}
                    </Button>

                    {startScan && (
                      <>
                        <QrReader
                          facingMode={selected}
                          delay={2000}
                          onError={handleError}
                          onScan={handleScan}
                          chooseDeviceId={() => selected}
                          style={{ width: '250px' }}
                        />
                      </>
                    )}
                  </div>
                </Form.Item>{' '}
              </Col> */}

              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name='aadharNo'
                      hidden={consent}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!id) {
                              if (!/^[0-9]{12}$/.test(value)) {
                                return Promise.reject(
                                  'Enter Valid Aadhaar No.'
                                );
                                // throw new Error('Enter Valid Aadhar No.');
                                // return 0;
                              }
                              if (
                                value.length === 12 &&
                                !(isFetchedFromAadhar || isAadharChecked)
                              ) {
                                const isUnique = await checkAadharUniqueness(
                                  value
                                );

                                if (!isUnique) {
                                  setDisable(true);
                                  return Promise.reject(
                                    'Aadhaar already exist.'
                                  );
                                }
                                setDisable(false);
                                setIsAadharChecked(true);
                              }
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter Aadhaar Number'
                        // value={adhar}
                        onChange={adharvalue}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Col>
                  {/* {!disabledButton.sendOtp && ( */}

                  <Col span={12}>
                    <Form.Item hidden={consent}>
                      {(isAadharValid || isExistingEntry) && (
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          onClick={Sendotp}
                          disabled={disable}
                          type='primary'>
                          Send OTP
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!disabledButton.verifyOtp && (
                  <Row style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Form.Item
                        name='otp'
                        hidden={consent}
                        rules={[
                          {
                            pattern: /^\d{6}$/,
                            message: 'Six Digit Required',
                          },
                        ]}>
                        <Input
                          size='large'
                          placeholder='Enter OTP'
                          value={otp}
                          onChange={otpvalue}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='Verify Otp' hidden={consent}>
                        {/* <div className="App" > */}
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          type='primary'
                          onClick={Verifyotp}
                          disabled={otpdisable}>
                          Verify OTP
                        </Button>
                        {/* </div> */}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Divider />

        {/* <Title level={5}>Personal Details</Title> */}
        {/* <h3>Personneldetails</h3> */}
        {/* <Form layout="vertical" initialValues={initialValues} onFinish={onFinish} form={form}

      >  */}
        <Collapse onChange={changeAccordian}>
          <Panel header='Personal Details' key='2'>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='firstName'
                  // label={getLabel('firstName')}
                  label='First Name'
                  rules={[
                    { required: true, message: 'Enter First Name ' },

                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) => {
                      const firstName = e.target.value;
                      const middleName = form.getFieldValue('middleName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                      handleFieldChange('firstName', e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='middleName'
                  label='Middle Name'
                  // label={getLabel('lastName')}
                  rules={[
                    // { required: true, message: 'Enter  Middle Name' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) => {
                      const middleName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                      handleFieldChange('middleName', e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='lastName'
                  label='Last Name'
                  // label={getLabel('lastName')}
                >
                  <Input
                    size='large'
                    // disabled={isEditingDisabled}
                    disabled={isDisabled}
                    onChange={(e) => {
                      const lastName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const middleName = form.getFieldValue('middleName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                      handleFieldChange('lastName', e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='LongName'
                  label='Long Name'
                  // label={getLabel('lastName')}
                  rules={[
                    {
                      max: 60,
                      message: 'Maximum length allowed is 60 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('LongName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='longName'
                  // label={getLabel('firstName')}
                  label='Aadhaar Long Name'
                  rules={[
                    // { required: true, message: 'Enter Long Name ' },

                    // {
                    //   pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                    //   message: 'Only Alphabetic Allowed',
                    // },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 50,
                      message: 'Maximum length allowed is 50 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    // value={longName}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='gender'
                  label='Gender'
                  // label={getLabel('gender')}
                  rules={[{ required: true, message: 'Enter gender' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'gender', value } })
                    }>
                    <Option value='Male'>Male</Option>
                    <Option value='Female'>Female</Option>
                    <Option value='Other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='fatherName'
                  label="Father / Guardian's Name"
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('fatherName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              {/* Date issue: Storing one day less @ Viresh Bangargi 17July2024 */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='Dob'
                  label='Date of Birth'
                  rules={[
                    {
                      required: true,
                      message: 'Select Date Of Birth',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isDisabled}
                    onChange={handleDateChange}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              {/* Fix the observations raised by L&R IR team @ Viresh Bangargi 23June2024 */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='dob'
                  label='Aadhaar Date of Birth'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Select Date Of Birth',
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isEditingDisabled || isFetchedFromAadhar}
                    inputReadOnly={true} // Disable manual input

                    // onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='mobile'
                  label='Mobile Number'
                  // label={getLabel('mobile')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Mobile Number',
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                    // {
                    //   min: 10,
                    //   max: 10,
                    //   message: "Mobile number should be 10 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('mobile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='alternateMobile'
                  label='Alternate Contact Number'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Alternate Contact Number',
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                    // {
                    //   min: 10,
                    //   max: 10,
                    //   message: "Mobile number should be 10 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('alternateMobile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='age'
                  label='Age'
                  // label={getLabel('age')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Age',
                    },
                    { pattern: /^[0-9]*$/, message: 'Age must be numeric' },
                    { validator: validateage },
                  ]}>
                  <Input
                    size='large'
                    // disabled={
                    //   isDisabled || isFetchedFromAadhar || isEditingDisabled
                    // }
                    disabled={isDisabled}
                    onChange={(e) => handleFieldChange('age', e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='aadharNo'
                  label='Aadhaar Number'
                  onBlur={() => {
                    //
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Aadhaar',
                    },

                    // {
                    //   pattern: /^[0-9]{12}$/,
                    //   message: 'Enter Valid Aadhar No.',
                    // },
                    {
                      validator: async (_, value) => {
                        if (!id) {
                          if (!/^[0-9]{12}$/.test(value)) {
                            return Promise.reject('Enter Valid Aadhaar No.');
                            // throw new Error('Enter Valid Aadhar No.');
                            // return 0;
                          }

                          if (
                            value.length === 12 &&
                            !(isFetchedFromAadhar || isAadharChecked)
                          ) {
                            const isUnique = await checkAadharUniqueness(value);

                            if (!isUnique) {
                              return Promise.reject('Aadhaar already exist.');
                            }
                            setIsAadharChecked(true);
                          }
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled || isFetchedFromAadhar}
                    onChange={adharvalue}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='martialStatus' label='Marital Status'>
                  <Select
                    size='large'
                    placeholder='Marital Status'
                    onChange={handleMaritalStatusChange}
                    disabled={isDisabled}>
                    <Option value='Married'>Married</Option>
                    <Option value='Unmarried'>Unmarried</Option>
                    <Option value='Widow'>Widow</Option>
                    <Option value='Divorced'>Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='spousename'
                  label='Spouse Name'
                  rules={[
                    {
                      pattern: '^[a-zA-Z]*$',
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={!isMarried || isDisabled}
                    onChange={(e) =>
                      handleFieldChange('spousename', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='childrenNo'
                  label='Number Of Children'
                  rules={[
                    {
                      pattern: '^[0-9]*$', // This allows only numeric (0-9) characters
                      message: 'Only Numeric Values Allowed',
                    },
                    // {
                    //   min: 1,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    {
                      max: 2,
                      message: 'Maximum length allowed is 2 Number',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('childrenNo', e.target.value)
                    }
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='emergencyContactName'
                  label='Emergency Contact Name'
                  rules={[
                    // { required: true, message: 'Enter Emergency Contact Name' },
                    // {
                    //   pattern: '^[a-zA-Z]*$',
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('emergencyContactName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='emergencyContactNo'
                  label='Emergency Contact Number'
                  rules={[
                    // {
                    //   required: true,
                    //   message: 'Enter Emergency Contact Number',
                    // },
                    {
                      min: 10,
                      max: 10,
                      message: 'Mobile number should be 10 digits',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('emergencyContactNo', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='idmark'
                  label={
                    <Space>
                      <span>Id Mark</span>
                      <Tooltip
                        title='Any physical mark on the body such as birth mark'
                        placement='top'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  rules={[
                    {
                      max: 60,
                      message: 'Maximum 60 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('idmark', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='religion'
                  label='Religion'
                  // onChange={(e) =>
                  //   handleFieldChange('religion', e.target.value)
                  // }
                >
                  {/* <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('religion', e.target.value)
                    }
                  /> */}
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'religion', value } })
                    }>
                    <Option value='bhudist'>BUDHIST</Option>
                    <Option value='christian'>CHRISTIAN</Option>
                    <Option value='hindu'>HINDU</Option>
                    <Option value='jain'>JAIN</Option>
                    <Option value='jew'>JEW</Option>
                    <Option value='muslim'>MUSLIM</Option>
                    <Option value='sikh'>SIKH</Option>
                    <Option value='zerostrian'>ZEROSTRIAN</Option>
                    <Option value='others'>OTHERS</Option>
                    <Option value='NA'>NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='domicile'
                  label='Domicile'
                  rules={[
                    {
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('domicile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='numberOfYearSpent'
                  label='Number of years spent in State'>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('numberOfYearSpent', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='height'
                  label='Height'
                  rules={
                    [
                      // {
                      //   min: 1,
                      //   max: 6,
                      //   message: 'Only 6 characters allowed',
                      // },
                    ]
                  }>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('height', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='votercardno'
                  label='Voter Card Number'
                  rules={
                    [
                      // { required: true, message: "Enter Emergency Contact Name" },
                      // {
                      //   pattern: '^[a-zA-Z]{3}[0-9]{7}$',
                      //   message: 'Please enter a valid Voter Card number',
                      // },
                    ]
                  }>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    placeholder='ABC1234567'
                    onChange={(e) =>
                      handleFieldChange('votercardno', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pan'
                  label='PAN'
                  rules={[
                    {
                      pattern: '[A-Z]{5}[0-9]{4}[A-Z]{1}',
                      message: 'Enter Valid PAN',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled || isEditingDisabled}
                  />
                </Form.Item>
              </Col> */}
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='rationcardno'
                  label='Ration Card Number'
                  rules={[
                    {
                      pattern: '^[0-9]{12}$',
                      message:
                        'Please enter a valid 12-digit Ration Card number',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('rationcardno', e.target.value)
                    }
                  />
                </Form.Item>
              </Col> */}

              {/* <Col className='gutter-row' span={6}>
                <Form.Item name='handicapped'>
                  <Checkbox
                    size='large'
                    name='handicapped'
                    onChange={PmeCheckbox}
                    onClick={IsworkingHandicapped}
                    style={{ paddingTop: '40px' }}
                    disabled={isDisabled}>
                    Handicapped?
                  </Checkbox>
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item name='handicapped' valuePropName='checked'>
                  <Checkbox
                    size='large'
                    name='handicapped'
                    onChange={PmeCheckbox}
                    onClick={IsworkingHandicapped}
                    checked={form.getFieldValue('handicapped')} // Controlled by form value
                    style={{ paddingTop: '40px' }}
                    disabled={isDisabled}>
                    Handicapped?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='disabilityType' label='Disability Type'>
                  <Input
                    size='large'
                    disabled={IsworkingHandicaped}
                    onChange={(e) =>
                      handleFieldChange('disabilityType', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='disabilityPercentage'
                  label='Disability Percentage'>
                  <Input
                    size='large'
                    disabled={IsworkingHandicaped}
                    onChange={(e) =>
                      handleFieldChange('disabilityPercentage', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeName'
                  label='Nominee name'
                  rules={[
                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeRelationship'
                  label='Nominee Relationship'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeAge'
                  label='Nominee Age'
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Age must be numeric' },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeAadharNo'
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (value && value?.trim() !== '' && !id) {
                          if (!/^[0-9]{12}$/.test(value)) {
                            return Promise.reject('Enter Valid Aadhaar No.');
                            // throw new Error('Enter Valid Aadhar No.');
                            // return 0;
                          }
                          if (value?.length === 12) {
                            const isUnique = await checkAadharUniqueness(value);

                            if (!isUnique) {
                              return Promise.reject('Aadhaar already exist.');
                            }
                            setIsAadharChecked(true);
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label='Nominee Aadhar'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeContactnumber'
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                  ]}
                  label='Nominee Contact number'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} hidden={IsworkingHandicaped}>
                <Form.Item
                  label='Attachment'
                  name='attachment'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: IsworkingHandicaped ? false : true,
                      message: 'Upload  Attachment',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    size='large'
                    style={{ width: '100%' }}
                    // disabled={PfDisabled || isDisabled}
                    onChange={onchange}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={onchange}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>

              <Col span={6}>
                <Form.Item label='Live Capture' name='livePhoto'>
                  <>
                    <Button
                      size='large'
                      style={{ width: '100%' }}
                      onClick={toggleCamera}
                      disabled={isDisabled}>
                      {imageSrc ? 'Retake Photo' : 'Capture Photo'}
                    </Button>
                  </>
                </Form.Item>
                <Image
                  src={imageSrc ? imageSrc : livePhoto}
                  // alt='Captured photo'
                  preview={true}
                />

                <Modal
                  open={visible}
                  onOk={handleOk}
                  onCancel={handleCancel1}
                  style={{ width: '150' }}>
                  {isCapturing && (
                    <Webcam
                      audio={false}
                      height={200}
                      ref={webcamRef}
                      screenshotFormat='image/jpeg'
                      width={200}
                    />
                  )}
                </Modal>
              </Col>

              <Col span={6}>
                <Form.Item label='Upload Photo' name='uploadPhoto' type='file'>
                  <Upload
                    listType='picture'
                    onPreview={handlePreview}
                    type='file'
                    accept='image/*'
                    maxCount={1}
                    beforeUpload={(f, fl) => {
                      return false;
                    }}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      disabled={isDisabled}
                      style={{ width: '100%' }}>
                      Upload Photo
                    </Button>
                  </Upload>
                </Form.Item>
                {uploadProfilePhoto && (
                  <img
                    alt='example'
                    // style={{
                    //   width: '100%',
                    // }}
                    src={uploadProfilePhoto}
                    preview={true}
                  />
                )}
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                    preview={true}
                  />
                </Modal>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='aadharPhoto'
                  label='Aadhaar Photo'
                  initialValue={uidai.profilePic}>
                  <img
                    src={
                      uidai?.profilePic
                        ? 'data:image/png;base64,' + uidai.profilePic
                        : aadharPhoto
                    }
                    // alt='aadhar-img'
                    width='60px'
                    style={{ marginLeft: '20px' }}
                  />
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item name='biometricBypass'>
                  <Checkbox
                    size='large'
                    name='biometricBypass'
                    // onChange={BioCheckbox}
                    onChange={(e) =>
                      handleFieldChange('biometricBypass', e.target.value)
                    }
                    onClick={BiometricDisable}
                    style={{ paddingTop: '40px' }}
                    disabled={isDisabled}>
                    Biometric Bypass
                  </Checkbox>
                </Form.Item>{' '}
              </Col> */}
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  label='Biometric Image'
                  name='biometricImage'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    disabled={IsworkingBio}
                    size='large'
                    style={{ width: '100%' }}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col> */}
              {/* <Col
                className='gutter-row'
                span={6}
                // hidden={users.profilePic === true ? false : true}
              >
                <Form.Item
                  label='Electronic Signature'
                  name='electronicSignature'
                  // onChange={(e) =>
                  //   handleFieldChange('electronicSignature', e.target.value)
                  // }
                  valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    disabled={IsworkingBio}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
               
              </Col> */}
            </Row>

            <br />
            <br />
            <Title level={5}>Aadhaar Address</Title>
            <Row gutter={16}>
              {/* <Col className='gutter-row' span={24}>
                <Form.Item
                  name='address'
                  label='Address'
                  rules={[
                    { required: true, message: 'Enter Address' },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 600 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address1'
                  label='Permanent Address Line 1'
                  rules={[
                    // {
                    //   required: true,
                    //   message: 'Enter Permanent Address Line 1',
                    // },
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, .!#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address1'
                    name='address1'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address1}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address2'
                  label='Permanent Address Line 2'
                  rules={[
                    // {
                    //   required: true,
                    //   message: 'Enter Permanent Address Line 2',
                    // },
                    // {
                    //   min: 3,
                    //   message: 'Minimum 3 Characters',
                    // },
                    // {
                    //   max: 300,
                    //   message: 'Maximum 600 characters allowed ',
                    // },
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, !#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address2'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address2}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address3'
                  label='Permanent Address Line 3'
                  rules={[
                    // {
                    //   required: true,
                    //   message: 'Enter Permanent Address Line 3',
                    // },
                    // {
                    //   min: 3,
                    //   message: 'Minimum 3 Characters',
                    // },
                    // {
                    //   max: 300,
                    //   message: 'Maximum 600 characters allowed ',
                    // },
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, !#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address3'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address3}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='village'
                  label='Village/Mandal'
                  rules={[
                    { required: true, message: 'Enter Village/Mandal' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='village'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.village}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6} id='state'>
                <Form.Item
                  name='state'
                  label='State'
                  rules={[{ required: true, message: 'Enter State' }]}
                  onChange={onChange}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // onChange={(e) => {
                    //   setSelectedState(e);
                    // }}
                    // onChange={(value) =>
                    //   onChange({ target: { name: 'state', value } })
                    // }
                    onChange={(e) => {
                      setSelectedState(e);
                      onChange({ target: { name: 'state', value: e } });
                    }}
                    size='large'
                    placeholder='Select State'
                    disabled={isDisabled || disableAddress.state}>
                    {states.map((state, index) => (
                      <Select.Option
                        key={`state-${index}`}
                        value={state.slice(0, 20)}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='district'>
                <Form.Item
                  name='district'
                  label='District'
                  rules={[{ required: true, message: 'Enter District' }]}
                  onChange={onChange}>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size='large'
                      disabled={isDisabled || disableAddress.district}
                      onChange={(value) =>
                        onChange({ target: { name: 'district', value } })
                      }>
                      {citi[selectedState]?.map((city, index) => (
                        <Select.Option
                          key={`city-${index}`}
                          value={city.slice(0, 20)}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pincode'
                  label='Pincode'
                  rules={[
                    { required: true, message: 'Enter Pincode' },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='pincode'
                    name='pincode'
                    disabled={isDisabled || disableAddress.pincode}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='taluka'
                  label='Taluka'
                  rules={[
                    { required: true, message: 'Enter Taluka' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='taluka'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.taluka}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='tehsil'
                  label='Tehsil'
                  rules={[
                    { required: true, message: 'Enter Tehsil' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='tehsil'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.tehsil}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='city'
                  label='City'
                  rules={[
                    { required: true, message: 'Enter City' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='city'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.city}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='country'
                  label='Country'
                  rules={[
                    { required: true, message: 'Enter Country' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='country'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={24} id='homepostalcheck'>
                <Form.Item>
                  <input
                    type='checkbox'
                    id='same'
                    name='same'
                    size='large'
                    style={{ fontWeight: 'bold' }}
                    onClick={addressFunction}
                    disabled={isDisabled}
                  />
                  <label
                    htmlFor='same'
                    style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                    Same As Above
                  </label>
                </Form.Item>
                <Title level={5} style={{ marginTop: '30px' }}>
                  Local Address
                </Title>
              </Col>

              <br />

              {/* <Col className='gutter-row' span={24}>
                <Form.Item
                  name='paddress'
                  label='Address'
                  rules={[
                    { required: true, message: 'Enter Address' },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 300 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='paddress'
                    value={presentAdress.address}
                    disabled={isDisabled}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='paddress1'
                  label='Present Address Line 1'
                  rules={[
                    // {
                    //   pattern: /^[a-zA-Z0-9, ]{1,30}$/,
                    //   message:
                    //     'Only alphanumeric characters and commas are allowed, up to 30 characters',
                    // },
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, !#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='paddress1'
                    name='paddress1'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('paddress1', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='paddress2'
                  label='Present Address Line 2'
                  rules={[
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, !#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='paddress2'
                    name='paddress2'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('paddress2', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='paddress3'
                  label='Present Address Line 3'
                  rules={[
                    {
                      // pattern:
                      //   /^(?!\s)[a-zA-Z0-9, !#$%^&*()_+=[\]{}|;:<>/?`~\\-]{1,30}$/,
                      // message:
                      //   'Up to 30 characters only, no leading spaces, avoid \', ", and @',
                      max: 30,
                      message: 'Only 30 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='paddress3'
                    name='paddress3'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('paddress3', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pvillage'
                  label='Village/Mandal'
                  rules={[
                    { required: true, message: 'Enter Village/Mandal' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='pvillage'
                    name='pvillage'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6} id='pstate'>
                <Form.Item
                  name='pstate'
                  label='State'
                  rules={[{ required: true, message: 'Enter State' }]}
                  onChange={onChange}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size='large'
                    // onChange={(e) => {
                    //   setSelectedstate(e);
                    // }}
                    // onChange={(value) =>
                    //   onChange({ target: { name: 'pstate', value } })
                    // }
                    onChange={(e) => {
                      setSelectedstate(e);
                      onChange({ target: { name: 'pstate', value: e } });
                    }}
                    placeholder='Select State'
                    disabled={isDisabled}>
                    {states.map((state, index) => (
                      <Select.Option
                        key={`state-${index}`}
                        value={state.slice(0, 20)}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='pdistrict'>
                <Form.Item
                  name='pdistrict'
                  label='District'
                  rules={[{ required: true, message: 'Enter District' }]}
                  onChange={onChange}>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size='large'
                      disabled={isDisabled}
                      onChange={(value) =>
                        onChange({ target: { name: 'pdistrict', value } })
                      }>
                      {citi[selectedstate]?.map((city, index) => (
                        <Select.Option
                          key={`city-${index}`}
                          value={city.slice(0, 20)}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ppincode'
                  label='Pincode'
                  rules={[
                    { required: true, message: 'Enter Pincode' },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='ppincode'
                    name='ppincode'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ptaluka'
                  label='Taluka'
                  rules={[
                    { required: true, message: 'Enter Taluka' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='ptaluka'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ptehsil'
                  label='Tehsil'
                  rules={[
                    { required: true, message: 'Enter Tehsil' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='ptehsil'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pcity'
                  label='City'
                  rules={[
                    { required: true, message: 'Enter City' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='pcity'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pcountry'
                  label='Country'
                  rules={[
                    { required: true, message: 'Enter Country' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      max: 20,
                      message: 'Only 20 characters allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='pcountry'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Collapse onChange={changeAccordian}>
          <Panel header='Professional Details' key='3'>
            <Row gutter={16}>
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='unitName'
                  label='Unit Name'
                  rules={[{ required: true, message: 'Enter Unit Name' }]}>
                  {/* <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'unitName', value } })
                    }>
                    <Option value='haziraWest'>Hazira West</Option>
                    <Option value='haziraEast'>Hazira East</Option>
                    <Option value='Ranoli'>RANOLI</Option>
                  </Select> */}
                  {/* <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'unitName', value } })
                    }>
                    <Option
                      hidden={userConfig?.location == 'RNW'}
                      value='haziraWest'>
                      Hazira West
                    </Option>
                    <Option
                      hidden={userConfig?.location == 'RNW'}
                      value='haziraEast'>
                      Hazira East
                    </Option>
                    <Option
                      value='Ranoli'
                      hidden={userConfig?.location == 'HZW'}>
                      RANOLI
                    </Option>
                  </Select>
                </Form.Item>
              </Col> */} 
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='location'
                  label='Unit Name'
                  rules={[{ required: true, message: 'Enter Location' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange(
                        { target: { name: 'location', value } },
                        handleLocationChange
                      )
                    }
                    >
                    {location?.Response2?.slice(1).map((option) => (
                      option.id == userConfig?.locationId && 
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                    {/* <Option value={userConfig?.locationId ? userConfig?.locationId: userConfig?.location}>
                      {userConfig?.locationId ? userConfig?.locationId: userConfig?.location}
                    </Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='empCode'
                  label={
                    <Space>
                      Worker Code
                      <span></span>
                      <Tooltip
                        title='Generated by the Principal Employer'
                        placement='top'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  // rules={[
                  //   { required: true, message: 'Enter Worker Code' },
                  //   {
                  //     pattern: /^[a-zA-Z0-9]+$/,
                  //     message: 'Enter Alpha Numeric Only',
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={true} />
                </Form.Item>{' '}
              </Col>

              {/* testing for custom fields */}

              {/* {customFields.map((data) => {
                if (!data.isDefault && data.Area === 'personalDetails') {
                  return (
                    <Col className='gutter-row' span={6}>
                      <CustomFields data={data} />
                    </Col>
                  );
                }
              })} */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='epnNumber'
                  label='EPN Number'
                  // rules={[{ required: true, message: 'Enter EPN Number' }]}
                >
                  <Input size='large' disabled={true} />
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='cardType'
                  label='Card Type'
                  rules={[{ required: true, message: 'Enter Card' }]}>
                  <Select size='large' disabled={isDisabled}>
                    <Option value='C'>C</Option>
                    <Option value='R'>R</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              {/* {shouldDisplayEpnButton && (
              <Col className='gutter-row' span={6}>
                <Form.Item name='epnRenew' label='EPN Renew'>
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    style={{ width: '100%' }}
                    format='DD-MM-YYYY'
                  />
                </Form.Item>{' '}
              </Col>
              )} 
              {/* {shouldDisplayEpnButton && ( */}
              {(initialValues?.status === 'active' ||
                initialValues?.status === 'approved' ||
                initialValues?.status === 'inactive') && (
                <Col
                  className='gutter-row'
                  span={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      disabled={
                        initialValues?.gatePassStage < 2 ||
                        initialValues?.status == 'inactive' ||
                        initialValues?.Edited == 'Yes'
                      }
                      type='primary'
                      size='large'
                      onClick={handleModal}>
                      EPN Renewal/Cancellation Records
                    </Button>
                  </Form.Item>
                </Col>
              )}

              <Modal
                title='EPN Renew'
                open={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                width='1000px'>
                <Form
                  layout='vertical'
                  initialValues={{
                    ...initialValues,
                    agencyEsicNo: agencyEsicNo,
                  }}
                  // ref={formRef1}
                  form={modalForm}>
                  <Row gutter={16}>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='epnNumber'
                        label='EPN Number'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input size='large' disabled={true} />
                      </Form.Item>
                    </Col>

                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='LongName'
                        label='Worker Name'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input size='large' disabled={true} />
                      </Form.Item>
                    </Col>

                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='designation'
                        label='Worker Type'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input size='large' disabled={true} />
                      </Form.Item>
                    </Col>

                    {/* <Col className='gutter-row' span={6}>
                      <Form.Item name='shiftCode' label='Shift Code'>
                        <Input size='large' disabled={isDisabled} />
                      </Form.Item>
                    </Col> */}
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='contractorName'
                        label='Contractor'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input size='large' disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='jobOrderNumber'
                        label='WO Code'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input size='large' disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='agencyEsicNo'
                        label='ESIC Number'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <Input
                          size='large'
                          // defaultValue={
                          //   initialValues?.contractorDetails?.addJobDetails?.find(
                          //     (agency) =>
                          //       agency?.jobordernumber ==
                          //       initialValues?.jobOrderNumber
                          //   )?.esicno
                          // }
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    {/* 
                    <Col className='gutter-row' span={6}>
                      <Form.Item name='foCode' label='FO Code'>
                        <Input size='large' disabled={isDisabled} />
                      </Form.Item>
                    </Col> */}
                    {initialValues?.safetyCerticationEndDate && (
                      <Col span={6}>
                        <Form.Item
                          name='safetyCerticationEndDate'
                          label='Safety Validity To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Select Safety Validity To Date',
                            },
                          ]}>
                          <DatePicker
                            disabled
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {initialValues?.pmeValidityDate && (
                      <Col span={6}>
                        <Form.Item
                          name='pmeValidityDate'
                          label='PME validity To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Select PME validity To Date',
                            },
                          ]}>
                          <DatePicker
                            disabled
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={6}>
                      <Form.Item
                        name='periodContractorEndDate'
                        label='WO validity To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select WO validity To Date',
                          },
                        ]}>
                        <DatePicker
                          disabled
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name='wcvt'
                        label='WCP validity To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select WCP validity To Date',
                          },
                        ]}>
                        <DatePicker
                          disabled
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name='doe'
                        label='Labor License validity To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select LL validity To Date',
                          },
                        ]}>
                        <DatePicker
                          disabled
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='passValidUpto'
                        label='Pass Valid Upto'
                        rules={[
                          {
                            required: true,
                            message: 'Select Pass Valid Upto Date',
                          },
                        ]}>
                        {initialValues?.passValidUpto && (
                          <DatePicker
                            defaultValue={dayjs(
                              initialValues?.passValidUpto,
                              'DD-MM-YYYY'
                            )}
                            disabledDate={(d) =>
                              !d ||
                              d.isBefore(new Date()) ||
                              d.isAfter(initialValues?.passExtendedUpto)
                            }
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                            disabled={userConfig?.role === 'supervisor'}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='passExtendedUpto'
                        label='Pass Extended Upto'
                        rules={[
                          {
                            required: true,
                            message: 'Select Pass Extended Upto Date',
                          },
                        ]}>
                        <DatePicker
                          disabled
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    {userConfig.role != 'supervisor' && (
                      <>
                        <Col className='gutter-row' span={6}>
                          <Form.Item
                            name='cardType'
                            label='Card Type'
                            rules={[
                              { required: true, message: 'Enter Card Type' },
                            ]}>
                            <Select size='large'>
                              <Option value='R'>Renewal</Option>
                              <Option value='S'>Surrender</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={6}>
                          <Form.Item
                            name='cardLostType'
                            label='Card Lost Type'
                            rules={[
                              {
                                required: true,
                                message: 'Enter Card Lost Type',
                              },
                            ]}>
                            <Select
                              size='large'
                              onChange={(value) => {
                                console.log('Selected value:', value);
                                setCardLost(value);
                              }}>
                              <Option value='S'>Submitted</Option>
                              <Option value='L'>Lost</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          className='gutter-row'
                          span={6}
                          hidden={cardLost !== 'L'}>
                          <Form.Item
                            label='Attachment'
                            name='attachment'
                            valuePropName='fileList'
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: cardLost !== 'L' ? false : true,
                                message: 'Upload  Attachment',
                              },

                              // {
                              //   min: 12,
                              //   max: 12,
                              //   message: "UAN number should be 12 digits",
                              // },
                            ]}>
                            <Upload
                              // type="file"
                              listType='picture'
                              accept='image/*'
                              onPreview={handlePreview}
                              maxCount={1}
                              beforeUpload={() => false}
                              size='large'
                              style={{ width: '100%' }}
                              // disabled={PfDisabled || isDisabled}
                              onChange={onchange}>
                              <Button
                                icon={<UploadOutlined />}
                                size='large'
                                style={{ width: '100%' }}
                                onChange={onchange}>
                                Upload Image
                              </Button>
                            </Upload>
                          </Form.Item>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}>
                            <img
                              alt='example'
                              style={{
                                width: '100%',
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            name='LostRemark'
                            label='Lost Remark'
                            rules={[
                              {
                                required: true,
                                message: 'Enter Card Lost Remark',
                              },
                            ]}>
                            <Input size='large' />
                          </Form.Item>
                        </Col>
                        <Col
                          className='gutter-row'
                          span={6}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                              type='primary'
                              size='large'
                              onClick={handleEpnRenew}
                              loading={renewingEpn}
                              //disabled={!isDisabled || renewingEpn}
                              disabled={statusOfRenewEPNButton === 1}>
                              Submit
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {/* <Col span={6}>
                      <Form.Item
                        name='workerCodeForBribs'
                        label='Worker Code For BRIBS'>
                        <Input size='large' disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='bribsEnrolmentStatus'
                        label='BRIBS Enrolment Status'>
                        <Input size='large' disabled={isDisabled} />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Form>
                <Space
                  direction='vertical'
                  style={{ margin: '22px 12px', display: 'flex' }}>
                  <Table
                    bordered
                    dataSource={initialValues?.epnRenewHistory}
                    columns={columns}
                    // title={Title}
                    rowKey={(record) => record.id || undefined}
                    // rowKey={(record, index) => (record.id ? record.id.toString() + index.toString() : index)}
                  />
                </Space>
              </Modal>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='locationId'
                  label='Location ID'
                  rules={[{ required: true, message: 'Enter Location' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange(
                        { target: { name: 'locationId', value } },
                        handleLocationChange
                      )
                    }>
                    {/* {location?.Response2?.slice(1).map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.id}
                      </Option>
                    ))} */}
                    <Option value={userConfig?.locationId ? userConfig?.locationId: userConfig?.location}>
                      {userConfig?.locationId ? userConfig?.locationId: userConfig?.location}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='hazardousProcessArea'
                  label='Hazardous Process Area'
                  rules={
                    [
                      // { required: true, message: "Enter Hazardous Process Area" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({
                        target: { name: 'hazardousProcessArea', value },
                      })
                    }>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='technicalDetails'
                  label='Technical Details'
                  rules={
                    [
                      // { required: true, message: "Enter Technical Details" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'technicalDetails', value } })
                    }>
                    {technicalDetails?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='natureOfWork'
                  label='Type of Contract'
                  rules={
                    [
                      // { required: true, message: "Enter Technical Details" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'natureOfWork', value } })
                    }>
                    {natureOfWork?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='policeVerification'
                  label='Police Verification'
                  //  rules={[{ required: true, message: "Enter Police Verification" }]}
                >
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({
                        target: { name: 'policeVerification', value },
                      })
                    }>
                    <Option value='Yes'>Yes</Option>
                    <Option value='No'>No</Option>
                    <Option value='NA'>NA</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='gateNumber'
                  label='Gate Number'
                  rules={
                    [
                      // { required: true, message: "Enter Hazardous Process Area" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'gateNumber', value } })
                    }>
                    {gateNumber?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}></Col>
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingFromDate'
                  label='Safety Training From Date'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    format='DD-MM-YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingUptoDate'
                  label='Safety Training Upto Date'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    format='DD-MM-YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingRemarks'
                  label='Safety Training Remarks'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col> */}
              {/* <Col span={6}></Col> */}
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='blockRemark'
                  label='Block Remark'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={onChange}
                    name='blockRemark'
                  />
                </Form.Item>
              </Col> */}

              <Col className='gutter-row' span={6}>
                <Form.Item name='esicexempt' valuePropName='checked'>
                  <Checkbox
                    onClick={Esictoggle}
                    size='large'
                    className='exempt'
                    value={!EsicDisabled}
                    disabled={isDisabled}
                    // disabled={isDisabled || isEditingDisabled}
                  >
                    ESIC/WC Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='esicno'
                  label='ESIC/WC Number'
                  rules={[
                    {
                      required: EsicDisabled || isDisabled ? false : true,
                      message: 'Enter ESI Number',
                    },

                    // {
                    //   pattern:
                    //     '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$',
                    //   message: 'Invalid ESIC number',
                    // },
                  ]}>
                  <Input
                    size='large'
                    // placeholder='Ex: 41-00-123456-000-0001'
                    disabled={EsicDisabled}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='Uan' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    value={!UanDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled}>
                    UAN Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='uan'
                  label='UAN'
                  rules={[
                    {
                      required: UanDisabled || isDisabled ? false : true,
                      message: 'Enter UAN Number',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    // disabled={UanDisabled || isDisabled || initialValues?.Uan}
                    disabled={UanDisabled}
                    // placeholder='Ex: 123456789012'
                    onChange={onchange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='pf' valuePropName='checked'>
                  <Checkbox
                    onClick={Pftoggle}
                    size='large'
                    value={!PfDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled}>
                    PF Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pfAccountNo'
                  label='PF Account Number'
                  rules={[
                    {
                      required: PfDisabled || isDisabled ? false : true,
                      message: 'Enter PF Account Number',
                    },

                    // {
                    //   pattern: '[A-Z]{5}[0-9]{17}',
                    //   message: 'Invalid PF Account number ',
                    // },
                    {
                      pattern: /^[a-zA-Z0-9/]{1,20}$/,
                      message:
                        'PF account number must be 1-20 characters, using letters, numbers, or slashes.',
                    },
                  ]}>
                  <Input
                    size='large'
                    // placeholder='Ex: BGBNG24718350000010018'
                    disabled={PfDisabled}
                    onChange={onchange}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} hidden={!PfDisabled}>
                <Form.Item
                  label=' Form-11 Attachment'
                  name='caseForm11'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: !PfDisabled ? false : true,
                      message: 'Upload Case Form-11 Attachment',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    size='large'
                    style={{ width: '100%' }}
                    // disabled={PfDisabled || isDisabled}
                    onChange={onchange}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={onchange}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>
              {/* <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Policy Number'
                  name='wcpn'
                  rules={[
                    // {
                    //   pattern: '^[0-9]*$',
                    //   message: 'Only Numbers Allowed',
                    // },
                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Policy Number',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Insurance Valid From'
                  name='wcvf'
                  rules={[
                    // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Insurance Valid From',
                    },
                  ]}>
                  <DatePicker
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                    disabled={isDisabled}
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Insurance Valid To'
                  name='wcvt'
                  rules={[
                    // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Insurance Valid To',
                    },
                  ]}>
                  <DatePicker
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                    disabled={isDisabled}
                    size='large'
                  />
                </Form.Item>
              </Col> */}

              {/* Need Clarification on employment Status @cheked Steephen Mohan 23July2024 */}

              {initialValues?.status &&
                initialValues?.status !== 'rejected' &&
                initialValues?.status != 'pending' &&
                initialValues?.status !== '' &&
                // userConfig.role === 'supervisor' && (
                userConfig.role === 'approver' &&
                userConfig.level === 1 && (
                  <Col className='gutter-row' span={6}>
                    <Form.Item
                      name='statusToBeImposed'
                      label='Employment Status'
                      // rules={[{ required: true, message: "Enter Status" }]}
                    >
                      {/* {employmentStatus && (      */}
                      <Select
                        key={employmentStatus}
                        size='large'
                        onChange={handleStatusChange}
                        //disabled={initialValues?.gatePassStage < 2}
                        // placeholder={"employmentStatus"}
                      >
                        <Option value='active'>Active</Option>
                        <Option value='retired'>Retired</Option>
                        <Option value='terminated'>Terminated</Option>
                        {/* <Option value='rehired'>Rehired</Option> */}
                        {/* <Option value='suspended'>Suspended</Option> */}
                        <Option value='blocked'>Blocked</Option>
                        <Option value='blacklisted'>Blacklisted</Option>
                        <Option value='hibernate' disabled={true}>
                          Hibernate
                        </Option>
                      </Select>
                      {/* )} */}
                    </Form.Item>
                    {employmentStatus === 'active' ? (
                      <div>
                        <Form.Item name='statusFromDate' label='Active Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>

                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'retired' ? (
                      <div>
                        {/*<Form.Item name='statusFromDate' label='Retired Date'>
                        <DatePicker
                           size='large'
                           style={{ width: '100%' }}
                           format='DD-MM-YYYY'
                         />
                       </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Retired Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a retired date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'terminated' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Terminated Date'>
                        <DatePicker
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item> */}
                        <Form.Item
                          name='statusTerminateNoticeDate'
                          label='Terminated Notice Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a terminated notice date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusFromDate'
                          label='Terminated Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a terminated date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'rehired' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Rehired Date'>
                         <DatePicker
                           size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Rehired Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a rehired date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'suspended' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Suspended From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusToDate'
                          label='Suspended To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a to date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blocked' ? (
                      <div>
                        <Form.Item
                          name='blockType'
                          label='Block Type'
                          rules={[
                            {
                              required: true,
                              message: 'Please select Dropdown',
                            },
                          ]}>
                          <Select>
                            <Option value='permanent'>Permanent</Option>
                            <Option value='temporary'>Temporary</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name='statusFromDate'
                          label='Blocked From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        {blockType == 'temporary' && (
                          <Form.Item
                            name='statusToDate'
                            label='Blocked To Date'
                            rules={[
                              {
                                required: true,
                                message: 'Please select a to date',
                              },
                            ]}>
                            <DatePicker
                              style={{ width: '100%' }}
                              format='DD-MM-YYYY'
                            />
                          </Form.Item>
                        )}
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blacklisted' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Blacklisted Date'>
                        <DatePicker size='large' style={{ width: '100%' }} />
                      </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Blacklisted Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a blacklisted date',
                            },
                          ]}>
                          <DatePicker size='large' style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                  </Col>
                )}
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Personaldetails;
