import React, { useEffect, useState } from 'react';
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  Input,
  Form,
  Modal,
  notification,
  Row,
  Tabs,
  Checkbox,
} from 'antd';
import {
  PlusOutlined,
  DeleteFilled,
  EditFilled,
  EyeFilled,
} from '@ant-design/icons';
import {
  createReport,
  deleteReport,
  getAllReports,
  updateReport,
} from '../../../services/auth';
import useAuth from '../../../hooks/useAuth';
import ChartModal from '../Reports/ChartModal';
import { getSupplier } from '../../../services/supplier';

const { TabPane } = Tabs;
const { Group: CheckboxGroup } = Checkbox;

const roles = [
  'Local Admin',
  'Contractor',
  'Contract IR',
  'Regular IR',
  'Medical officer',
  'Safety officer',
];

const ManageReports = () => {
  const [reportsData, setReportsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAddMode, setIsAddMode] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [tabData, setTabData] = useState([]);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const { user, userConfig } = useAuth();

  useEffect(() => {
    getReportsData();
    fetchData();
  }, []);

  useEffect(() => {
    let data = [];
    if (activeTab == '1') data = reportsData.filter((r) => r.type == 'REPORTS');
    if (activeTab == '2')
      data = reportsData.filter((r) => r.type == 'ONBOARDING_DASHBOARD');
    if (activeTab == '3')
      data = reportsData.filter((r) => r.type == 'COMPLIANCE_DASHBOARD');

    form.setFieldsValue({ reports: data });
    setTabData(data);
  }, [activeTab]);

  const getReportsData = async () => {
    const response = await getAllReports();
    const data = response?.data?.data.filter((r) => r.type == 'REPORTS');
    form.setFieldsValue({
      reports: data,
    });
    setTabData(data);
    setReportsData(response?.data?.data);
  };

  const fetchData = async () => {
    try {
      const res = await getSupplier(userConfig.orgId);
      const list = [];
      const locationId = userConfig.location || userConfig.locationId;

      if (locationId == null) {
        // case of super admin
        const locationMap = {};
        res.data?.suppliers?.map((c) => {
          list.push(c.name);
          locationMap[c.location] = 1;
        });
        setLocationList(Object.keys(locationMap));
      } else if (locationId) {
        res.data?.suppliers?.map((c) => {
          if (c.location == locationId) {
            list.push(c.name);
          }
        });
        setLocationList([locationId]);
      }
      setContractorList(list);
    } catch (error) {
      console.error('Error fetching contractor name:', error);
    }
  };

  const handleEdit = (row, isAddModeVar) => {
    setSelectedRow(row);
    console.log('isAddModeVar', isAddModeVar);
    modalForm.setFieldsValue(
      isAddModeVar
        ? {
            reportName: '',
            baseUrl: '',
            chartId: '',
            editLink: '',
          }
        : tabData[row.key]
    );
    setIsModalVisible(true);
  };

  const getChartType = () => {
    let type = ''
    if (activeTab == '1')  type = 'REPORTS' 
    else if (activeTab == '2') type = 'ONBOARDING_DASHBOARD' 
    else type = 'COMPLIANCE_DASHBOARD'

    return type;
  }

  const handleModalOk = () => {
    modalForm.validateFields().then(async (values) => {
      const reports = form.getFieldValue('reports');
      let data = {};
      const updatedReports = reports.map((report, index) => {
        if (index === selectedRow.key) {
          data = report;
          return { ...report, ...values };
        }
        return report;
      });
      form.setFieldsValue({ reports: updatedReports });

      if (isAddMode) {
        await createReport({
          ...values,
          type: getChartType(),
        });
        setIsAddMode(false);
      } else {
        await updateReport({ ...values, _id: data._id });
      }
      setIsModalVisible(false);
      setSelectedRow(null);
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleDelete = async (remove, record) => {
    const reports = form.getFieldValue('reports');
    const data = reports[record.key];
    try {
      await deleteReport(data?._id);
      remove(record.name);
      notification.success({ message: 'Successfully deleted the report.' });
    } catch (error) {
      notification.error({ message: 'Error while deleting report.' });
    }
  };

  const handleView = (record) => {
    setSelectedRow(record);
    setViewModalVisible(true);
  };

  const columns = (remove) => [
    {
      title: 'SI. No.',
      dataIndex: 'index',
      width: 100,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: `${activeTab == '1' ? 'Report Name' : 'Chart Name'}`,
      dataIndex: 'reportName',
      width: 600,
      render: (_, record) => (
        <Form.Item name={[record.name, 'reportName']}>
          <Input disabled />
        </Form.Item>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <Row style={{ marginTop: '-20px' }}>
          <Typography.Link onClick={() => handleView(record)}>
            <EyeFilled />
          </Typography.Link>
          <Typography.Link
            style={{ margin: '0px 10px 0px 10px' }}
            onClick={() => handleEdit(record, false)}>
            <EditFilled />
          </Typography.Link>
          <Popconfirm
            title='Delete'
            onConfirm={() => handleDelete(remove, record)}>
            <Typography.Link>
              <DeleteFilled />
            </Typography.Link>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  const renderFooter = (add) => (
    <Button
      type='dashed'
      htmlType='button'
      onClick={() => {
        add();
        setIsAddMode(true);
        console.log('val', form.getFieldsValue('reports'));
        handleEdit(
          {
            key: form.getFieldsValue('reports')?.reports?.length - 1,
          },
          true
        );
      }}
      block
      icon={<PlusOutlined />}>
      {`Add ${activeTab == '1' ? 'Report' : 'Chart'}`}
    </Button>
  );

  return (
    <>
      <Tabs
        defaultActiveKey='1'
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}>
          
        <TabPane tab='Reports' key='1' />
        <TabPane tab='Onboarding Dashboard' key='2' />
        <TabPane tab='Compliance Dashboard' key='3' />
      </Tabs>
      <Form
        form={form}
        initialValues={{
          reports: reportsData.filter((r) => r.type == 'REPORT'),
        }}
        layout='vertical'>
        <Form.List name='reports'>
          {(fields, { add, remove }) => (
            <Table
              columns={columns(remove)}
              dataSource={fields.map((field) => ({
                ...field,
                key: field.key,
              }))}
              style={{ maxWidth: '800px' }}
              footer={() => renderFooter(add)}
              pagination={false}
              locale={{
                emptyText: `No ${
                  activeTab == '1' ? 'reports' : 'charts'
                } added.`,
              }}
            />
          )}
        </Form.List>
      </Form>

      {/* Edit Modal */}
      <Modal
        title={isAddMode ? 'Add Report' : 'Edit Report'}
        open={isModalVisible}
        onOk={handleModalOk}
        maskClosable={false} // Prevent closing on outside click
        onCancel={handleModalCancel}>
        <Form form={modalForm} layout='vertical'>
          <Form.Item
            name='reportName'
            label='Report Name'
            rules={[{ required: true, message: 'Please enter report name' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='baseUrl'
            label='Base Url'
            rules={[{ required: true, message: 'Please enter base url' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='chartId'
            label='Chart Id'
            rules={[{ required: true, message: 'Please enter chart Id' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='editLink'
            label='Edit Link'
            rules={[{ required: true, message: 'Please enter edit link' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='viewAccessRoles'
            label='View access for roles'
            rules={[{ required: true, message: 'Please select at least one role' }]}
          >
            <CheckboxGroup options={roles} />
          </Form.Item>
        </Form>
      </Modal>

      {/* View Report Modal */}
      {viewModalVisible && (
        <ChartModal
          modalVisible={viewModalVisible}
          setModalVisible={setViewModalVisible}
          selectedReport={tabData[selectedRow.key]}
          userConfig={userConfig}
          contractorList={contractorList}
          locationList={locationList}
          isManageScreen={true}
        />
      )}
    </>
  );
};

export default ManageReports;
