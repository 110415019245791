import React, { useCallback, useEffect } from 'react';
import 'form-builder/dist/style.css';

import { Builder, useFormStore } from 'form-builder';
import { notification } from 'antd';
import { saveFormSchema } from './../../api/dynamicForm';

const FormBuilder = () => {
  const reset = useFormStore((state) => state.reset);

  useEffect(() => {
    reset(true);
  }, []);
  const onSchemaSave = async (schema) => {
    try {
      const response = await saveFormSchema(schema);
      if (response?.status == 200) {
        notification.success({
          message: 'Form saved successfully!',
        });
      }
    } catch (error) {
      notification.error({
        message: error?.message,
      });
    }
  };

  return <Builder onFormSave={onSchemaSave} />;
};

export default FormBuilder;
