import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row, Typography, Spin, Table } from 'antd';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

import Header from './Header';
import { getMusterrollData } from '../../../../../services/auth';
import { getLocalDate } from '../../../../../utils/helperFunction';

const WageCumMusterroll = (props) => {
  const {
    userConfig,
    contractorList,
  } = props;
  const [filters, setFilters] = React.useState({
    contractors: null,
    locations: [],
    dateRange: [],
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { title: 'SI. No.', dataIndex: 'key', key: 'key' },
    { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId' },
    { title: 'Name of Employee', dataIndex: 'name', key: 'name' },
    { title: 'Designation/Nature of Work', dataIndex: 'designation', key: 'designation' },
    { title: '01', dataIndex: '1', key: '01' },
    { title: '02', dataIndex: '2', key: '02' },
    { title: '03', dataIndex: '3', key: '03' },
    { title: '04', dataIndex: '4', key: '04' },
    { title: '05', dataIndex: '5', key: '05' },
    { title: '06', dataIndex: '6', key: '06' },
    { title: '07', dataIndex: '7', key: '07' },
    { title: '08', dataIndex: '8', key: '08' },
    { title: '09', dataIndex: '9', key: '09' },
    { title: '10', dataIndex: '10', key: '10' },
    { title: '11', dataIndex: '11', key: '11' },
    { title: '12', dataIndex: '12', key: '12' },
    { title: '13', dataIndex: '13', key: '13' },
    { title: '14', dataIndex: '14', key: '14' },
    { title: '15', dataIndex: '15', key: '15' },
    { title: '16', dataIndex: '16', key: '16' },
    { title: '17', dataIndex: '17', key: '17' },
    { title: '18', dataIndex: '18', key: '18' },
    { title: '19', dataIndex: '19', key: '19' },
    { title: '20', dataIndex: '20', key: '20' },
    { title: '21', dataIndex: '21', key: '21' },
    { title: '22', dataIndex: '22', key: '22' },
    { title: '23', dataIndex: '23', key: '23' },
    { title: '24', dataIndex: '24', key: '24' },
    { title: '25', dataIndex: '25', key: '25' },
    { title: '26', dataIndex: '26', key: '26' },
    { title: '27', dataIndex: '27', key: '27' },
    { title: '28', dataIndex: '28', key: '28' },
    { title: '29', dataIndex: '29', key: '29' },
    { title: '30', dataIndex: '30', key: '30' },
    { title: '31', dataIndex: '31', key: '31' },
    { title: 'Total Attendances/Units of Work Done', dataIndex: 'totalAttendances', key: 'totalAttendances' },
    { title: 'Daily-rate of wages/piece rate', dataIndex: 'dailyRate', key: 'dailyRate' },
    { title: 'Basic Wages', dataIndex: 'basicWages', key: 'basicWages' },
    { title: 'Dearness Allowance', dataIndex: 'dearnessAllowance', key: 'dearnessAllowance' },
    { title: 'Overtime', dataIndex: 'overtime', key: 'overtime' },
    { title: 'Other Cash Payments', dataIndex: 'otherCashPayments', key: 'otherCashPayments' },
    { title: 'Total', dataIndex: 'amountEarned', key: 'amountEarned' },
    { title: 'Deductions, if any (Indicate Nature)', dataIndex: 'deductions', key: 'deductions' },
    { title: 'Net Amount Paid', dataIndex: 'netAmountPaid', key: 'netAmountPaid' },
    { title: 'Signature/Thumb impression of workman', dataIndex: 'signature', key: 'signature' },
    { title: 'Initial of Contractor or his Representative', dataIndex: 'contractorInitial', key: 'contractorInitial' },
  ];

  const downloadCSV = async () => {
    const documents = data?.musterrollData;

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Wage Cum Musterroll');

    // Step 2: Add the header section
    const headerSection = [
      'Contract Labour (Regulation and Abolition) Central Rules',
      'Form XVIII [(See Rule 78(1) (a)(i))]',
      'Form of Register of Wages cum Muster Roll',
      `Name and address of Contractor : ${data?.contractorDetails?.name}`,
      `Nature and location of work : ${data?.contractorDetails?.location}`,
      `Name and address of establishment: ${data?.contractorDetails?.location}`,
      `Month: ${dayjs(filters?.dateRange[0]).format('MMMM-YYYY')}`,
    ];

    headerSection.forEach((headerText, index) => {
      const headerRow = worksheet.addRow([headerText]);
      headerRow.font = { bold: true, size: 14, name: 'Times New Roman' };
      headerRow.alignment = { horizontal: (index > 2) ? 'left' : 'center' };
      headerRow.height = 20;
      worksheet.mergeCells(`A${index + 1}:AH${index + 1}`);
    });

    // Step 3: Group headers for "Amount of Wages Earned"
    worksheet.mergeCells('AK7:AO7');
    worksheet.getCell('AK7').value = 'Amount of Wages Earned';
    worksheet.getCell('AK7').alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell('AK7').font = { bold: true };

    // Step 5: Define the table headers
    const tableHeaders = [
      "SI. No.",
      "Employee ID",
      "Name of Employee",
      "Designation/Nature of Work",
      ...Array.from({ length: 30 }, (_, i) => `${i + 1}`), // Day-wise attendance columns
      "Total Attendances/Units of Work Done",
      "Daily-rate of wages/piece rate",
      "Basic Wages",
      "Dearness Allowance",
      "Overtime",
      "Other Cash Payments (Nature of payment to be indicated)",
      "Total",
      "Deductions, if any (Indicate Nature)",
      "Net Amount Paid",
      "Signature/Thumb impression of workman",
      "Initial of Contractor or his Representative",
    ];

    // Add headers to the worksheet
    const headerRow = worksheet.addRow(tableHeaders);
    headerRow.font = { bold: true, name: 'Times New Roman' };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Step 6: Add table data
    documents.forEach((doc, index) => {
      const rowData = [
        index + 1,
        doc.employeeId,
        doc.name,
        doc.designation,
        ...Array.from({ length: 30 }, (_, i) => doc[i + 1] || ''), // Filling daily attendance columns
        doc.totalAttendances,
        doc.dailyRate,
        doc.basicWages,
        doc.dearnessAllowance,
        doc.overtime,
        doc.otherCashPayments,
        doc.amountEarned,
        doc.deductions,
        doc.netAmountPaid,
        doc.signature,
        doc.contractorInitial,
      ];

      const row = worksheet.addRow(rowData);
      row.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Step 7: Set column widths
    worksheet.columns = [
      { width: 10 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      ...Array.from({ length: 30 }, () => ({ width: 5 })), // Days columns
      { width: 20 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
    ];

    // Step 8: Add borders to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Step 9: Write the workbook to a file and trigger
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Wage_Cum_Musterroll.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  const applyFilters = async (filters) => {
    setLoading(true);

    try {
      const { contractors, dateRange } = filters;
      const response = await getMusterrollData({
        contractorName: contractors,
        startDate: getLocalDate(dateRange[0]),
        endDate: getLocalDate(dateRange[1])
      });
      setData(response?.data?.data);
    } catch (error) {
      setData({ musterrollData: [], contractorDetails: {} });
    } finally {
      setLoading(false);
    }
  };

  return (
      <>
        <Header
          filters={filters}
          setFilters={setFilters}
          downloadCSV={downloadCSV}
          userConfig={userConfig}
          contractorList={contractorList}
          applyFilters={applyFilters}
          loading={loading}
          disableDownload={!data?.musterrollData?.length || loading}
        />
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={data?.musterrollData}
            scroll={{ x: true }}
            bordered
            title={() => (<></>)}
          />
        </Spin>
      </>
  );
};

export default WageCumMusterroll;
