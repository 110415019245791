import RegisterOfContractors from "./Components/RegisterOfContractors";
import RegisterOfContractWorkMenByContractor from "./Components/RegisterOfWorkMenByContractor";
import WageCumMusterroll from "./Components/WageCumMusterRoll";
import WageRegister from "./Components/WageRegister";


const MappedReport = (props) => {
  const {
    selectedReport,
    userConfig,
    locationList,
    contractorList,
    isManageScreen,
    locationwiseContractors,
  } = props;

  let MappedReport = <></>

  if (selectedReport.reportName == "Wage Cum Musterroll") MappedReport = WageCumMusterroll
  if (selectedReport.reportName == "Register of Contractors - Form XII") MappedReport = RegisterOfContractors
  if (selectedReport.reportName == "Register of Contract Workmen employed by Contractor - Form XIII") MappedReport = RegisterOfContractWorkMenByContractor
  if (selectedReport.reportName.includes('Wage Register')) MappedReport = WageRegister

  return (<MappedReport
    contractorList={contractorList}
    userConfig={userConfig}
    locationList={locationList}
    selectedReport={selectedReport}
    isManageScreen={isManageScreen}
    locationwiseContractors={locationwiseContractors}
  />)
}

export default MappedReport;