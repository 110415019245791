import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

import Header from './Header';
import { getSupplierByLocationAndContractValidity } from '../../../../../services/supplier';

const RegisterOfContractors = (props) => {
  const {
    userConfig,
    contractorList,
    locationList,
  } = props;

  const [filters, setFilters] = React.useState({
    contractors: null,
    locations: null,
    dateRange: [],
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const today = dayjs();

  useEffect(() => {
    const initialFilters = {
      dateRange: [today.subtract(1, 'month').startOf('day'), today],
      locations: null
    }
    setFilters(initialFilters)
    //applyFilters(initialFilters)
  }, [])

  const columns = [
    { title: 'Sl. No.', dataIndex: 'slNo', key: 'slNo' },
    { title: 'Name and address of contractor', dataIndex: 'contractorName', key: 'contractorName' },
    { title: 'Nature of work of contractor', dataIndex: 'natureOfWork', key: 'natureOfWork' },
    { title: 'Location of contract work', dataIndex: 'location', key: 'location' },
    { title: 'Period of Contract From', dataIndex: 'fromDate', key: 'fromDate', width: 120 },
    { title: 'Period of Contract To', dataIndex: 'toDate', key: 'toDate', width: 120 },
    // { title: 'Maximum No. of workmen employed by contractor', dataIndex: 'maxWorkmen', key: 'maxWorkmen' },
    { title: 'Maximum No. of workmen employed by contractor', dataIndex: 'totalLicenceCoverage', key: 'totalLicenceCoverage' },
    { title: 'Labour License Number', dataIndex: 'lln', key: 'lln' },
  ];

  const addNewRow = (worksheet, rowData, index) => {
    const newRow = worksheet.addRow();
    newRow.font = { size: 9, name: 'Arial' };
    newRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.mergeCells(`A${index}:C${index}`);
    worksheet.getCell(`A${index}`).value = rowData[0];
    worksheet.mergeCells(`D${index}:G${index}`);
    worksheet.getCell(`D${index}`).value = rowData[1];
    worksheet.mergeCells(`H${index}:I${index}`);
    worksheet.getCell(`H${index}`).value = rowData[2];
    worksheet.getCell(`J${index}`).value = rowData[3];
    worksheet.getCell(`K${index}`).value = rowData[4];
    worksheet.mergeCells(`L${index}:N${index}`);
    worksheet.getCell(`L${index}`).value = rowData[5];
    worksheet.mergeCells(`O${index}:P${index}`);
    worksheet.getCell(`O${index}`).value = rowData[6];
    worksheet.getCell(`Q${index}`).value = rowData[7];
  }

  const selectedLocation = userConfig?.organisation?.locations?.filter(l => l.id == filters?.locations)[0]

  const downloadCSV = async () => {
    const documents = data;

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    // Step 2: Add the image to cell A1
    const base64Data = userConfig?.organisation?.logoFileData?.replace(/^data:image\/\w+;base64,/, '');
    if(base64Data) {
      // Decode Base64 to binary string using atob
      const binaryString = atob(base64Data);

      // Convert binary string to Uint8Array
      const uint8Array = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      const imageId = workbook.addImage({
        buffer: uint8Array,
        extension: 'png', // Change to 'jpeg' if your image is a JPEG
      });
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 }, // Top-left corner at column 0 (A), row 0 (1)
        br: { col: 1, row: 1 }, // Bottom-right corner at column 1 (B), row 1 (2)
      });
    }
    
    // Step 3: Adjust the size of cell A1 to fit the image
    const sheetHeaderRow = worksheet.getRow(1)
    sheetHeaderRow.height = 55; // Set row height
    sheetHeaderRow.font = { bold: true, size: 10, name: 'Arial' };
  
    // Step 4: Add the formatted text to a cell
    const headerText = `Contract Labour (Regulation and Abolition) Central Rules\nForm XII [(See Rule 74(1) (a)(ii)]\nRegister Of Contractors`;
    worksheet.mergeCells('A1:F1');
    worksheet.mergeCells('G1:L1');
    worksheet.mergeCells('M1:Q1');

    // Apply formatting to the cell
    const headerCell = worksheet.getCell('G1');
    headerCell.value = headerText;
    headerCell.alignment = {
      vertical: 'middle', // Center vertically
      horizontal: 'center', // Center horizontally
      wrapText: true, // Enable text wrapping
    };

    worksheet.mergeCells('A2:M3');
    worksheet.mergeCells('N2:O2');
    worksheet.mergeCells('N3:O3');
    worksheet.getCell('N2').value = 'Executed on :';
    const now = new Date();
    const formattedTime = now.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    }).replace(/,/g, ''); // Remove commas
    worksheet.mergeCells('P2:Q2');
    worksheet.getCell('P2').value = formattedTime
    worksheet.getCell('N3').value = 'Printed for:';
    worksheet.mergeCells('P3:Q3');
    worksheet.getCell('P3').value = userConfig?.userid;
    worksheet.getRow(2).font = { size: 7.5, name: 'Arial' }
    worksheet.getRow(3).font = { size: 7.5, name: 'Arial' }

    worksheet.mergeCells('A4:I4');
    worksheet.getCell('A4').value = 'Name and Address of the Principal Employer:';
    worksheet.mergeCells('J4:Q4');
    worksheet.getCell('J4').value = `${selectedLocation?.name || ''}  ${selectedLocation?.address || ''}`;  // location name and address
    worksheet.getRow(4).font = { size: 9, name: 'Arial' }

    worksheet.mergeCells('A5:I5');
    worksheet.getCell('A5').value = 'Name and Address of the Establishment:';
    worksheet.mergeCells('J5:Q5');
    worksheet.getCell('J5').value = `${userConfig?.organisation?.name || ''} ${userConfig?.organisation?.locations[0]?.address || ''}`; // org name and ho location address
    worksheet.getRow(5).font = { size: 9, name: 'Arial' }

    // Step 5: Group headers for "Period of Contract"
    
    worksheet.mergeCells('K6:N6');
    worksheet.getCell('K6').value = 'Period of Contract';
    worksheet.getCell('K6').alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell('K6').font = { bold: true, size: 9, name: 'Arial' };

    // Step 6: Define the table headers
    const tableHeaders = [
      'Sl. No.',
      'Name and address of contractor',
      'Nature of work of contractor',
      'Location of contract work',
      'From',
      'To',
      'Maximum No. of workmen employed by contractor',
      'Labour License Number'
    ];

    worksheet.addRow()
  
    // Add headers to the worksheet
    addNewRow(worksheet, tableHeaders, 7)
    const headerRow = worksheet.getRow(7);
    headerRow.font = { bold: true, size: 9, name: 'Arial' };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  
    // Step 7: Add table data
    documents.forEach((doc, index) => {
      const rowData = [
        doc.slNo, // Sl. No.
        doc.contractorName, // Name and address of contractor
        doc.natureOfWork, // Nature of work of contractor
        selectedLocation?.name, // Location of contract work
        doc.fromDate, // From
        doc.toDate, // To
        doc.totalLicenceCoverage, // Maximum No. of workmen employed by contractor
        doc.lln
      ];

      addNewRow(worksheet, rowData, 8+index)
    });

    // Step 8: Set column widths
    worksheet.columns = [
      { width: 10 },
      { width: 5 },
      { width: 10 },
      { width: 6 },
      { width: 3 },
      { width:3.5 },
      { width: 10 },
      { width: 1 },
      { width: 12.6 },
      { width: 17 },
      { width: 25.7 },
      { width: 11 },
      { width: 1.7 },
      { width: 11.3 },
      { width: 0.5 },
      { width: 24.6 },
      { width: 25 },
    ];

    // Step 9: Add borders to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Step 10: Write the workbook to a file and trigger
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filters?.locations} - Register of Contractors Report.xlsx - Form XII`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  const applyFilters = async (filters) => {
    setLoading(true);

    try {
      const { dateRange, locations } = filters;
      const response = await getSupplierByLocationAndContractValidity({
        location: locations,
        startDate: dateRange[0] ,//getLocalDate(dateRange[0]),
        endDate: dateRange[1]//getLocalDate(dateRange[1])
      });
      const data = response?.data?.suppliers
      setData(data.map(c => ({...c, location: selectedLocation?.name})));
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        downloadCSV={downloadCSV}
        userConfig={userConfig}
        contractorList={contractorList}
        locationList={locationList}
        applyFilters={applyFilters}
        loading={loading}
        disableDownload={!data?.length || loading}
      />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: true }}
          bordered
          title={() => (<></>)}
          pagination={{ pageSize: 50 }}
        />
      </Spin>
    </>
  );
};

export default RegisterOfContractors;
