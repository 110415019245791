import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Dropdown,
  Menu,
  Typography,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import '../../../styles.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Header = (props) => {
  const {
    filters,
    setFilters,
    downloadCSV,
    userConfig,
    contractorList,
    applyFilters,
    loading,
    disableDownload,
  } = props;

  const today = dayjs();

  const [dateOption, setDateOption] = useState('Last Month');
  const [selectedDateRange, setSelectedDateRange] = useState([
    today.subtract(1, 'month').startOf('day'),
    today,
  ]);

  const dateOptions = ['Last Week', 'Last Month', 'Custom Range'];

  useEffect(() => {
    setFilters((prev) => ({ ...prev, dateRange: [today.subtract(1, 'month').startOf('day'), today] }));
  }, [])

  const handleDateChange = (value) => {
    setDateOption(value);

    let range = [];

    if (value === 'Today') {
      range = [today, today];
    } else if (value === 'Last Week') {
      range = [today.subtract(7, 'day'), today];
    } else if (value === 'Last Month') {
      range = [today.subtract(1, 'month').startOf('day'), today];
    } else {
      range = null;
    }

    setSelectedDateRange(range);
    setFilters((prev) => ({ ...prev, dateRange: range }));
  };

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    setFilters((prev) => ({ ...prev, dateRange: dates }));
  };

  return (
    <div
      style={{
        border: '1px solid #f0f0f0',
        padding: '16px',
        borderRadius: '4px',
      }}>
      <Row gutter={[16, 16]} align='middle'>
        <Col span={24}>
          <Row align='middle'>
            <Col flex='100px'>
              <Typography.Text strong>Date</Typography.Text>
            </Col>
            <Col flex='auto'>
              <Select
                className="custom-select"
                popupClassName="custom-select-dropdown"
                value={dateOption}
                style={{ width: '110px' }}
                onChange={handleDateChange}
                options={dateOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
              />
              <RangePicker
                style={{
                  marginLeft: '16px',
                  maxWidth: '186px',
                  height: '32px',
                }}
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                className='custom-range-picker'
                disabled={dateOption != 'Custom Range'}
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Button
                style={{ marginRight: '5px' }}
                type='primary'
                disabled={loading || (!filters.contractors)}
                onClick={() => applyFilters(filters)}>
                Apply
              </Button>
              <Button disabled={disableDownload} onClick={downloadCSV} icon={<DownloadOutlined />} />
            </Col>
          </Row>
        </Col>

        {contractorList.length > 0 && (
          <Col span={22}>
            <Row align='middle'>
              <Col flex='100px'>
                <Typography.Text strong>Contractor</Typography.Text>
              </Col>
              <Col flex='auto'>
                <Select
                  style={{ width: '200px' }}
                  onChange={(value) =>
                    setFilters((prev) => ({ ...prev, contractors: value }))
                  }
                  placeholder="Select Contractor">
                  {
                    contractorList?.map(c =>
                      <Option value={c}>{c}</Option>
                    )
                  }
                </Select>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Header;
