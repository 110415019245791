import React from 'react';
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import AdminFormModal from './AdminFormModal';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import DesignationFormModal from './DesignationFormModal';

const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: '',
  roleName: '',
  locationId: '',
  userid: '',
  password: '',
  emailId: '',
  level:'',
  departmentNameDepartmentCode:'',
  sectionNameSectionCode:'',
};

export default function OrganizationDesignationForm({
  initialValues,
  locationsData,
  onNext,
  onPrev,
}) {
  const [data, setData] = React.useState(initialValues);

  const { userConfig } = useAuth();

  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  const formRef = React.useRef();
  const navigate = useNavigate();

  const [editIndex, setEditIndex] = React.useState(null);
  // const isEdit = editIndex !== null;
  const [isEdit, setIsEdit] = React.useState(false);

  const [adminFormOpen, setAdminFormOpen] = React.useState(false);
  const roleName = userConfig?.roleName;
  const selectedRoleType = userConfig?.selectedRoleType;
  const adminLocation = userConfig?.location;
  //

  const tableData = React.useMemo(() => {
    return data?.map((userData, index) => ({
      index: index,
      name: userData.firstName + ' ' + userData.lastName,
      userid: userData.userid,
      role: userData.role[0].toUpperCase() + userData.role.slice(1),
      // supplierId: userData.supplierId,
      locationId: userData.locationId,
      location: userData.location,
      roleName: userData?.roleName?.selectedRoleType,
    }));
  }, [data]);

  //new added
  const adminData = tableData?.filter(
    (item) =>
      item.role === 'Approver' || item.role === 'Shop' || item.role === 'Ir' || item.role === 'Department' 
  );

  const dataSource =
    userConfig?.location || userConfig?.locationId
      ? adminData.filter((item) => {
          const isAdmin =
            item.role === 'Approver' ||
            item.role === 'Shop' ||
            item.role === 'Ir'||
            item.role === "Department";
          // const isMatchingLocation = item.location === userConfig.location;
          // return isAdmin;
          const isMatchingLocation =
            item.locationId === userConfig.location ||
            item.locationId === userConfig.locationId;
          return isAdmin && isMatchingLocation;
        })
      : adminData;

  const openAdminModal = () => {
    setIsEdit(false);

    setFormInitialValue(DEFAULT_ADMIN_FORM_VALUE);
    setAdminFormOpen(true);
  };

  const addAdmin = (value, isEdit = false) => {
    if (isEdit) {
      setData((currData) => {
        return currData.map((d, index) => {
          if (index === editIndex) {
            //
            return value;
          }

          return d;
        });
      });

      setEditIndex(null);
    } else {
      setData((currData) => [...currData, value]);
    }
    setAdminFormOpen(false);
  };
  const edit = (record) => {
    setEditIndex(record.index);
    setIsEdit(true);

    setFormInitialValue(
      data.find((d) => d.userid === record.userid) || DEFAULT_ADMIN_FORM_VALUE
    );
    setAdminFormOpen(true);
  };

  const deleteRecord = (record) => {
    setData(data.filter((_, i) => record.index !== i));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'User ID',
      dataIndex: 'userid',
      width: '20%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      editable: true,
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',

      render: (_, record) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
              }
              onClick={() => edit(record)}>
              <EditFilled />
              Edit
            </Typography.Link>
            {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
              <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
                <DeleteFilled />
                Delete
              </Typography.Link>
            </Popconfirm>  */}
          </Space>
        );
      },
    },
  ];

  const checkIsValidAddition = (userData = {}) => {
    if (userData) {
      if (
        data?.filter(
          (user) =>
            user.userid !== formInitialValue.userid &&
            user.userid === userData.userid
        ).length > 0
      ) {
        notification.error({ message: 'User ID already exists' });
        return false;
      }

      if (
        data?.filter(
          (user) =>
            user.emailId !== formInitialValue.emailId &&
            user.emailId === userData.emailId
        ).length > 0
      ) {
        notification.error({ message: 'Email ID already exists' });
        return false;
      }

      return true;
    }
    return false;
  };

  const Title = () => {
    return (
      <Form.Item>
        <Button
          type='dashed'
          onClick={openAdminModal}
          block
          // disabled={userConfig.roleName}
          icon={<PlusOutlined />}>
          Add Designation
        </Button>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    //

    onNext(data);
    // navigate('/home/admin');
  };

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 12px', display: 'flex' }}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        title={Title}
        rowKey='userid'
      />
      {/* <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}> */}
      <DesignationFormModal
        open={adminFormOpen}
        onOk={addAdmin}
        onCancel={() => setAdminFormOpen(false)}
        checkIsValidAddition={checkIsValidAddition}
        initialValues={formInitialValue}
        isEdit={isEdit}
        locationsData={locationsData}
        adminData={adminData}
      />

      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => onPrev(data)}>Prev</Button>
        {/* <Form.Item noStyle> */}
        <Button
          type='primary'
          onClick={() => onNext(data)}
          hidden={userConfig.role == 'ir'}>
          Next
        </Button>
        {/* </Form.Item> */}
      </Space>
      {/* </Form> */}
    </Space>
  );
}
