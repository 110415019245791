//     [2020] - [2024] Wikiworks Innovations Private Limited.
//  All Rights Reserved.

// NOTICE:  All information contained herein is, and remains
// the property of Wikiworks its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Wikiworks
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process and are protected by trade secret or copyright
// law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Wikiworks.
// """

import React, { useState, useEffect } from 'react';

import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Typography,
  notification,
  Divider,
  Upload,
  Modal,
} from 'antd';
import dayjs from 'dayjs';

import useAuth from '../../hooks/useAuth';
import EventHistoryModal from './EventHistoryModal';
import {
  UploadOutlined,
  PlusSquareOutlined,
  EyeFilled,
} from '@ant-design/icons';
import {
  getWorkmenLimit,
  jobOrderCount,
  getExcelData,
  getSportsGroup,
  getPSNumber,
  getRNNumber,
} from '../../services/auth';
const { Option } = Select;
const { Title } = Typography;

const Jobdetails = ({
  initialValues,
  setjobDetails,
  currentBasic,
  onNext,
  onPrev,
  isViewOnly,
  masterData,
  personalDetails,
  targetSheet,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* eslint-disable */
  const [data, setData] = React.useState(initialValues);
  

  // const [datas, setDatas] = React.useState(initialValues);

  const [Isworking, setIsworking] = React.useState(true);
  const [Empstatus, setEmpstatus] = React.useState(true);
  // const [addEvent, setAddEvent] = React.useState(false);
  // const [addEvent, setAddEvent] = React.useState(
  //   initialValues?.aadEvent || false
  // );
  const [addEvent, setAddEvent] = React.useState(
    window?.localStorage?.getItem('addEventDepartmentCode') == 'true'
      ? true
      : false
  );

  const [deptHead1, setDeptHead] = React.useState('');
  const [ImmediateFuncSupervisor, setImmediateFuncSupervisor] =
    React.useState('');
  const [OfficeTel, setOfficeTel] = React.useState('');

  const [ImmediateAdminSupervisor, setImmediateAdminSupervisor] =
    React.useState('');
  const [ContactSupevisor, setContactSupervisor] = React.useState('');
  const [Nextfuncsupervisor, setNextfuncsupervisor] = React.useState('');

  // const [deptHead1, setDeptHead] = React.useState('');

  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  // const isDisabled = data?.approvalStage > 0;
  // const [selectedContractorCode, setSelectedContractorCode] = React.useState(null);
  const [eventHistoryTableOpen, setEventHistoryTableOpen] =
    React.useState(false);

  const [jobOrderList, setJobOrderList] = React.useState([]);

  // const [selectedContractorName, setSelectedContractorCode] = React.useState(null);
  const [selectedJobOrder, setSelectedJobOrder] = React.useState(null);
  // const [selectedJobOrder, setSelectedJobOrder] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(false);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [contractorName, setContractorName] = React.useState([]);
  const [functionCodeMaster, setFunctionCodeMaster] = React.useState([]);
  const [currentStatusMaster, setCurrentStatusMaster] = React.useState([]);
  const [cadreCodeMaster, setCadreCodeMaster] = React.useState([]);
  const [psNo, setPsNo] = useState(""); // Local state for Previous PS Number


  const [bloodGroup, setBloodGroup] = React.useState([]);
  const [busRouteMaster, setBusRouteMaster] = React.useState([]);
  const [categoryCode, setCategoryCode] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [shiftList, setShiftList] = React.useState([]);
  const [cadreCode, setCadreCode] = React.useState([]);
  const [qualification, setQualification] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const [masterDataShop, setMasterDataShop] = React.useState([]);
  const [drmr, setDrmr] = React.useState([]);
  const [Area, setArea] = React.useState([]);
  const [CategoryProbation, setCategoryProbation] = React.useState([]);
  const [psNumber, setPSNumber] = useState('');
  const [rnNumber, setRNNumber] = useState('');
  const [currentCadre, setCurrentCadre] = useState('');
  const [oldPsNumber, setoldPsNumber] = useState('');
  const [lastDateCadre, setlastDateCadre] = useState('');
  const [processedValues, setProcessedValues] = useState({ ...initialValues });
  const [ exTrainee, setExTrainee] = useState(false)

  // CategoryCode
  const [CategoryCode, setCategoryCodes] = React.useState([]);
  const [isSportAssigned, setIsSportAssigned] = React.useState(false);
  const [IsworkingHandicaped, setIsworkingHandicaped] = React.useState(false);

  const [primaryJobSkilldata, setPrimaryJobSkill] = useState([]);
  const [BoilerSuitSize, setboilerSuitSize] = useState([]);
  const [SafetyShoeSize, setSafetyShoeSize] = useState([]);
  const [workingArea, setWorkingArea] = React.useState([]);

  const { userConfig } = useAuth();

  const supplierId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  const isDisabled = userConfig.role === 'approver';
  // const isDisabled = personalDetails?.statusToBeImposed === 'completion';

  const isEditingDisabled = initialValues?.autoApproved == true;

  const compareDateWithoutTime = (date1, date2) => {
    const dateString1 = new Date(date1)?.toISOString()?.split('T')[0];
    const dateString2 = new Date(date2)?.toISOString()?.split('T')[0];
    return dateString1.localeCompare(dateString2);
  };

  //     console.log("158values ", values)
  //     values.contractorCode = userConfig?.supplierId;
  //     values.oldestPsNumber = oldPsNumber;
  //     if (addEvent == false) {
  //       values.oldestPsNumber = oldPsNumber;
  //     } else {
  //       values.oldestPsNumber = '';
  //     }
  //     values.exTrainee = IsworkingHandicaped;
  //     values.aadEvent = addEvent;
  //     values.company = userConfig?.organisation?.name;
  //     // values.officeTel = OfficeTel;
  //     // if (personalDetails?.statusToBeImposed == 'completion') {
  //     //   // form.setFieldsValue({ event: '14 SEPARATION' });

  //     // }

  //     // if (deptHead1 || initialValues?.deptHead) {
  //     //   values.deptHead = deptHead1 || initialValues?.deptHead;
  //     // }

  //     // if (ImmediateAdminSupervisor || initialValues?.immediateadmsupervisor) {
  //     //   values.immediateadmsupervisor =
  //     //     ImmediateAdminSupervisor || initialValues?.immediateadmsupervisor;
  //     // }
  //     // if (ImmediateFuncSupervisor || initialValues?.immediatefuncsupervisor) {
  //     //   values.immediatefuncsupervisor =
  //     //     ImmediateFuncSupervisor || initialValues?.immediatefuncsupervisor;
  //     // }
  //     // if (OfficeTel || initialValues?.officeTel) {
  //     //   values.officeTel = OfficeTel || initialValues?.officeTel;
  //     // }
  //     // if (ContactSupevisor || initialValues?.contactSupervisor) {
  //     //   values.contactSupervisor =
  //     //     ContactSupevisor || initialValues?.contactSupervisor;
  //     // }
  //     // if (Nextfuncsupervisor || initialValues?.nextfuncsupervisor) {
  //     //   values.nextfuncsupervisor =
  //     //     Nextfuncsupervisor || initialValues?.nextfuncsupervisor;
  //     // }
  //     if (values.addEventDepartmentCode) {
  //       console.log("values.addEventDepartmentCode", values.addEventDepartmentCode);

  //       window.localStorage.setItem('addEventDepartmentCode', 'true');
  //       if (compareDateWithoutTime(values.effDate, new Date()) <= 0) {
  //         values.departmentCode = values.addEventDepartmentCode;

  //         const changedValue = values.addEventDepartmentCode?.split(' ');

  //         let index = masterDataShop?.dataParsed?.find((item) => {
  //           return item?.Dept_Code == changedValue[0];
  //         });

  //         if (index) {
  //           values.workingArea = index?.Working_Area;
  //           values.area = index?.Working_Area;
  //           values.officeTel = index?.Extention_1
  //             ? index?.Extention_1
  //             : OfficeTel;
  //           values.immediateadmsupervisor =
  //            (index?.IS_PS_Number || index?.IS) || '';
  //             //   ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
  //             //  : ImmediateAdminSupervisor;
  // // console.log(" ImmediateAdminSupervisor ", ImmediateAdminSupervisor)
  //               // console.log("immediateadm216 ",index?.IS_PS_Number,  index?.IS)
  //           values.immediatefuncsupervisor =
  //             (index?.IS_PS_Number || index?.IS) || ''
  //               // ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
  //               // : ImmediateFuncSupervisor;

  //           values.nextfuncsupervisor =
  //             (index?.NS_PS_Number || index?.NS) || ''
  //               // ? `${index?.NS_PS_Number || ''} ${index?.NS || ''}`
  //               // : Nextfuncsupervisor;
  //           values.deptHead =
  //             index && (index?.DH_PS_Number || index?.DH)
  //               ? `${index?.DH_PS_Number || ''} ${index?.DH || ''}`
  //               : deptHead1;
  //           values.contactSupervisor =
  //             (index?.Coordinator_PS_Number || index?.Coordinator) || ''
  //               // ? `${index?.Coordinator_PS_Number || ''} ${
  //               //     index?.Coordinator || ''
  //               //   }`
  //               // : ContactSupevisor;
  //         }
  //       }
  //     }

  //     if (isSportAssigned) {
  //       values.sportGroupAssignedDate = new Date();
  //     }

  //     if (values?.deptHead?.length == '0')
  //       values.deptHead = initialValues?.deptHead;
  //     if (values?.immediateadmsupervisor?.length == '0')
  //       values.immediateadmsupervisor = initialValues?.immediateadmsupervisor;
  //     if (values?.immediatefuncsupervisor?.length == '0')
  //       values.immediatefuncsupervisor = initialValues?.immediatefuncsupervisor;
  //     if (values?.nextfuncsupervisor?.length == '0')
  //       values.nextfuncsupervisor = initialValues?.nextfuncsupervisor;
  //     if (values?.officeTel?.length == '0')
  //       values.officeTel = initialValues?.officeTel;
  //     if (values?.contactSupervisor?.length == '0')
  //       values.contactSupervisor = initialValues?.contactSupervisor;

  //     onNext(values);
  //     setjobDetails({ ...initialValues, ...values });
  //   };
  //Vighnesh new code
  // const onFinish = async (values) => {
  //   console.log('Form submitted with values:', values);

  //   try {
  //     // Populate default values without overwriting existing inputs
  //     values.contractorCode ??= userConfig?.supplierId;
  //     values.oldestPsNumber ??= addEvent ? '' : oldPsNumber;
  //     values.exTrainee ??= IsworkingHandicaped;
  //     values.aadEvent ??= addEvent;
  //     values.company ??= userConfig?.organisation?.name;

  //     // Process addEventDepartmentCode if provided
  //     if (values.addEventDepartmentCode) {
  //       console.log('Processing department code changes...');
  //       window.localStorage.setItem('addEventDepartmentCode', 'true');

  //       if (compareDateWithoutTime(values.effDate, new Date()) <= 0) {
  //         values.departmentCode = values.addEventDepartmentCode;

  //         const changedValue = values.addEventDepartmentCode.split(' ');
  //         const index = masterDataShop?.dataParsed?.find(
  //           (item) => item?.Dept_Code === changedValue[0]
  //         );

  //         console.log('index', index?.IS_PS_Number, index?.IS);
  //         if (index) {
  //           console.log('Setting department-related values...');
  //           Object.assign(values, {
  //             workingArea: index?.Working_Area ?? values.workingArea,
  //             area: index?.Working_Area ?? values.area,
  //             officeTel: index?.Extention_1 ?? OfficeTel ?? values.officeTel,
  //             immediateadmsupervisor:
  //               index?.IS_PS_Number ||
  //               index?.IS ||
  //               values.immediateadmsupervisor,
  //               // '',
  //             immediatefuncsupervisor:
  //               index?.IS_PS_Number ||
  //               index?.IS ||
  //               values.immediatefuncsupervisor,
  //               // '',
  //             nextfuncsupervisor:
  //               index?.NS_PS_Number || index?.NS ||
  //               values.nextfuncsupervisor,
  //               // '',
  //             deptHead:
  //               index?.DH_PS_Number ||
  //               index?.DH ||
  //               deptHead1 ||
  //               values.deptHead,
  //               // '',
  //             contactSupervisor:
  //               index?.Coordinator_PS_Number ||
  //               index?.Coordinator ||
  //               values.contactSupervisor,
  //               // '',
  //           });
  //         }
  //       }
  //     }

  //     if (isSportAssigned) {
  //       values.sportGroupAssignedDate = new Date();
  //     }

  //     // Fallback for empty fields using nullish coalescing
  //     values.deptHead ??= initialValues?.deptHead;
  //     values.immediateadmsupervisor ??= initialValues?.immediateadmsupervisor;
  //     values.immediatefuncsupervisor ??= initialValues?.immediatefuncsupervisor;
  //     values.nextfuncsupervisor ??= initialValues?.nextfuncsupervisor;
  //     values.officeTel ??= initialValues?.officeTel;
  //     values.contactSupervisor ??= initialValues?.contactSupervisor;

  //     console.log('Final values before onNext:', values);

  //     // Call onNext function
  //     onNext(values);

  //     // Update job details state
  //     setjobDetails((prev) => ({ ...prev, ...values }));
  //   } catch (error) {
  //     console.error('Validation error:', error);

  //     // Handle form validation errors (do not clear form values)
  //     form.scrollToField(error.field, { behavior: 'smooth' });
  //     form.setFields(
  //       Object.entries(values).map(([name, value]) => ({
  //         name,
  //         errors: [error.message || 'Please fill this field.'],
  //         value,
  //       }))
  //     );
  //   }
  // };

  // Viresh Old Code

  const onFinish = (values) => {
    if (values.addEventDepartmentCode) {
      window.localStorage.setItem('addEventDepartmentCode', 'true');
    }
    //console.log("values359",values);
    values.contractorCode = userConfig?.supplierId;
    values.oldestPsNumber = oldPsNumber;
    if (addEvent == false) {
      values.oldestPsNumber = oldPsNumber;
    } else {
      values.oldestPsNumber = '';
    }
    values.exTrainee = IsworkingHandicaped;
    values.aadEvent = addEvent;
    values.company = userConfig?.organisation?.name;
    // values.officeTel = OfficeTel;
    // if (personalDetails?.statusToBeImposed == 'completion') {
    //   // form.setFieldsValue({ event: '14 SEPARATION' });

    // }

    // if (deptHead1 || initialValues?.deptHead) {
    //   values.deptHead = deptHead1 || initialValues?.deptHead;
    // }

    // if (ImmediateAdminSupervisor || initialValues?.immediateadmsupervisor) {
    //   values.immediateadmsupervisor =
    //     ImmediateAdminSupervisor || initialValues?.immediateadmsupervisor;
    // }
    // if (ImmediateFuncSupervisor || initialValues?.immediatefuncsupervisor) {
    //   values.immediatefuncsupervisor =
    //     ImmediateFuncSupervisor || initialValues?.immediatefuncsupervisor;
    // }
    // if (OfficeTel || initialValues?.officeTel) {
    //   values.officeTel = OfficeTel || initialValues?.officeTel;
    // }
    // if (ContactSupevisor || initialValues?.contactSupervisor) {
    //   values.contactSupervisor =
    //     ContactSupevisor || initialValues?.contactSupervisor;
    // }
    // if (Nextfuncsupervisor || initialValues?.nextfuncsupervisor) {
    //   values.nextfuncsupervisor =
    //     Nextfuncsupervisor || initialValues?.nextfuncsupervisor;
    // }
    // if (values.addEventDepartmentCode) {
    //   if (compareDateWithoutTime(values.effDate, new Date()) <= 0) {
    //     values.departmentCode = values.addEventDepartmentCode;

    //     const changedValue = values.addEventDepartmentCode?.split(' ');

    //     let index = masterDataShop?.dataParsed?.find((item) => {
    //       return item?.Dept_Code == changedValue[0];
    //     });

    //     if (index) {
    //       values.workingArea = index?.Working_Area;
    //       values.area = index?.Working_Area;
    //       values.officeTel = index?.Extention_1
    //         ? index?.Extention_1
    //         : OfficeTel;
    //       values.immediateadmsupervisor =
    //         index && (index?.IS_PS_Number || index?.IS)
    //           ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
    //           : ImmediateAdminSupervisor;
    //       values.immediatefuncsupervisor =
    //         index && (index?.IS_PS_Number || index?.IS)
    //           ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
    //           : ImmediateFuncSupervisor;
    //       values.nextfuncsupervisor =
    //         index && (index?.NS_PS_Number || index?.NS)
    //           ? `${index?.NS_PS_Number || ''} ${index?.NS || ''}`
    //           : Nextfuncsupervisor;
    //       values.deptHead =
    //         index && (index?.DH_PS_Number || index?.DH)
    //           ? `${index?.DH_PS_Number || ''} ${index?.DH || ''}`
    //           : deptHead1;
    //       values.contactSupervisor =
    //         index && (index?.Coordinator_PS_Number || index?.Coordinator)
    //           ? `${index?.Coordinator_PS_Number || ''} ${
    //               index?.Coordinator || ''
    //             }`
    //           : ContactSupevisor;
    //     }
    //   }
    // }

    if (isSportAssigned) {
      values.sportGroupAssignedDate = new Date();
    }

    // if (values?.deptHead?.length == '0')
    //   values.deptHead ||= initialValues?.deptHead;
    // if (values?.immediateadmsupervisor?.length == '0')
    //   values.immediateadmsupervisor ||= initialValues?.immediateadmsupervisor;
    // if (values?.immediatefuncsupervisor?.length == '0')
    //   values.immediatefuncsupervisor ||= initialValues?.immediatefuncsupervisor;
    // if (values?.nextfuncsupervisor?.length == '0')
    //   values.nextfuncsupervisor ||= initialValues?.nextfuncsupervisor;
    // if (values?.officeTel?.length == '0')
    //   values.officeTel ||= initialValues?.officeTel;
    // if (values?.contactSupervisor?.length == '0')
    //   values.contactSupervisor ||= initialValues?.contactSupervisor;

    onNext(values);
    setjobDetails({ ...initialValues, ...values });
  };

  // console.log('INitialValues', initialValues);
  // console.log("Job Details ", Jobdetails)

  // const onFinishFailed = ({ errorFields }) => {
  //   console.error('Validation Failed:', errorFields);

  //   // Get the current values of the form
  //   const currentValues = form.getFieldsValue();

  //   // Merge current values with initialValues, keeping the current values intact
  //   const updatedValues = { ...initialValues, ...currentValues };

  //   const updateVal = Object.keys(updatedValues).map((key) => ({
  //     name: key,
  //     value: updatedValues[key],
  //     errors: errorFields.find((field) => field.name[0] === key)?.errors || [],
  //   }));

  //   // Set the updated values back into the form
  //   form.setFields(updateVal);

  //   console.log('449 updateVal ', updateVal);

  //   console.log('Retained and merged values:', form.getFieldsValue());
  // };

  const onFinishFailed = ({ errorFields }) => {
    console.error('Validation Failed:', errorFields);

    // Use initialValues to reset the fields
    const fieldsToUpdate = Object.keys(initialValues).map((key) => ({
      name: key,
      value: initialValues[key],
    }));

    form.setFields(fieldsToUpdate); // Update the form with initial values

    console.log('Retained values after reset:', form.getFieldsValue());
  };

  // const IsworkingHandicapped = (e) => {
  //   setExTrainee(e.target.checked);
  //   setIsworkingHandicaped(!IsworkingHandicaped);
  //   form.setFieldValue('psNo', initialValues?.psNo);


  //   if(!exTrainee){
  //   //  const updatedVal = {...initialValues, psNo: ""}
  //   initialValues.psNo = ''
  //   }
  // };

  const IsworkingHandicapped = (e) => {
    setExTrainee(e.target.checked); // Toggle exTrainee
    setIsworkingHandicaped(!IsworkingHandicaped);
    
    if (!e.target.checked) {
      setPsNo("");
      form.setFieldsValue({psNo: ''}) // Reset PS Number if exTrainee is unchecked
    }
  }
  const Esictoggle = (e) => {
    const isInputEmpty = e.target.value.trim() === '';

    setEsicDisabled(isInputEmpty);
  };
  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const Empstatustoggle = () => {
    setEmpstatus(!Empstatus);
  };
  useEffect(() => {
    // Get the initial value of 'cadreAtJoining'
    const initialCadreAtJoining = form.getFieldValue('cadreAtJoining');

    if (initialCadreAtJoining) {
      const changedValue = initialCadreAtJoining.split(' ');

      const index = drmr?.find((item) => item?.Cadre_Code == changedValue[0]);


      if (index) {
        form.setFieldsValue({
          categoryCode: index?.Category_Description,
        });

        console.log('1013 index?.Category_Description', [
          index?.Category_Description,
        ]);
      }
    }
  }, [drmr]);

  const onChange = (e) => {};

  const normfile = () => {
    //
  };

  const historyData = initialValues && initialValues?.eventHistory;

  const openEventHistoryTableModal = () => {
    //
    setEventHistoryTableOpen(true);
  };

  useEffect(() => {
    if (personalDetails?.statusToBeImposed == 'rehired') {
      initialValues.cadreBeforeRehire = initialValues?.currentCadre;
    }


    // Check if the initial value for 'withCompany' is not set
    if (!initialValues?.withCompany) {
      form.setFieldsValue({ withCompany: 'yes' });
    }
    if (initialValues?.exTrainee == 'Yes' || initialValues?.exTrainee == 'Y') {
      setIsworkingHandicaped(!IsworkingHandicaped);
    }

    {
      /* 'With Company' field should be automatically 'No' in case when workman's Employment status is not 'Active' or 'Rehired'. @ Viresh Bangargi 17July2024 */
    }

    if (
      personalDetails?.statusToBeImposed == 'rehired' || personalDetails?.statusToBeImposed == 'active' ||
      !personalDetails?.statusToBeImposed
    ) {
      form.setFieldsValue({ withCompany: 'yes' });
    } else {
      form.setFieldsValue({ withCompany: 'no' });
    }
    setoldPsNumber(
      personalDetails?.statusToBeImposed == 'rehired'
        ? initialValues?.psNumber
        : ''
    );
    form.setFieldsValue({ basic: currentBasic });
  }, [initialValues]);

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
    getExcel();
    OrganizationInfo();
  }, []);
  const OrganizationInfo = async () => {
    getSportsGroup().then((res) => {
      // form.setFieldValue({ sportGroup: res.data?.nextSportGroup });
      // form.setFieldValue('sportGroup', res.data?.nextSportGroup);
      const currentSportGroup = form.getFieldValue('sportGroup');

      if ([undefined, null, '', ' '].includes(currentSportGroup)) {
        // Set the field value only if it's not already set
        form.setFieldValue('sportGroup', res?.data?.nextSportGroup);
        setIsSportAssigned(true);
      }
    });
  };

  // Old Code

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res?.data?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching Excel data:', error);
      });
  };

  // New Vighnesh Code

  // const getExcel = async () => {
  //   try {
  //     const res = await getExcelData(orgId);
  //     console.log("getExcelResponse:", res);

  //     const masterData = res?.data?.data?.data;
  //     setMasterData(masterData);

  //     // Assuming `masterData` is an array of sheets, find the desired sheet
  //     const sheetName = "Cadre_Master_Regular";
  //     const targetSheet = masterData?.[22]?.sheets?.find(sheet => sheet.name === sheetName);

  //     if (!targetSheet) {
  //       console.error(`Sheet named "${sheetName}" not found at index 22.`);
  //       return;
  //     }

  //     // Fetch the category description based on `cadreCode`
  //     const cadreCode = "desiredCadreCode"; // Replace with your actual code logic
  //     const categoryDescription = targetSheet.data.find(row => row.cadreCode === cadreCode)?.categoryDescription;

  //     if (categoryDescription) {
  //       console.log("Category Description:", categoryDescription);
  //     } else {
  //       console.warn(`No category description found for cadreCode: ${cadreCode}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching Excel data:', error);
  //   }
  // };

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        //

        const contractorIdsAndJobOrderNumbers = res?.data?.map((item) => {
          if (
            item &&
            item?.addJobDetails &&
            Array?.isArray(item?.addJobDetails)
          ) {
            return item?.addJobDetails?.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
              name: item?.name,
            }));
          }
          return null;
        });

        const newArr = res.data?.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item?.id,
              name: item?.name,
              jobOrderNumber: jobOrderNumber,
            };
          }
        });

        //

        setSeperatedVariables(newArr);

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        //

        //

        //

        const filteredContractors =
          flattenedContractorIdsAndJobOrderNumbers.filter(
            (contractor) => contractor.id === supplierId
          );

        //

        const jobOrders = filteredContractors?.map(
          (contractor) => contractor.jobOrderNumber
        );
        //
        const name = filteredContractors?.map((contractor) => contractor?.name);
        //
        const uniqueNames = [...new Set(name)];
        //
        setContractorName(uniqueNames);

        setJobOrderList(jobOrders);

        // // Get unique contractor IDs
        // const uniqueContractorIds = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.id))];

        // // Get unique job order numbers
        // const uniqueJobOrderNumbers = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.jobOrderNumber))];

        // const uniqueContractorNames = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.name))];

        // // Set state variables with unique IDs
        //
        //
        //
        // setContractorList(uniqueContractorIds);
        // setJobOrderList(uniqueJobOrderNumbers);
        // setContractorNameList(uniqueContractorNames)
        // // setData(res.data);
        // setMaxContractorLimit(res.data.maxContractors)
        // setlln(res.data.lln)

        // Filter job order numbers based on the selected contractor code
        // if (res && res.data) {
        // const filteredJobOrderNumbers = res.data
        //   .flatMap(item => item.addJobDetails)
        //   .filter(details => details?.contractorCode === value)
        //   .map(details => details?.jobOrderNumber)
        //   .filter(number => number !== undefined);
        //
        // // Set the filtered job order numbers to the state variable
        // setJobOrderList(filteredJobOrderNumbers);
        // }
      })
      .catch((error) => {
        // setLoader(false);
      });
  };

  const handleJobOrderChange = async (value) => {
    setSelectedJobOrder(value);
    //
    const jobId = value;
    const contractorCode = userConfig?.supplierId;

    jobOrderCount({ jobId, contractorCode })
      .then((res) => {
        // Make a request to the backend to get information from MongoDB

        const { maxContractors, jobCount } = res.data;

        // Check if jobCount exceeds the limit
        if (jobCount == maxContractors - 1) {
          notification.error({
            message: 'Limit Reached',
            description: 'Adding one more contractor would exceed the limit.',
          });

          // Set isNextButtonDisabled to true to disable the "Next" button
          //  setIsNextButtonDisabled(false);
        } else {
          // Display a notification if jobCount is greater than maxContractors
          if (jobCount > maxContractors - 1) {
            notification.info({
              message: 'Notice',
              description:
                'The number of contractors is more than the permitted limit.',
            });
            // If the limit is not reached, you might reset the state to enable the button
            setIsNextButtonDisabled(true);
          }
        }
      })
      .catch((error) => {
        // setLoader(false);
      });
  };

  // const handleContractorNameChange = (value) => {
  //     //
  //     const id = separatedVariables.find((item)=>{
  //        return item?.name === value
  //     })
  //     //
  //     setSelectedContractorCode(id.id)
  //     setContractorList([id.id]);

  //     setJobOrderList(id.jobOrderNumber)

  // }

  // const handleContractorCodeChange = (value) => {
  //   setSelectedContractorCode(value);

  // }
  // const handleContractorNameChange = (value) => {
  //   const selectedContractor = contractorList.find((contractor) => contractor.name === value);
  //   if (selectedContractor) {
  //     setSelectedContractorCode(selectedContractor.id);
  //     setJobOrderList(selectedContractor.addJobDetails.map((details) => details.jobOrderNumber));
  //   } else {
  //     setSelectedContractorCode(null);
  //     setJobOrderList([]);
  //   }
  // };

  //

  //
  React.useEffect(() => {
    functionCodeMasterInput(),
      currentStatusMasterInput(),
      cadreCodeMasterInput(),
      bloodGroupInput(),
      busRouteMasterInput(),
      categoryCodeMasterInput(),
      eventsInput(),
      shiftListInput();
    educationalQualification();
    deptCodeTypeInput();
    primaryJobSkill();
    boilerSuitSize();
    safetyShoeSize();
    CategoryCodeMaster();
  }, [masterData]);

  function deptCodeTypeInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Latest_Department_master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    console.log('dataFromArray', dataFromArray);

    setMasterDataShop(dataFromArray);
    //
    const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Dept_Code + ' ' + item?.Department_Name;
    });
    setDepartment(deptCodeInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function primaryJobSkill() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skills_for_Regular_Workman;
    });

    setPrimaryJobSkill(primaryJobSkillAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function CategoryCodeMaster() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Category_Code_Master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Category_Code + ' ' + item?.Description;
    });

    // setCategoryCodes(primaryJobSkillAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function boilerSuitSize() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Boiler_Suit_Size';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const biolersuitsize1 = dataFromArray?.dataParsed?.map((item) => {
      return item?.BoilerSuitSize;
    });
    // setPrimaryJobSkill(primaryJobSkillAreas);
    setboilerSuitSize(biolersuitsize1);

    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function safetyShoeSize() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Safety_Shoes_Size';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const biolersuitsize1 = dataFromArray?.dataParsed?.map((item) => {
      return item?.SafetyShoesSize;
    });
    // setPrimaryJobSkill(primaryJobSkillAreas);
    setSafetyShoeSize(biolersuitsize1);

    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  const fieldChanges = (changedFields, allFields) => {
    console.log('683 changedFields ', changedFields);
    // if (changedFields[0].name == 'departmentCode') {
    //   const changedValue = changedFields[0]?.value?.split(' ');
    //   //

    //   let index = masterDataShop?.dataParsed?.find((item) => {
    //     return item?.Dept_Code + ' ' + item?.Department_Name == changedValue[0];
    //   });
    //

    //
    //   const filteredDepartments = masterDataShop?.dataParsed?.filter((item) => {
    //     return item?.Production_Centre === changedValue[0];
    //   });

    //   if (filteredDepartments.length > 0) {
    //     // Extract department codes from the filtered data
    //     const departmentCodes = filteredDepartments?.map((dept) => {
    //       return `${dept.Dept_Code} ${dept.Department_Name}`;
    //     });

    //     // Update the department code options
    //     setWorkingArea(departmentCodes);

    //     // Optionally, set the default value of department code
    //     form.setFieldsValue({
    //       departmentCode: departmentCodes[0], // Set the first department code as default
    //     });
    //   }
    // }
    if (changedFields[0]?.name[0] == 'departmentCode') {
      // const changedValue = changedFields[0]?.value?.split(' ');

      const changedValue = changedFields[0]?.value
        ? changedFields[0]?.value?.split(' ')
        : [];

      let index = masterDataShop?.dataParsed?.find((item) => {
        return item?.Dept_Code == changedValue[0];
      });

      if (index) {
        console.log('index 799 ', index);
        form.setFieldsValue({
          workingArea: index?.Working_Area,
        });
        setArea([index?.Working_Area]);
        form.setFieldsValue({
          area: index?.Working_Area,
        });
        form.setFieldsValue({
          officeTel: index?.Extention_1 ? index?.Extention_1 : OfficeTel,
        });
        console.log('810 officeTel ', index?.Extentio);
        // form.setFieldValue(
        //   'immediateadmsupervisor',
        //   index?.IS_PS_Number + ' ' + index?.IS
        // );
        form.setFieldValue(
          'immediateadmsupervisor',
          index && (index?.IS_PS_Number || index?.IS)
            ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
            : ImmediateAdminSupervisor
        );
        form.setFieldValue(
          'immediatefuncsupervisor',
          index && (index?.IS_PS_Number || index?.IS)
            ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
            : ImmediateFuncSupervisor
        );

        // form.setFieldValue(
        //   'nextfuncsupervisor',
        //   index?.NS_PS_Number + ' ' + index?.NS
        // );
        form.setFieldValue(
          'nextfuncsupervisor',
          index && (index?.NS_PS_Number || index?.NS)
            ? `${index?.NS_PS_Number || ''} ${index?.NS || ''}`
            : Nextfuncsupervisor
        );
        // form.setFieldValue('deptHead', index?.DH_PS_Number + ' ' + index?.DH);
        form.setFieldValue(
          'deptHead',
          index && (index?.DH_PS_Number || index?.DH)
            ? `${index?.DH_PS_Number || ''} ${index?.DH || ''}`
            : deptHead1
        );

        //----------
        // setDeptHead(
        //   index?.DH_PS_Number || index?.DH
        //     ? `${index?.DH_PS_Number || ''} ${index?.DH || ''}`
        //     : ''
        // );
        // setImmediateAdminSupervisor(
        //   index?.IS_PS_Number || index?.IS
        //     ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
        //     : ''
        // );
        // setImmediateFuncSupervisor(
        //   index?.IS_PS_Number || index?.IS
        //     ? `${index?.IS_PS_Number || ''} ${index?.IS || ''}`
        //     : ''
        // );
        // setNextfuncsupervisor(
        //   index?.NS_PS_Number || index?.NS
        //     ? `${index?.NS_PS_Number || ''} ${index?.NS || ''}`
        //     : ''
        // );

        //----------

        // form.setFieldValue(
        //   'immediatefuncsupervisor',
        //   index?.IS_PS_Number + ' ' + index?.IS
        // );

        // form.setFieldValue('contactSupervisor', index?.Coordinator || '');
        form.setFieldValue(
          'contactSupervisor',
          index && (index?.Coordinator_PS_Number || index?.Coordinator)
            ? `${index?.Coordinator_PS_Number || ''} ${
                index?.Coordinator || ''
              }`
            : ContactSupevisor
        );
      }
    }
    if (changedFields[0]?.name[0] == 'cadreAtJoining') {
      const changedValue = changedFields[0]?.value?.split(' ');

      let index = drmr?.find((item) => {
        return item?.Cadre_Code == changedValue[0];
      });

      console.log('index1128', index);
      console.log('index1129', index?.Category_Description);

      if (index) {
        setCategoryCodes([index?.Category_Description]);
       
        form.setFieldsValue({
          categoryCode: index?.Category_Description,
        });
      }
    }
    if (changedFields[0]?.name[0] == 'toCadre') {
      const changedValue = changedFields[0]?.value?.split(' ');

      let index = drmr?.find((item) => {
        return item?.Cadre_Code == changedValue[0];
      });

      if (index) {
        setCategoryCodes([index?.Category_Description]);
        form.setFieldsValue({
          categoryCode: index?.Category_Description,
        });
      }
    }
  };

  // const fieldChangesCategoryCode = (changedFields, allFields) => {
  //
  //   if (changedFields[0]?.name[0] == 'cadreAtJoining') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //

  //     let index = masterDataShop?.dataParsed?.find((item) => {
  //       return item.DR_MR == changedValue[0];
  //     });
  //
  //     if (index) {
  //       setArea([
  //         index?.Category,
  //       ]);
  //     }
  //   }
  // };

  function functionCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Function_Code_Master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    const functionCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        return item?.Function_Code + ' ' + item?.Description;
      }
    );
    setFunctionCodeMaster(functionCodeMasterInputAreas);

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  // useEffect(() => {
  //   if (initialValues) {
  //     const initialExTrainee =
  //       initialValues.exTrainee === 'Y' || initialValues.exTrainee === 'Yes'
  //         ? true
  //         : initialValues.exTrainee === 'N' || initialValues.exTrainee === 'No'
  //         ? false
  //         : initialValues.exTrainee;

  //     setExTrainee(initialExTrainee);
  //   }
  // }, [initialValues]);
  useEffect(() => {
    if (initialValues?.exTrainee === 'Y' || initialValues?.exTrainee === 'Yes' || initialValues?.exTrainee === true) {
      setExTrainee(true);
      setPsNo(initialValues?.psNo || "");
      
    } // Set PS Number value if available
     else if(initialValues?.exTrainee === 'N' || initialValues?.exTrainee === 'No' || initialValues?.exTrainee === false){
      form.setFieldsValue({ psNo: "" });
      setExTrainee(false)
      setPsNo('')
     }
  }, [initialValues]);


  function bloodGroupInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Blood_Group';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const bloodGroupInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Blood_Group;
    });
    setBloodGroup(bloodGroupInputAreas);

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function primaryJobSkill() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skills_for_Regular_Workman;
    });
    setPrimaryJobSkill(primaryJobSkillAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function currentStatusMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Current_Status_Master';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const functionCurrentStatusMasterInputAreas =
      dataFromArray?.dataParsed?.map((item) => {
        //
        return item?.Description;
      });
    //
    setCurrentStatusMaster(functionCurrentStatusMasterInputAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  // function cadreCodeMasterInput() {
  //   if (!Array?.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'DR_MR';
  //   //
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //
  //   setDrmr(dataFromArray?.dataParsed);
  //   const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
  //     (item) => {
  //       //
  //       return item?.Cadre_Code + ' ' + item?.Description;
  //     }
  //   );
  //   //
  //   setCadreCodeMaster(functionCadreCodeMasterInputAreas);
  //   //

  //   if (dataFromArray) {
  //     console.log(
  //       'functionCadreCodeMasterInputAreas',
  //       functionCadreCodeMasterInputAreas
  //     );
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }

  function cadreCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Cadre_Master_Regular';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );

    setDrmr(dataFromArray?.dataParsed);

    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        return item?.Cadre_Code + ' ' + item?.Cadre_Description;
        // return item?.Cadre_Description;
      }
    );

    setCadreCodeMaster(functionCadreCodeMasterInputAreas);
    //

    // if (dataFromArray) {
    //   console.log(
    //     'functionCadreCodeMasterInputAreas',
    //     functionCadreCodeMasterInputAreas
    //   );
    // } else {
    //   console.error(
    //     "Object with sheetName '" + sheetName + "' not found in masterData."
    //   );
    // }
  }

  function busRouteMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Bus_Route_Master';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const functionbusRouteMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Bus_Route_Code + ' ' + item?.Description;
      }
    );
    //
    setBusRouteMaster(functionbusRouteMasterInputAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function categoryCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Category_Code_Master';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const functioncategoryCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Category_Code + ' ' + item?.Description;
      }
    );
    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Cadre_Code;
      }
    );

    //
    setCategoryCode(functioncategoryCodeMasterInputAreas);
    setCadreCode(functionCadreCodeMasterInputAreas);

    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  //Educational Qualification

  function educationalQualification() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Qualification';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const functioncategoryCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Qualification;
      }
    );

    //
    setQualification(functioncategoryCodeMasterInputAreas);

    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  // function deptCodeTypeInput() {
  //   if (!Array.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'SHOP';
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   setMasterDataShop(dataFromArray);
  //   //
  //   const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
  //     return item?.Department_Code + ' ' + item?.Department;
  //   });
  //   setDepartment(deptCodeInputAreas);
  //   //

  //   if (dataFromArray) {
  //
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }
  // const fieldChanges = (changedFields, allFields) => {
  //
  //   if (changedFields[0].name == 'departmentCode') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //

  //     let index = masterDataShop?.dataParsed?.find((item) => {
  //       return item.Department_Code == changedValue[0];
  //     });
  //
  //     if (index) {
  //       setWorkingArea([
  //         index?.Production_Centre + ' ' + index?.Production_Centre_Discription,
  //       ]);
  //     }
  //   }
  // };

  function eventsInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Events';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    if (
      dataFromArray &&
      !['approved', 'rehired'].includes(initialValues?.status)
    ) {
      dataFromArray.dataParsed = dataFromArray?.dataParsed?.filter(
        (i) => i?.Event_Code != 9
      );
    }
    const functionEventsInputAreas = dataFromArray?.dataParsed?.map((item) => {
      //
      return item?.Event_Code + ' ' + item?.Description;
    });
    //
    setEvents(functionEventsInputAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function shiftListInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Shift_List';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const functionShiftListInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Shift_Code + ' ' + item?.Shift_Name;
      }
    );
    //
    setShiftList(functionShiftListInputAreas);
    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  //support document upload
  const handleCancel = () => setPreviewOpen(false);

  const normFile = (e) => {
    if (Array?.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    });
  const [form] = Form.useForm();

  const handleDateChange = (date) => {
    console.log('Date---->', date);
    setlastDateCadre(date);
    form.setFieldsValue({ currentCadre: date });
  };
  const handleUpdateDateChange = (date) => {
    form.setFieldsValue({ currentCadre: date });
  };

  //Date of Retirement
  const [dob, setDob] = useState(null);
  const [retirementDate, setRetirementDate] = useState(null);

  useEffect(() => {
    if (!initialValues?.departmentCode) {
      const dob = personalDetails?.Dob?.$d;
      const retirementAge1 = 58;
      const dobDate = new Date(dob);

      // Calculate the retirement date
      const retirementYear = dobDate.getFullYear() + retirementAge1;
      const retirementMonth = dobDate.getMonth(); // Months are zero-indexed
      const retirementDay = dobDate.getDate();

      const retirementDate = new Date(
        retirementYear,
        retirementMonth,
        retirementDay
      );

      const formattedRetirementDate =
        dayjs(retirementDate).format('DD-MM-YYYY');

      // Function to format date as DD-MM-YYYY

      form.setFieldsValue({
        dateofRetiremenet: dayjs(retirementDate),
      });
    }
  }, []);
  

  const addEventTableModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to add a new event?',
      content: 'Please confirm that you want to add a new event to the table.',
      onOk: () => {
        setAddEvent(true);
        form.setFieldsValue({ fromCadre: initialValues?.currentCadre });
        form.setFieldsValue({ effDate: '' });
        form.setFieldsValue({ points: '' });
        form.setFieldsValue({ toCadre: '' });

        if (personalDetails?.statusToBeImposed === 'completion') {
          form.setFieldsValue({ event: '14 SEPARATION' });
        } else if (personalDetails?.statusToBeImposed === 'rehired') {
          form.setFieldsValue({ event: '15 TEMPORARY' });
        } else {
          form.setFieldsValue({ event: initialValues?.event });
        }
      },
      onCancel() {},
    });
  };
  const uniqueDepartments = new Set(department);

  const locationId = userConfig.locationId;

  const handleCadreChange = async (value) => {
    form.setFieldsValue({ currentCadre: value });
    setCurrentCadre(value);

    const cadre = value.split(' ')[0];
    if (locationId === 'RNW') {
      try {
        const response = await getRNNumber(locationId, cadre);
        const responseData = response.data;

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newRNNumber = 1;
          const updatedRNNumber = `RN${newRNNumber}`;
          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            psNumber: updatedRNNumber,
            // Add more fields as needed
          });
        } else {
          let existingNumericPart = responseData.lastRNNumber;
          let numericPart;

          numericPart = parseInt(existingNumericPart) + 1;

          const paddedNumericPart = numericPart.toString().padStart(4, '0');

          const updatedRNNumber = paddedNumericPart;

          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            psNumber: updatedRNNumber,
          });
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    } else {
      try {
        const response = await getPSNumber(locationId, cadre);
        const responseData = response.data;

        if (responseData.message === 'New entry') {
          const newPSNumber = 1;
          const updatedPSNumber = `PS${newPSNumber}`;
          form.setFieldsValue({
            psNumber: updatedPSNumber,
          });
        } else {
          let existingNumericPart = responseData.lastPSNumber;
          let numericPart;

          numericPart = parseInt(existingNumericPart) + 1;

          const paddedNumericPart = numericPart.toString().padStart(4, '0');

          const updatedPSNumber = paddedNumericPart;

          // Update the state with the new PSNumber
          setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    }
  };

  // Standard code
  // const handleButtonClick = async (value) => {
  //   const currentCadre = form.getFieldValue('currentCadre');
  //   const cadre = currentCadre.split(' ')[0];

  //   if (locationId === 'RNW') {
  //     try {
  //       const response = await getRNNumber(locationId, cadre);
  //       const responseData = response.data;

  //       if (responseData.message === 'New entry') {
  //         // If it's a new entry, setPSNumber to '0001'
  //         const newRNNumber = 1;
  //         const updatedRNNumber = `RN${newRNNumber}`;
  //         setRNNumber(updatedRNNumber);

  //         form.setFieldsValue({
  //           // Specify the field names and their new values
  //           // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
  //           psNumber: updatedRNNumber,
  //           // Add more fields as needed
  //         });

  //         // You can do other processing or actions if needed
  //       } else {
  //         // If it's an existing entry, increment the existing numeric part by 1
  //         let existingNumericPart = responseData.lastRNNumber;
  //         let numericPart;

  //         numericPart = parseInt(existingNumericPart) + 1;

  //         // Pad the numeric part with leading zeros based on your desired length
  //         const paddedNumericPart = numericPart.toString().padStart(4, '0');

  //         // Concatenate with any prefix or other formatting you may need
  //         const updatedRNNumber = paddedNumericPart;

  //         // Update the state with the new PSNumber
  //         setRNNumber(updatedRNNumber);

  //         form.setFieldsValue({
  //           // Specify the field names and their new values
  //           // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
  //           psNumber: updatedRNNumber,
  //         });
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error if necessary
  //     }
  //   } else {
  //     try {
  //       //
  //       const response = await getPSNumber(locationId, cadre);
  //       const responseData = response.data;

  //       if (responseData.message === 'New entry') {
  //         // If it's a new entry, setPSNumber to '0001'
  //         const newPSNumber = 1;
  //         const updatedPSNumber = `PS${newPSNumber}`;
  //         // setPSNumber(updatedPSNumber);
  //         form.setFieldsValue({
  //           // Specify the field names and their new values
  //           // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
  //           psNumber: updatedPSNumber,
  //           // Add more fields as needed
  //         });

  //         // You can do other processing or actions if needed
  //       } else {
  //         // If it's an existing entry, increment the existing numeric part by 1
  //         let existingNumericPart = responseData.psNumber;

  //         if (
  //           existingNumericPart === '144092' ||
  //           existingNumericPart === '144084'
  //         ) {
  //           const updatedPSNumber = '144104';

  //           // Update the state with the new PSNumber
  //           setPSNumber(updatedPSNumber);
  //           form.setFieldsValue({
  //             psNumber: updatedPSNumber,
  //           });

  //           console.log(
  //             'Set PSNumber to 144098 as existing was not 144098',
  //             updatedPSNumber
  //           );
  //         } else if (
  //           existingNumericPart === '125650' ||
  //           existingNumericPart === '120190'
  //         ) {
  //           const updatedPSNumber = '126465';

  //           // Update the state with the new PSNumber
  //           setPSNumber(updatedPSNumber);
  //           form.setFieldsValue({
  //             psNumber: updatedPSNumber,
  //           });

  //           console.log(
  //             'Set PSNumber to 126465 as existing was not 126464',
  //             updatedPSNumber
  //           );
  //         } else if (existingNumericPart === '591533') {
  //           const updatedPSNumber = '591535';

  //           // Update the state with the new PSNumber
  //           setPSNumber(updatedPSNumber);
  //           form.setFieldsValue({
  //             psNumber: updatedPSNumber,
  //           });

  //           console.log(
  //             'Set PSNumber to 591535 as existing was not 591535',
  //             updatedPSNumber
  //           );
  //         } else {
  //           let numericPart;

  //           numericPart = parseInt(existingNumericPart) + 1;

  //           // Pad the numeric part with leading zeros based on your desired length
  //           const paddedNumericPart = numericPart.toString().padStart(4, '0');

  //           // Concatenate with any prefix or other formatting you may need
  //           const updatedPSNumber = paddedNumericPart;

  //           // Update the state with the new PSNumber
  //           setPSNumber(updatedPSNumber);
  //           form.setFieldsValue({
  //             // Specify the field names and their new values
  //             // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
  //             psNumber: updatedPSNumber,
  //             // Add more fields as needed
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error if necessary
  //     }
  //   }
  // };

  // Viresh code
  const handleButtonClick = async (value) => {
    const currentCadre = form.getFieldValue('currentCadre');
    const cadre = currentCadre?.split(' ')[0];
    console.log('cadre1394', cadre);

    if (locationId === 'RNW') {
      try {
        const response = await getRNNumber(locationId, cadre);
        const responseData = response.data;
        console.log('inside RNW', responseData);

        if (responseData.message === 'New entry') {
          console.log('iside RNW if');

          // If it's a new entry, setPSNumber to '0001'
          const newPSNumber = 1;
          const updatedPSNumber = `PS${newPSNumber}`;
          // setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed
        } else {
          console.log('iside RNW else');

          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.psNumber;
          console.log('existingNumericPart', existingNumericPart);

          if (
            existingNumericPart === '144092' ||
            existingNumericPart === '144084'
          ) {
            const updatedPSNumber = '144104';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 144098 as existing was not 144098',
              updatedPSNumber
            );
          } else if (
            existingNumericPart === '125650' ||
            existingNumericPart === '120190'
          ) {
            const updatedPSNumber = '126465';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 126465 as existing was not 126464',
              updatedPSNumber
            );
          } else if (existingNumericPart === '591533') {
            const updatedPSNumber = '591535';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 591535 as existing was not 591535',
              updatedPSNumber
            );
          } else {
            let numericPart;

            numericPart = parseInt(existingNumericPart) + 1;

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedPSNumber = paddedNumericPart;
            console.log('1477', updatedPSNumber);

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              psNumber: updatedPSNumber,
              // Add more fields as needed
            });
          }
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    } else {
      try {
        console.log('inside other');
        const response = await getPSNumber(locationId, cadre);
        const responseData = response.data;

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newPSNumber = 1;
          const updatedPSNumber = `PS${newPSNumber}`;
          // setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed
        } else {
          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.psNumber;

          if (
            existingNumericPart === '144092' ||
            existingNumericPart === '144084'
          ) {
            const updatedPSNumber = '144104';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 144098 as existing was not 144098',
              updatedPSNumber
            );
          } else if (
            existingNumericPart === '125650' ||
            existingNumericPart === '120190'
          ) {
            const updatedPSNumber = '126465';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 126465 as existing was not 126464',
              updatedPSNumber
            );
          } else if (existingNumericPart === '591533') {
            const updatedPSNumber = '591535';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 591535 as existing was not 591535',
              updatedPSNumber
            );
          } else {
            let numericPart;

            numericPart = parseInt(existingNumericPart) + 1;

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedPSNumber = paddedNumericPart;

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              psNumber: updatedPSNumber,
              // Add more fields as needed
            });
          }
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    }
  };
  const deptHead = (value) => {
    setDeptHead(value?.target?.value);
    // form.setFieldsValue({
    //   deptHead: value?.target?.value,
    // });
  };
  const immediateadmsupervisor = (value) => {
    setImmediateAdminSupervisor(value?.target?.value);
  };
  const immediatefuncsupervisor = (value) => {
    setImmediateFuncSupervisor(value?.target?.value);
  };
  const officeTel = (value) => {
    console.log('Valiuuuuu', value?.target?.value);
    setOfficeTel(value?.target?.value);
  };
  const contactSupervisor = (value) => {
    setContactSupervisor(value?.target?.value);
  };
  const nextfuncsupervisor = (value) => {
    setNextfuncsupervisor(value?.target?.value);
  };

  //Last date of employment calculating
  const handleDateOfJoiningChange = (date) => {
    // if (lastDateCadre === 'O2' && date) {
    //   const lastDateOfEmployment = dayjs(date).add(1, 'year');
    //   form.setFieldsValue({ lastDateOfEmployement: lastDateOfEmployment });
    // } else {
    //   form.setFieldsValue({ lastDateOfEmployement: null });
    // }

    console.log('lastDateCadre', lastDateCadre);

    // Check if lastDateCadre is 'O2', 'ZD', or 'KN'
    if (['O2', 'ZD', 'KN'].includes(lastDateCadre) && date) {
      // Calculate the last date of employment as one year after the date of joining
      const lastDateOfEmployment = dayjs(date).add(1, 'year');
      form.setFieldsValue({ lastDateOfEmployement: lastDateOfEmployment });
    } else {
      // Set the last date of employment to empty if conditions are not satisfied
      form.setFieldsValue({ lastDateOfEmployement: null });
    }
  };

  console.log("extraineee2145 ", exTrainee)



  return (
    <>
      <Title level={5}>Job Details</Title>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed} // Handle validation failures here
        form={form}
        onFieldsChange={fieldChanges}
        // disabled={personalDetails?.statusToBeImposed == 'completion'}
      >
        <Row
          gutter={16}
          // hidden={personalDetails?.statusToBeImposed == 'completion'}
        >
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='company'
              label='Company'
              // rules={[{ required: true, message: 'Enter Company' }]}
            >
              <Input
                size='large'
                disabled={isDisabled}
                defaultValue={userConfig?.organisation?.name}
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              rules={[{ required: true, message: 'Enter Working Area' }]}>
              <Select size='large' disabled={isDisabled}>
                
                {Array?.from(uniqueDepartments)?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[{ required: true, message: 'Select Department Code' }]}>
              <Select size='large' disabled={isDisabled}>
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[
                {
                  required: true,
                  message: 'Enter Department Code',
                },
              ]}>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                optionLabelProp='label' // Ensures full description is displayed
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {department?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option
                      key={index}
                      value={code}
                      label={`${code} ${description}`}>
                      {code} - {description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              // rules={[{ required: true, message: 'Enter Working Area' }]}
            >
              <Select size='large' disabled={isDisabled}>
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='deptHead'
              label='Dept Head'
              // rules={[{ required: true, message: 'Enter Dept Head' }]}
            >
              <Input size='large' disabled={isDisabled} onChange={deptHead} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='functionCode' label='Function Code'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item> */}

            <Form.Item name='functionCode' label='Function Code'>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {functionCodeMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
                {/* {functionCodeMaster?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option key={index} value={code}>
                      {description}
                    </Option>
                  );
                })} */}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='immediateadmsupervisor'
              label='Immediate Adm. Supervisor'
              rules={
                [
                  // { required: true, message: 'Enter Immediate Adm.Supervisor' },
                ]
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={immediateadmsupervisor}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='immediatefuncsupervisor'
              label='Immediate Func. Supervisor'
              rules={
                [
                  // { required: true, message: 'Enter Immediate Func. Supervisor' },
                ]
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={immediatefuncsupervisor}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='nextfuncsupervisor'
              label='Next Func. Supervisor'
              rules={
                [
                  // { required: true, message: 'Enter Next Func. Supervisor' },
                ]
              }
              onChange={nextfuncsupervisor}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='contactSupervisor'
              label='Coordinator'
              onChange={contactSupervisor}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='location'
              label='Location ID'
              rules={[{ required: true, message: 'Select Location Name' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='RNW'>RNW</Option>
                <Option value='HZW'>HZW</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='csnCode' label='CSN Code'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='officeTel'
              label='Office Tel.'
              rules={[
                // {
                //   required: true,
                //   message: 'Enter Office Tel.',
                // },
                {
                  pattern: /^[0-9]{9}$/,
                  message: 'Enter number should be 9 digits',
                },
                // {
                //   min: 10,
                //   max: 10,
                //   message: "Mobile number should be 10 digits",
                // },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='officeTel'
              label='Office Tel.'
              rules={[
                {
                  pattern: /^[0-9]{0,7}$/, // This pattern allows up to 7 digits
                  message: 'Enter a number with up to 7 digits',
                },
                // {
                //   validator: (_, value) => {
                //     if (value && value.length > 7) {
                //       return Promise.reject(
                //         new Error('Number should not exceed 7 digits')
                //       );
                //     }
                //     return Promise.resolve();
                //   },
                // },
              ]}>
              <Input size='large' disabled={isDisabled} onChange={officeTel} />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='transportUser'
              label='Transport User'
              rules={[{ required: true, message: 'Select Transport User' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='Yes'>Yes</Option>
                <Option value='No'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='withCompany'
              label='With Company'
              rules={[{ required: true, message: 'Select With Company ' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='designation'
              label='Designation'
              rules={[
                // {
                //   pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                //   message: 'Only Alphabetic Allowed',
                // },
                {
                  required: true,
                  message: 'Enter Designation',
                },
              ]}>
              {/* <Select size='large' disabled={isDisabled}>
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select> */}
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='cadreAtJoining'
              label='Cadre At Joining'
              rules={[{ required: true, message: 'Select Cadre At Joining' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size='large'
                disabled={isDisabled}
                onChange={(value) => {
                  // handleCadreChange(value);
                  handleDateChange(value);
                }}>
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={index} value={area.split(' ')[0]}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='cadreAtJoining'
              label='Cadre At Joining'
              rules={[{ required: true, message: 'Select Cadre At Joining' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size='large'
                // disabled={isDisabled || initialValues?.status == 'approved'}
                onChange={(value) => {
                  handleDateChange(value);
                }}
                disabled={isDisabled}>
                {cadreCodeMaster?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option key={index} value={code}>
                      {description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* <Form.Item
        name="cadreAtJoining"
        label="Cadre At Joining"
        rules={[{ required: true, message: 'Select Cadre At Joining' }]}>
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size="large"
          disabled={isDisabled}
          onChange={(value) => {
            form.setFieldsValue({ categoryCode }); // Update category code dynamically
          }}
          >
          {cadreCodeMaster?.map((area, index) => {
            const [code, ...descriptionParts] = area.split(' ');
            const description = descriptionParts.join(' ');

            return (
              <Option key={index} value={code}>
                {description}
              </Option>
            );
          })}
        </Select>
      </Form.Item> */}
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='currentCadre'
              label='Current Cadre'
              rules={[{ required: true, message: 'Select Current Cadre' }]}>
              <Select
                size='large'
                showSearch
                disabled={isDisabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {cadreCodeMaster?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option key={index} value={code}>
                      {description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item>
              <Button
                type='primary'
                onClick={handleButtonClick}
                disabled={isDisabled}>
                Generate PSNumber
              </Button>
            </Form.Item>
          </Col>

          {/* While Onboarding the regular workmen, after selecting the cadre at joining,the category code was not populating @ Viresh Bangargi 17July2024 */}

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='categoryCode'
              label='Category Code'
              rules={[{ required: true, message: 'Select Category Code' }]}>
              <Select
                size='large'
                showSearch
                optionFilterProp='label'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={isDisabled}
                onChange={(value) => {
                  setCategoryProbation(value);
                }}>
                {CategoryCode?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
                {/* {CategoryCode?.map((area, index) => {
      const [code, ...descriptionParts] = area.split(" ");
      const description = descriptionParts.join(" ");

      return (
        <Option key={index} value={code} label={`${code} ${description}`}>
          {code} - {description}
        </Option>
      );
    })} */}
              </Select>
            </Form.Item>
            {/* <Form.Item
        name="categoryCode"
        label="Category Code"
        rules={[{ required: true, message: 'Select Category Code' }]}>
        <Select
          size="large"
          showSearch
          optionFilterProp="label"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={isDisabled}>
          {CategoryCode?.map((area, index) => (
            <Option key={index} value={area}>
              {area}
            </Option>
          ))}
        </Select>
      </Form.Item> */}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={categoryCode[0] == 'Workmen'}
          >
            <Form.Item
              name='dateofStartingProbation'
              label='Date Of Starting Probation'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={
                  isDisabled ||
                  CategoryCode == '3 APPRENTICE' ||
                  CategoryCode == '2 TRIAL/TEMP WORKMAN'
                }
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateofConfirmation' label='Date Of Confirmation'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={
                  isDisabled ||
                  CategoryCode == '3 APPRENTICE' ||
                  CategoryCode == '2 TRIAL/TEMP WORKMAN'
                }
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='primaryJobSkill' label='Primary Job Skill'>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {primaryJobSkilldata?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item name='gradeYear' label='Grade/Training Year'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='shiftCode'
              label='Shift Code'
              rules={[{ required: true, message: 'Select Shift Code' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                optionLabelProp='label'
                filterOption={(input, option) =>
                  String(option.children).toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {/* {shiftList?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))} */}
                {shiftList?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option
                      key={index}
                      value={code}
                      label={`${code} ${description}`}>
                      {code} - {description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateofRetiremenet' label='Date Of Retirement'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                //disabled={true}
                format='DD-MM-YYYY'
                //inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lockerNo'
              label='Locker No.'
              rules={[
                { pattern: /^\d+$/, message: 'Please Enter Only Numbers' },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* Sports group was not following the sequence after bulk upload workmen. @ Viresh Bangargi InProgress */}

          <Col className='gutter-row' span={6}>
            <Form.Item name='sportGroup' label='Sports Group'>
              <Select size='large' disabled={isDisabled}>
                <Option value='mercury'>Mercury</Option>
                <Option value='venus'>Venus</Option>
                <Option value='mars'>Mars</Option>
                <Option value='jupiter'>Jupiter</Option>
              </Select>
              {/* <Input /> */}
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='directWorkmen'
              label='Direct Workmen'
              rules={[{ required: true, message: 'Select Direct Workmen' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='Y'>Yes</Option>
                <Option value='N'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='lastPromotion' label='Last Promotion'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='currentStatus'
              label='Current status'
              rules={[{ required: true, message: 'Select Current status' }]}>
              <Select size='large' disabled={isDisabled}>
                {currentStatusMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='appoinmentRefNo' label='Appointment Ref. No.'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='appointmentRefDate' label='Appointment Ref. Date.'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='psNumber'
              label='PS Number'
              rules={[{ required: true, message: 'Enter PS Number' }]}>
              <Input
                size='large'
                //defaultValue={psNumber || 'PS1'}
                value={psNumber}
                //readOnly
                style={{ color: 'grey' }}
                disabled
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='exTrainee'
              label='Ex Trainees?'
              rules={[{ required: true, message: 'Select Ex Trainees?' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='exTrainee'>
              <Checkbox
                size='large'
                // name='handicapped'
                // onChange={PmeCheckbox}
                className='exempt'
                onClick={IsworkingHandicapped}
                style={{ paddingTop: '40px' }}
                defaultChecked={
                  initialValues?.exTrainee === 'Y' || initialValues?.exTrainee === 'Yes' || initialValues?.exTrainee === true
                }
                checked={exTrainee}
                disabled={isDisabled}>
                Ex Trainees?
              </Checkbox>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='psNo' label='Previous PS Number'>
              {/* <Input
                size='large'
                value = {psNo}

                disabled={!IsworkingHandicaped}
                // onChange={(e) =>
                //   handleFieldChange('disabilityType', e.target.value)
                // }
              /> */}
                <Input
              size="large"
              value = {psNo}
              disabled={!exTrainee}
            />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='area' label='Area(LEMF/East Shops/CS)'>
              <Select size='large' disabled={isDisabled}>
                {Area?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='trtLocation1'
              label='TRT Location(1)'
              rules={[
                { pattern: /^\d+$/, message: 'Please Enter Only Numbers' },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='trtLocation2'
              label='TRT Location(2)'
              rules={[
                { pattern: /^\d+$/, message: 'Please Enter Only Numbers' },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='remark' label='Remark'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='busRouteCode'
              label='Bus Route Code'
              rules={[{ required: true, message: 'Bus Route Code' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                optionLabelProp='label'
                filterOption={(input, option) =>
                  String(option.children).toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                >
                {/* {busRouteMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))} */}
                {busRouteMaster?.map((area, index) => {
                  const [code, ...descriptionParts] = area.split(' ');
                  const description = descriptionParts.join(' ');

                  return (
                    <Option
                      key={index}
                      value={code}
                      label={`${code} ${description}`}>
                      {code} - {description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item name='joiningRoute' label='Joining Route'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='committee' label='Committee/Post'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='achievement' label='Achievement'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward15'
              label='Long Service Award 15 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent15' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>{' '}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward20'
              label='Long Service Award 20 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent20' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward25'
              label='Long Service Award 25 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent25' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward30'
              label='Long Service Award 30 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent30' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward35'
              label='Long Service Award 35 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent35' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              hasFeedback
              // valuePropName="date"
              // getValueFromEvent={normfile}
            >
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                inputReadOnly={true}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lastDateOfEmployement'
              label='Last Date Of Employement'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              hasFeedback>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                inputReadOnly={true}
                disabled={isDisabled}
                onChange={handleDateOfJoiningChange} // Handle change to calculate date
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lastDateOfEmployement'
              label='Last Date Of Employment'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
                inputReadOnly={true}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              label='Support Document'
              name='supportDocument'
              valuePropName='fileList'
              getValueFromEvent={normFile}>
              <Upload
                // type="file"
                listType='picture'
                accept='image/*'
                onPreview={handlePreview}
                maxCount={1}
                beforeUpload={() => false}
                // disabled={IsworkingBio}
                size='large'
                style={{ width: '100%' }}>
                <Button
                  icon={<UploadOutlined />}
                  size='large'
                  style={{ width: '100%' }}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}>
              <img
                alt='example'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Col>

          <Divider
            hidden={!data?.status || data?.status === 'pending'}></Divider>
          <Col
            className='gutter-row'
            span={3}
            // hidden={data?.status !== 'approved' && data?.status !== 'rehired'}
            hidden={!data?.status || data?.status === 'pending'}>
            <Form.Item name='eventHistory' label='Event History'>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#FAFAFA',
                }}
                icon={<EyeFilled />}
                size='large'
                type='default'
                // htmlType='submit'
                onClick={openEventHistoryTableModal}>
                View
              </Button>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={3}
            hidden={!data?.status || data?.status == 'pending'}>
            <Form.Item name='addEvent' label='Add Event'>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                }}
                icon={<PlusSquareOutlined />}
                size='large'
                // style={{ width: '100%' }}
                type='default'
                onClick={addEventTableModal}
                disabled={
                  !(
                    (data?.status === 'rehired' &&
                      personalDetails?.statusToBeImposed === 'rehired') ||
                    (data?.status === 'approved' &&
                      !personalDetails?.statusToBeImposed) ||
                    (personalDetails?.statusToBeImposed === 'active' &&
                      data?.status === 'approved')
                  )
                }>
                Add
              </Button>
            </Form.Item>
          </Col>
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              // hidden={data?.status !== 'approved'}
            >
              <Form.Item name='event' label='Event'>
                <Select
                  size='large'
                  disabled={isDisabled}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {events?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              // hidden={data?.status !== 'approved'}
            >
              <Form.Item
                name='fromCadre'
                label='From Cadre'
                // rules={[{ required: true, message: 'Select Cadre At Joining' }]}
              >
                <Select
                  size='large'
                  value={currentCadre}
                  disabled={isDisabled}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {/* {cadreCodeMaster?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))} */}
                  {cadreCodeMaster?.map((area, index) => {
                    const [code, ...descriptionParts] = area.split(' ');
                    const description = descriptionParts.join(' ');

                    return (
                      <Option key={index} value={code}>
                        {description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              // hidden={data?.status !== 'approved'}
            >
              <Form.Item
                name='toCadre'
                label='To Cadre'
                // rules={[{ required: true, message: 'Select Cadre At Joining' }]}
              >
                <Select
                  size='large'
                  disabled={isDisabled}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    // handleCadreChange(value);
                    // handleUpdateDateChange(value); //Aprajita
                  }}>
                  {' '}
                  {/* {cadreCodeMaster?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))} */}
                  {cadreCodeMaster?.map((area, index) => {
                    const [code, ...descriptionParts] = area.split(' ');
                    const description = descriptionParts.join(' ');

                    return (
                      <Option key={index} value={code}>
                        {description}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col className='gutter-row' span={6}>
              <Form.Item
                name='effDate'
                label='Eff. Date'
                rules={[{ required: true, message: 'Select Eff. Date' }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  size='large'
                  disabled={isDisabled}
                  format='DD-MM-YYYY'
                  value={initialValues?.effDate}
                  // onChange={(date) => handleInputChange('effDate', date)}
                  // defaultValue={new Date(initialValues.effDate)}
                  inputReadOnly={true} // Disable manual input
                />
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              // hidden={data?.status !== 'approved'}
            >
              <Form.Item name='points' label='Points'>
                <Input size='large' />
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              // hidden={data?.status !== 'approved'}
            >
              <Form.Item name='basic' label='Basic'>
                <Input
                  size='large'
                  defaultValue={currentBasic}
                  disabled={isDisabled}
                  onChange={(e) => {
                    const basicValue = e.target.value;
                    // onNext({ basic: basicValue }); // Pass basic value to parent component
                    // if (basicValue && basicValue.length === 1) {
                    //   onNext(); // Automatically move to the next page
                    // }
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col className='gutter-row' span={6}>
              <Form.Item
                // name='departmentCode'
                name='addEventDepartmentCode'
                label='Department Code'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Enter Department Code',
                //   },
                // ]}
              >
                <Select
                  size='large'
                  disabled={isDisabled}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {department?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))}
                  {/* {department?.map((area, index) => {
                    const [code, ...descriptionParts] = area.split(' ');
                    const description = descriptionParts.join(' ');

                    return (
                      <Option key={index} value={code}>
                        {description}
                      </Option>
                    );
                  })} */}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Divider></Divider>
        <Title level={5}>Other Details</Title>

        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='shoeSize' label='Safety Shoes Size'>
              <Select size='large' disabled={isDisabled}>
                {SafetyShoeSize?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='bloodGroup' label='Blood Group'>
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }>
                {bloodGroup?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='boilerSuitSize' label='Boiler Suit Size'>
              {/* <Select
                size='large'
                placeholder='Boiler Suit Size'
                disabled={isDisabled}>
                <Option value=''></Option>

                <Option value='E'>E</Option>
                <Option value='P'>P</Option>
              </Select> */}
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }>
                {BoilerSuitSize?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='transferFromDate' label='Transfer From Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='transferToDate' label='Transfer To Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
                inputReadOnly={true} // Disable manual input
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='experience' label='Experience'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='academicQualification'
              label='Educational Qualification'>
              <Select size='large' disabled={isDisabled}>
                {qualification?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='yearOfPassing' label='Year Of Passing'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isNextButtonDisabled}>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
      {historyData && historyData.length > 0 && (
        <EventHistoryModal
          visible={eventHistoryTableOpen} // Change prop name to 'visible'
          onCancel={() => setEventHistoryTableOpen(false)}
          eventHistoryData={initialValues?.eventHistory}
          masterData={masterData}
        />
      )}
    </>
  );
};
export default Jobdetails;
