import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Button, Col, Row, Tabs, Typography } from 'antd';
import { getAllReports, getDetails } from '../../../services/auth';
import Chart from './Chart';
import { getSupplier } from '../../../services/supplier';
import OnboardingCharts from './OnboardingCharts';
import ComplianceCharts from './ComplianceCharts';

const { TabPane } = Tabs;

const getUserType = (userConfig) => {
  const { role, level, location, locationId, locationName } = userConfig
  let type

  if(role=='supervisor') type = 'Contractor';

  if(role=='admin') {
    if(location != null || locationId != null || locationName != null) type = 'Local Admin';
    if(location == null && locationId == null && locationName == null) type = 'Super Admin';
  }

  if(role=='approver') {
    if(level==1) type = 'Contract IR';  // Central IR
    if(level==2) type = 'Safety officer';
    if(level==3) type = 'Medical officer';
  }

  if(role=='ir') type = 'Regular IR';

  return type;
}

const Dashboard = () => {
  const { user, userConfig } = useAuth();
  const [chartsData, setChartData] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [tabData, setTabData] = useState([]);

  const getFilters = () => {
    let filter = '{}';
    if (['admin', 'approver'].includes(userConfig.role)) {
      // #dev
      filter = `{%22locationId%22:%22${'RNW'}%22}`;
      // #prod
      // filter = `{%22locationId%22:%22${userConfig.location || userConfig.locationId}%22}`
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // #dev
      filter = `{%22createdBy%22:%22${'120189'}%22}`;
      // #prod
      // filter = `{%22createdBy%22:%22${userConfig.userid}%22}`
    }

    return filter;
  };

  const handleEditClick = () => {
    window.open(userConfig?.editLink, '_blank');
  };

  useEffect(() => {
    getChartsData();
    fetchData();
  }, []);

  useEffect(() => {
      let data = [];
      if (activeTab == '1') data = chartsData.filter((r) => r.type == 'ONBOARDING_DASHBOARD');
      if (activeTab == '2')
        data = chartsData.filter((r) => r.type == 'COMPLIANCE_DASHBOARD');

      if(getUserType(userConfig) != 'Super Admin')
       data = data.filter(c => c?.viewAccessRoles?.includes(getUserType(userConfig)))

      setTabData(data);
    }, [chartsData, activeTab]);

  const getChartsData = async () => {
    const response = await getAllReports();
    setChartData(
      response?.data?.data?.filter((chart) => chart.type != 'REPORTS')
    );
    getPermissions(userConfig.orgId);
  };

  const fetchData = async () => {
    try {
      const res = await getSupplier(userConfig.orgId);
      const list = [];
      const locationId = userConfig.location || userConfig.locationId;

      if (locationId == null) {
        // case of super admin
        const locationMap = {};
        res.data?.suppliers?.map((c) => {
          list.push(c.name);
          locationMap[c.location] = 1;
        });
        setLocationList(Object.keys(locationMap));
      } else if (locationId) {
        res.data?.suppliers?.map((c) => {
          if (c.location == locationId) {
            list.push(c.name);
          }
        });
        setLocationList([locationId]);
      }
      setContractorList(list);
    } catch (error) {
      console.error('Error fetching contractor name:', error);
    }
  };

  const getPermissions = (orgId) => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data?.filter(
          (i) => i.selectedRole?.toLowerCase() == userConfig.role.toLowerCase()
        );
        if (filteredData.length > 0) {
          setPermissions(filteredData[0]?.permissions?.Reports_And_Dashboard);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Typography.Text
            strong
            style={{ fontSize: '16px', padding: '0px 0px 20px 10px' }}>
            Dashboard
          </Typography.Text>
          <Tabs
            defaultActiveKey='1'
            activeKey={activeTab}
            style={{ margin: '0px 0px 0px 10px' }}
            onChange={(key) => setActiveTab(key)}>

            <TabPane tab='Onboarding' key='1'>
            <OnboardingCharts />
            </TabPane>
            <TabPane tab='Compliance' key='2'>
              <ComplianceCharts />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', // Responsive grid
            gap: '20px', // Spacing between cards
            padding: '10px', // Optional: Adds padding around the container
            width: '100%', // Ensures the container takes full width
          }}>
          {tabData.length > 0 &&
            locationList.length > 0 &&
            tabData?.map((c) => (
              <div
                key={`chart-${c.chartId}`}
                style={{
                  width: '100%', // Ensures the card takes full width of the grid cell
                }}>
                <Chart
                  chartData={c}
                  permissions={permissions}
                  userConfig={userConfig}
                  contractorList={contractorList}
                  locationList={locationList}
                />
              </div>
            ))}
        </div>
      </Row>
    </>
  );
};

export default Dashboard;
