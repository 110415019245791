import axios from '../../../services/axiosInstance'

/**
 * Application Preview
 * @param {Object} payload
 */
export const createWorkOrderDetailData = (payload) => {
  return axios.post('/workOrderDetails', {workOrderDetailsData:payload});
};

export const getWorkOrderDetailData = (page, limit) => {
  return axios.get('/workOrderDetails', {
    params: {
      limit: limit, // API expects `pageSize`
      page: page      // API expects `pageNo`
    }
  });
};

export const updateWorkOrderDetailDataById = async(id) => {
//   return axios.patch('/workOrderDetails/:id');
  try {
        const response = await axios.patch(`/workOrderDetails/${id}`);
        return response.data; // Assuming your data is in response.data
    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};


export const updateWorkOrderDetailDataByWorkOrderNumber = async(workOrderNumber, data) => {
    try {
        // Ensure data is an array

        const dataArray = Array.isArray(data) ? data : {workOrderDetailsData:[data]};

        const response = await axios.patch(`/workOrderDetails/workOrderNumber/${workOrderNumber}`, dataArray);
        return response.data; // Assuming your data is in response.data
    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};

export const deleteWorkOrderDetailData = () => {
    return axios.delete('/workOrderDetails/:id');
};

export const fetchWorkOrderDetailData = async(workOrderNumber) => {
    try {
        const response = await axios.get(`/workOrderDetails/${workOrderNumber}`);
        return response.data; // Assuming your data is in response.data

    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};

export const uploadWorkOrderDetailData = async (payload) => {
    try {
        const response = await axios.post(`/workOrderDetails/upload`, payload);
        
        // Check if response is defined and has data property before accessing it
        if (response && response.data) {
            return response.data; // Assuming your data is in response.data
        } else {
            throw new Error("Invalid response received from server");
        }
    } catch (error) {
        // Handle errors appropriately
        throw new Error(`Error uploading work order details data: ${error.message}`);
    }
};

export const getExcelTemplateData = () => {
    return axios.get('/workOrderDetails/excelTemplate');
    // return axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};

export const getDownloadExcel = () => {
    // return axios.get('/workOrderDetails/excelTemplate');
    return axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};

export const getSearch = (filters) => {
    return axios.get('/workOrderDetails/search', { params: filters });
};

