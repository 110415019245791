//     [2020] - [2024] Wikiworks Innovations Private Limited.
//  All Rights Reserved.

// NOTICE:  All information contained herein is, and remains
// the property of Wikiworks its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Wikiworks
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process and are protected by trade secret or copyright
// law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Wikiworks.
// """
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Space,
  Collapse,
  notification,
  Typography,
  Modal,
  Divider,
  Image,
  message,
  Tooltip,
  Upload,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import axios1 from '../../services/axiosInstance';
import Webcam from 'react-webcam';
import {
  SendOtp,
  callCheckAadharUniquenessApi,
  getPSNumber,
  getRNNumber,
  getExcelData,
  postdata,
} from '../../services/auth';
import { VerifyOtp } from '../../services/auth';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { State } from '../../JSON/data';
import {
  Dadra,
  Delhi,
  Jharkhand,
  Karnataka,
  AndhraPradesh,
  ArunachalPradesh,
  Assam,
  Bihar,
  Chhattisgarh,
  Chandigarh,
  Goa,
  Gujarat,
  Haryana,
  HimachalPradesh,
  Jammu,
  Kerala,
  Lakshadweep,
  MadhyaPradesh,
  Maharashtra,
  Manipur,
  Meghalaya,
  Mizoram,
  Nagaland,
  Odisha,
  Punjab,
  Puducherry,
  Rajasthan,
  TamilNadu,
  Telangana,
  Tripura,
  Uttarakhand,
  UttarPradesh,
  WestBengal,
  Sikkim,
} from '../../JSON/data';
import { useParams } from 'react-router-dom';
import { init } from 'echarts';
import debounce, { getLocalDate } from '../../utils/helperFunction';
import { setLoader } from '../../actions';
import CustomFields from '../customFields';
import { getAppStore } from '../../store';
import getLabel from '../../utils/getLabel';
import { getSignedUrl } from '../../services/auth';
import moment from 'moment';

var convert = require('xml-js');

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Personaldetails = ({
  initialValues,
  onNext,
  setImageSrc,
  imageSrc,
  masterData,
}) => {
  const { user, userConfig } = useAuth();
  //
  const orgId = userConfig.orgId;
  const locationId = userConfig.locationId;
  const { id } = useParams();

  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled = initialValues?.autoApproved == true;
  // const isDisabled = initialValues?.approvalStage > 0 && userConfig.role !== "supervisor";

  const formRef = React.useRef();
  const [form] = Form.useForm();
  const [location, setlocation] = React.useState({});
  const [values, setValues] = React.useState(initialValues);
  const [selected, setSelected] = React.useState('environment');
  const [livePhoto, setlivePhoto] = React.useState('');
  const [uploadProfilePhoto, setUploadProfilePhoto] = React.useState('');
  const [aadharPhoto, setaadharPhoto] = React.useState('');
  const [isFetchedFromAadhar, setISFetchedFromAadhar] = React.useState(false);
  const [Isworking, setIsworking] = React.useState(true);
  const [IsworkingBio, setIsworkingBio] = React.useState(true);
  const [aadharNumber, setAadharNumber] = React.useState('');
  const [ReligionCode, setReligionCode] = useState('');
  const [blockType, setBlockType] = useState(
    initialValues?.blockType || 'permanent'
  );
  const [isMarried, setIsMarried] = useState(false);
  const [IsworkingHandicaped, setIsworkingHandicaped] = React.useState(true);

  const { customFields = [] } = getAppStore();
  //

  const [disabledButton, setDisabledButton] = React.useState({
    sendOtp: true,
    verifyOtp: true,
  });

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        // setMasterData(res.data.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Excel data:', error);
      });
  };

  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const BiometricDisable = () => {
    setIsworkingBio(!IsworkingBio);
  };

  const IsworkingHandicapped = () => {
    setIsworkingHandicaped(!IsworkingHandicaped);
  };
  const PmeCheckbox = (e) => {};

  const setExemptStatus = () => {
    //
    let uanDisabled =
      !id || (id && initialValues?.uan)
        ? initialValues?.Uan
          ? true
          : false
        : true;
    let PfDisabled =
      !id || (id && initialValues?.pfAccountNo)
        ? initialValues?.pf
          ? true
          : false
        : true;
    let esicDisabled =
      !id || (id && initialValues?.esicno)
        ? initialValues?.esicexempt
          ? true
          : false
        : true;

    //
    setUanDisabled(uanDisabled);
    setPfDisabled(PfDisabled);
    setEsicDisabled(esicDisabled);
  };

  useEffect(() => {
    EmployeeList();
    setExemptStatus();
    religion();
    getExcel();
  }, []);

  async function EmployeeList() {
    const Response = await axios1.get('/org/locations');
    //
    const Response2 = Response.data.org.locations;
    setlocation({ Response2 });
  }

  const profileImagePreview = async () => {
    if (initialValues?.aadharPhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.aadharPhotoGcpPath)
      );
      setaadharPhoto(data?.data?.data);
    }
    if (initialValues?.livePhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.livePhotoGcpPath)
      );
      setlivePhoto(data?.data?.data);
    }
    if (initialValues?.uploadPhotoGcpPath) {
      const data = await getSignedUrl(
        encodeURIComponent(initialValues?.uploadPhotoGcpPath)
      );
      setUploadProfilePhoto(data?.data?.data);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (initialValues)
      initialValues.initialStatusToBeImposed = initialValues?.statusToBeImposed;
    if (initialValues?.disable == 'no') {
      setDisabilityGray(initialValues?.disable === 'no');
    }
  }, [initialValues]);

  useEffect(() => {
    profileImagePreview();
  }, [initialValues]);

  useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);

    let childNumber = initialValues?.childrenNo || 0;
    setChildNo(childNumber);
    setExemptStatus();
    // setlivePhoto(initialValues?.livePhoto);
    // setaadharPhoto(initialValues?.aadharPhoto);
    setISFetchedFromAadhar(initialValues?.isFetchedFromAadhar);
    setEmploymentStatus(initialValues?.statusToBeImposed);
    setMaritalStatus(initialValues?.martialStatus);
    if (initialValues?.isFetchedFromAadhar) {
      setDisableAddress({
        address1: true,
        address2: true,
        address3: true,
        village: true,
        district: true,
        state: true,
        postOffice: true,
        pincode: true,
      });
    }
    // Determine if married
    const maritalState = initialValues?.martialStatus?.toLowerCase();
    console.log(maritalState, 'maritalState');
    setMaritalStatus(maritalState);

    // Update isMarried state based on the loaded maritalStatus
    const marriedStatus =
      maritalState === 'married' ||
      maritalState === 'widow' ||
      maritalState === 'divorced';
    console.log(marriedStatus, 'marriedStatus');
    setIsMarried(marriedStatus);
  }, [initialValues]);

  const [startScan, setStartScan] = React.useState(false);
  const [loadingScan, setLoadingScan] = React.useState(false);
  const [data, setData] = useState();
  const [date1, setdate1] = React.useState({});
  const [state, setstate] = React.useState({});
  const { Panel } = Collapse;
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [isAadharChecked, setIsAadharChecked] = useState(false);
  const [ViewOnly, setViewOnly] = useState(false);
  const [psNumber, setPSNumber] = useState('');
  const [rnNumber, setRNNumber] = useState('');
  const [DisabilityGray, setDisabilityGray] = useState(false);

  //const [currentPsNumber, setCurrentPsNumber] = useState(psNumber);

  // useEffect(() => {
  //   if (locationChangedId) {

  //     PSNumber(orgId, locationChangedId);
  //     RNNumber();
  // }
  // }, [locationChangedId]);

  // const RNNumber = async (orgId) => {
  //   try {
  //     const response = await getRNNumber(orgId);
  //     const responseData = response.data;

  //

  //     if (responseData.message === 'New entry') {
  //       // If it's a new entry, setPSNumber to '0001'
  //       const newRNNumber = 1;
  //       const updatedRNNumber = `RN${newRNNumber}`;
  //       setRNNumber(updatedRNNumber);

  //       // You can do other processing or actions if needed

  //
  //     } else {
  //       // If it's an existing entry, increment the existing numeric part by 1
  //       let existingNumericPart = responseData.lastRNNumber;
  //       let numericPart = existingNumericPart?.split(/\D+/);

  //       numericPart = parseInt(numericPart[1]) + 1;
  //

  //       // Pad the numeric part with leading zeros based on your desired length
  //       const paddedNumericPart = numericPart.toString().padStart(4, '0');

  //       // Concatenate with any prefix or other formatting you may need
  //       const updatedRNNumber = `RN${paddedNumericPart}`;

  //       // Update the state with the new PSNumber
  //       setRNNumber(updatedRNNumber);

  //       //
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error if necessary
  //   }
  // };

  // const PSNumber = async (orgId,locationChangedId) => {
  //   try {
  //     const response = await getPSNumber(orgId,locationChangedId);
  //     const responseData = response.data;

  //

  //     if (responseData.message === 'New entry') {
  //       // If it's a new entry, setPSNumber to '0001'
  //       const newPSNumber = 1;
  //       const updatedPSNumber = `PS${newPSNumber}`;
  //       setPSNumber(updatedPSNumber);

  //       // You can do other processing or actions if needed

  //
  //     } else {
  //       // If it's an existing entry, increment the existing numeric part by 1
  //       let existingNumericPart = responseData.lastPSNumber;
  //       let numericPart = existingNumericPart?.split(/\D+/);

  //       numericPart = parseInt(numericPart[1]) + 1;
  //

  //       // Pad the numeric part with leading zeros based on your desired length
  //       const paddedNumericPart = numericPart.toString().padStart(4, '0');

  //       // Concatenate with any prefix or other formatting you may need
  //       const updatedPSNumber = `PS${paddedNumericPart}`;

  //       // Update the state with the new PSNumber
  //       setPSNumber(updatedPSNumber);

  //
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error if necessary
  //   }
  // };

  const handleScan = async (scanData) => {
    setViewOnly(true);
    setLoadingScan(true);
    if (scanData && scanData !== '') {
      const xmlDocument1 = new DOMParser().parseFromString(
        scanData,
        'text/xml'
      );
      var xml = scanData;
      var result1 = convert.xml2js(xml, { compact: true, spaces: 4 });
      const fullName = result1.PrintLetterBarcodeData._attributes.name;
      const parts = fullName.split(' ');
      //const lastName = parts.pop();
      const longName = result1.PrintLetterBarcodeData._attributes.longName;
      const lastName = result1.PrintLetterBarcodeData._attributes.lastName;
      //const firstName = parts.join(' ');
      const firstName = result1.PrintLetterBarcodeData._attributes.firstName;
      const gender = result1.PrintLetterBarcodeData._attributes.gender;
      const yob = result1.PrintLetterBarcodeData._attributes.yob;
      const dob = dayjs(
        result1.PrintLetterBarcodeData._attributes.dob,
        'DD-MM-YYYY'
      );
      var theAge = 2023 - yob;
      const uid = result1.PrintLetterBarcodeData._attributes.uid;
      const endNum = uid.slice(-4);
      const maskNum = endNum.padStart(uid.length, '*');
      const co = result1.PrintLetterBarcodeData._attributes.co;
      const house = result1.PrintLetterBarcodeData._attributes.house;
      const street = result1.PrintLetterBarcodeData._attributes.street;
      const lm = result1.PrintLetterBarcodeData._attributes.lm;
      const loc = result1.PrintLetterBarcodeData._attributes.loc;
      const village = result1.PrintLetterBarcodeData._attributes.vtc;
      const dist = result1.PrintLetterBarcodeData._attributes.dist;
      const state = result1.PrintLetterBarcodeData._attributes.state;
      const pc = result1.PrintLetterBarcodeData._attributes.pc;
      const address =
        result1.PrintLetterBarcodeData._attributes.house +
        ', ' +
        street +
        ', ' +
        lm +
        ', ' +
        loc;
      setdate1({ dob });
      setData(xml);
      setStartScan(false);
      setLoadingScan(false);

      setstate({ state });

      form.setFieldsValue({
        longName: longName,
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        aadharNo: maskNum,
        fatherName: co,
        address: address,
        village: village,
        district: dist,
        state: state,
        pincode: pc,
        age: theAge,
        dob: dob,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  const onFinish = (values) => {
    console.log('values', values);
    form.setFieldValue(
      'personalDetails.Dob',
      values?.personalDetails?.Dob
        ? dayjs(values?.personalDetails?.Dob).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.dob',
      values?.personalDetails?.dob
        ? dayjs(values?.personalDetails?.dob).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.yearsInGujarath',
      values?.personalDetails?.yearsInGujarath
        ? dayjs(values?.personalDetails?.yearsInGujarath).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.addressRevisedDate',
      values?.personalDetails?.addressRevisedDate
        ? dayjs(values?.personalDetails?.addressRevisedDate).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.dobOFMarriage',
      values?.personalDetails?.dobOFMarriage
        ? dayjs(values?.personalDetails?.dobOFMarriage).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.dobOFSpouse',
      values?.personalDetails?.dobOFSpouse
        ? dayjs(values?.personalDetails?.dobOFSpouse).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.dobFather',
      values?.personalDetails?.dobFather
        ? dayjs(values?.personalDetails?.dobFather).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );
    
    form.setFieldValue(
      'personalDetails.dobMother',
      values?.personalDetails?.dobMother
        ? dayjs(values?.personalDetails?.dobMother).utc().local().startOf('day').format('YYYY-MM-DD')
        : ''
    );

    console.log("values 519 ", values)
    values.aadharPhoto = uidai?.profilePic
      ? 'data:image/png;base64,' + uidai?.profilePic
      : initialValues?.aadharPhoto || '';

    if (initialValues?.status) {
      values.addressRevised = isFieldChanged;
    } else {
      values.addressRevised = false;
    }
    if (isFieldChanged) {
      values.addressRevisedDate = dayjs();
      console.log('addressRevisedDate433', new Date());
    }

    values.livePhoto = imageSrc || initialValues?.livePhoto;
    values.LongName = values?.LongName || initialValues?.LongName;

    if (ReligionCode || initialValues?.religion) {
      values.religion = ReligionCode || initialValues.religion;
    }

    if (
      !(values.firstName || initialValues.firstName) ||
      !(values.locationId || initialValues.locationId)
    ) {
      message.error('Please open all the section to proceed.');
      return false;
    }
    //
    // if (values.psNumber === undefined) {
    //   values.psNumber = psNumber;
    // }
    values.isFetchedFromAadhar = isFetchedFromAadhar;
    // values.statusFromDate = new Date(values.statusFromDate).getTime();
    // values.statusToDate = new Date(values.statusToDate).getTime();
    // values.statusTerminateNoticeDate = new Date(
    //   values.statusTerminateNoticeDate
    // ).getTime();
    onNext(values);
  };

  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const handleFieldChange1 = (fieldName, value) => {
    console.log('iam inside 1894');
    setIsFieldChanged(true);
  };

  const [UanDisabled, setUanDisabled] = useState();
  const [PfDisabled, setPfDisabled] = useState();
  const [EsicDisabled, setEsicDisabled] = useState();

  const religionDropdown = initialValues?.religion;

  useEffect(() => {
    form.setFieldValue('esicexempt', EsicDisabled);
    form.setFieldValue('pf', PfDisabled);
    form.setFieldValue('Uan', UanDisabled);

    // form.setFieldValue('locationId', userConfig?.locationId);
  }, [EsicDisabled, PfDisabled, UanDisabled]);
  const [MaritalDisabled, setMaritalDisabled] = useState(true);
  const [date, setDate] = useState(new Date());
  const [uidai, setuidai] = useState({});
  const [adhar, setadhar] = useState('');
  const [otp, setotp] = useState('');
  const [Request, setRequest] = useState({});
  const [disable, setDisable] = React.useState(false);
  const [otpdisable, setotpDisable] = React.useState(false);
  const [consent, setconsent] = React.useState(true);
  const [openedAccordian, setOpenAccordian] = useState([]);

  const onChange1 = (date) => {
    setDate(date);
  };

  //on preview Usestate
  /* eslint-disable */

  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [presentAdress, setpresentAdress] = useState({
    address: '',
    village: '',
    district: '',
    state: '',
    pincode: '',
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setpresentAdress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsFieldChanged(true);
  };
  const [disableAddress, setDisableAddress] = useState({});

  useEffect(() => {
    // Check if the initial value for 'withCompany' is not set
    if (!initialValues?.employeeType) {
      form.setFieldsValue({ employeeType: '1' });
    }
  }, [initialValues]);

  const religionCodeMap = {
    1: 'BUDHIST',
    2: 'CHRISTIAN',
    3: 'HINDU',
    4: 'JAIN',
    5: 'JEW',
    6: 'MUSLIM',
    7: 'SIKH',
    8: 'ZEROSTRIAN',
    // 9: 'OTHERS',
    0: 'OTHERS',
  };

  React.useEffect(() => {
    setIsExistingEntry(initialValues?.aadharNo);
    // form.setFieldValue(
    //   'religion',
    //   religionDropdown == '3' ? 'HINDU' : 'OTHERS'
    // );
    const religionValue = religionCodeMap[religionDropdown] || '';
    if (religionValue) {
      form.setFieldsValue({
        religion: religionValue,
      });
    }
    setBlockType(initialValues?.blockType);
  }, [initialValues]);

  const agecalculated = (e) => {
    //
  };
  const Uantoggle = () => {
    form.setFieldValue('uan', '');
    setUanDisabled(!UanDisabled);
  };
  const Pftoggle = (e) => {
    form.setFieldValue('pfAccountNo', '');
    setPfDisabled(!PfDisabled);
  };
  const Esictoggle = (e) => {
    form.setFieldValue('esicno', '');
    // form.setFieldValue('esicexempt', e.target.value);
    setEsicDisabled(!EsicDisabled);
  };
  const Maritaltoggle = (values) => {
    if (Option.value == values.married) {
      setMaritalDisabled(!MaritalDisabled);
    } else {
      setMaritalDisabled(MaritalDisabled);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);
  function addressFunction(e) {
    if (e.target.checked) {
      form.setFieldsValue({
        paddress1: form.getFieldValue('address1'),
        paddress2: form.getFieldValue('address2'),
        paddress3: form.getFieldValue('address3'),

        pstate: form.getFieldValue('state'),
        pvillage: form.getFieldValue('village'),
        pdistrict: form.getFieldValue('district'),
        ppincode: form.getFieldValue('pincode'),
        ppostOffice: form.getFieldValue('postOffice'),
        pmobile: form.getFieldValue('mobile'),
      });
    } else {
      form.setFieldsValue({
        paddress1: form.getFieldValue(''),
        paddress2: form.getFieldValue(''),
        paddress3: form.getFieldValue(''),
        pstate: form.getFieldValue(''),
        pvillage: form.getFieldValue(''),
        pdistrict: form.getFieldValue(''),
        ppincode: form.getFieldValue(''),
        ppostOffice: form.getFieldValue(''),
        pmobile: form.getFieldValue(''),
      });
    }
  }

  const adharvalue = (event) => {
    const inputValue = event.target.value;
    setAadharNumber(inputValue);
    setadhar(event.target.value);

    // Check Aadhar length
    if (inputValue.length === 12) {
      setIsAadharValid(true); // Set Aadhar as valid if it's 12 digits
    } else {
      setIsAadharValid(false);
    }

    setIsAadharChecked(false);
    // Other logic for checking Aadhar uniqueness if needed
  };

  const otpvalue = (event) => {
    setotp(event.target.value);
  };

  const handleDisabledChange = (value) => {
    const shouldDisable = value === 'no';
    setDisabilityGray(shouldDisable);

    if (shouldDisable) {
      form.resetFields([
        'disabilityByBirth',
        'disabilityType',
        'disabilityPercentage',
      ]);
    }
  };

  //new verify otp
  const existingValuesForCheck = {
    FirstName: initialValues?.firstName,
    LastName: initialValues?.lastName,
    Gender: initialValues?.gender,
    DateOfBirth: dayjs(initialValues?.dob).format('DD-MM-YYYY'),
    Age: initialValues?.age,
  };

  const ReligionCode1 = (value) => {
    setReligionCode(value);
  };

  //new send otp
  const Sendotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'http://localhost:3000/api/newSendOtp',
        `${process.env.REACT_APP_API_URL}/newSendOtp`,
        {
          // If running on different domains or ports in development, specify the full URL, e.g., "http://localhost:5000/api/getOkycOtp"
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            aadhaarNumber: adhar,
          }),
        }
      );
      //

      if (response?.ok) {
        const data = await response.json();
        setDisabledButton({ ...disabledButton, verifyOtp: false });
        setDisable(true);
        const requestId = data?.responseObject?.requestId;
        setRequest({ requestId });
        //if(data?.responseObject?.result)
        if (Object.keys(data?.responseObject?.result)?.length !== 0) {
          notification.success({
            // message: 'OTP sent to registered mobile number',
            message: data?.responseObject?.result?.message,
          });
        } else if (Object.keys(data?.responseObject?.result)?.length === 0) {
          notification.error({
            status: data?.responseObject?.statusCode,
            message: 'Please try again after a few seconds',
          });
        } else {
          notification.error({
            message: data?.responseObject?.error,
          });
        }
      } else {
        console.error('Failed to send OTP');
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
    }
  };

  //Verify Otp
  const Verifyotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'http://localhost:3000/api/verifyOtp',
        `${process.env.REACT_APP_API_URL}/verifyOtp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-karza-key': 'TrW5eNdWoKynOjsl',
          },
          body: JSON.stringify({
            requestId: Request.requestId,
            otp: otp,
            aadhaarNo: adhar,
            consent: 'Y',
            clientData: {
              caseId: '123456', // Case ID (ensure it's the correct value)
            },
          }),
        }
      );
      console.log('response is669', response);
      //
      if (response.ok) {
        setotpDisable(true);
        const data = await response.json();
        console.log('data', data);
        const realData = data?.result?.dataFromAadhaar;
        const realDataAddress =
          data?.result?.dataFromAadhaar?.address?.splitAddress;
        const addressParts =
          data?.result?.dataFromAadhaar?.address?.combinedAddress
            .split(',')
            .map((part) => part.trim());

        console.log(
          '678',
          data?.result?.dataFromAadhaar?.address?.splitAddress
        );
        var dob = new Date(realData?.dob);
        console.log('dob688', dob);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);

        setViewOnly(true);
        const name = realData?.name;
        const parts = name?.trim().split(' ');

        const lastName = parts.pop();
        const firstName = parts.join(' ');
        console.log(firstName, lastName, 'name');
        const profilePic = realData?.image;

        const endNum = adhar.slice(-4);
        const maskNum = endNum.padStart(adhar.length, '*');

        setuidai({
          profilePic,
        });

        form.setFieldsValue({
          firstName: firstName,
          lastName: lastName,
          gender: realData?.gender,
          longName: realData?.name?.trim(),
          LongName: firstName + ' ' + lastName,
          fatherName: realData?.fatherName,
          address1: (realDataAddress?.houseNumber || '')?.slice(0, 30),
          address2: (realDataAddress?.street || '')?.slice(0, 30),
          address3: (
            (realDataAddress?.landmark || '') +
            (realDataAddress?.location || '')
          )?.slice(0, 30),

          village: realDataAddress?.vtcName,
          district: realDataAddress?.district,
          state: realDataAddress?.state,
          city: realDataAddress?.subdistrict,
          pincode: realDataAddress?.pincode,
          postOffice: realDataAddress?.postOffice,
          dob: dayjs(realData?.dob, 'YYYY-MM-DD'),
          Dob: dayjs(realData?.dob, 'YYYY-MM-DD'),
          yearsInGujarath: dayjs(realData?.dob, 'YYYY-MM-DD'),
          age: age,
          country: realDataAddress?.country,
        });
        validateAddressFields(realDataAddress);
        setISFetchedFromAadhar(true);
        notification.success({
          message: 'Fetching Details From UIDAI Server',
        });
      }
      // }
      else {
        notification.error({
          message: 'Failed to verify OTP. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
      // setOtp('');
    }
  };

  const validateAddressFields = (realDataAddress) => {
    const obj = {
      address1: false,
      address2: false,
      address3: false,
      village: false,
      district: false,
      state: false,
      postOffice: false,
      pincode: false,
    };
    if (
      realDataAddress?.houseNumber &&
      realDataAddress?.houseNumber?.length > 0
    )
      obj.address1 = true;
    if (realDataAddress?.street && realDataAddress?.street?.length > 0)
      obj.address2 = true;
    if (
      (realDataAddress?.landmark && realDataAddress?.landmark?.length > 0) ||
      (realDataAddress?.location && realDataAddress?.location?.length > 0)
    )
      obj.address3 = true;
    if (realDataAddress?.vtcName && realDataAddress?.vtcName?.length > 0)
      obj.village = true;
    if (realDataAddress?.district && realDataAddress?.district?.length > 0)
      obj.district = true;
    if (realDataAddress?.state && realDataAddress?.state?.length > 0)
      obj.state = true;
    if (
      realDataAddress?.postOffice &&
      realDataAddress?.postOffice?.length > 0
    ) {
      obj.postOffice = true;
    }
    if (realDataAddress?.pincode && realDataAddress?.pincode?.length > 0)
      obj.pincode = true;
    setDisableAddress({ ...obj });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const consentreview = (event) => {
    setconsent(!consent);
  };
  /* Some of States not fetching the district in address section. @ Viresh Bangargi 29July2024 */

  //State And District
  const states = State;
  const citi = {
    'Andhra Pradesh': AndhraPradesh,
    'Andaman and Nicobar Islands': AndhraPradesh,
    'Arunachal Pradesh': ArunachalPradesh,
    Assam: Assam,
    Bihar: Bihar,
    Karnataka: Karnataka,
    Chhattisgarh: Chhattisgarh,
    Chandigarh: Chandigarh,
    Dadra: Dadra,
    Delhi: Delhi,
    Goa: Goa,
    Gujarat: Gujarat,
    Haryana: Haryana,
    'Himachal Pradesh': HimachalPradesh,
    'Jammu and Kashmir': Jammu,
    Jharkhand: Jharkhand,
    Kerala: Kerala,
    Lakshadweep: Lakshadweep,
    'Madhya Pradesh': MadhyaPradesh,
    Maharashtra: Maharashtra,
    Manipur: Manipur,
    Meghalaya: Meghalaya,
    Mizoram: Mizoram,
    Nagaland: Nagaland,
    Odisha: Odisha,
    Punjab: Punjab,
    Puducherry: Puducherry,
    Rajasthan: Rajasthan,
    'Tamil Nadu': TamilNadu,
    Telangana: Telangana,
    Tripura: Tripura,
    Uttarakhand: Uttarakhand,
    UttarPradesh: UttarPradesh,
    'West Bengal': WestBengal,
    Sikkim: Sikkim,
  };
  const [selectedState, setSelectedState] = useState('');
  const [selectedstate, setSelectedstate] = useState('');
  //Live Capture
  const [visible, setVisible] = useState(false);
  const [imageSrc1, setImageSrc1] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const webcamRef = useRef(null);
  const [isAadharValid, setIsAadharValid] = React.useState(false);
  const [isExistingEntry, setIsExistingEntry] = React.useState(
    initialValues?.aadharNo
  );

  const toggleCamera = () => {
    setVisible(!visible);
    setIsCapturing(true);
  };

  const handleOk = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setVisible(false);
    setIsCapturing(true);
  };

  const handleCancel1 = () => {
    setIsCapturing(false);
    setVisible(false);
  };
  //Marital Status
  const handleMaritalStatusChange = (value) => {
    setMaritalStatus(value);
    const marriedStatus =
      value === 'married' || value === 'widow' || value === 'divorced';
    setIsMarried(marriedStatus);
    if (value === 'unmarried') {
      form.setFieldsValue({
        dobOFMarriage: '',
        spousename: '',
        relation: '',
        dobOFSpouse: '',
        qualificationOfSpouse: '',
        occupationOfSpouse: '',
      });
    }
  };

  const validateage = (_, value) => {
    const age = Number(value);

    if (age < 15 || age > 58) {
      return Promise.reject('Age must be between 15 and 58');
    }

    return Promise.resolve();
  };

  const changeAccordian = (e) => {
    if (e[0] && !openedAccordian.includes(e[0])) {
      setOpenAccordian([...openedAccordian, e[0]]);
    }
  };

  //for enable and disble aadhaar details
  const [employmentStatus, setEmploymentStatus] = useState(
    initialValues?.statusToBeImposed
  ); // Default value

  const handleStatusChange = (value) => {
    setEmploymentStatus(value);
  };

  // useEffect(() => {
  //   const checkAadhar = setTimeout(() => {
  //     callCheckAadharUniquenessApi(adhar)
  //       .then((res) => {
  //
  //       })
  //       .catch((error) => {
  //
  //       });
  //   }, 1000);

  //   return () => clearTimeout(checkAadhar);
  // }, [adhar]);

  const checkAadharUniqueness = async (data) => {
    try {
      setLoader(true);
      const res = await callCheckAadharUniquenessApi(data);
      setLoader(false);

      if (!res.data.isAadhaarAvailable) {
        setDisabledButton({ ...disabledButton, sendOtp: false });
        return true;
        // throw Error('Aadhar already exist.');
      }
      return false;
      //
    } catch (error) {
      return false;
    }
  };

  //--------------------------
  const initialChildNo = localStorage.getItem('childNo') || '1';

  const [isDisabled1, setIsDisabled1] = useState(false);
  const [childNo, setChildNo] = useState('0');
  const [Religion, setReligion] = useState([]);

  // useEffect(() => {
  //   // Save 'childNo' to local storage whenever it changes
  //   localStorage.setItem('childNo', childNo);
  // }, [childNo]);

  const handleChildNoChange = (value) => {
    setChildNo(value);
  };
  function religion() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Religion';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const biolersuitsize1 = dataFromArray?.dataParsed?.map((item) => {
      return item?.Religion;
    });
    // setPrimaryJobSkill(primaryJobSkillAreas);
    setReligion(biolersuitsize1);

    //

    if (dataFromArray) {
      //
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  //Aprajita
  const renderRowsBasedOnChildNo = () => {
    const rows = [];
    const childrenNo = childNo == 0 ? initialValues?.childrenNo : childNo;
    const childrenData = initialValues?.children || [];

    const [mediclaimValues, setMediclaimValues] = useState(
      childrenData.map((child) => child.mediclaim || '')
    );

    const handleMediclaimChange = (value, index) => {
      const newValues = [...mediclaimValues];
      newValues[index] = value;
      setMediclaimValues(newValues);
    };

    const children = [];
    if (childrenData.length >= 0 && childrenNo > 0) {
      for (let i = 0; i < childrenNo; i++) {
        const childData = childrenData[i] || {};

        const birthDate = childData.birthDate
          ? dayjs(getLocalDate(childData.birthDate))
          : null;
        const effDate = childData.childMediclaomEffDate
          ? dayjs(getLocalDate(childData?.childMediclaomEffDate))
          : null;
        const child = {
          name: childrenData[i]?.name || '',
          male: childrenData[i]?.male || '',
          // birthDate: '20-08-1998',
          birthDate: childrenData[i]?.birthDate
            ? dayjs(new Date(childrenData[i]?.birthDate))
            : null,
          medium: childrenData[i]?.medium || '',
          mediclaim: childrenData[i]?.mediclaim || '',
          occupation: childrenData[i]?.occupation || '',
          effDate: childrenData[i]?.childMediclaomEffDate
            ? dayjs(getLocalDate(childrenData[i]?.childMediclaomEffDate))
            : null,
        };

        children.push(child);

        rows.push(
          <React.Fragment key={i}>
            <Row gutter={16}>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`name${i + 1}`}
                  label='Name'
                  initialValue={child.name}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Name`,
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled1} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`male${i + 1}`}
                  label='Male/Female'
                  initialValue={child.male}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please Select Child ${i + 1} Male/Female`,
                  //   },
                  // ]}
                >
                  <Select size='large' disabled={isDisabled1}>
                    <Option value='male'>Male</Option>
                    <Option value='female'>Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`birthDate${i + 1}`}
                  label='Birth Date'
                  // initialValue={moment(child.birthDate, 'YYYY-MM-DD')}
                  initialValue={child.birthDate}
                  // initialValue={child.birthDate ? moment(child.birthDate, 'YYYY-MM-DD') : null}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Birth Date`,
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    format='DD-MM-YYYY'
                    disabled={isDisabled1}
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        [`birthDate${i + 1}`]: date,
                      });
                    }}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`medium${i + 1}`}
                  // label={`Child ${i} Medium`}
                  label='Medium'
                  initialValue={child.medium}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Medium`,
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled1} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`occupation${i + 1}`}
                  // label={`Child ${i} Occupation`}
                  label='Occupation'
                  initialValue={child.occupation}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Occupation`,
                  //   },
                  // ]}
                >
                  <Select size='large' disabled={isDisabled}>
                    {/* <Option value='Student'>Student</Option>
                    <Option value='Child'>Child</Option>
                    <Option value='Working'>Working</Option> */}
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                    {/* <Option value='Working'>Working</Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`mediclaim${i + 1}`}
                  // label={`Child ${i} Mediclaim`}
                  label='Mediclaim'
                  initialValue={child.mediclaim}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Mediclaim`,
                  //   },
                  // ]}
                >
                  {/* <Input size='large' disabled={isDisabled1} /> */}
                  <Select
                    size='large'
                    onChange={(value) => handleMediclaimChange(value, i)}>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={4}>
                <Form.Item
                  name={`effDate${i + 1}`}
                  // label={`Child ${i} Mediclaim`}
                  label='Mediclaim Eff.Date'
                  initialValue={child.effDate}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${i + 1} Mediclaim Eff.Date`,
                  //   },
                  // ]}
                >
                  {/* <Input size='large' disabled={isDisabled1} /> */}
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={
                      childData?.mediclaim == 'No' ||
                      mediclaimValues[i] === 'no'
                    }
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        [`effDate${i + 1}`]: date,
                      });
                    }}
                    inputReadOnly={true} // Disable manual input

                    // disabled={isEditingDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
    } else {
      childrenData.forEach((child, index) => {
        rows.push(
          <React.Fragment key={index}>
            <Row gutter={16}>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`name${index + 1}`}
                  label={`Name`}
                  initialValue={child.name}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${index + 1} Name`,
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled1} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`male${index + 1}`}
                  label='Male/Female'
                  initialValue={child.male}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please Select Child ${index + 1} Male/Female`,
                  //   },
                  // ]}
                >
                  <Select size='large' disabled={isDisabled1}>
                    <Option value='male'>Male</Option>
                    <Option value='female'>Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`birthDate${index + 1}`}
                  label='Birth Date'
                  initialValue={child.birthDate}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${index + 1} Birth Date`,
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    placeholder='20-03-2020'
                    disabled={isDisabled1}
                    format='DD-MM-YYYY'
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        [`birthDate${index + 1}`]: date,
                      });
                    }}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`medium${index + 1}`}
                  label='Medium'
                  initialValue={child.medium}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${index + 1} Medium`,
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled1} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`occupation${index + 1}`}
                  label='Occupation'
                  initialValue={child.occupation}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${index + 1} Occupation`,
                  //   },
                  // ]}
                >
                  <Select size='large' disabled={isDisabled}>
                    {/* <Option value='Student'>Student</Option>
                    <Option value='Child'>Child</Option>
                    <Option value='Working'>Working</Option> */}
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={3}>
                <Form.Item
                  name={`mediclaim${index + 1}`}
                  label='Mediclaim'
                  initialValue={child.mediclaim}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${index + 1} Mediclaim`,
                  //   },
                  // ]}
                >
                  <Select size='large'>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={4}>
                <Form.Item
                  name={`effDate${index + 1}`}
                  label='Mediclaim Eff.Date'
                  initialValue={child.effDate}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: `Please enter Child ${
                  //       index + 1
                  //     } Mediclaim Eff.Date`,
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={childData?.mediclaim == 'No'}
                    onChange={(date, dateString) => {
                      form.setFieldsValue({
                        [`effDate${index + 1}`]: date,
                      });
                    }}
                    inputReadOnly={true} // Disable manual input

                    // disabled={isEditingDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        );
      });
    }

    // });

    return rows;
  };

  useEffect(() => {
    // PSNumber();
    religion();
    getExcelData();
    //RNNumber();
  }, []);

  const [dob, setDob] = useState(null);

  const handleDateChange = (date) => {
    setDob(date);
    var dob = new Date(date);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);

    form.setFieldsValue({ age });
    form.setFieldsValue({ yearsInGujarath: date });
    console.log('date1741', date);
  };

  // const RNNumber = async (orgId) => {
  //   try {
  //     const response = await getRNNumber(orgId);
  //     const responseData = response.data;

  //

  //     if (responseData.message === 'New entry') {
  //       // If it's a new entry, setPSNumber to '0001'
  //       const newRNNumber = 1;
  //       const updatedRNNumber = `RN${newRNNumber}`;
  //       setRNNumber(updatedRNNumber);

  //       // You can do other processing or actions if needed

  //
  //     } else {
  //       // If it's an existing entry, increment the existing numeric part by 1
  //       let existingNumericPart = responseData.lastRNNumber;
  //       let numericPart = existingNumericPart?.split(/\D+/);

  //       numericPart = parseInt(numericPart[1]) + 1;
  //

  //       // Pad the numeric part with leading zeros based on your desired length
  //       const paddedNumericPart = numericPart.toString().padStart(4, '0');

  //       // Concatenate with any prefix or other formatting you may need
  //       const updatedRNNumber = `RN${paddedNumericPart}`;

  //       // Update the state with the new PSNumber
  //       setRNNumber(updatedRNNumber);

  //       //
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error if necessary
  //   }
  // };

  const PSNumber = async (orgId, locationId) => {
    try {
      const response = await getPSNumber(orgId, locationId);
      const responseData = response.data;

      if (responseData.message === 'New entry') {
        // If it's a new entry, setPSNumber to '0001'
        const newPSNumber = 1;
        const updatedPSNumber = `PS${newPSNumber}`;
        setPSNumber(updatedPSNumber);

        // You can do other processing or actions if needed
      } else {
        // If it's an existing entry, increment the existing numeric part by 1
        let existingNumericPart = responseData.lastPSNumber;
        let numericPart = existingNumericPart?.split(/\D+/);

        numericPart = parseInt(numericPart[1]) + 1;

        // Pad the numeric part with leading zeros based on your desired length
        const paddedNumericPart = numericPart.toString().padStart(4, '0');

        // Concatenate with any prefix or other formatting you may need
        const updatedPSNumber = `PS${paddedNumericPart}`;

        // Update the state with the new PSNumber
        setPSNumber(updatedPSNumber);
      }
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const handleFieldChange = async (changedField, allField) => {
    //
    if (changedField[0]?.name[0] === 'locationId') {
      //const currentPsNumber = psNumberRef.current;
      // const currentRnNumber = rnNumberRef.current;
      // if (changedField[0]?.value === 'ranoli') {
      //   try {
      //     const response = await getRNNumber(orgId);
      //     const responseData = response.data;
      //
      //     if (responseData.message === 'New entry') {
      //       // If it's a new entry, setPSNumber to '0001'
      //       const newRNNumber = 1;
      //       const updatedRNNumber = `RN${newRNNumber}`;
      //       setRNNumber(updatedRNNumber);
      //       // You can do other processing or actions if needed
      //
      //     } else {
      //       // If it's an existing entry, increment the existing numeric part by 1
      //       let existingNumericPart = responseData.lastRNNumber;
      //       let numericPart = existingNumericPart?.split(/\D+/);
      //       numericPart = parseInt(numericPart[1]) + 1;
      //
      //       // Pad the numeric part with leading zeros based on your desired length
      //       const paddedNumericPart = numericPart.toString().padStart(4, '0');
      //       // Concatenate with any prefix or other formatting you may need
      //       const updatedRNNumber = `RN${paddedNumericPart}`;
      //       // Update the state with the new PSNumber
      //       setRNNumber(updatedRNNumber);
      //       form.setFieldsValue({
      //         // Specify the field names and their new values
      //         // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
      //         psNumber: updatedRNNumber,
      //         // Add more fields as needed
      //       });
      //       //
      //     }
      //   } catch (error) {
      //     console.error(error);
      //     // Handle error if necessary
      //   }
      // }
      // else{
      //   try {
      //     const response = await getPSNumber(orgId,locationChangedId);
      //     const responseData = response.data;
      //
      //     if (responseData.message === 'New entry') {
      //       // If it's a new entry, setPSNumber to '0001'
      //       const newPSNumber = 1;
      //       const updatedPSNumber = `PS${newPSNumber}`;
      //      // setPSNumber(updatedPSNumber);
      //       form.setFieldsValue({
      //         // Specify the field names and their new values
      //         // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
      //         psNumber: updatedPSNumber,
      //         // Add more fields as needed
      //       });
      //       // You can do other processing or actions if needed
      //
      //     } else {
      //       // If it's an existing entry, increment the existing numeric part by 1
      //       let existingNumericPart = responseData.lastPSNumber;
      //       let numericPart = existingNumericPart?.split(/\D+/);
      //       numericPart = parseInt(numericPart[1]) + 1;
      //
      //       // Pad the numeric part with leading zeros based on your desired length
      //       const paddedNumericPart = numericPart.toString().padStart(4, '0');
      //       // Concatenate with any prefix or other formatting you may need
      //       const updatedPSNumber = `PS${paddedNumericPart}`;
      //       // Update the state with the new PSNumber
      //       setPSNumber(updatedPSNumber);
      //       form.setFieldsValue({
      //         // Specify the field names and their new values
      //         // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
      //         psNumber: updatedPSNumber,
      //         // Add more fields as needed
      //       });
      //
      //     }
      //   } catch (error) {
      //     console.error(error);
      //     // Handle error if necessary
      //   }
      // }
    } else if (changedField[0]?.name[0] === 'blockType') {
      setBlockType(changedField[0]?.value);
    }
  };

  // You can access form values using getFieldInstance method provided by Form instance
  const firstName = form.getFieldValue('firstName');
  const fatherName = form.getFieldValue('fatherName');
  const lastName = form.getFieldValue('lastName');

  // Now, you can concatenate the values to form the long name
  const longName = `${firstName || ''} ${fatherName || ''} ${lastName || ''}`;

  // const [previewOpen, setPreviewOpen] = useState(true);
  return (
    <>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        onFieldsChange={handleFieldChange}
        ref={formRef}
        form={form}>
        <Collapse onChange={changeAccordian}>
          <Panel header='Aadhaar Details' key='1'>
            <Row>
              <Checkbox
                disabled={
                  initialValues?.approvalStage === 0 &&
                  userConfig.role === 'approver'
                }
                onClick={consentreview}>
                I hereby, at my own discretion, voluntarily submit the physical
                copy of my Aadhaar card / physical e-Aadhaar / masked Aadhaar /
                offline electronic Aadhaar / copies of my Aadhaar card in
                physical or electronic form or xml as issued by UIDAI (Aadhaar),
                for the purpose of establishing my identity / age / address
                proof and voluntarily give my consent for the purpose of
                employment-related verification. I understand that I have the
                right to withdraw this consent at any point in time and that I
                have the right to complain to the Data Protection Board of India
                for any breach of my personal identity.
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name='aadharNo'
                      hidden={consent}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!id) {
                              if (!/^[0-9]{12}$/.test(value)) {
                                return Promise.reject(
                                  'Enter Valid Aadhaar No.'
                                );
                                // throw new Error('Enter Valid Aadhar No.');
                                // return 0;
                              }
                              if (
                                value.length === 12 &&
                                !(isFetchedFromAadhar || isAadharChecked)
                              ) {
                                const isUnique = await checkAadharUniqueness(
                                  value
                                );
                                //
                                if (!isUnique) {
                                  return Promise.reject(
                                    'Aadhaar already exist.'
                                  );
                                }
                                setIsAadharChecked(true);
                              }
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter Aadhaar Number'
                        // value={adhar}
                        onChange={adharvalue}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Col>
                  {(isAadharValid || isExistingEntry) && (
                    <Col span={12}>
                      <Form.Item hidden={consent}>
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          onClick={Sendotp}
                          disabled={disable}
                          type='primary'>
                          Send OTP
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {!disabledButton.verifyOtp && (
                  <Row style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Form.Item
                        name='otp'
                        hidden={consent}
                        rules={[
                          {
                            pattern: /^\d{6}$/,
                            message: 'Six Digit Required',
                          },
                        ]}>
                        <Input
                          size='large'
                          placeholder='Enter OTP'
                          value={otp}
                          onChange={otpvalue}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='Verify Otp' hidden={consent}>
                        {/* <div className="App" > */}
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          type='primary'
                          onClick={Verifyotp}
                          disabled={otpdisable}>
                          Verify OTP
                        </Button>
                        {/* </div> */}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Divider />

        {/* <Title level={5}>Personal Details</Title> */}
        {/* <h3>Personneldetails</h3> */}
        {/* <Form layout="vertical" initialValues={initialValues} onFinish={onFinish} form={form}

      >  */}
        <Collapse onChange={changeAccordian}>
          <Panel header='Personal Details' key='2'>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='firstName'
                  // label={getLabel('firstName')}
                  label='First Name'
                  rules={[
                    { required: true, message: 'Enter First Name ' },

                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    // {
                    //   max: 50,
                    //   message: 'Maximum length allowed is 50 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    // disabled={isEditingDisabled}
                    onChange={(e) => {
                      const firstName = e.target.value;
                      const fatherName = form.getFieldValue('fatherName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${fatherName} ${lastName}`;
                      const initialsValue = `${(firstName[0] || '').trim()}${(
                        fatherName[0] || ''
                      ).trim()}${(lastName[0] || '').trim()}`;

                      form.setFieldsValue({ initials: initialsValue });
                      form.setFieldsValue({ LongName });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='fatherName'
                  label="Father's Name"
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    // {
                    //   min: 1,
                    //   message: 'Minimum length must be 1 characters',
                    // },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) => {
                      const fatherName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${fatherName} ${lastName}`;
                      const initialsValue = `${(firstName[0] || '').trim()}${(
                        fatherName[0] || ''
                      ).trim()}${(lastName[0] || '').trim()}`;

                      form.setFieldsValue({ initials: initialsValue });
                      form.setFieldsValue({ LongName });
                    }}
                    // <Input
                    //   size='large'
                    //   disabled={isEditingDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='lastName'
                  label='Last Name'
                  // label={getLabel('lastName')}
                  rules={[
                    { required: true, message: 'Enter  LastName' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 1,
                      message: 'Minimum length must be 1 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    // disabled={isEditingDisabled}
                    onChange={(e) => {
                      const lastName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const fatherName = form.getFieldValue('fatherName') || '';
                      const LongName = `${firstName} ${fatherName} ${lastName}`;
                      const initialsValue = `${(firstName[0] || '').trim()}${(
                        fatherName[0] || ''
                      ).trim()}${(lastName[0] || '').trim()}`;

                      form.setFieldsValue({ initials: initialsValue });
                      form.setFieldsValue({ LongName });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='LongName'
                  // label={getLabel('firstName')}
                  label=' Long Name'
                  rules={[
                    { required: true, message: 'Enter Long Name ' },

                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 50,
                      message: 'Maximum length allowed is 50 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    // value={longName}
                    disabled={isEditingDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='longName'
                  // label={getLabel('firstName')}
                  label='Aadhaar Long Name'
                  rules={[
                    // { required: true, message: 'Enter Long Name ' },

                    // {
                    //   pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                    //   message: 'Only Alphabetic Allowed',
                    // },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 50,
                      message: 'Maximum length allowed is 50 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    // value={longName}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='initials'
                  label='Initials'
                  // rules={[
                  //   {
                  //     pattern: '^[a-zA-Z]*$',
                  //     message: 'Only Alphabets Allowed',
                  //   },
                  //   {
                  //     min: 3,
                  //     message: 'Minimum length must be 3 characters',
                  //   },
                  //   {
                  //     max: 30,
                  //     message: 'Maximum length allowed is 30 characters',
                  //   },
                  // ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='gender'
                  label='Gender'
                  // label={getLabel('gender')}
                  rules={[{ required: true, message: 'Enter gender' }]}>
                  <Select
                    size='large'
                    disabled={isEditingDisabled || isFetchedFromAadhar}>
                    <Option value='male'>Male</Option>
                    <Option value='female'>Female</Option>
                    <Option value='other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='Dob'
                  label='Date of Birth'
                  rules={[
                    {
                      required: true,
                      message: 'Select Date Of Birth',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    // disabled={isEditingDisabled}
                    onChange={handleDateChange}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='dob'
                  label='Aadhaar Date of Birth'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Select Date Of Birth',
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={true}
                    onChange={handleDateChange}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='aadharNo'
                  label='Aadhaar Number'
                  onBlur={() => {
                    //
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Aadhaar',
                    },

                    // {
                    //   pattern: /^[0-9]{12}$/,
                    //   message: 'Enter Valid Aadhar No.',
                    // },
                    {
                      validator: async (_, value) => {
                        if (!id) {
                          if (!/^[0-9]{12}$/.test(value)) {
                            return Promise.reject('Enter Valid Aadhaar No.');
                            // throw new Error('Enter Valid Aadhar No.');
                            // return 0;
                          }
                          //
                          if (
                            value.length === 12 &&
                            !(isFetchedFromAadhar || isAadharChecked)
                          ) {
                            const isUnique = await checkAadharUniqueness(value);
                            //
                            if (!isUnique) {
                              return Promise.reject('Aadhaar already exist.');
                            }
                            setIsAadharChecked(true);
                          }
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}>
                  <Input
                    size='large'
                    // disabled={isEditingDisabled || isFetchedFromAadhar}
                    disabled={isFetchedFromAadhar}
                    onChange={adharvalue}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='healthIssue'
                  label='Health Issue'
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='extraInformation'
                  label='Extra Information'
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='age'
                  label='Age'
                  // label={getLabel('age')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Age',
                    },
                    { pattern: /^[0-9]*$/, message: 'Age must be numeric' },
                    { validator: validateage },
                  ]}>
                  <Input
                    size='large'
                    // disabled={isEditingDisabled || isFetchedFromAadhar}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='birthPlace' label='Birth Place'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='religion'
                  label='Religion'
                  rules={[{ required: true, message: 'Select Religion' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={ReligionCode1}>
                    <Option value='budhist'>BUDHIST</Option>
                    <Option value='christian'>CHRISTIAN</Option>
                    <Option value='hindu'>HINDU</Option>
                    <Option value='jain'>JAIN</Option>
                    <Option value='jew'>JEW</Option>
                    <Option value='muslim'>MUSLIM</Option>
                    <Option value='sikh'>SIKH</Option>
                    <Option value='zerostrian'>ZEROSTRIAN</Option>
                    <Option value='others'>OTHERS</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='caste'
                  label='Caste'
                  rules={[{ required: true, message: 'Select Caste' }]}>
                  {/* <Input size='large' disabled={isDisabled} /> */}
                  <Select size='large' disabled={isDisabled}>
                    <Option value='SC'>SC</Option>
                    <Option value='ST'>ST</Option>
                    <Option value='OBC'>OBC</Option>
                    <Option value='GENERAL'>General</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='domicile' label='Domicile(State)'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='basedinGujarath' label='Based In Gujarat?'>
                  <Select size='large' disabled={isDisabled}>
                    <Option value='Yes'>Yes</Option>
                    <Option value='No'>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pan'
                  label='PAN'
                  rules={[
                    {
                      pattern: '[A-Z]{5}[0-9]{4}[A-Z]{1}',
                      message: 'Enter Valid PAN',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              {/* While fetching Aadhar details the DOB is not reflecting in the 'Years In Gujarat(Since When)" field. @ Viresh Bangargi InProgress */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='yearsInGujarath'
                  label='Years In Gujarat(Since When)'>
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    disabled={isDisabled}
                    format='DD-MM-YYYY'
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='disable' label='Disabled'>
                  <Select
                    disabled={isDisabled}
                    size='large'
                    onChange={handleDisabledChange}>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='disabilityByBirth'
                  label='Disability By Birth or while Working'>
                  <Select size='large' disabled={isDisabled || DisabilityGray}>
                    <Option value='byBirth'>By Birth</Option>
                    <Option value='whileWorking'>While Working</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='disabilityType' label='Disability Type'>
                  <Input size='large' disabled={isDisabled || DisabilityGray} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='disabilityPercentage' label='Disability %'>
                  <Input size='large' disabled={isDisabled || DisabilityGray} />
                </Form.Item>
              </Col>
              <Col
                className='gutter-row'
                span={6}
                // hidden={initialValues?.status !== 'approved'}
                hidden={
                  initialValues?.status == 'pending' || !initialValues?.status
                }>
                <Form.Item
                  name='addressRevisedDate'
                  label='Address Revised Date'>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isDisabled || isFetchedFromAadhar}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Live Capture' name='livePhoto'>
                  <>
                    <Button
                      size='large'
                      style={{ width: '100%' }}
                      onClick={toggleCamera}
                      disabled={isDisabled}>
                      {imageSrc ? 'Retake Photo' : 'Capture Photo'}
                    </Button>
                  </>
                </Form.Item>
                <Image
                  src={imageSrc ? imageSrc : livePhoto}
                  // alt='Captured photo'
                  preview={true}
                />
                <Modal
                  open={visible}
                  onOk={handleOk}
                  onCancel={handleCancel1}
                  style={{ width: '150' }}>
                  {isCapturing && (
                    <Webcam
                      audio={false}
                      height={200}
                      ref={webcamRef}
                      screenshotFormat='image/jpeg'
                      width={200}
                    />
                  )}
                </Modal>
              </Col>
              {/*               
              <Col span={6}>
                <Form.Item label='Uplaod Photo' name='uploadPhoto' type='file'>
                  <Upload
                    listType='picture'
                    onPreview={handlePreview}
                    type='file'
                    accept='.pdf, image/*'
                    maxCount={1}
                    beforeUpload={(f, fl) => {
                      return false;
                    }}>
                    <Button icon={<UploadOutlined />}>Uplaod Photo</Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col> */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  label='Upload Photo'
                  name='uploadPhoto'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    // disabled={IsworkingBio}
                    size='large'
                    style={{ width: '100%' }}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}>
                      Upload Photo
                    </Button>
                  </Upload>
                </Form.Item>
                {(previewImage || uploadProfilePhoto) && (
                  <img
                    alt='example'
                    // style={{
                    //   width: '100%',
                    // }}
                    src={previewImage || uploadProfilePhoto}
                    preview={true}
                  />
                )}
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='aadharPhoto'
                  label='Aadhaar Photo'
                  initialValue={uidai.profilePic}>
                  <img
                    src={
                      (uidai?.profilePic &&
                        'data:image/png;base64,' + uidai.profilePic) ||
                      aadharPhoto
                    }
                    // alt='aadhar-img'
                    width='60px'
                    style={{ marginLeft: '20px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <br /> <Title level={5}>Family Details</Title>
            <Row gutter={6}>
              <Col className='gutter-row' span={6}>
                <Form.Item name='dobFather' label='DOB of Father'>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isDisabled}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='motherName'
                  label="Mother's Name"
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='dobMother' label='DOB OF Mother'>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isDisabled}
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='martialStatus'
                  label='Marital Status'
                  rules={[
                    { required: true, message: 'Select Marital Status' },
                  ]}>
                  <Select
                    size='large'
                    placeholder='Marital Status'
                    onChange={handleMaritalStatusChange}
                    disabled={isDisabled}>
                    <Option value='married'>Married</Option>
                    <Option value='unmarried'>Unmarried</Option>
                    <Option value='widow'>Widow</Option>
                    <Option value='divorced'>Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='dobOFMarriage' label='Date Of Marriage'>
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    disabled={!isMarried}
                    format='DD-MM-YYYY'
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='spousename'
                  label='Name of Spouse'
                  rules={
                    [
                      // {
                      //   pattern: '^[a-zA-Z]*$',
                      //   message: 'Only Alphabets Allowed',
                      // },
                      // {
                      //   min: 3,
                      //   message: 'Minimum length must be 3 characters',
                      // },
                      // {
                      //   max: 30,
                      //   message: 'Maximum length allowed is 30 characters',
                      // },
                    ]
                  }>
                  <Input size='large' disabled={!isMarried || isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='relation' label='Relation'>
                  {/* <Se size='large' disabled={ isDisabled} /> */}
                  <Select size='large' disabled={!isMarried}>
                    <Option value='husband'>Husband</Option>
                    <Option value='wife'>Wife</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='dobOFSpouse' label='DOB of Spouse'>
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    disabled={isDisabled || !isMarried}
                    format='DD-MM-YYYY'
                    inputReadOnly={true} // Disable manual input
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='qualificationOfSpouse'
                  label='Qualification of Spouse'
                  rules={
                    [
                      // {
                      //   pattern: '^[a-zA-Z]*$',
                      //   message: 'Only Alphabets Allowed',
                      // },
                      // {
                      //   min: 3,
                      //   message: 'Minimum length must be 3 characters',
                      // },
                      // {
                      //   max: 30,
                      //   message: 'Maximum length allowed is 30 characters',
                      // },
                    ]
                  }>
                  <Input size='large' disabled={isDisabled || !isMarried} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='occupationOfSpouse'
                  label='Occupation of Spouse'
                  rules={
                    [
                      // {
                      //   pattern: '^[a-zA-Z]*$',
                      //   message: 'Only Alphabets Allowed',
                      // },
                      // {
                      //   min: 3,
                      //   message: 'Minimum length must be 3 characters',
                      // },
                      // {
                      //   max: 30,
                      //   message: 'Maximum length allowed is 30 characters',
                      // },
                    ]
                  }>
                  <Input size='large' disabled={isDisabled || !isMarried} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='noOFDependents' label='No. Of Dependents'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='nomineeName' label='Nominee name'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeRelationship'
                  label='Nominee Relationship'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='nomineeContactnumber'
                  label='Nominee Contact number'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='noOFChildren' label='No. Of Children'>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='childrenNo' label='Child Details'>
                  <Select
                    size='large'
                    disabled={isDisabled1}
                    // disabled={isDisabled1 || isMarried1}
                    // disabled={!isMarried}
                    onChange={handleChildNoChange}>
                    <Option value='0'>Nil</Option>
                    <Option value='1'>1</Option>
                    <Option value='2'>2</Option>
                    <Option value='3'>3</Option>
                    <Option value='4'>4</Option>
                    <Option value='5'>5</Option>
                  </Select>
                </Form.Item>
              </Col>

              {renderRowsBasedOnChildNo()}
            </Row>
            <br />
            <br />
            <Title level={5}>Aadhaar Address</Title>
            <Row gutter={6}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address1'
                  label='Permanent Address Line 1'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Permanent Address Line 1',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum 3 Characters',
                    // },
                    // {
                    //   max: 300,
                    //   message: 'Maximum 600 characters allowed ',
                    // },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address1}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address2'
                  label='Permanent Address Line 2'
                  rules={
                    [
                      // {
                      //   required: true,
                      //   message: 'Enter Permanent Address Line 2',
                      // },
                      // {
                      //   min: 3,
                      //   message: 'Minimum 3 Characters',
                      // },
                      // {
                      //   max: 300,
                      //   message: 'Maximum 600 characters allowed ',
                      // },
                    ]
                  }>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address2}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address3'
                  label='Permanent Address Line 3'
                  rules={
                    [
                      // {
                      //   required: true,
                      //   message: 'Enter Permanent Address Line 3',
                      // },
                      // {
                      //   min: 3,
                      //   message: 'Minimum 3 Characters',
                      // },
                      // {
                      //   max: 300,
                      //   message: 'Maximum 600 characters allowed ',
                      // },
                    ]
                  }>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.address3}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='village'
                  label='Permanent Village/Mandal'
                  rules={[
                    { required: true, message: 'Enter Village/Mandal' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                  ]}>
                  <Input
                    size='large'
                    name='village'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.village}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6} id='state'>
                <Form.Item
                  name='state'
                  label='Permanent State'
                  rules={[{ required: true, message: 'Enter State' }]}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => {
                      setSelectedState(e);
                      setIsFieldChanged(true);
                    }}
                    size='large'
                    placeholder='Select State'
                    disabled={isDisabled || disableAddress.state}>
                    {states?.map((state, index) => (
                      <Select.Option key={`state-${index}`} value={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='district'>
                <Form.Item
                  name='district'
                  label='Permanent District'
                  rules={[{ required: true, message: 'Enter District' }]}>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onchange={handleFieldChange1}
                      size='large'
                      disabled={isDisabled || disableAddress.district}>
                      {citi[selectedState]?.map((city, index) => (
                        <Select.Option key={`city-${index}`} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pincode'
                  label='Permanent Pincode'
                  rules={[{ required: true, message: 'Enter Pincode' }]}>
                  <Input
                    size='large'
                    id='pincode'
                    disabled={isDisabled || disableAddress.pincode}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='mobile'
                  label=' Permanent Contact Number'
                  // label={getLabel('mobile')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Mobile Number (As Per Aadhaar)',
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                    // {
                    //   min: 10,
                    //   max: 10,
                    //   message: "Mobile number should be 10 digits",
                    // },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='postOffice'
                  label='Permanent Post Office'
                  rules={[
                    { required: true, message: 'Enter Post Office' },
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabets Allowed',
                    // },
                  ]}>
                  <Input
                    size='large'
                    name='postOffice'
                    onChange={onChange}
                    disabled={isDisabled || disableAddress.postOffice}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={24} id='homepostalcheck'>
                <Form.Item>
                  <input
                    type='checkbox'
                    id='same'
                    name='same'
                    size='large'
                    style={{ fontWeight: 'bold' }}
                    onClick={addressFunction}
                    disabled={isDisabled}
                  />
                  <label
                    htmlFor='same'
                    style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                    Same As Above
                  </label>
                </Form.Item>
                <Title level={5} style={{ marginTop: '30px' }}>
                  Local Address
                </Title>
              </Col>

              <br />

              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress1' label='Present Address Line 1'>
                  <Input
                    size='large'
                    id='paddress'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress2' label='Present Address Line 2'>
                  <Input
                    size='large'
                    id='paddress'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress3' label='Present Address Line 3'>
                  <Input
                    size='large'
                    id='paddress'
                    // value={presentAdress.address}
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='pvillage' label='Present Village/Mandal'>
                  <Input
                    size='large'
                    id='pvillage'
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6} id='pstate'>
                <Form.Item name='pstate' label='Present State'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size='large'
                    onChange={(e) => {
                      setSelectedstate(e);
                      setIsFieldChanged(true);
                    }}
                    placeholder='Select State'
                    disabled={isDisabled}>
                    {states?.map((state, index) => (
                      <Select.Option key={`state-${index}`} value={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='pdistrict'>
                <Form.Item name='pdistrict' label='Present District'>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onchange={handleFieldChange1}
                      size='large'
                      disabled={isDisabled}>
                      {citi[selectedstate]?.map((city, index) => (
                        <Select.Option key={`city-${index}`} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6}>
                <Form.Item name='ppincode' label='Present Pincode'>
                  <Input
                    size='large'
                    id='ppincode'
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='pmobile' label='Present Contact Number'>
                  <Input
                    size='large'
                    id='pmobile'
                    disabled={isDisabled}
                    onchange={handleFieldChange1}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ppostOffice'
                  label='Present Post Office'
                  rules={
                    [
                      // { required: true, message: 'Enter Post Office' },
                      // {
                      //   pattern: /^[A-Za-z\s]*$/,
                      //   message: 'Only Alphabets Allowed',
                      // },
                    ]
                  }>
                  <Input
                    size='large'
                    name='ppostOffice'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Collapse onChange={changeAccordian}>
          <Panel header='Professional Details' key='3'>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='unitName'
                  label='Unit Name'
                  rules={[{ required: true, message: 'Enter Unit Name' }]}>
                  <Select size='large' disabled={isDisabled}>
                    <Option
                      hidden={userConfig?.locationId == 'RNW'}
                      value='haziraWest'>
                      Hazira West
                    </Option>
                    <Option
                      hidden={userConfig?.locationId == 'RNW'}
                      value='haziraEast'>
                      Hazira East
                    </Option>
                    <Option
                      value='Ranoli'
                      hidden={userConfig?.locationId == 'HZW'}>
                      RANOLI
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='empCode'
                  label={
                    <Space>
                      Worker Code
                      <span></span>
                      <Tooltip
                        title='Generated by the Principal Employer'
                        placement='top'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  rules={[
                    // { required: true, message: 'Enter Worker Code' },
                    {
                      pattern: /^[a-zA-Z0-9]+$/,
                      message: 'Enter Alpha Numeric Only',
                    },
                  ]}>
                  <Input size='large' disabled={true} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='locationId'
                  label='Location Id'
                  rules={[{ required: true, message: 'Enter Location' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    //onChange={handleLocationChange}
                  >
                    {/* {location?.Response2?.slice(1).map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.id}
                      </Option>
                    ))} */}
                    <Option value={userConfig.locationId}>
                      {userConfig.locationId}
                    </Option>
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='employeeType'
                  label='Employee Type'
                  rules={[{ required: true, message: 'Select Employee Type' }]}>
                  <Select
                    size='large'
                    // onChange={handleStatusChange}
                    // defaultValue={'1'}
                    // placeholder='1'
                  >
                    <Option value='1'>1</Option>
                    <Option value='2'>2</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='experienceDetails'
                  label='Experience Details (YY-MM)'
                  // rules={[
                  //   {
                  //     // pattern: /^\d{2}-(0[1-9]|1[0-2])$/,
                  //     pattern: /^\d{2}-(00|0[1-9]|1[0-1])$/,

                  //     message:
                  //       'Please enter a valid year-month format (e.g., 13-10)',
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='emailId'
                  label='Email Id'
                  rules={[
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Invalid email' },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='esicexempt' valuePropName='checked'>
                  <Checkbox
                    onClick={Esictoggle}
                    size='large'
                    className='exempt'
                    value={!EsicDisabled}
                    // disabled={isDisabled}
                    disabled={isDisabled}>
                    ESIC Exempt
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='esicno'
                  label='ESI Number'
                  rules={[
                    {
                      required: EsicDisabled || isDisabled ? false : true,
                      message: 'Enter ESI Number',
                    },

                    // {
                    //   pattern:
                    //     '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$',
                    //   message: 'Invalid ESIC number',
                    // },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Ex: 41-00-123456-000-0001'
                    disabled={EsicDisabled}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='Uan' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    value={!UanDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled}>
                    UAN Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='uan'
                  label='UAN'
                  rules={[
                    {
                      required: UanDisabled || isDisabled ? false : true,
                      message: 'Enter UAN Number',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    // disabled={UanDisabled || isDisabled || initialValues?.Uan}
                    disabled={UanDisabled}
                    placeholder='Ex: 123456789012'
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='pf' valuePropName='checked'>
                  <Checkbox
                    onClick={Pftoggle}
                    size='large'
                    value={!PfDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled}>
                    PF Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pfAccountNo'
                  label='PF Account Number'
                  rules={[
                    {
                      required: PfDisabled || isDisabled ? false : true,
                      message: 'Enter PF Account Number',
                    },

                    // {
                    //   pattern: '[A-Z]{5}[0-9]{17}',
                    //   message: 'Invalid PF Account number ',
                    // },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Ex: BGBNG24718350000010018'
                    disabled={PfDisabled}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>

              {/* While workmen status in pending status, the regular IR can see the employment status. @ Viresh Bangargi 17July2024 */}

              {initialValues?.autoApproved &&
                initialValues?.status !== 'pending' && (
                  // (initialValues?.approvalStage == 2 &&
                  //   userConfig.role === 'ir')
                  <Col className='gutter-row' span={6}>
                    <Form.Item
                      name='statusToBeImposed'
                      label='Employment Status'
                      // rules={[{ required: true, message: "Enter Status" }]}
                    >
                      {/* <Select
                        size='large'
                        onChange={handleStatusChange}
                        placeholder='Active'>
                        <Option value='active'>Active</Option>
                        <Option value='retired'>Retired</Option>
                        <Option value='terminated'>Terminated</Option>
                        <Option
                          value='rehired'
                          disabled={
                            !(
                              initialValues?.statusToBeImposed ===
                                'terminated' ||
                              initialValues?.statusToBeImposed === 'completion'
                            )
                          }>
                          Rehired
                        </Option>
                        <Option value='suspended'>Suspended</Option>
                        <Option value='blocked'>Blocked</Option>
                        <Option value='completion'>Completion</Option>
                       
                        <Option value='death'>Death</Option>
                        <Option value='resigned'>Resigned</Option>
                        <Option value='blacklisted'>Blacklisted</Option>
                      </Select> */}
                      <Select
                        size='large'
                        // onChange={handleStatusChange}
                        onChange={(value) => {
                          // Reset dependent fields dynamically based on selected value
                          form.setFieldsValue({
                            statusFromDate: null,
                            statusToDate: null,
                            remark: null,
                            blockType: null,
                            statusTerminateNoticeDate: null,
                          });

                          // Set employment status for conditional rendering
                          setEmploymentStatus(value);
                        }}
                        placeholder='Active'>
                        <Option
                          value='active'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Active
                        </Option>
                        <Option
                          value='retired'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Retired
                        </Option>
                        <Option
                          value='terminated'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Terminated
                        </Option>
                        <Option
                          value='rehired'
                          disabled={
                            !(
                              initialValues?.statusToBeImposed ===
                                'terminated' ||
                              initialValues?.statusToBeImposed === 'completion'
                            )
                          }>
                          Rehired
                        </Option>
                        <Option
                          value='suspended'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Suspended
                        </Option>
                        <Option
                          value='blocked'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Blocked
                        </Option>
                        <Option
                          value='completion'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Completion
                        </Option>
                        {/* <Option value='deHibernate'>Dehibernate</Option> */}

                        {/* <Option value='absconding'>Absconding</Option> */}
                        <Option
                          value='death'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Death
                        </Option>
                        <Option
                          value='resigned'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Resigned
                        </Option>
                        <Option
                          value='blacklisted'
                          disabled={
                            initialValues?.statusToBeImposed === 'completion'
                          }>
                          Blacklisted
                        </Option>
                      </Select>
                    </Form.Item>
                    {employmentStatus === 'active' ? (
                      <div>
                        <Form.Item name='statusFromDate' label='Active Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'retired' ? (
                      <div>
                        <Form.Item name='statusFromDate' label='Retired Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'terminated' ? (
                      <div>
                        <Form.Item
                          name='statusTerminateNoticeDate'
                          label='Terminated Notice Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a terminated notice date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusFromDate'
                          label='Terminated Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'rehired' ? (
                      <div>
                        <Form.Item name='statusFromDate' label='Rehired Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'suspended' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Suspended From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusToDate'
                          label='Suspended To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a to date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Suspended Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blocked' ? (
                      <div>
                        <Form.Item
                          name='blockType'
                          label='Block Type'
                          rules={[
                            {
                              required: true,
                              message: 'Please select Dropdown',
                            },
                          ]}>
                          <Select>
                            <Option value='permanent'>Permanent</Option>
                            <Option value='temporary'>Temporary</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name='statusFromDate'
                          label='Blocked From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        {blockType == 'temporary' && (
                          <Form.Item
                            name='statusToDate'
                            label='Blocked To Date'
                            rules={[
                              {
                                required: true,
                                message: 'Please select a to date',
                              },
                            ]}>
                            <DatePicker
                              style={{ width: '100%' }}
                              format='DD-MM-YYYY'
                            />
                          </Form.Item>
                        )}
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'completion' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Completion From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        {/* <Form.Item
                        name='statusToDate'
                        label='Completion To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a to date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item> */}
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'deHibernate' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Dehibernate From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        {/* <Form.Item
                        name='statusToDate'
                        label='Completion To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a to date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item> */}
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'absconding' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Absconding From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        {/* <Form.Item
                        name='statusToDate'
                        label='Blocked To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a to date',
                          },
                        ]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item> */}
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Absconding Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'death' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Death Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                            //disabledDate={(d) => !d || d.isAfter(new Date())}
                            disabledDate={(d) =>
                              !d ||
                              d.isSame(new Date(), 'day') ||
                              d.isAfter(new Date())
                            }
                          />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'resigned' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Resigned From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blacklisted' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Blacklisted Date'>
                          <DatePicker size='large' style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                  </Col>
                )}
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Personaldetails;
