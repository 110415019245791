//Mallikarjun Code
import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Table,
  Input,
  Space,
  Typography,
  Popover,
  Button,
  Row,
  Tooltip,
  Divider,
} from 'antd';
import dayjs from 'dayjs';

import {
  EyeFilled,
  SearchOutlined,
  InteractionOutlined,
  IdcardOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from '../services/axiosInstance';
import Highlighter from 'react-highlight-words';
import { downloadEmployeeList
} from '../services/auth';

import Personneldetails from '../components/EmployeeOnboard/Personneldetails';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
//import { setLoader } from '../actions';
import {
  getEmployeeList,
  searchEmployee,
  setTempGatePassValidity,
} from '../services/auth';
import ReplaceEmployee from '../components/Contractor/replaceEmployee';
import ColumnGroup from 'antd/es/table/ColumnGroup';

import useConfig from 'antd/es/config-provider/hooks/useConfig';
import {
  compareDateWithoutTime,
  getDifferenceInDays,
} from '../utils/helperFunction';

const Approver1 = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [Employee, setEmployee] = useState([]);
  
  // console.log('Employee', Employee);
  const [loading, setLoading] = useState(true);
  // Search icon
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const searchInput = useRef(null);
  const { user, userConfig } = useAuth();
  const [replaceOpen, setReplaceOpen] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = useState({});
  const [employementType, setemployementType] = useState('Contractor');
  const [Supplier, setSupplier] = React.useState([]);
  const [selectedContractorCode, setSelectedContractorCode] = useState(null);
  const [selectedContractorName, setSelectedContractorName] = useState(null);
  const [loader, setLoader] = useState(null);

  const location = useConfig?.locationId;
  //const role = userConfig?.role;
  useEffect(() => {
    getEmpList(pageSize, currentPage, employementType);
  }, [pageSize, currentPage, employementType]);

  const getEmpList = async (pageSize, currentPage, employmentType) => {
    setLoading(true);
    try {
      const response = await getEmployeeList(
        pageSize,
        currentPage,
        employmentType
      );
      setLoading(false);
      setTotalCount(response?.data?.count);
      setEmployee(
        response.data.passList.map((row) => ({
          key: row.id,
          id: row._id,
          _id: row?._id,
          contractorName: row.jobDetails?.contractorName,
          contractorId: row.jobDetails?.contractorCode,
          firstName:
            row.personalDetails?.firstName +
            ' ' +
            row.personalDetails?.lastName,
          mobile: row?.personalDetails?.mobile,
          aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
          createdBy: row?.createdBy,
          workMenCode: row?.personalDetails?.empCode,
          // status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
          status: row.status
            ? row?.status[0]?.toUpperCase() + row?.status?.slice(1)
            : '',
          locationId: row?.locationId,
          Edited: row?.Edited,
          employmentType: row?.employmentType,
          epnNumber: row?.personalDetails?.epnNumber,
          approvalStage: row?.approvalStage,
          medicalException: row?.exceptions?.Medical?.includes(
            row?.jobDetails?.designation
          ),
          safetyException: row?.exceptions?.Safety?.includes(
            row?.jobDetails?.designation
          ),
          medicalNeeded: row?.medicalNeeded,
          pmeDonedate: row?.pmeDonedate,
          pmeValidityDate: row?.pmeValidityDate,
          safetyNeeded: row?.safetyNeeded,
          safetyTrainingFromDate: row?.safetyTrainingFromDate,
          safetyCerticationEndDate: row?.safetyCerticationEndDate,
          policeVerificationDocUploaded: row?.policeVerificationDocUploaded,
          gatePassStage: row?.gatePassStage,
          tempGatePassValidity: row?.tempGatePassValidity,
          tempGatePassGenerationDate: row?.tempGatePassGenerationDate,
          contractorName: row?.jobDetails?.contractorName,
          workerType: row?.jobDetails?.designation,
        }))
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // const SearchEmployeeData = async (search) => {
  //   try {
  //     const response = await searchEmployee(pageSize, currentPage, search);
  //     console.log("Search118 ", response.data.passList[0].employementType)
  //     setLoading(false);
  //     if(response?.data?.passList?.employementType === "Contractor"){
  //       console.log("inside Con")
  //     setTotalCount(response?.data?.count);

  //     setEmployee(
  //       response.data.passList.map((row) => ({
  //         key: row.id,
  //         id: row._id,
  //         firstName:
  //           row.personalDetails?.firstName +
  //           ' ' +
  //           row?.personalDetails?.lastName,
  //         mobile: row?.personalDetails?.mobile,
  //         aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
  //         createdBy: row?.personalDetails?.empCode,
  //         status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
  //         locationId: row?.locationId,
  //         epnNumber: row?.personalDetails?.epnNumber,
  //         contractorName: row?.jobDetails?.contractorName,
  //         workMenCode: row?.personalDetails?.empCode,
  //         workerType: row?.jobDetails?.designation,
  //       }))
  //     )}
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // ===================================


  // const SearchEmployeeData = async (search) => {
  //   try {
  //     const response = await searchEmployee(pageSize, currentPage, search);
  //     console.log('Search118 ', response.data.passList);
  
  //     setLoading(false);
  //     let contractorData = [];
  
  //     if (userConfig?.level == 1) {
  //       contractorData = response.data.passList.filter((row) => {
  //         return (
  //           row?.employementType && row?.employementType.includes('Contractor')
  //         );
  //       });
  //     } else if (userConfig?.level == 2) {
  //       contractorData = response.data.passList.filter((row) => {
  //         return (
  //           row?.employementType &&
  //           row?.employementType.includes('Contractor') &&
  //           row?.approvalStage == 1
  //         );
  //       });
  //       console.log('contractorData level 2 ', contractorData);
  //     } else if (userConfig?.level == 3) {
  //       contractorData = response.data.passList.filter((row) => {
  //         return (
  //           row?.employementType &&
  //           row?.employementType.includes('Contractor') &&
  //           row?.approvalStage == 2 &&
  //           !row?.exceptions?.medical?.includes(row?.jobDetails?.designation)
  //         );
  //       });
  //       console.log('contractorData level 3 ', contractorData);
  //     }
  
  //     if (contractorData.length > 0) {
  //       setTotalCount(response?.data?.count);
  
  //       setEmployee(
  //         contractorData.map((row) => ({
  //           key: row.id,
  //           id: row._id,
  //           firstName:
  //             row.personalDetails?.firstName +
  //             ' ' +
  //             row?.personalDetails?.lastName,
  //           mobile: row?.personalDetails?.mobile,
  //           aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
  //           createdBy: row?.personalDetails?.empCode,
  //           status: row?.status[0]?.toUpperCase() + row?.status?.slice(1),
  //           locationId: row?.locationId,
  //           epnNumber: row?.personalDetails?.epnNumber,
  //           contractorName: row?.jobDetails?.contractorName,
  //           workMenCode: row?.personalDetails?.empCode,
  //           workerType: row?.jobDetails?.designation,
  //         }))
  //       );
  //     } else {
  //       setEmployee([]); // Clear data if no contractors are found
  //       setTotalCount(0);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error fetching employee data:', error);
  //   }
  // };

   const structureEmployee = (row, contractorName) => {
      const designation = row?.jobDetails?.designation;
  console.log("row inside structureEmployee ", row)
      return {
        key: row?._id,
        id: row?._id,
        _id: row?._id,
        firstName:
          row?.personalDetails?.firstName + ' ' + row.personalDetails?.lastName,
        aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
  
        village: row?.personalDetails?.village,
        address: row?.personalDetails?.address,
        state: row?.personalDetails?.state,
        district: row?.personalDetails?.district,
        pincode: row?.personalDetails?.pincode,
        mobile: row?.personalDetails?.mobile,
        fatherName: row?.personalDetails?.fatherName,
        gender: row?.personalDetails?.gender,
        Uan: row?.personalDetails?.uan,
        pfAccountNo: row?.personalDetails?.pfAccountNo,
        esicno: row?.personalDetails?.esicno,
        // empCode: row.personalDetails.empCode,
        //Job Details
        departmentCode: row?.jobDetails?.departmentCode,
        sectionCode: row?.jobDetails?.sectionCode,
        designation: row?.jobDetails?.designation,
        bloodGroup: row?.jobDetails?.bloodGroup,
        AccountNo: row?.jobDetails?.AccountNo,
        bankname: row?.jobDetails?.bankname,
        name: row?.jobDetails?.name,
        ifsc: row?.jobDetails?.ifsc,
        employeeType: row?.jobDetails?.employeeType,
        userName: row?.jobDetails?.userName,
        academicQualification: row?.jobDetails?.academicQualification,
        jobCode: row?.jobDetails?.jobCode,
        labourType: row?.jobDetails?.labourType,
        itemServiceCode: row?.jobDetails?.itemServiceCode,
        //Wage Details
        basic: row?.wageDetails?.basic,
        da: row?.wageDetails?.da,
        userName: row?.wageDetails?.userName,
        allowance1: row?.wageDetails?.allowance1,
        allowance2: row?.wageDetails?.allowance2,
        allowance3: row?.wageDetails?.allowance3,
        conveyance: row?.wageDetails?.conveyance,
        hra: row?.wageDetails?.hra,
        ot: row?.wageDetails?.ot,
        status: row.status
          ? row?.status[0]?.toUpperCase() + row?.status?.slice(1)
          : '',
        createdBy: row?.createdBy,
        locationId: row?.locationId,
        workMenCode: row?.personalDetails?.empCode,
        epnNumber: row?.personalDetails?.epnNumber.toString() || '',
  
        approvalStage: row?.approvalStage,
  
        erdate: dayjs(row?.erdate).format('DD-MM-YYYY'), // Format erdate
        medicaldate: dayjs(row?.medicaldate).format('DD-MM-YYYY'), // Format medicaldate
        safetydate: dayjs(row?.safetydate).format('DD-MM-YYYY'), // Format safetydate
        supervisordate: dayjs(row?.supervisordate).format('DD-MM-YYYY'), // Format supervisordate
        Edited: row?.Edited,
        medicalException: row?.exceptions?.Medical?.includes(designation),
        safetyException: row?.exceptions?.Safety?.includes(designation),
        medicalNeeded: row?.medicalNeeded,
        pmeDonedate: row?.pmeDonedate,
        pmeValidityDate: row?.pmeValidityDate,
        safetyNeeded: row?.safetyNeeded,
        safetyTrainingFromDate: row?.safetyTrainingFromDate,
        safetyCerticationEndDate: row?.safetyCerticationEndDate,
        policeVerificationDocUploaded: row?.policeVerificationDocUploaded,
        gatePassStage: row?.gatePassStage,
        contractorName: row?.jobDetails?.contractorName,
        contractorId: row?.jobDetails?.contractorCode,
        workerType: row?.jobDetails?.designation,
        reason:row?.reason,
        tempGatePassValidity: row?.tempGatePassValidity?.split('T')[0],
        tempGatePassGenerationDate: row?.tempGatePassGenerationDate?.split('T')[0]
      };
    };

  const SearchEmployeeData = async (search) => {
      try {
        const res = await searchEmployee(pageSize, currentPage, search);
        console.log("res ", res)
        setLoading(false);
        setTotalCount(res?.data?.count);
        setEmployee(res.data.passList.map((row) => structureEmployee(row)));
      } catch (error) {
        setLoading(false);
      }
    };

  // const SearchEmployeeData = async (search) => {
  //   try {
  //     const res = await searchEmployee(pageSize, currentPage, search);
  //     console.log("res ", res);
      
  //     setLoading(false);
  
  //     let contractorData = [];
  
  //     // Check userConfig level and filter contractor data accordingly
  //     if (userConfig?.level === 1) {
  //       contractorData = res.data.passList.filter((row) => 
  //         row?.employementType && row?.employementType.includes('Contractor')
  //       );
  //     } else if (userConfig?.level === 2) {
  //       contractorData = res.data.passList.filter((row) => 
  //         row?.employementType && 
  //         row?.employementType.includes('Contractor') && 
  //         row?.approvalStage === 1
  //       );
  //       console.log('contractorData level 2', contractorData);
  //     } else if (userConfig?.level === 3) {
  //       contractorData = res.data.passList.filter((row) => 
  //         row?.employementType && 
  //         row?.employementType.includes('Contractor') && 
  //         row?.approvalStage === 2 &&
  //         !row?.exceptions?.medical?.includes(row?.jobDetails?.designation)
  //       );
  //       console.log('contractorData level 3', contractorData);
  //     }
  
  //     // Update total count and set employee data
  //     if (contractorData.length > 0) {
  //       setTotalCount(res?.data?.count);
  //     }
      
  //     setEmployee(contractorData.map((row) => structureEmployee(row)));
  //   } catch (error) {
  //     console.error("Error fetching employee data:", error);
  //     setLoading(false);
  //   }
  // };
  
  
  const handleReset = (clearFilters, employmentType) => {
    clearFilters();
    setSearchText('');
    getEmpList(pageSize, currentPage, employmentType);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Enter atleast 3 character`}
          value={selectedKeys[0]}
          onChange={(e) => {
            if (e.target.value.length >= 3) {
              // setSearchText(selectedKeys[0])
              SearchEmployeeData(e.target.value);
            } else if (
              e.target.value.length === 0 ||
              e.target.value.length === 2
            ) {
              getEmpList(pageSize, currentPage, employementType);
            }
            // setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        {/* <Space>
          <a
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginRight: 8 }}>
            Search
          </a>
          <a onClick={() => {
            setSelectedKeys([]);
            handleReset(clearFilters)
          }}>Reset</a>
        </Space> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const replaceContractor = (record) => {
    console.log('record757', record);
    setSelectedContractor(record.id);
    setSelectedContractorCode(record.contractorId);
    setSelectedContractorName(record.contractorName);
    setReplaceOpen(true);
  };

  // console.log('Employee764 is', Employee[0].contractorName);
  // console.log('Employee765 is', Employee[0].contractorCode);

  const generateGatePass = async (record) => {
    setLoader('generate');
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    const response = await setTempGatePassValidity({
      id: record._id,
      date: currentDate.toISOString(),
      generationDate: new Date().toISOString(),
    });
    getEmpList(pageSize, currentPage, employementType);
    setLoader(null);
  };

  const extendGatePassValidity = async (record) => {
    setLoader('extend');
    const currentDate = new Date(record.tempGatePassValidity);
    currentDate.setDate(currentDate.getDate() + 7);
    const response = await setTempGatePassValidity({
      id: record._id,
      date: currentDate.toISOString(),
    });
    getEmpList(pageSize, currentPage, employementType);
    setLoader(null);
  };

  const downloadGatePass = async (record) => {
    setLoader('download');
    await axios
      .post('/pass/pdf', { id: record._id }, { responseType: 'arraybuffer' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Token Pass.pdf');
        document.body.appendChild(link);
        link.click();
      });
    setLoader(null);
  };
  console.log('loader', loader);
  const columns = useMemo(
    () => [
      {
        title: 'Worker Name',
        dataIndex: 'firstName',
        key: 'firstName',
        ...getColumnSearchProps('firstName'),
        fixed: 'left', // Freeze this column

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
        ellipsis: true,
        width: 150,
      },

      {
        title: 'Contractor Name',
        dataIndex: 'contractorName',
        key: 'aadharNo',
        fixed: 'left', // Freeze this column

        width: 150,
      },
      {
        title: 'Workman Code ',
        dataIndex: 'workMenCode',
        key: 'workMenCode',
        ...getColumnSearchProps('workMenCode'),

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'workMenCode' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'left',
        width: 150,
      },
      {
        title: 'EPN Number',
        dataIndex: 'epnNumber',
        key: 'epnNumber',
        ...getColumnSearchProps('epnNumber'),

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'epnNumber' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'left',
        width: 150,
      },
      {
        title: 'Aadhaar No',
        dataIndex: 'aadharNo',
        key: 'aadharNo',
        width: 150,
      },
      {
        title: 'Worker Type',
        dataIndex: 'workerType',
        key: 'workerType',

        width: 150,
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ...getColumnSearchProps('status'),
        render: (status) => (status === 'Approved' ? 'Active' : status),
        filterMode: 'tree',
        filterSearch: true,
        width: '17%',
        sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
        ellipsis: true,
        width: 150,
      },
      {
        title: 'Transaction Status',
        dataIndex: 'approvalStage',
        key: 'approvalStage',
        render: (stage, record) => {
          const isExpired = (date) =>
            date && new Date(date).getTime() <= new Date().getTime();

          // Case: Stage 0
          if (stage === 0) {
            return (
              <>
                IR <br />
                {(!record.safetyCerticationEndDate ||
                  isExpired(record.safetyCerticationEndDate)) &&
                  !record.safetyException && (
                    <>
                      Safety
                      <br />
                    </>
                  )}
                {(!record.pmeValidityDate ||
                  isExpired(record.pmeValidityDate)) &&
                  !record.medicalException &&
                  'Medical'}
              </>
            );
          }

          // Case: Stage 1
          if (stage === 1) {
            return (
              <>
                {(!record.safetyCerticationEndDate ||
                  isExpired(record.safetyCerticationEndDate)) &&
                  !record.safetyException &&
                  'Safety'}
                <br />
                {(!record.pmeValidityDate ||
                  isExpired(record.pmeValidityDate)) &&
                  !record.medicalException &&
                  'Medical'}
              </>
            );
          }

          // Case: Stage 2
          if (stage === 2) {
            if (!record.medicalException && !record.pmeValidityDate) {
              return 'Medical';
            }
            if (record.pmeValidityDate && isExpired(record.pmeValidityDate)) {
              return !record.medicalException && 'Medical';
            }
          }

          // Other Scenarios
          if (record.medicalNeeded || record.safetyNeeded) {
            return (
              <>
                {(record.safetyNeeded === 1 || record.medicalNeeded === 1) && (
                  <>
                    IR <br />
                  </>
                )}
                {[1, 2].includes(record.safetyNeeded) && (
                  <>
                    Safety <br />
                  </>
                )}
                {[1, 2].includes(record.medicalNeeded) && 'Medical'}
              </>
            );
          } else if (
            record.gatePassStage !== undefined &&
            record.gatePassStage < 2
          ) {
            if (record.gatePassStage === 0) {
              return 'Temporary GP to be generated';
            }
            if (record.policeVerificationDocUploaded) {
              return 'Permanent GP approval pending';
            }
            if (record.gatePassStage === 1) {
              return 'Temporary GP generated';
            }
          } else if (record.Edited === 'Yes') {
            return 'Profile updated. Approval pending with IR.';
          } else {
            return 'Approved & Synched With WFD';
          }
        },
        width: 150,
      },
      {
        title: 'Location Id',
        dataIndex: 'locationId',
        key: 'locationId',
        width: 150,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        width: 150,

        render: (id, record) => {
          if (userConfig.level === 2) {
            return (
              <Typography>
                <Link to={`/home/approver/employee/${id}/safety`}>
                  <EyeFilled />
                </Link>
              </Typography>
            );
          } else if (userConfig.level === 3) {
            return (
              <Typography>
                <Link to={`/home/approver/employee/${id}/medical`}>
                  <EyeFilled />
                </Link>
              </Typography>
            );
          } else {
            return (
              <Space>
                <Typography>
                  <Link to={`/home/approver/employee/${id}`}>
                    <EyeFilled />
                  </Link>
                </Typography>
                <Typography.Link
                  onClick={() => replaceContractor(record)}
                  hidden={record.status !== 'Inactive'}
                  // hidden={
                  //   record.status !== 'Approved' && record.status !== 'Inactive'
                  // }
                >
                  <InteractionOutlined />
                </Typography.Link>
                {[1, 2].includes(record?.approvalStage) &&
                  record?.status !== 'Rejected' && (
                    <Button
                      type='link'
                      title='Download data sheet'
                      icon={<DownloadOutlined />}
                      loading={loader == 'download'}
                      onClick={(e) => downloadGatePass(record)}></Button>
                  )}
                {
                  ((
                    record?.workerType &&
                    [
                      'Rigger',
                      'Crane Operator',
                      'Multiskill',
                      'M/c Operator',
                    ].includes(String(record.workerType)) &&
                    String(record.approvalStage) === '3' &&
                    record?.Edited !== 'Yes' &&
                    record?.medicalNeeded === 2
                  ) ||
                  (
                    ["3"].includes(record?.approvalStage) &&
                    (
                      record?.safetyNeeded === 2 || 
                      record?.medicalNeeded === 2 || 
                      (record?.safetyNeeded === 2 && record?.medicalNeeded === 2)
                    ) &&
                    record?.gatePassStage === 2
                  )) && record?.status !== 'Rejected' && (
                    <Button
                      type='link'
                      title='Download data sheet'
                      icon={<DownloadOutlined />}
                      loading={loader === 'download'}
                      onClick={(e) => downloadGatePass(record)}
                    ></Button>
                  )
                }
                {record.status == 'Approved' && record.Edited != 'Yes' && (
                  <Popover
                    placement='topRight'
                    title={'Gate pass action(s)'}
                    content={
                      <>
                        <Row style={{ marginBottom: '5px' }}>
                          {record.gatePassStage == 0 && (
                            <Button
                              onClick={() => generateGatePass(record)}
                              size='small'
                              loading={loader == 'generate'}
                              type='primary'>
                              Generate
                            </Button>
                          )}
                          {record?.gatePassStage > 0 && (
                            <Button
                              onClick={(e) => downloadGatePass(record)}
                              size='small'
                              loading={loader == 'download'}
                              type='primary'>
                              Download
                            </Button>
                          )}
                        </Row>

                        {record?.gatePassStage == 1 && (
                          <>
                            <Row style={{ marginBottom: '5px' }}>
                              Temporary GP validity:{' '}
                              {record?.tempGatePassValidity?.split('T')[0]}
                            </Row>
                            <Row>
                              {getDifferenceInDays(
                                record?.tempGatePassGenerationDate,
                                record?.tempGatePassValidity
                              ) <= 7 && (
                                <Button
                                  onClick={() => extendGatePassValidity(record)}
                                  size='small'
                                  loading={loader == 'extend'}
                                  type='primary'>
                                  Extend
                                </Button>
                              )}
                            </Row>
                          </>
                        )}
                      </>
                    }>
                    <Typography.Link title={`Gate pass actions`}>
                      <IdcardOutlined />
                    </Typography.Link>
                  </Popover>
                )}
              </Space>
            );
          }
        },
      },
      // {
      //   title: 'Summary',
      //   dataIndex: '_id',
      //   render: (id) => (
      //     <Typography>
      //       <Link to={`/home/supervisor/employee/summary/${id}`}>
      //         <EyeFilled />
      //       </Link>
      //     </Typography>
      //   ),
      // },
    ],
    [sortedInfo, searchText, searchedColumn, userConfig, loader]
  );
  const onChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  // const handleReplaceCancel = (employmentType) => {
  //   setReplaceOpen(false);
  //   getEmpList(pageSize, currentPage, employmentType);
  // };
  const { role } = userConfig;

  //let filteredData = Array.isArray(Employee) ? Employee : [];
  //console.log('Employee State:', Employee);

  //console.log('Filtered Data:', filteredData);
  //console.log('Total Count of Filtered Data:', filteredData.length);
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = currentPage * pageSize;
  // const paginatedData = filteredData.slice(startIndex, endIndex);
  let filteredData = Array.isArray(Employee) ? Employee : [];
  console.log('Employee576 ', Employee);
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

  const downloadExcel = () => {
    const url = `/pass/downloademployeelist`;
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadEmployeeList(data, responseType)
      .then((response) => {
        console.log("response 825 ", response)
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='primary'
          onClick={downloadExcel}
          hidden={userConfig?.level == 2 || userConfig?.level == 3}>
          <Tooltip title='Download Employee List'>
            <DownloadOutlined />
          </Tooltip>
        </Button>
      </div>

      <Divider />
      <h1>
        <b>Workmen List</b>
      </h1>
      <Table
        columns={columns}
        dataSource={Employee}
        loading={loading}
        onChange={onChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
          // showSizeChanger: true,
          // pageSizeOptions: ['10', '20', '50', '100'],
          onChange: async (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            getEmpList(pageSize, page, employementType);
            // Fetch data for the new page and pageSize
            // EmployeeList(pageSize, page);
          },
        }}
        scroll={{ y: 400 }} // Set vertical scroll limit
        style={{ width: '100%' }}
      />
      <ReplaceEmployee
        open={replaceOpen}
        onCancel={() => setReplaceOpen(false)}
        initialValues={selectedContractor}
        contractorsList={Employee}
        contractorName={selectedContractorName}
        // contractorName={Employee?.contractorName}
        contractorCode={selectedContractorCode}
        // contractorCode={Employee?.contractorCode}
      />
    </div>
  );
};

export default Approver1;
