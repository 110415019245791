import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Tabs, Row, Col } from 'antd';
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import '../WorkOrderDetails.css';
import { fetchWorkOrderDetailsData } from '../services/actions/api/workOrderDetails';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import JobOrderDetails from './JobOrderDetails'
import LaborRequisitionList from './LaborRequisitionList'
import { LeftOutlined } from '@ant-design/icons';

dayjs.extend(customParseFormat);

const { TabPane } = Tabs;

const WorkOrderDetail = ({navigateToTab}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { workOrderNumber } = useParams();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState('1');

  const [formValues, setFormValues] = useState(null);
  const [formData, setFormData] = useState({
    workOrderDetailsData: []
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (workOrderNumber) {
          const response = await fetchWorkOrderDetailsData(workOrderNumber);
          
  
          // Check if validFrom and validTo are valid dates before formatting
          const formattedResponse = {
            ...response,
            validFrom: response.validFrom ? dayjs(response.validFrom, 'YYYY-MM-DD') : null,
            validTo: response.validTo ? dayjs(response.validTo, 'YYYY-MM-DD') : null
          };

           // Set form fields value with formatted dates
          setFormValues(formattedResponse);
          form.setFieldsValue(formattedResponse);
  
          const payload = {
            workOrderDetailsData: [{
              ...formattedResponse,
              serviceDetails: response.serviceDetails
            }]
          };
          setFormData(payload);
        }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };
  
    fetchData();
  }, [workOrderNumber]);

  // Function to get the query parameter from the URL
  const getTabFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') || '1';
  };

  
  useEffect(() => {
    const tab = getTabFromQuery();
    setActiveTabKey(tab);
  }, [location.search]);


  const onFinish = (values) => {
    console.log('Form values: ', values);
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
    const url = new URL(window.location);
    url.searchParams.set('tab', key);
    window.history.pushState(null, '', url.toString());
  };

  return (
    <div>
      <Button type="link" style={{ color: 'black' }} onClick={() => navigate('/home/department/sectionhead/workorderlist')}>
          <LeftOutlined />
      </Button> 
      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Work Order Details</span>
      {/* <h2>Work Order Details</h2> */}
      <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={onTabChange}>
        <TabPane tab="Details" key="1">
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <div className="section">
              <div className="section-header">Work Order Identifiers</div>
              <div className="section-content">
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="Work Order Number" name="workOrderNumber" className="custom-form-item">
                      <Input disabled style={{ color: "gray" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Section Code" name="sectionCode" className="custom-form-item">
                      <Input disabled style={{ color: "gray" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Vendor Code" name="vendorCode" className="custom-form-item">
                      <Input disabled style={{ color: "gray" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="GL Code" name="gLCode" className="custom-form-item">
                      <Input disabled style={{ color: "gray" }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Work Order Type" name="workOrderType" className="custom-form-item">
                      <Input disabled style={{ color: "gray" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="section">
              <div className="section-header">Key Personnel</div>
              <div className="section-content">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="Work Order Owner" name="workOrderOwner" className="custom-form-item">
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Contractor" name="contractorName" className="custom-form-item">
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Contract supervisor"
                    name="contractorSupervisor"
                    className="custom-form-item"
                  >
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
              </Row></div>
            </div>

            <div className="section">
              <div className="section-header">Organization Specification</div>
              <div className="section-content">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="Department" name="departmentName" className="custom-form-item">
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Unit" name="unitName" className="custom-form-item">
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Cost center" name="costCentre" className="custom-form-item">
                    <Input disabled style={{ color: "gray" }} />
                  </Form.Item>
                </Col>
              </Row>
              </div>
            </div>

            <div className="section">
              <div className="section-header">Date Range</div>
              <div className="section-content">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="WO Validity From" name="validFrom" className="custom-form-item">
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{ width: "100%", color: "black" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="WO Validity to" name="validTo" className="custom-form-item">
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{ width: "100%", color: "black" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              </div>
            </div>

            <div className="section">
              <div className="section-header">Job description</div>
              <div className="section-content">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Job description" name="jobDescription" className="custom-form-item">
                    <Input.TextArea rows={4} disabled style={{ color: "gray" }}/>
                  </Form.Item>
                </Col>
              </Row>
              </div>
            </div>
          </Form>
        </TabPane>
        <TabPane tab="Job Order" key="2">
          <JobOrderDetails />
        </TabPane>
        <TabPane tab="Labor Requisition" key="3">
          <LaborRequisitionList workOrderDetail = {formData}/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default WorkOrderDetail;
