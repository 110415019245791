import React, { useEffect, useState } from 'react'
import blueCard from '../../../../assets/blueCard.svg'
import yellowCard from '../../../../assets/yellowCard.svg'
import orangeCard from '../../../../assets/orangeCard.svg'
import purpuleCard from '../../../../assets/purpuleCard.svg'
import { ReactComponent as ActiveEmployee } from '../../../../assets/ActiveEmployee.svg'
import { ReactComponent as OnboardThisMonth } from '../../../../assets/OnboardThisMonth.svg'
import { ReactComponent as OffBoardThisMonth } from '../../../../assets/OffBoardThisMonth.svg'
import { ReactComponent as PendingOnboarding } from '../../../../assets/PendingOnboarding.svg'
import { Row, Col, Card, Typography } from 'antd'
import { getPassReports } from '../../../../vyn/services/auth'



const OnboardingCharts = () => {

  const [chartsData, setChartData] = useState([]);
  const [passData, setPassData] = useState([]);
  const [data, setData] = useState({
    active: 0,
    pending: 0,
    totalEmployees: 0,
    dojCurrentMonthCount: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPassReports();
        if (response) {
          setData(response);
        }
      } catch (error) {
        console.error("Error fetching pass reports:", error);
      }
    };

    fetchData();
  }, []);
  const cardData = [
    {
      title: "Active employees count",
      value: `${data?.data?.active ? data?.data?.active : '0'}`,
      color: "#6C63FF",
      icon: <ActiveEmployee />,
      backgroundImage: `url(${blueCard})`
    },
    {
      title: "Onboardings done this month",
      value: `${data?.data?.dojCurrentMonthCount ? data?.data?.dojCurrentMonthCount : '0'}`,
      color: "#EAAA00",
      icon: <OnboardThisMonth />,
      backgroundImage: `url(${yellowCard})`
    },
    {
      title: "Offboardings done this month",
      value: `${data?.data?.offboardingCurrentMonthCount ? data?.data?.offboardingCurrentMonthCount : '0'}`,
      color: "#E4572E",
      icon: <OffBoardThisMonth />,
      backgroundImage: `url(${orangeCard})`
    },
    {
      title: "Pending onboarding requests",
      value: `${data?.data?.pending ? data?.data?.pending : '0'}`,
      color: "#6C63FF",
      icon: <PendingOnboarding />,
      backgroundImage: `url(${purpuleCard})`
    },
  ];
  return (
    <>
      <Row gutter={16} style={{ padding: "2px" }}>
        {cardData.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={6}>
            <Card
              style={{
                position: "relative",
                borderRadius: "10px",
                color: "white",
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                textAlign: "left",
                overflow: "hidden",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: card.backgroundImage, // Background for the card
              }}
              bodyStyle={{
                padding: "20px",
                height: "117px",
                width: "300px", // Adjust width as needed
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                position: "relative",
              }}
            >
              {/* Title styled as per Figma */}
              <div
                style={{
                  position: "absolute",
                  top: "15px",
                  left: "50px",
                  fontSize: "12px",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "560",
                  lineHeight: "14.52px",
                  color: "white",
                }}
              >
                {card.title}
              </div>

              {/* Icon and Value section */}
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "1px",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {/* Move the icon further left */}
                {/* <div
                style={{
                  fontSize: "2rem",
                  position: "relative",
                  top: "2px",
                  left: "-10px", // Adjust left positioning
                  paddingRight: "10px",
                  height: "58px",
                  width: "58px"
                }}
              >
                {card.icon}
              </div> */}
                <div
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "-15px", // Moves icon more to the left
                    paddingRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {React.cloneElement(card.icon, {
                    style: { height: "40px", width: "40px" }, // Ensure the icon is properly sized
                  })}
                </div>
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "580",
                    position: "relative",
                    top: "1.1rem",
                    left: "-29px"
                  }}
                >
                  {card.value}
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default OnboardingCharts
