import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row, Typography, Spin, Table } from 'antd';
import MappedReport from './MappedReport';


const PreviewModal = (props) => {
  const {
    selectedReport,
    previewModalVisible,
    setPreviewModalVisible,
    userConfig,
    locationList,
    contractorList,
    isManageScreen,
    locationwiseContractors,
  } = props;
  

  return (
    <Modal
      title={
        <>
          <span>{selectedReport?.reportName}</span>{' '}
        </>
      }
      open={previewModalVisible}
      width='90%'
      onCancel={() => setPreviewModalVisible(false)}
      footer={null}
      destroyOnClose>
      <MappedReport
        contractorList={contractorList}
        userConfig={userConfig}
        locationList={locationList}
        selectedReport={selectedReport}
        isManageScreen={isManageScreen}
        locationwiseContractors={locationwiseContractors} />
    </Modal>
  );
};

export default PreviewModal;
