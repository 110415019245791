import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row, Typography } from 'antd';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import ReportHeader from './ReportHeader';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

const ChartModal = (props) => {
  const {
    selectedReport,
    modalVisible,
    setModalVisible,
    userConfig,
    locationList,
    contractorList,
    isManageScreen,
  } = props;
  const [chart, setChart] = useState(null);
  const [filters, setFilters] = React.useState({
    contractors: [],
    locations: [],
    dateRange: [],
  });

  const today = dayjs();
  const filtersOnLoad = () => {
    let query = {};

    const dateRange = [today.subtract(1, 'month').startOf('day'), today];
    query['jobDetails.doj'] = {
      $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
      $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
    };
    if (['admin', 'approver'].includes(userConfig.role)) {
      query.locationId = { $in: locationList };
      query['jobDetails.contractorName'] = { $in: contractorList };
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // query.createdBy = [userConfig.userid]
    }

    setFilters({
      dateRange: dateRange,
      contractors: contractorList,
      locations: locationList,
    });

    console.log('onLoad query', query);
    return query;
  };

  const createChart = () => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: selectedReport.baseUrl,
    });

    const query = filtersOnLoad();

    const newChart = sdk.createChart({
      chartId: selectedReport.chartId,
      filter: query,
    });

    newChart.render(document.getElementById('mongodbChart')).catch((e) => {
      //window.alert('Chart failed to initialise');
      console.log('Chart failed to initialise');
    });

    setChart(newChart);
  };

  useEffect(() => {
    if (modalVisible) createChart();
  }, [modalVisible]);

  const handleEditClick = () => {
    window.open(selectedReport?.editLink, '_blank');
  };

  const getQuery = (filters) => {
    const { contractors, locations, dateRange } = filters;

    const query = {};

    if (contractors?.length > 0) {
      query['jobDetails.contractorName'] = { $in: contractors };
    }

    if (locations?.length > 0) {
      query.locationId = { $in: locations };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] != dateRange[1]) {
      query['jobDetails.doj'] = {
        $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
        $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
      };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] == dateRange[1]) {
      query['jobDetails.doj'] = new Date(
        dateRange[0].format('YYYY-MM-DD')
      ).toISOString();
    }

    console.log('download pdf query', query);
    return query;
  };

  const downloadPDF = () => {
    const filterString = encodeURIComponent(JSON.stringify(getQuery(filters)));
    const publicViewLink = `${selectedReport.baseUrl}/embed/charts?id=${selectedReport.chartId}&autorefresh=true&theme=light&filter=${filterString}`;
    window.open(publicViewLink, '_blank');
  };

  // const downloadCSV = async () => {
  //   const data = await chart.getData();
  //   const { documents, fields } = data;

  //   let headers = '';
  //   for (let key in fields) {
  //     headers += `${fields[key]},`
  //   }
  //   headers = headers.slice(0, -1);
  //   headers += '\n';

  //   let rows = documents.map((doc) => {
  //     let row = '';
  //     for (let key in doc) {
  //       row += `${doc[key]},`
  //     }
  //     row = row.slice(0, -1);
  //     return row
  //   });
  //   rows = rows.join('\n');

  //   const csvContent = headers + rows;

  //   console.log('csv data', data)

  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);
  //   link.href = url;
  //   link.download = 'chart_data.csv';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  const downloadCSV = async () => {
    const data = await chart.getData();
    const { documents, fields } = data;

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Wage Cum Musterroll');

    // Step 2: Add the header section
    const headerSection = [
      'Contract Labour (Regulation and Abolition) Central Rules',
      'Form XVIII [(See Rule 78(1) (a)(i))]',
      'Form of Register of Wages cum Muster Roll',
      'Name and address of establishment: KUMBALAGODU',
      'Month: April-2025',
    ];

    // Add header section rows with larger font size, center alignment, and 'Times New Roman' font
    headerSection.forEach((headerText, index) => {
      const headerRow = worksheet.addRow([headerText]);
      headerRow.font = { bold: true, size: 14, name: 'Times New Roman' }; // Times New Roman font
      headerRow.alignment = { horizontal: 'center' }; // Center-align header section
      headerRow.height = 20; // Increase row height for better visibility

      // Merge cells for each header line
      worksheet.mergeCells(`A${index + 1}:AH${index + 1}`);
    });

    worksheet.addRow([]); // Empty row for spacing

    // Step 3: Define the table headers
    const tableHeaders = [
      "SI. No.",
      "Employee ID",
      "Name of Employee",
      "Designation/Nature of Work",
      "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
      "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
      "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
      "Total Attendances/Units of Work Done",
      "Daily-rate of wages/piece rate",
      "Basic Wages",
      "Dearness Allowance",
      "Overtime",
      "Other Cash Payments",
      "Amount of wages Earned",
      "Deductions, if any (Indicate Nature)",
      "Net Amount Paid",
      "Signature/Thumb impression of workman",
      "Initial of Contractor or his Representative",
    ];

    // Add headers to the worksheet
    const headerRow = worksheet.addRow(tableHeaders);
    headerRow.font = { bold: true, name: 'Times New Roman' }; // Bold headers with Times New Roman font
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment

    // Step 4: Map the documents to the table rows
    documents.forEach((doc, index) => {
      const rowData = [
        index + 1, // SI. No.
        doc.group, // Employee ID
        doc.group_series_0, // Name of Employee
        doc.group_series_1, // Designation/Nature of Work
        doc.group_series_2, // 01
        doc.group_series_3, // 02
        doc.group_series_4, // 03
        doc.group_series_5, // 04
        doc.group_series_6, // 05
        "", // 06 (empty, as per your sample data)
        "", // 07 (empty, as per your sample data)
        "", // 08 (empty, as per your sample data)
        "", // 09 (empty, as per your sample data)
        "", // 10 (empty, as per your sample data)
        "", // 11 (empty, as per your sample data)
        "", // 12 (empty, as per your sample data)
        "", // 13 (empty, as per your sample data)
        "", // 14 (empty, as per your sample data)
        "", // 15 (empty, as per your sample data)
        "", // 16 (empty, as per your sample data)
        "", // 17 (empty, as per your sample data)
        "", // 18 (empty, as per your sample data)
        "", // 19 (empty, as per your sample data)
        "", // 20 (empty, as per your sample data)
        "", // 21 (empty, as per your sample data)
        "", // 22 (empty, as per your sample data)
        "", // 23 (empty, as per your sample data)
        "", // 24 (empty, as per your sample data)
        "", // 25 (empty, as per your sample data)
        "", // 26 (empty, as per your sample data)
        "", // 27 (empty, as per your sample data)
        "", // 28 (empty, as per your sample data)
        "", // 29 (empty, as per your sample data)
        "", // 30 (empty, as per your sample data)
        doc.group_series_7, // Total Attendances/Units of Work Done
        doc.group_series_8, // Daily-rate of wages/piece rate
        doc.group_series_9, // Basic Wages
        doc.group_series_10, // Dearness Allowance
        doc.group_series_11, // Overtime
        doc.group_series_12, // Other Cash Payments
        doc.group_series_13, // Amount of wages Earned
        doc.group_series_14, // Deductions, if any (Indicate Nature)
        doc.group_series_15, // Net Amount Paid
        doc.group_series_16, // Signature/Thumb impression of workman
        doc.group_series_17, // Initial of Contractor or his Representative
      ];

      const row = worksheet.addRow(rowData);
      row.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment for all cells
    });

    // Step 5: Set column widths (re-enable this section)
    worksheet.columns = [
      { header: "SI. No.", width: 10 },
      { header: "Employee ID", width: 15 },
      { header: "Name of Employee", width: 20 },
      { header: "Designation/Nature of Work", width: 25 },
      ...Array.from({ length: 30 }, () => ({ width: 5 })), // Days 01–30
      { header: "Total Attendances/Units of Work Done", width: 20 },
      { header: "Daily-rate of wages/piece rate", width: 20 },
      { header: "Basic Wages", width: 15 },
      { header: "Dearness Allowance", width: 15 },
      { header: "Overtime", width: 15 },
      { header: "Other Cash Payments", width: 20 },
      { header: "Amount of wages Earned", width: 20 },
      { header: "Deductions, if any (Indicate Nature)", width: 25 },
      { header: "Net Amount Paid", width: 15 },
      { header: "Signature/Thumb impression of workman", width: 25 },
      { header: "Initial of Contractor or his Representative", width: 25 },
    ];

    // Step 6: Add borders to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Step 7: Write the workbook to a file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Wage_Cum_Musterroll.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  return (
    <Modal
      title={
        <>
          <span>Report View</span>{' '}
          {isManageScreen && (
            <span>
              <Typography.Link
                style={{
                  fontSize: '10px',
                  textDecoration: 'underline',
                  marginLeft: '10px',
                }}
                onClick={handleEditClick}>
                Edit Chart
              </Typography.Link>
            </span>
          )}
        </>
      }
      open={modalVisible}
      width='60%'
      onCancel={() => setModalVisible(false)}
      footer={null}
      destroyOnClose>
      <>
        <ReportHeader
          filters={filters}
          setFilters={setFilters}
          downloadCSV={downloadCSV}
          downloadPDF={downloadPDF}
          userConfig={userConfig}
          chart={chart}
          locationList={locationList}
          contractorList={contractorList}
        />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            {/* <Button
              disabled={!permissions?.edit}
              type='primary'
              onClick={handleEditClick}>
              Edit
            </Button> */}
          </Col>
        </Row>
        <Row>
          <div
            id='mongodbChart'
            role='region'
            aria-label='Chart container'
            style={{
              background: '#FFFFFF',
              border: 'none',
              borderRadius: '2px',
              boxShadow: '0 2px 10px 0 rgba(70, 76, 79, 0.2)',
              width: '640px',
              height: '480px',
            }}></div>
        </Row>
      </>
    </Modal>
  );
};

export default ChartModal;
