import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Form } from 'antd';
import { SearchOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadWorkOrderDetailsData, getWorkOrderDetailsData, getSearchData } from '../services/actions/api/workOrderDetails'
import moment from 'moment';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import BulkUploadModal from './BulkUploadModal'
import { useParams, useHistory, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;


const transformSheetData = (entry) => {
  return {
    workOrderNumber: entry['WORKORDER_NUM'],
    unitName: entry['UNIT_CODE'],
    contractorName: entry['VENDOR_NAME'],
    vendorCode: entry['VENDOR_CODE'],
    validFrom: entry['WORKORDER_VALID_FROM'],
    validTo: entry['WORKORDER_VALID_TO'],
    workOrderType: entry['SAP_WORKORDER_TYPE'],
    departmentName: entry['DEPT_NAME'],
    sectionCode: entry['SECTION_CODE'],
    costCentre: entry['COST_CENTRE_CODE'],
    gLCode: entry['GL_CODE'],
    jobDescription: entry['JOB_NAME'],
    workOrderOwner: '', // Not available in provided data
    contractorSupervisor: '', // Not available in provided data
    serviceDetails: [{
      itemNum: entry['ITEM_NUM'],
      sVCLnItemNum: entry['SVC_LN_ITEM_NUM'],
      // Add mappings for other service detail attributes here 
      sVCLnItemDel:entry['SVC_LN_ITEM_DEL'],
      sVCNum: entry['SVC_NUM'],	
      sVCLnItemName: entry['SVC_LN_ITEM_NAME'],
      itemDel: entry["ITEM_DEL"],	
      delvCompletionSw: entry["DELV_COMPLETION_SW"],
      itemChangedOnDate: entry["ITEM_CHANGED_ON_DATE"],
      vendorCode: entry["VENDOR_CODE"],
      contractorName: entry["VENDOR_NAME"],
      vendorAddress: entry["VENDOR_ADDRESS"],
      vendorBlocked: entry["VENDOR_BLOCKED"],
      validFrom: entry["WORKORDER_VALID_FROM"],
      validTo: entry["WORKORDER_VALID_TO"],
      workOrderType: entry["SAP_WORKORDER_TYPE"],	
      unitCode: entry["UNIT_CODE"],
      sectionCode: entry["SECTION_CODE"],
      sectionName: entry["SECTION_NAME"],
      sectionDescription: entry["SECTION_DESCRIPTION"],
      gLCode:entry["GL_CODE"],
      costCentre:entry["COST_CENTRE_CODE"],
      jobName:entry["JOB_NAME"],	
      rate:entry["RATE"],	
      qty: entry["QTY"],
      uom: entry["UOM"],
      workorderReleasedSw: entry["WORKORDER_RELEASED_SW"],
      pmWorkorderNum: entry["PM_WORKORDER_NUM"],
      wbsElement: entry["WBS_ELEMENT"],
      completedQty: entry["COMPLETED_QTY"],
      workorderReleasedDate: entry["WORKORDER_RELEASED_DATE"],
      sVCEntryCreatedDate: entry["SVC_ENTRY_CREATED_DATE"],
      sVCEntryUpdatedDate: entry["SVC_ENTRY_UPDATED_DATE"],	
      recordCreatedOn:entry["RECORD_CREATED_ON"],
      recordUpdatedOn: entry["RECORD_UPDATED_ON"],
      recordStatus: entry["RECORD_STATUS"],
      recordStatusText: entry["RECORD_STATUS_TEXT"],
      recordCreatedBy: entry["RECORD_CREATED_BY"],
      recordUpdatedBy:entry["RECORD_UPDATED_BY"],
      deptCode: entry["DEPT_CODE"],
      departmentName: entry["DEPT_NAME"],
      deptDescription: entry["DEPT_DESCRIPTION"],
      trade: entry["TRADE"],
      skill: entry["SKILL"],
      storageLocation: entry["STORAGE_LOCATION"],
      netAmount: entry["NET_AMOUNT"],	
      marginZcm1: entry["MARGIN_ZCM1"],	
      discountRa01: entry["DISCOUNT_RA01"],
      marginCmsZcm2: entry["MARGIN_CMS_ZCM2"],
      marginCmsQtyZcmq: entry["MARGIN_CMS_QTY_ZCMQ"],
      surchargeZa00: entry["SURCHARGE_ZA00"],	
      toolsTacklesZttc: entry["TOOLS_TACKLES_ZTTC"],
      taxCode: entry["TAX_CODE"],
      cgstTaxRate: entry["CGST_TAX_RATE"],
      sgstTaxRate: entry["SGST_TAX_RATE"],
      igstTaxRate: entry["IGST_TAX_RATE"],
      utgstTaxRate: entry["UTGST_TAX_RATE"],
      companyCode: entry["COMPANY_CODE"],
      paymentTerms: entry["PAYMENT_TERMS"],
      vendorStateCodeGst: entry["VENDOR_STATE_CODE_GST"],
      plantStateCodeGst: entry["PLANT_STATE_CODE_GST"],
      vendorGstin: entry["VENDOR_GSTIN"],
      plantGstin: entry["PLANT_GSTIN"],
      hraQty: entry["HRA_QTY"],	
      eInvoiceEligibility: entry["E_INVOICE_ELIGIBILITY"]
    }]
    
  };
};


const WorkOrderList = () => {
  const { userConfig } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    unit: null,
    status: null,
    sectionCode: null,
    validFrom: null,
    validTo: null,
  });
  
  const [jsonData, setJsonData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();
  const isLRPage = location.pathname.includes('scheduleworkmen');
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [totalRecords, setTotalRecords] = useState(0); // Total records
  const [loading, setLoading] = useState(false);

  // Fetch API with pagination parameters
  const fetchData = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      const data = await getWorkOrderDetailsData(page, limit); // API Call
  
      const dataWithKeys = data?.data?.map((item, index) => ({
        ...item,
        key: item.id || index, // Ensure each row has a unique key
      }));
  
      setTableData(dataWithKeys);
      setFilteredData(dataWithKeys);
      // setTotalRecords(data?.total || 0); // FIXED: Correct total count from API
      setTotalRecords(data?.pagination?.totalDocuments || 0);
  
    } catch (error) {
      console.error("Error fetching work order details data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when `currentPage` or `pageSize` changes
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

const handleFileUpload = async (file) => {
  try {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(firstSheet);

      // Function to convert Excel date serial number to Date object
      function excelDateToDate(excelDate) {
        if (typeof excelDate === 'number') {
          // Define the base date for Excel (December 30, 1899)
          const excelEpoch = new Date(1899, 11, 30);
          // Calculate the number of milliseconds per day
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          // Calculate the offset in milliseconds from the base date
          const millisecondsOffset = (excelDate - 1) * millisecondsPerDay;
          // Add the offset to the base date to get the corresponding JavaScript Date object
          return new Date(excelEpoch.getTime() + millisecondsOffset);
        } else if (typeof excelDate === 'string') {
          const [day, month, year] = excelDate.split('-').map(Number);
          // JavaScript months are 0-based, so we need to subtract 1 from the month
          return new Date(year, month - 1, day);
        } else {
          return null; // If the date format is unexpected
        }
      }

      function formatDate(date) {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
          return ''; // Return empty string if the date is invalid
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      // Transform the sheet data into the desired payload format
      const payload = sheetData.reduce((acc, entry) => {
        const workOrderNumber = JSON.stringify(entry['WORKORDER_NUM']);
        const existingEntry = acc.find(item => item.workOrderNumber === workOrderNumber);

        const serviceDetail = {
          itemNum: entry['ITEM_NUM'],
          sVCLnItemNum: entry['SVC_LN_ITEM_NUM'],
          sVCLnItemDel: entry['SVC_LN_ITEM_DEL'],
          sVCNum: entry['SVC_NUM'],
          sVCLnItemName: entry['SVC_LN_ITEM_NAME'],
          itemDel: entry['ITEM_DEL'],
          delvCompletionSw: entry['DELV_COMPLETION_SW'],
          itemChangedOnDate: entry['ITEM_CHANGED_ON_DATE'] ? formatDate(excelDateToDate(entry['ITEM_CHANGED_ON_DATE'])) : '',
          vendorCode: JSON.stringify(entry['VENDOR_CODE']),
          contractorName: entry['VENDOR_NAME'],
          vendorAddress: entry['VENDOR_ADDRESS'],
          vendorBlocked: entry['VENDOR_BLOCKED'],
          validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
          validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
          workOrderType: entry['SAP_WORKORDER_TYPE'],
          unitCode: entry['UNIT_CODE'],
          sectionCode: entry['SECTION_CODE'],
          sectionName: entry['SECTION_NAME'],
          sectionDescription: entry['SECTION_DESCRIPTION'],
          gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
          costCentre: entry['COST_CENTRE_CODE'],
          jobName: entry['JOB_NAME'],
          rate: entry['RATE'],
          qty: entry['QTY'],
          uom: entry['UOM'],
          workorderReleasedSw: entry['WORKORDER_RELEASED_SW'],
          pmWorkorderNum: entry['PM_WORKORDER_NUM'],
          wbsElement: entry['WBS_ELEMENT'],
          completedQty: entry['COMPLETED_QTY'],
          workorderReleasedDate: entry['WORKORDER_RELEASED_DATE'] ? formatDate(excelDateToDate(entry['WORKORDER_RELEASED_DATE'])) : '',
          sVCEntryCreatedDate: entry['SVC_ENTRY_CREATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_CREATED_DATE'])) : '',
          sVCEntryUpdatedDate: entry['SVC_ENTRY_UPDATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_UPDATED_DATE'])) : '',
          recordCreatedOn: entry['RECORD_CREATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_CREATED_ON'])) : '',
          recordUpdatedOn: entry['RECORD_UPDATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_UPDATED_ON'])) : '',
          recordStatus: entry['RECORD_STATUS'],
          recordStatusText: entry['RECORD_STATUS_TEXT'],
          recordCreatedBy: entry['RECORD_CREATED_BY'],
          recordUpdatedBy: entry['RECORD_UPDATED_BY'],
          deptCode: entry['DEPT_CODE'],
          departmentName: entry['DEPT_NAME'],
          deptDescription: entry['DEPT_DESCRIPTION'],
          trade: entry['TRADE'],
          skill: entry['SKILL'],
          storageLocation: entry['STORAGE_LOCATION'],
          netAmount: entry['NET_AMOUNT'],
          marginZcm1: entry['MARGIN_ZCM1'],
          discountRa01: entry['DISCOUNT_RA01'],
          marginCmsZcm2: entry['MARGIN_CMS_ZCM2'],
          marginCmsQtyZcmq: entry['MARGIN_CMS_QTY_ZCMQ'],
          surchargeZa00: entry['SURCHARGE_ZA00'],
          toolsTacklesZttc: entry['TOOLS_TACKLES_ZTTC'],
          taxCode: entry['TAX_CODE'],
          cgstTaxRate: entry['CGST_TAX_RATE'],
          sgstTaxRate: entry['SGST_TAX_RATE'],
          igstTaxRate: entry['IGST_TAX_RATE'],
          utgstTaxRate: entry['UTGST_TAX_RATE'],
          companyCode: entry['COMPANY_CODE'],
          paymentTerms: entry['PAYMENT_TERMS'],
          vendorStateCodeGst: entry['VENDOR_STATE_CODE_GST'],
          plantStateCodeGst: entry['PLANT_STATE_CODE_GST'],
          vendorGstin: entry['VENDOR_GSTIN'],
          plantGstin: entry['PLANT_GSTIN'],
          hraQty: entry['HRA_QTY'],
          eInvoiceEligibility: entry['E_INVOICE_ELIGIBILITY']
        };

        if (existingEntry) {
          existingEntry.serviceDetails.push(serviceDetail);
        } else {
          acc.push({
            workOrderNumber: entry['WORKORDER_NUM'],
            unitName: entry['UNIT_CODE'],
            contractorName: entry['VENDOR_NAME'],
            vendorCode: entry['VENDOR_CODE'],
            validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
            validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
            workOrderType: entry['SAP_WORKORDER_TYPE'],
            departmentName: entry['DEPT_NAME'],
            sectionCode: entry['SECTION_CODE'],
            gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
            jobDescription: entry['JOB_NAME'],
            workOrderOwner: entry['WORKORDER_OWNER'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            costCentre: entry['COST_CENTER_CODE'],
            sectionHead: entry['SECTION_HEAD'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            status: entry['STATUS'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            costCentre: entry['COST_CENTRE_CODE'],
            serviceDetails: [serviceDetail]
          });
        }

        return acc;
      }, []);

      console.log('Formatted Sheet Data', sheetData, payload);

      // Upload the parsed data to the server
      const response = await uploadWorkOrderDetailsData(payload);
      console.log('Backend response:', response);
      message.success('File uploaded successfully!');
      window.location.reload();
    };

    reader.readAsArrayBuffer(file);
  } catch (error) {
    console.error('Error uploading file:', error);
    message.error('Failed to upload file.');
    // Handle the error
  }
};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleUploadSuccess = (newData) => {
    // setTableData(newData); // Update the table data with the new data from the server
    setTableData(newData);
    setFilteredData(newData);
    handleReset();
  };



  const beforeUpload = (file) => {
    // handleFileUpload(file);
    return false; // Prevent default Upload behavior
  };
  
  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

 
  const handleSearch = async () => {
    try {
        const res = await getSearchData({ 
            ...filters, 
            unitName: filters.unit,  // Fix unit name mismatch
            validFrom: filters.validFrom ? new Date(filters.validFrom).toISOString() : null, 
            validTo: filters.validTo ? new Date(filters.validTo).toISOString() : null, 
            page: 1,  // Ensure page is sent
            limit: 30  // Ensure limit is sent
        });
        console.log("res", res);
        
        setFilteredData(res?.data || []); // Update state
    } catch (error) {
        console.error("Error fetching backend data:", error);
    }
  };

 
  useEffect(() => {
    handleSearch();
  }, [filters]);

  const handleFilterChange = (field, value) => {
      setFilters(prevFilters => ({
          ...prevFilters,
          [field]: value
      }));
  };


  const handleReset = () => {
    
    setFilters({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
    setFilteredData(tableData);
    
    // form.resetFields(['search', 'unit', 'status', 'sectionCode', 'validFrom', 'validTo']);
    // Manually reset form fields immediately after state update
    form.setFieldsValue({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
  };

  // const uniqueUnits = [...new Set(filteredData.map(record => record.unitName))];
  const uniqueUnits = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.unitName))] : [];
  // const uniqueStatuses = [...new Set(filteredData.map(record => record.status))];
  const uniqueStatuses = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.status))] : [];
  // const uniqueSectionCodes = [...new Set(filteredData.map(record => record.sectionCode))];
  const uniqueSectionCodes = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.sectionCode))] : [];

  const columns = [
    {
      title: 'Work Order Number',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        )
      ),
    },
    {
      title: 'Work Order Type',
      dataIndex: 'workOrderType',
      key: 'workOrderType',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        )
      ),
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      key: 'jobDescription',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        )
      ),
    },
    {
      title: 'Section Code',
      dataIndex: 'sectionCode',
      key: 'sectionCode',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        )
      ),
    },
    // {
    //   title: 'Section Head',
    //   dataIndex: 'sectionHead',
    //   key: 'sectionHead',
    // },
    {
      title: 'WO Valid From ',
      width:'11%',
      dataIndex: 'validFrom',
      key:'validFrom',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'WO Valid To ',
      width:'10%',
      dataIndex: 'validTo',
      key:'validTo',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        )
      ),
    },
    {
      title: 'Unit Name',
      dataIndex: 'unitName',
      key: 'unitName',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        )
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        )
      ),
    },
  ];

console.log("fiter",filteredData);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Work Order List</span>
        </Col>
        <Col span={12}>
        <Form form={form}>
            <Form.Item name="search" initialValue={filters.search}>
          <Input
            placeholder="Search"
            onChange={e => setFilters({ ...filters, search: e.target.value })}
            style={{ width: '100%'}}
            suffix={<SearchOutlined />}
          />
          </Form.Item>
          </Form>
        </Col>
        { !isLRPage && (
        <Col span={6} style={{ textAlign: 'right' }}>
        {userConfig.level === "Section Head" || userConfig.level === "sectionHead" &&
        <Button type="primary" icon={<UploadOutlined />} onClick={showModal}>
            Import Work Order
        </Button>
        }
        <BulkUploadModal visible={isModalVisible} onClose={handleClose} onUploadSuccess={handleUploadSuccess} isWorkOrderSheet={true} isMinWageSheet={false}/>
         
        </Col>
      )}
      </Row>
      <div style={{ backgroundColor: '#F4F6FF', border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
      <Form form={form}>  
        <Row gutter={16}>
            <Col span={4}>
            <Form.Item name="unit" initialValue={filters.unit}>
            <Select
                placeholder="Unit"
                // value={filters.unit}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, unit: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
              return (
                  <>
                    {uniqueUnits.length > 0 ? (
                      uniqueUnits.map(unit => (
                        <Option key={unit} value={unit}>
                          {unit}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="status" initialValue={filters.status}>
            <Select
                placeholder="Status"
                // value={filters.status}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, status: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
                return (
                  <>
                    {uniqueStatuses.length > 0 ? (
                      uniqueStatuses.map(status => (
                        <Option key={status} value={status}>
                          {status}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="sectionCode" initialValue={filters.sectionCode}>
            <Select
                placeholder="Section Code"
                // value={filters.sectionCode}
                style={{ width: '100%'}}
                onChange={value => setFilters({ ...filters, sectionCode: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
                return (
                  <>
                    {uniqueSectionCodes.length > 0 ? (
                      uniqueSectionCodes.map(sectionCode => (
                        <Option key={sectionCode} value={sectionCode}>
                          {sectionCode}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validFrom" initialValue={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid From"
                // value={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                // onChange={date => setFilters({ ...filters, validFrom: date })}
                onChange={(date, dateString) => setFilters({ ...filters, validFrom: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validTo" initialValue={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid To"
                style={{ width: '100%' }}
                // value={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}
                format="DD-MM-YYYY"
                // onChange={date => setFilters({ ...filters, validTo: date })}
                onChange={(date, dateString) => setFilters({ ...filters, validTo: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Button
                icon={<ReloadOutlined />}
                onClick={handleReset}
                style={{ width: '100%' }}
            >
                Reset
            </Button>
            </Col>
        </Row>
        </Form>
      </div>
      {filteredData && (
      <Table columns={columns} dataSource={filteredData} 
      rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords, // Total records from API
          showSizeChanger: true, // Enable page size changer dropdown
          pageSizeOptions: ["10", "20", "50", "100"], // Allow user to change page size
          onChange: (page) => {
            console.log("Page changed to:", page);
            setCurrentPage(page)
          }, // Update current page
          onShowSizeChange: (_, size) => {
            console.log("Page size changed to:", size);
            setPageSize(size)
            setCurrentPage(1);
          }, // Update page size
        }}
      />
      )}
    </div>
  );
};

export default WorkOrderList;
