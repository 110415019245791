import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, Space, Input, Form, Table, Typography } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditFilled,
  InteractionOutlined,
  EyeFilled,
} from '@ant-design/icons';
import { getContractorList, getPermissionList } from '../services/auth';
import Highlighter from 'react-highlight-words';
import AddContractor from '../components/Contractor/AddContractor';
import ContractorSummary from '../components/ContractorOnboard/ContractorSummary';
import dayjs from 'dayjs';
import { Editcontractor } from '../services/auth';
import { AuthContext } from '../contexts/AuthProvider';
import useAuth from '../hooks/useAuth';

export default function ContractorApprovalPage({ initialValues, onNext }) {
  const [sortedInfo, setSortedInfo] = React.useState({});
  const [, forceUpdate] = React.useState({});
  const [Supplier, setSupplier] = React.useState([]);
  const [, setloading] = React.useState(true);
  const [, setEditingKey] = React.useState('');
  // search icon
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const [adminFormOpen, setAdminFormOpen] = React.useState(false);
  const [replaceOpen, setReplaceOpen] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = useState({});
  const { customDropdownValues } = useContext(AuthContext);
  const [contractorCreated, setContractorCreated] = useState(false);
  const [permissionList, setPermissionList] = useState({});
  const formRef = React.useRef();

  // const [data, setData] = React.useState(initialValues);

  const DEFAULT_ADMIN_FORM_VALUE = {
    id: '',
    name: '',
    maxContractors: '',
    // doe: "",
    // logoFile: "",
  };
  const [getApprovalData, setApprovalData] = React.useState();
  const [editIndex, setEditIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const searchInput = useRef(null);
  const { userConfig } = useAuth();
  const orgId = userConfig.orgId; //admin
  const role = userConfig.role;
  const roleName = userConfig.roleName;
  if (userConfig?.roleName) {
  }
  useEffect(() => {
    ContractorList();
    forceUpdate({});
    // PermissionList();
  }, []);

  useEffect(() => {}, [customDropdownValues]);

  const ContractorList = async () => {
    getContractorList().then((res) => {
      setloading(false);
      setSupplier(
        res.data.suppliers.map((row) => ({
          _id: row._id,
          key: row.id,
          id: row.id,
          name: row.name,
          // status: row.status,
          status: row?.status[0].toUpperCase() + row.status?.slice(1),
          // jobStatus: row.addJobDetails.status,
          // doe: row.doe,
          doe: dayjs(row.doe).format('DD-MM-YYYY'),
          location: row.location,
          Edited: row?.Edited,
        }))
      );
    });
  };

  console.log('supplier86', Supplier);

  useEffect(() => {
    let data = [];

    // if (userConfig?.locationId || userConfig?.location) {
    //   // If userConfig.location is present, filter by both userConfig.location and supplier location
    //   data = Supplier.filter(
    //     (supplier) =>
    //       userConfig.locationId == supplier.location ||
    //       userConfig.location == supplier.location
    //   );
    // } else {
    //   data = Supplier;
    // }

    if (userConfig?.locationId || userConfig?.location) {
      // If userConfig.location is present, filter by both userConfig.location and supplier location
      data = Supplier.filter(
        (supplier) =>
          userConfig.locationId == supplier?.location ||
          userConfig.location == supplier?.location
      );
    } else {
      data = Supplier;
    }

    if (userConfig.role == 'contractor') {
      data = data.filter((supplier) => {
        return supplier.id == userConfig?.supplierId;
      });
    } else {
      //data = Supplier;
    }

    if (userConfig.role == 'approver') {
      data = data.filter((supplier) => {
        console.log('supplier109', supplier);
        return supplier.Edited == 'Yes';
      });
    } else {
      // data = Supplier;
    }

    setFilteredData(data);
  }, [Supplier, userConfig]);

  // const PermissionList = async () => {
  //   getPermissionList(orgId, roleName).then((res) => {
  //     setloading(false);

  //
  //     setPermissionList(res?.data?.permissions);
  //   });
  // };
  //

  // const addAdmin = (value, isEdit = false) => {
  //   if (isEdit) {
  //     setData((currData) => {
  //       return currData.map((d, index) => {
  //         if (index === editIndex) {
  //           return value;
  //         }

  //         return d;
  //       });
  //     });

  //     setEditIndex(null);
  //   } else {
  //     setData((currData) => [...currData, value]);
  //   }
  //   setAdminFormOpen(false);
  // };

  const edit = (record) => {
    setAdminFormOpen(true);
    setEditIndex(record.index);
    setIsEdit(true);

    Editcontractor({ id: record?.id })
      .then((res) => {
        const Response = res?.data;
        console.log("Response ", Response)
        Response.statusFromDate = res.data.statusFromDate
          ? dayjs(new Date(res?.data?.statusFromDate))
          : '';
          Response.foreignNumber = res?.data?.foreignNumber
        Response.statusToDate = res?.data?.statusToDate
          ? dayjs(new Date(res?.data?.statusToDate))
          : '';
        Response.reason = res?.data?.reason;

        let data = Response;
        setApprovalData(data);
      })
      .catch((err) => {});
  };

  const replaceContractor = (record) => {
    setSelectedContractor(record);
    setReplaceOpen(true);
  };

  const onFinish = (values) => {
    onNext(values);
    setContractorCreated(true);
  };
  const openAdminModal = () => {
    setIsEdit(false);
    setAdminFormOpen(true);
  };

  // pagination
  const cancel = () => {
    setEditingKey('');
  };
  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      filterMode: 'tree',
      filterSearch: true,
      // onFilter: (value, record) => record.name.startsWith(value),
      onFilter: (value, record) =>
        record?.name?.toLowerCase()?.startsWith(value?.toLowerCase()), // Case-insensitive
    },
    {
      title: 'Contractor Code',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      filterMode: 'tree',
      filterSearch: true,
      // onFilter: (value, record) => record.id.startsWith(value),
      // width: '30%',
      // sorter: (a, b) => a.id.length - b.id.length,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Job Order Status',
    //   render: () => {
    //     const isPending = getApprovalData?.addJobDetails?.some(
    //       (option) => option.newStatus === 'Pending'
    //     );

    //     console.log('isPending400', );

    //     const statusText = isPending ? 'Pending' : 'Active';
    //     const statusColor = isPending ? 'red' : 'green';

    //     return <span style={{ color: statusColor }}>{statusText}</span>;
    //   },
    // },

    // {
    //   title: 'Next Task',
    //   dataIndex: 'Edited',
    //   render: (_, record, _id) => {
    //     return (
    //       <Space>
    //         <Typography>{record.Edited === 'Yes' ? 'IR' : 'NA'}</Typography>
    //       </Space>
    //     );
    //   },
    // },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, _id) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.role === 'admin'
                  ? userConfig.roleName &&
                    !roleName?.permissions?.Contractors?.edit
                  : ''
              }
              onClick={() => edit(record)}>
              <EyeFilled />
            </Typography.Link>
            {userConfig.role !== 'contractor' &&
              userConfig.role !== 'approver' && (
                <Typography.Link
                  onClick={() => replaceContractor(record)}
                  disabled={
                    userConfig.role === 'admin'
                      ? userConfig.roleName &&
                        !roleName?.permissions?.Contractors?.edit
                      : ''
                  }>
                  <InteractionOutlined />
                </Typography.Link>
              )}
          </Space>
        );
      },
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      {/* <h2>Contractor Agency Onboarding</h2> */}
      <Form
        name='orgOnboard'
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        ref={formRef}>
        {userConfig.role !== 'contractor' && userConfig.role !== 'approver' && (
          <Form.Item>
            <Button
              type='dashed'
              onClick={openAdminModal}
              block
              disabled={
                userConfig.roleName &&
                !roleName?.permissions?.Contractors?.create
              }
              icon={<PlusOutlined />}>
              Add Contractor Agency
            </Button>
          </Form.Item>
        )}

        <ContractorSummary
          open={adminFormOpen}
          onCancel={() => {
            // setFormInitialValue({});
            setAdminFormOpen(false);
          }}
          initialValues={getApprovalData}
          // onOk={addAdmin}
        />

        <Table
          columns={columns}
          dataSource={filteredData}
          onChange={onChange}
          pagination={true}
          scroll={{ y: 400 }} // Set vertical scroll limit
          style={{ width: '100%' }}
        />
      </Form>
    </>
  );
}
