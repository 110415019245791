import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Form } from 'antd';
import { SearchOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { fetchWorkOrderDetailsData } from '../cwfm/services/actions/api/workOrderDetails'
import { getLaborRequisitionDetailsData } from '../cwfm/services/actions/api/laborRequisitionsDetails'
import moment from 'moment';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// import axios from 'axios';
import BulkUploadModal from './SectionHead/BulkUploadModal'
import { useParams, useHistory, useNavigate, useLocation } from 'react-router-dom';
import Item from 'antd/es/list/Item';
import {getShiftPatternFromWfds} from './services/actions/api/workmenSchedulerService';
import useAuth from '../hooks/useAuth';
import {getSupplier} from '../services/supplier'
import axios from '../services/axiosInstance'; 
import axios1 from 'axios';
import { getContractorList } from '../services/auth';


const { Option } = Select;


const LrListWorkorderDetail = () => {
  const { userConfig } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    unit: null,
    status: null,
    sectionCode: null,
    validFrom: null,
    validTo: null,
  });
 

  const [jsonData, setJsonData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();
  const isLRPage = location.pathname.includes('scheduleworkmen');
  const [users, setUsers] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("")

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        // Wait for all asynchronous functions to finish
        await getShiftPatternFromWfds(); // This is assumed to be a simple call, no need to await if no state update.
        await fetchData(); // Fetch the data after waiting for other calls
      } catch (error) {
        console.error('Error in fetchDataAsync:', error);
      }
    };

    fetchDataAsync(); // Trigger data fetching on mount
  }, []); // Empty dependency array to only run once on mount

  const fetchData = async () => {
    try {
      // Fetch labor requisition data
      const lrData = await getLaborRequisitionDetailsData();
      console.log('Labor Requisition Data:', lrData);

      // Map through labor requisition data and fetch work order details
      const workOrderDetailsPromises = lrData.map(async (item) => {
        // Fetch contractor list for each labor requisition
        const contractorList = await getContractorList();
        console.log('Contractor List:', contractorList.data.suppliers);

        // Loop through contractors to find matching suppliers and job order details
        for (const contractor of contractorList.data.suppliers) {
          if (userConfig.supplierId === contractor.id) {
            console.log('Matching supplier:', userConfig.supplierId, contractor.id);

            // Loop through job details and check for matching work order numbers
            for (const jobDetail of contractor.addJobDetails) {
              console.log('Checking job details:', jobDetail.jobordernumber, item.workOrderNumber);
              if (item.status === 'Approved' && jobDetail.jobordernumber === item.workOrderNumber) {
                console.log('Match found:', item.status, item.workOrderNumber);

                // Fetch work order details if status and work order match
                return fetchWorkOrderDetailsData(item.workOrderNumber); // Return promise
              }
            }
          }
        }
      });

      // Wait for all work order details promises to resolve
      const workOrderData = await Promise.all(workOrderDetailsPromises);

      // Flatten the array of results
      const flattenedData = workOrderData.flat();

      // Filter out duplicates based on workOrderNumber
      const uniqueData = flattenedData
        .filter((item) => item && item.workOrderNumber)
        .filter((item, index, self) => index === self.findIndex((t) => t.workOrderNumber === item.workOrderNumber));

      // Update state with unique data
      setTableData(uniqueData);
      setFilteredData(uniqueData);
    } catch (error) {
      console.error('Error fetching labor requisition details:', error);
    }
  };

  const handleSearch = async () => { 
      try {
        // Set the initial filtered data based on the backend response
        // let data = [...actualData];
        let data = [...tableData];
        if (filters.search) {
          
          
          data = data.filter((item) =>
            [
              item.workOrderNumber,
              item.workOrderType,
              item.jobDescription,
              item.sectionCode,
              item.sectionHead,
              item.contractorName,
              item.unitName,
              item.status,
              item.validFrom,
              item.validTo,
             
            ].some((field) =>
              field?.toString().toLowerCase().includes(filters.search.toLowerCase())
            )
          );
        }
     
        // Apply additional frontend filters if necessary
        if (filters.unit) {
          data = data.filter(item => item.unitName.includes(filters.unit));
        }
        if (filters.status) {
          data = data.filter(item => item.status.includes(filters.status));
        }
        if (filters.sectionCode) {
          data = data.filter(item => item.sectionCode.includes(filters.sectionCode));
        }
        if (filters.validFrom) {
          data = data.filter(item => 
            moment(item.validFrom, 'YYYY-MM-DD').isSameOrAfter(moment(filters.validFrom, 'DD-MM-YYYY'))
          );
        }
        if (filters.validTo) {
          data = data.filter(item => 
            moment(item.validTo, 'YYYY-MM-DD').isSameOrBefore(moment(filters.validTo, 'DD-MM-YYYY'))
          );
        }
    
        // Log the final filtered data and update state
        setFilteredData(data);
     
        
     
      } catch (error) {
        console.error("Error fetching backend data:", error);
      }
    };

  useEffect(() => {
    handleSearch();
  }, [filters]);

  const handleReset = () => {
    setFilters({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
    setFilteredData(tableData);
    // Manually reset form fields immediately after state update
    form.setFieldsValue({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
  };


  // const uniqueUnits = [...new Set(filteredData.map(record => record.unitName))];
  const uniqueUnits = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.unitName))] : [];
  // const uniqueStatuses = [...new Set(filteredData.map(record => record.status))];
  const uniqueStatuses = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.status))] : [];
  // const uniqueSectionCodes = [...new Set(filteredData.map(record => record.sectionCode))];
  const uniqueSectionCodes = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.sectionCode))] : [];

  const columns = [
    {
      title: 'Work Order Number',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        )
      ),
    },
    {
      title: 'Work Order Type',
      dataIndex: 'workOrderType',
      key: 'workOrderType',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        )
      ),
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      key: 'jobDescription',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        )
      ),
    },
    {
      title: 'Section Code',
      dataIndex: 'sectionCode',
      key: 'sectionCode',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        )
      ),
    },
    {
      title: 'Valid From ',
      width:'10%',
      dataIndex: 'validFrom',
      key:'validFrom',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'Valid To ',
      width:'10%',
      dataIndex: 'validTo',
      key:'validTo',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        )
      ),
    },
    {
      title: 'Unit Name',
      dataIndex: 'unitName',
      key: 'unitName',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        )
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        isLRPage ? (
          <Link 
            to={`/home/supervisor/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        ) : (
          <Link 
            to={`/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        )
      ),
    },
  ];

console.log("fiter",filteredData);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <span style={{ fontSize: '18px', fontWeight: 'bold',fontFamily:'Inter' }}>Work Order List</span>
        </Col>
        <Col span={12} style={{justifyContent:"left"}}>
        <Form form={form}>
        <Form.Item name="search" initialValue={filters.search}>
          <Input
            placeholder="Search"
            value={filters.search || ""}
            onChange={e => setFilters({ ...filters, search: e.target.value })}
            style={{ width: '100%'}}
            suffix={<SearchOutlined />}
          />
          </Form.Item>
          </Form>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#F4F6FF', border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
      <Form form={form}>  
        <Row gutter={16} justify={'center'}>
            <Col span={4}>
            <Form.Item name="validFrom" initialValue={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid From"
                value={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                onChange={(date, dateString) => setFilters({ ...filters, validFrom: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validTo" initialValue={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid To"
                style={{ width: '100%' }}
                value={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}
                format="DD-MM-YYYY"
                onChange={(date, dateString) => setFilters({ ...filters, validTo: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="status" initialValue={filters.status}>
            <Select
                placeholder="Status"
                value={filters.status}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, status: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
                return (
                  <>
                    {uniqueStatuses.length > 0 ? (
                      uniqueStatuses.map(status => (
                        <Option key={status} value={status}>
                          {status}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
            </Select>
            </Form.Item>
            </Col>
            {/* <Col span={3}>
            <Button
                type=""
                border= "primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
                style={{ width: '100%' }}
            >
                Search
            </Button>
            </Col> */}
            <Col span={3}>
            <Button
                icon={<ReloadOutlined />}
                onClick={handleReset}
                style={{ width: '100%' }}
            >
                Reset
            </Button>
            </Col>
        </Row>
        </Form>
      </div>
      {filteredData && (
      <Table columns={columns} dataSource={filteredData} />
      )}
    </div>
  );
};

export default LrListWorkorderDetail;
