import React, { useState, useEffect } from 'react';
import LtheApp from './lthe/App';
import VynApp from './vyn/App';
 
const App = React.memo(function App() {
  const [userInfo, setUserInfo] = useState(null);
 
  useEffect(() => {
    try {
      const storedUserInfo = JSON.parse(sessionStorage.getItem('employeeStore'));
      setUserInfo(storedUserInfo);
    } catch (error) {
      console.error('Failed to parse userInfo from sessionStorage:', error);
    }
  }, []);
 
  if (userInfo?.loggedInUser?.orgId === 'LTHE') {
    console.log('THIS IS LTHE!!');
    return <LtheApp />;
  }
 
  console.log('THIS IS VYN FRAMEWORK!!');
  return <VynApp />;
}, (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  const prevOrgId = prevProps.userInfo?.loggedInUser?.orgId;
  const nextOrgId = nextProps.userInfo?.loggedInUser?.orgId;
  return prevOrgId === nextOrgId;
});
 
export default App;
