import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  notification,
  Col,
  Row,
  Divider,
  Select,
  DatePicker,
  Table,
} from 'antd';

import { getCronCompactLogs } from '../../services/cron';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SchedulerHistory = (props) => {
  const { viewHistory: { id }} = props
  const [filter, setFilter] = useState({
      startDate: '',
      endDate: '',
      status: '',
    });
  const [isFilter, setIsFilter] = useState(false);
  const [cronData, setCronData] = useState([]);
  
  useEffect(() => {
    getCron(id);
  }, [filter, id]);

  const getCron = (id) => {
    getCronCompactLogs(id, filter.startDate, filter.endDate, filter.status)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setCronData(
            res.data.map((item) => ({
              id: item.ID || '',
              key: item.jobName,
              jobName: item.jobName,
              status: item.status,
              info: item.psNumber + ' ' + item.error || '',
              timestamp: item.updatedAt ? new Date(item.updatedAt) : null,
              action: item.status === 'Failed' ? 'Re-run' : '', // Add action based on status
              cronsInformation: item?.cronsInformation,
            }))
          );
        } else {
          // Handle when response data is not an array
          setCronData([]);
        }
      })
      .catch((error) => {
        console.log('Error fetching cron:', error);
        setCronData([]); // Reset data in case of error
      });
  };
  
  const handleReRun = (record) => {
    console.log(record);
    // Send a request to rerun the cron job and update status
    // axios.post('http://localhost:3000/api/cron/update', { jobName: record.jobName })
    // axios.post('https://demo.wikiworks.in:443/api/cron/update', { jobName: record.jobName })
    axios
      .post(`${process.env.REACT_APP_API_URL}/cron/update`, {
        jobName: record.jobName,
        record: record.id,
      })
      .then((response) => {
        console.log(
          'Cron job rerun initiated and status updated:',
          response.data
        );
        // Optionally, you can update the UI to reflect the success of the operation
        notification.success({ message: response.data.message });
        // After updating, you might want to refresh the cron data to reflect the changes
        getCron(id);
      })
      .catch((error) => {
        console.log('error==>', error);
        console.error('Error rerunning cron job:', error);
        // Handle the error, show a notification, or display an error message to the user
        notification.error({ message: 'Error rerunning cron job' });
      });
  };

  const columns = [
    // {
    //   title: 'Job Name',
    //   dataIndex: 'jobName',
    //   key: 'jobName',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Information',
      dataIndex: 'cronsInformation',
      key: 'cronsInformation',
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (text, record) => {
        const formattedTime = new Date(text).toLocaleString();
        return <span>{formattedTime}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        console.log('record from:', record);
        if (text === 'Re-run') {
          return (
            <Button type='primary' onClick={() => handleReRun(record)}>
              Re-run
            </Button>
          );
        }
        return null;
      },
    },
  ];

  console.log('cronDataaa', cronData)
  return(
    <>
      {isFilter && (
        <>
          <Divider></Divider>
          <Row style={{ marginBottom: '5px' }} gutter={24}>
            <Col span={8}>
              <label>Date Range</label>
            </Col>
            <Col span={8}>
              <label>Status</label>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format='YYYY-MM-DD HH:mm'
                onChange={(dates, dateStrings) => {
                  setFilter({
                    ...filter,
                    startDate: dateStrings[0]
                      ? new Date(dateStrings[0]).getTime()
                      : '',
                    endDate: dateStrings[1]
                      ? new Date(dateStrings[1]).getTime()
                      : '',
                  });
                }}></RangePicker>
            </Col>
            <Col span={8}>
              <Select
                style={{ width: '100%' }}
                value={filter.status}
                onChange={(e) => {
                  setFilter({ ...filter, status: e });
                }}>
                <Option value='Success' key='success'>
                  Success
                </Option>
                <Option value='Failed' key='failed'>
                  Failed
                </Option>
              </Select>
            </Col>
          </Row>
          <Divider></Divider>
        </>
      )}

      <Table
        columns={columns}
        dataSource={cronData}
        onChange={() => {}}
        pagination={true}
      />
    </>
  )
}

export default SchedulerHistory