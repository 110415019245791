// import Axios from './axios';
import axios from '../../../services/axiosInstance';
import axiosRetry from 'axios-retry';

/**
 * Application Preview
 * @param {Object} payload
 */
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
export const createLaborRequisitionDetailData = (payload) => {
  return axios.post(`/labour-requisition`, payload);
};

export const getLaborRequisitionDetailData = (page, limit) => {
  return axios.get('/labour-requisition', {
    params: {
      page: page,   // ✅ Correct API parameter
      limit: limit // ✅ Correct API parameter
    }
  });
};


export const fetchLaborRequisitionDetailData = async(workOrderNumber) => {
  try {
      const response = await axios.get(`/labour-requisition/workOrderNumber/${workOrderNumber}`);
      // return response
      if (response && response.data) {
        return response.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};

export const fetchLrLaborRequisitionDetailData = async(lrNumber) => {
  try {
      const response = await axios.get(`/labour-requisition/lrNumber/${lrNumber}`);
      
      if (response && response.data) {
        return response.data.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};

// // Function to update labor requisition data by LR number
export const updateLrDetailDataByLrNumber = async (lrNumber, data) => {
  try {
      
      const response = await axios.put(`/labour-requisition/${lrNumber}`, data );
      
      return response.data;
  } catch (error) {
      console.error('Error in updateLrDetailDataByLrNumber:', error);
      throw new Error(`Error updating labor requisition data: ${error.message}`);
  }
};

export const updateOrgPathAndEffectDate = async (data) => {
  try {
      
      const response = await axios.post(
          `/labour-requisition/updateOrg-path-effectDate`, 
          data,
          {
            timeout: 30000  // Set timeout to 300 seconds
          },
          (response_imd)=>{
            console.log("response_imd", response_imd);
          }
      );
      return response; // Return the actual response data
     
  } catch (error) {
      // console.error('Error in updateOrgPathAndEffectDate:', error);
      console.error('Error in updateOrgPathAndEffectDate:', {
        message: error.message,
        config: error.config,
        code: error.code,
        response: error.response ? error.response.data : 'No response data'
    });
    throw new Error(`Error updating labor requisition data: ${error.message}`);
  }

};

export const lrSearch = async() => {
  try {
      const response = await axios.get('/labour-requisition/search');
     
      if (response && response.data) {
        return response.data.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};
  


