import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message } from 'antd';
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import {jobOrderDetailsColumn} from '../constant/jobOrderList';
import { fetchWorkOrderDetailsData } from '../services/actions/api/workOrderDetails'

const JobOrderDetails = () => {
    const [jobData, setJobData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const { workOrderNumber } = useParams();
    const location = useLocation();
    const isLRPage = location.pathname.includes('laborRequisitionDetail'); 
    const columns = jobOrderDetailsColumn(isLRPage);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchWorkOrderDetailsData(workOrderNumber);
                if (data?.serviceDetails) {
                    // Assuming serviceDetails is the data you need for jobData
                    setJobData(data?.serviceDetails);
                    }
            } catch(error) {
            console.error('Error fetching work order details data:', error);
            }
        }
        fetchData();
        }, []);
        
        return(
            <Table columns={columns} dataSource={jobData} />
        )
    }

export default JobOrderDetails;
