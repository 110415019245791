// import Axios from './axios';
import axios from '../../../services/axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */

export const getShiftPatternFromWfdData = () => {
  return axios.get('/getShiftPatternFromDB');
};


export const updateSchedule = (payload, selectedJob) => {
  return axios.post('/updateScheduleToWfd', payload, selectedJob);
};

export const getShiftPatternDetail = (name) => {
  return axios.get(`/getShiftPatternDetail?name=${name}`);
};

export const findByLrNumberData = async(lrNumber) => {
  return await axios.post(`/labour-requisition/schdeule-lrNumberData/${lrNumber}`);
};

export const getStatusScheduleDatas = (validFrom, validTo) => {
  return axios.get('/getStatusScheduleData', {
    params: { validFrom, validTo }
  });
}

export const getShiftPatternFromWfd = () => {
  return axios.post('/getShiftPatternFromWfd');
}




