import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, Button, Col, Row } from 'antd';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import DashboardHeader from './DashboardHeader';

const Chart = (props) => {
  const { chartData, userConfig, locationList, contractorList } = props;
  const [chart, setChart] = useState(null);
  const [filters, setFilters] = React.useState({
    contractors: [],
    locations: [],
    dateRange: [],
  });

  const today = dayjs();

  const filtersForComplianceDashboard = () => {
    let query = {};

    const dateRange = [today.subtract(1, 'month').startOf('day'), today];
    query['timestamp'] = {
      $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
      $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
    };
    if (['admin', 'approver'].includes(userConfig.role)) {
      query['supplierName'] = { $in: contractorList };
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // query.createdBy = [userConfig.userid]
    }

    setFilters({
      dateRange: dateRange,
      contractors: contractorList,
      locations: locationList,
    });

    console.log('onLoad query', query);
    return query;
  }

  const filtersOnLoad = () => {
    if(chartData.type == 'COMPLIANCE_DASHBOARD') return filtersForComplianceDashboard();

    let query = {};

    const dateRange = [today.subtract(1, 'month').startOf('day'), today];
    query['jobDetails.doj'] = {
      $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
      $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
    };
    if (['admin', 'approver'].includes(userConfig.role)) {
      query.locationId = { $in: locationList };
      query['jobDetails.contractorName'] = { $in: contractorList };
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // query.createdBy = [userConfig.userid]
    }

    setFilters({
      dateRange: dateRange,
      contractors: contractorList,
      locations: locationList,
    });

    console.log('onLoad query', query);
    return query;
  };

  const createChart = () => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: chartData.baseUrl,
    });

    const query = filtersOnLoad();

    const newChart = sdk.createChart({
      chartId: chartData.chartId,
      filter: query,
    });

    newChart.render(document.getElementById(chartData.chartId)).catch((e) => {
      //window.alert('Chart failed to initialise');
      console.log(`Chart failed to initialise: ${chartData.chartId}`)
    });

    setChart(newChart);
  };

  useEffect(() => {
    createChart();
  }, []);

  const handleEditClick = () => {
    window.open(chartData?.editLink, '_blank');
  };

  const getQueryForComplianceDashboard = (filters) => {
    const { contractors, locations, dateRange } = filters;

    const query = {};

    if (contractors?.length > 0) {
      query['supplierName'] = { $in: contractors };
    }

    if (locations?.length > 0) {
      //query.locationId = { $in: locations };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] != dateRange[1]) {
      query['timestamp'] = {
        $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
        $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
      };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] == dateRange[1]) {
      query['timestamp'] = new Date(
        dateRange[0].format('YYYY-MM-DD')
      ).toISOString();
    }

    console.log('download pdf query', query);
    return query;
  };

  const getQuery = (filters) => {
    if (chartData.type == 'COMPLIANCE_DASHBOARD') {
      return getQueryForComplianceDashboard(filters)
    }

    const { contractors, locations, dateRange } = filters;

    const query = {};

    if (contractors?.length > 0) {
      query['jobDetails.contractorName'] = { $in: contractors };
    }

    if (locations?.length > 0) {
      query.locationId = { $in: locations };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] != dateRange[1]) {
      query['jobDetails.doj'] = {
        $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
        $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
      };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] == dateRange[1]) {
      query['jobDetails.doj'] = new Date(
        dateRange[0].format('YYYY-MM-DD')
      ).toISOString();
    }

    console.log('download pdf query', query);
    return query;
  };

  const downloadPDF = () => {
    const filterString = encodeURIComponent(JSON.stringify(getQuery(filters)));
    const publicViewLink = `${chartData.baseUrl}/embed/charts?id=${chartData.chartId}&autorefresh=true&theme=light&filter=${filterString}`;
    window.open(publicViewLink, '_blank');
  };

  const downloadCSV = async () => {
    const data = await chart.getData();
    const { documents, fields } = data;

    let headers = '';
    for (let key in data.fields) {
      headers += `${data.fields[key]},`
    }
    headers = headers.slice(0, -1);
    headers += '\n';

    let rows = data.documents.map((doc) => {
      let row = '';
      for (let key in doc) {
        row += `${doc[key]},`
      }
      row = row.slice(0, -1);
      return row
    });
    rows = rows.join('\n');

    const csvContent = headers + rows;

    console.log('csv data', data, documents, fields)

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'chart_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <DashboardHeader
        chartData={chartData}
        filters={filters}
        setFilters={setFilters}
        downloadCSV={downloadCSV}
        downloadPDF={downloadPDF}
        userConfig={userConfig}
        chart={chart}
        locationList={[...new Set(locationList)]}
        contractorList={[...new Set(contractorList)]}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          {/* <Button
              disabled={!permissions?.edit}
              type='primary'
              onClick={handleEditClick}>
              Edit
            </Button> */}
        </Col>
      </Row>
      <Row>
        <div
          id={chartData.chartId}
          role='region'
          aria-label='Chart container'
          style={{
            background: '#FFFFFF',
            border: 'none',
            borderRadius: '2px',
            boxShadow: '0 2px 10px 0 rgba(70, 76, 79, 0.2)',
            width: '378px',
            height: '439px',
          }}></div>
      </Row>
    </>
  );
};

export default Chart;
