import React, { useState, useEffect, useRef } from 'react';
import FormItem from 'antd/es/form/FormItem';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import createPass from '../services/employeeonboard';
import { useNavigate } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import Webcam from 'react-webcam';
import { SendOtp, callCheckAadharUniquenessApi } from '../services/auth';
import { VerifyOtp } from '../services/auth';
import dayjs from 'dayjs';
import useAuth from '../hooks/useAuth';
import { getWorkmenLimit, jobOrderCount } from '../services/auth';

import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Col,
  Row,
  Typography,
  Modal,
  Divider,
  notification,
  Collapse,
  Checkbox,
  Image,
  Tooltip,
  Space,
} from 'antd';
import {
  Dadra,
  Delhi,
  Jharkhand,
  Karnataka,
  AndhraPradesh,
  ArunachalPradesh,
  Assam,
  Bihar,
  Chhattisgarh,
  Chandigarh,
  Goa,
  Gujarat,
  Haryana,
  HimachalPradesh,
  Jammu,
  Kerala,
  Lakshadweep,
  MadhyaPradesh,
  Maharashtra,
  Manipur,
  Meghalaya,
  Mizoram,
  Nagaland,
  Odisha,
  Punjab,
  Puducherry,
  Rajasthan,
  TamilNadu,
  Telangana,
  Tripura,
  Uttarakhand,
  UttarPradesh,
  WestBengal,
} from '../JSON/data';
import { useParams } from 'react-router-dom';
import axios1 from '../services/axiosInstance';
import { Email } from '../services/auth';
import { setLoader } from '../actions';

var convert = require('xml-js');
const { Title } = Typography;

const Quickonboarding = () => {
  // set('myCookie', 'myValue', {
  //   sameSite: 'Lax',
  //   expires: 365,
  //   secure: true
  // });
  let State = [
    'Select State',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'MadhyaPradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'UttarPradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry',
  ];
  const [location, setlocation] = React.useState({});
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [otploading, setotploading] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [aadharDetails, setAadharDetails] = useState({});
  const { user, userConfig } = useAuth();
  const [isAadharChecked, setIsAadharChecked] = useState(false);

  const [contractorList, setContractorList] = React.useState([]);
  const [jobOrderList, setJobOrderList] = React.useState([]);
  const [selectedContractorCode, setSelectedContractorCode] =
    React.useState(null);
  const [contractorNameList, setContractorNameList] = React.useState([]);
  const [selectedJobOrder, setSelectedJobOrder] = React.useState(null);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [contractorName, setContractorName] = React.useState([]);

  const orgId = userConfig?.orgId;

  useEffect(() => {
    EmployeeList1();
  }, []);
  const EmployeeList1 = async () => {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response.data.org.locations;

    setlocation({ Response2 });
  };
  const [form] = Form.useForm();

  const [UanDisabled, setUanDisabled] = useState(true);
  const [PfDisabled, setPfDisabled] = useState(true);
  const [EsicDisabled, setEsicDisabled] = useState(true);
  const [MaritalDisabled, setMaritalDisabled] = useState(true);
  const [date, setDate] = useState(new Date());
  const [date1, setdate1] = React.useState({});

  // const [agevalue, setagevalue] = useState("24");
  const [users, setUsers] = useState({});
  const navigate = useNavigate();
  //aadhar scanner
  const [selected, setSelected] = React.useState('environment');
  const [startScan, setStartScan] = React.useState(false);
  const [loadingScan, setLoadingScan] = React.useState(false);
  const [data, setData] = useState();
  const [Qrcode, setQrcode] = useState({});
  const [firstName, setfirstName] = React.useState('');
  const [lastName, setlastName] = React.useState('');
  const [gender, setgender] = React.useState('');
  const [aadhar, setaadhar] = React.useState('');
  const [dob, setdob] = React.useState('');
  const [state, setstate] = React.useState('');
  const [dist, setdist] = React.useState('');
  const [pc, setpc] = React.useState('');
  const [village, setvillage] = React.useState('');
  const [fathername, setfathername] = React.useState('');
  const [address, setaddress] = React.useState('');
  const [uidai, setuidai] = useState({});
  const [adhar, setadhar] = useState('');
  const [otp, setotp] = useState('');
  const [Request, setRequest] = useState({});
  const [disable, setDisable] = React.useState(false);
  const [otpdisable, setotpDisable] = React.useState(false);
  const [consent, setconsent] = React.useState(true);

  const DEFAULT_ADMIN_FORM_VALUE = {
    firstName: '',
    lastName: '',
    gender: '',
    aadharNo: '',
    fatherName: '',
    address: '',
    village: '',
    pincode: '',
    state: '',
    district: '',
    dob: '',
    profilePic: [],
  };
  const [personalDetails, setpersonalDetails] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );

  const handleScan = async (scanData) => {
    setIsViewOnly(true);

    setLoadingScan(true);

    if (scanData && scanData !== '') {
      const xmlDocument1 = new DOMParser().parseFromString(
        scanData,
        'text/xml'
      );

      var xml = scanData;

      var result1 = convert.xml2js(xml, { compact: true, spaces: 4 });

      const fullName = result1.PrintLetterBarcodeData._attributes.name;

      const parts = fullName.split(' ');
      const lastName = parts.pop();
      const firstName = parts.join(' ');
      const gender = result1.PrintLetterBarcodeData._attributes.gender;
      const yob = result1.PrintLetterBarcodeData._attributes.yob;
      // const dob = result1.PrintLetterBarcodeData._attributes.dob;
      const dob = dayjs(
        result1.PrintLetterBarcodeData._attributes.dob,
        'DD-MM-YYYY'
      );

      setdate1({ dob });

      var theAge = 2023 - yob;
      const uid = result1.PrintLetterBarcodeData._attributes.uid;
      const endNum = uid.slice(-4);
      const maskNum = endNum.padStart(uid.length, '*');
      const co = result1.PrintLetterBarcodeData._attributes.co;
      const house = result1.PrintLetterBarcodeData._attributes.house;
      const street = result1.PrintLetterBarcodeData._attributes.street;
      const lm = result1.PrintLetterBarcodeData._attributes.lm;
      const loc = result1.PrintLetterBarcodeData._attributes.loc;
      const village = result1.PrintLetterBarcodeData._attributes.vtc;
      const dist = result1.PrintLetterBarcodeData._attributes.dist;
      const state = result1.PrintLetterBarcodeData._attributes.state;
      const pc = result1.PrintLetterBarcodeData._attributes.pc;
      const address =
        result1.PrintLetterBarcodeData._attributes.house +
        ', ' +
        street +
        ', ' +
        lm +
        ', ' +
        loc;

      setData(xml);
      setStartScan(false);
      setLoadingScan(false);

      form.setFieldsValue({
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        gender: gender,
        aadharNo: maskNum,
        fatherName: co,
        address: address,
        village: village,
        district: dist,
        state: state,
        pincode: pc,
        age: theAge,
        // dob: theAge,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const [show, setShow] = useState(true);

  // Get ShortOnboarding

  const { _id } = useParams();

  useEffect(() => {
    EmployeeList();
  }, []);

  async function EmployeeList() {
    const Response = await axios1.get('/shortonboard?pageSize=1&pageNo=1');
    // const Normaldata = Response.data.employees[0].firstName;
    const firstName = Response.data.employees[0].firstName;
    const lastName = Response.data.employees[0].lastName;
    const aadharNo = Response.data.employees[0].aadharNo;
    const age = Response.data.employees[0].age;
    const mobile = Response.data.employees[0].mobile;
    const profilePic = Response.data.employees[0].profilePic;
    const dob = Response.data.employees[0].dob;
    const emergencyContactName =
      Response.data.employees[0].emergencyContactName;
    const emergencyContactNo = Response.data.employees[0].emergencyContactNo;
    const spousename = Response.data.employees[0].spousename;
    const childrenNo = Response.data.employees[0].childrenNo;
    const idmark = Response.data.employees[0].idmark;
    const religion = Response.data.employees[0].religion;
    const domicile = Response.data.employees[0].domicile;
    const numberOfYearSpent = Response.data.employees[0].numberOfYearSpent;
    const height = Response.data.employees[0].height;
    const pan = Response.data.employees[0].pan;
    const handicapped = Response.data.employees[0].handicapped;
    const disabilityType = Response.data.employees[0].disabilityType;
    const disabilityPercentage =
      Response.data.employees[0].disabilityPercentage;
    const votercardno = Response.data.employees[0].votercardno;
    const rationcardno = Response.data.employees[0].rationcardno;
    const biometricBypass = Response.data.employees[0].biometricBypass;
    const biometricImage = Response.data.employees[0].biometricImage;
    const electronicSignature = Response.data.employees[0].electronicSignature;
    const pmeRequired = Response.data.employees[0].pmeRequired;
    const fatherName = Response.data.employees[0].fatherName;
    const gender = Response.data.employees[0].gender;
    const address = Response.data.employees[0].address;
    const paddress = Response.data.employees[0].paddress;
    const epnNumber = Response.data.employees[0].epnNumber;
    const epnRenew = Response.data.employees[0].epnRenew;
    const hazardousProcessArea =
      Response.data.employees[0].hazardousProcessArea;
    const technicalDetails = Response.data.employees[0].technicalDetails;
    const natureOfWork = Response.data.employees[0].natureOfWork;
    const policeVerification = Response.data.employees[0].policeVerification;
    const safetyTrainingFromDate =
      Response.data.employees[0].safetyTrainingFromDate;
    const safetyTrainingUptoDate =
      Response.data.employees[0].safetyTrainingUptoDate;
    const safetyTrainingRemarks =
      Response.data.employees[0].safetyTrainingRemarks;
    const blockRemark = Response.data.employees[0].blockRemark;
    const workingArea = Response.data.employees[0].workingArea;
    const gateNumber = Response.data.employees[0].gateNumber;
    const esicno = Response.data.employees[0].esicno;
    const uan = Response.data.employees[0].uan;
    const pfAccountNo = Response.data.employees[0].pfAccountNo;

    //Job Details
    const departmentCode = Response.data.employees[0].departmentCode;
    const sectionCode = Response.data.employees[0].sectionCode;
    const workorderCode = Response.data.employees[0].workorderCode;
    const itemserviceCode = Response.data.employees[0].itemserviceCode;
    const employeeType = Response.data.employees[0].employeeType;
    const labourType = Response.data.employees[0].labourType;
    const jobCode = Response.data.employees[0].jobCode;
    const skillCode = Response.data.employees[0].skillCode;
    const subSkillCode = Response.data.employees[0].subSkillCode;
    const subSkillDate = Response.data.employees[0].subSkillDate;
    const designation = Response.data.employees[0].designation;
    const duration = Response.data.employees[0].duration;
    const workerCategory = Response.data.employees[0].workerCategory;
    const contractorReferenceNumber =
      Response.data.employees[0].contractorReferenceNumber;

    const doj = Response.data.employees[0].doj;
    const shoeSize = Response.data.employees[0].shoeSize;
    const bloodGroup = Response.data.employees[0].bloodGroup;
    const academicQualification =
      Response.data.employees[0].academicQualification;
    const techQualification = Response.data.employees[0].techQualification;
    const dl = Response.data.employees[0].dl;
    const dld = Response.data.employees[0].dld;
    const terminationstatus = Response.data.employees[0].terminationstatus;
    const workexperience = Response.data.employees[0].workexperience;
    const relativeworking = Response.data.employees[0].relativeworking;
    const bankdetails = Response.data.employees[0].bankdetails;

    //Wage Details
    const basic = Response.data.employees[0].basic;
    const da = Response.data.employees[0].da;
    const allowance1 = Response.data.employees[0].allowance1;
    const allowance2 = Response.data.employees[0].allowance2;
    const vda = Response.data.employees[0].vda;
    const hra = Response.data.employees[0].hra;
    const conveyance = Response.data.employees[0].conveyance;
    const specialAllowance = Response.data.employees[0].specialAllowance;
    const medicalAllowance = Response.data.employees[0].medicalAllowance;
    const eduAllowance = Response.data.employees[0].eduAllowance;
    const travelAllowance = Response.data.employees[0].travelAllowance;
    const ot = Response.data.employees[0].ot;
    const effectiveDate = Response.data.employees[0].effectiveDate;

    setUsers({
      firstName,
      village,
      lastName,
      aadharNo,
      emergencyContactName,
      emergencyContactNo,
      age,
      dob,

      profilePic,
      fatherName,
      idmark,
      spousename,
      childrenNo,
      religion,
      domicile,
      numberOfYearSpent,
      height,
      pan,
      handicapped,
      disabilityPercentage,
      disabilityType,
      votercardno,
      rationcardno,
      pmeRequired,
      biometricBypass,
      biometricImage,
      electronicSignature,
      gender,
      mobile,
      address,
      paddress,
      epnNumber,
      epnRenew,
      academicQualification,
      technicalDetails,
      hazardousProcessArea,
      policeVerification,
      natureOfWork,
      safetyTrainingFromDate,
      safetyTrainingRemarks,
      safetyTrainingUptoDate,
      workingArea,
      blockRemark,
      gateNumber,
      esicno,
      uan,
      pfAccountNo,
      //job Details
      departmentCode,
      sectionCode,
      workorderCode,
      itemserviceCode,
      employeeType,
      labourType,
      jobCode,
      skillCode,
      subSkillCode,
      subSkillDate,
      designation,
      duration,
      workerCategory,
      contractorReferenceNumber,
      doj,
      shoeSize,
      bloodGroup,
      academicQualification,
      techQualification,
      dl,
      dld,
      terminationstatus,
      workexperience,
      relativeworking,
      bankdetails,

      basic,
      da,
      hra,
      allowance1,
      allowance2,
      vda,
      conveyance,
      specialAllowance,
      medicalAllowance,
      eduAllowance,
      travelAllowance,
      effectiveDate,
      ot,
      bloodGroup,
    });
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [presentAdress, setpresentAdress] = useState({
    address: '',
    village: '',
    district: '',
    state: '',
    pincode: '',
  });

  const [disabledButton, setDisabledButton] = React.useState({
    sendOtp: true,
    verifyOtp: true,
  });

  /* eslint-disable */

  const [permenantAdress, setpermenantAdress] = useState({
    paddress: '',
    pvillage: '',
    pdistrict: '',
    pstate: '',
    ppincode: '',
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setpresentAdress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const agecalculated = (e) => {
    let date = new Date(e).toLocaleDateString();
  };

  //Id
  const formRef = React.useRef();

  const Uantoggle = () => {
    setUanDisabled(!UanDisabled);
  };
  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };
  const Esictoggle = () => {
    setEsicDisabled(!EsicDisabled);
  };
  const Maritaltoggle = (values) => {
    if (Option.value == values.married) {
      setMaritalDisabled(!MaritalDisabled);
    } else {
      setMaritalDisabled(MaritalDisabled);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  // Upload Image
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  //-----Job Details----
  // const normfile = () => {
  // };
  const [Isworking, setIsworking] = React.useState(true);
  const [Empstatus, setEmpstatus] = React.useState(true);
  const [isFetchedFromAadhar, setISFetchedFromAadhar] = React.useState(false);

  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const Empstatustoggle = () => {
    setEmpstatus(!Empstatus);
  };

  //Same As Above

  function addressFunction(e) {
    if (e.target.checked) {
      form.setFieldsValue({
        pstate: form.getFieldValue('state'),
        paddress: form.getFieldValue('address'),
        pvillage: form.getFieldValue('village'),
        pdistrict: form.getFieldValue('district'),
        ppincode: form.getFieldValue('pincode'),
        ptaluka: form.getFieldValue('taluka'),
        ptehsil: form.getFieldValue('tehsil'),
        pcity: form.getFieldValue('city'),
        pcountry: form.getFieldValue('country'),
      });

      // form.setFieldsValue({ address:handleScan.address,village:village,district:district,state:state,pincode:pincode});
    } else {
      form.setFieldsValue({
        pstate: form.getFieldValue(''),
        paddress: form.getFieldValue(''),
        pvillage: form.getFieldValue(''),
        pdistrict: form.getFieldValue(''),
        ppincode: form.getFieldValue(''),
        ptaluka: form.getFieldValue(''),
        ptehsil: form.getFieldValue(''),
        pcity: form.getFieldValue(''),
        pcountry: form.getFieldValue(''),
      });
    }
  }

  const onFinish = (value) => {
    if (!aadharDetails.maskedAadhaarNumber) {
      setAadharDetails({
        maskedAadhaarNumber: `XXXX XXXX ${`${value.aadharNo}`.slice(-4)}`,
        requestId: '',
      });
    }
    const data1 = {
      personalDetails: {
        empCode: value.empCode,
        firstName: value.firstName,
        lastName: value.lastName,
        gender: value.gender,
        age: value.age,
        dob: value.dob,

        mobile: value.mobile,
        alternateMobile: value.alternateMobile,
        aadharNo: Number(value.aadharNo),
        fatherName: value.fatherName,
        emergencyContactName: value.emergencyContactName,
        emergencyContactNo: value.emergencyContactNo,
        // profilePic: value.profilePic,
        livePhoto: imageSrc,
        aadharPhoto: 'data:image/png;base64,' + uidai.profilePic,
        address: value.address,
        village: value.village,
        district: value.district,
        state: value.state,
        pincode: value.pincode,
        taluka: value.taluka,
        tehsil: value.tehsil,
        city: value.city,
        country: value.country,
        paddress: value.paddress,
        pvillage: value.pvillage,
        pdistrict: value.pdistrict,
        pstate: value.pstate,
        ppincode: value.ppincode,
        ptaluka: value.ptaluka,
        ptehsil: value.ptehsil,
        pcity: value.pcity,
        pcountry: value.pcountry,
        locationId: value.locationId,
        epnNumber: value.epnNumber,
        esicno: value.esicno,
        uan: value.uan,
        pfAccountNo: value.pfAccountNo,
      },
      jobDetails: {
        employerCode: userConfig?.orgId,
        contractorName: value.contractorName,
        contractorCode: userConfig?.supplierId,
        departmentCode: value.departmentCode,
        jobOrderNumber: value.jobOrderNumber,
        sectionCode: value.sectionCode,
        workorderCode: value.workorderCode,
        itemserviceCode: value.itemserviceCode,
        empType: value.employeeType,
        labourCode: value.labourType,
        jobCode: value.jobCode,
        skillCode: value.skillCode,
        doj: value.doj,
        shoeSize: value.shoeSize,
        bloodGroup: value.bloodGroup,
        academicQualification: value.academicQualification,
        techQualification: value.techQualification,
        dl: value.dl,
        dlExpiry: value.dlExpiry,
        terminationReason: value.terminationReason,
        totalExperience: value.totalExperience,
        previousOrg: value.previousOrg,
        referredBy: value.referredBy,
        name: value.name,
        mobileNo: value.mobileNo,
        bankname: value.bankname,
        AccountNo: value.AccountNo,
        ifsc: value.ifsc,
        userName: value.userName,

        // medicalcheckupdone: value.medicalCheckup,
        // medicalcheckupdate: value.medicalCheckupDate,
        // safetytraindone: value.safetyTraining,
        // safetytraindate: value.safetyTrainingDate,
        // medicaltestdate: value.medicaltestdate,
        // safetytestdate: value.safetytestdate,
      },
      wageDetails: {
        basic: value.basic,
        da: value.da,
        hra: value.hra,
        allowance1: value.allowance1,
        allowance2: value.allowance2,
        vda: value.vda,
        conveyance: value.conveyance,
        splAllowance: value.splAllowance,
        medicalAllowance: value.medicalAllowance,
        educationAllowance: value.eduAllowance,
        leaveTravelAllowance: value.leaveTravelAllowance,
        effectiveDate: value.effectiveDate,
      },

      aadharDetails: aadharDetails,
      onboarding: 'quickEmployeeOnboarding',

      locationId: value.locationId,
      isFetchedFromAadhar,
      // livePhoto: imgSrc,
    };
    // if (personalDetails.profilePic) {
    //   const fileList =personalDetails.profilePic || [];
    //   if (fileList.length > 0) {
    //     data["profilePic"] = fileList[0].name;
    //     data["logoFileData"] = fileList[0].thumbUrl;
    //   } else {
    //     delete data["profilePic"];
    //   }
    // }

    createPass(data1)
      .then((res) => {
        notification.success({ message: 'Profile submitted successfully' });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      workmenName: value.firstName + ' ' + value.lastName,
      templateId: 'WORKMEN_ONBOARDED',
      orgId: userConfig.orgId,
      role: 'approver',
      level: 1,
      locationId: value.locationId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    navigate('/home/supervisor/employee');
  };
  const Sendotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/newSendOtp',
        `${process.env.REACT_APP_API_URL}/newSendOtp`,
        {
          // If running on different domains or ports in development, specify the full URL, e.g., "http://localhost:5000/api/getOkycOtp"
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            aadhaarNumber: adhar,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDisabledButton({ ...disabledButton, verifyOtp: false });

        const requestId = data.data.requestId;
        setRequest({ requestId });

        notification.success({
          message: 'OTP sent to registered mobile number',
        });
      } else {
        console.error('Failed to send OTP');
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
    }
  };
  const adharvalue = (event) => {
    setadhar(event.target.value);
  };
  const otpvalue = (event) => {
    setotp(event.target.value);
  };
  // const Verifyotp = () => {
  //   setotpDisable(true);

  //   setotploading(true);
  //   setTimeout(() => {
  //     setotploading(false);
  //   }, 3500);
  //   setDisable(true);

  //   VerifyOtp({
  //     otp: otp,
  //     aadhaarNo: adhar,
  //     requestId: Request.requestId,
  //     consent: 'Y',
  //   })
  //     .then((res) => {
  //       setIsViewOnly(true);
  //       const {
  //         responseObject1: {
  //           result: { dataFromAadhaar },
  //         },
  //       } = res?.data;
  //       setAadharDetails({
  //         maskedAadhaarNumber: dataFromAadhaar.maskedAadhaarNumber,
  //         generatedDateTime: dataFromAadhaar.generatedDateTime,
  //         requestId: res.data.responseObject1.requestId,
  //       });

  // const name = res.data.responseObject1.result.dataFromAadhaar.name;
  // const parts = name.split(' ');
  // const lastName = parts.pop();
  // const firstName = parts.join(' ');
  // const dob2 = res.data.responseObject1.result.dataFromAadhaar.dob;
  // const dob3 = dayjs(dob2, 'YYYY-MM-DD');

  // const reverse = dob2.split('-').reverse().join('-');
  // // const dob3 = dayjs(reverse, "DD-MM-YYYY");

  // var dob = new Date(dob2);
  // var month_diff = Date.now() - dob.getTime();
  // var age_dt = new Date(month_diff);
  // var year = age_dt.getUTCFullYear();
  // var age = Math.abs(year - 1970);
  // setdate1(reverse);

  // const mobile =
  //   res.data.responseObject1.result.dataFromAadhaar.mobileHash;

  // // const age=moment(dob, "YYYYMMDD").fromNow();
  // const fatherName =
  //   res.data.responseObject1.result.dataFromAadhaar.fatherName;
  // const profilePic =
  //   res.data.responseObject1.result.dataFromAadhaar.image;

  // const gender = res.data.responseObject1.result.dataFromAadhaar.gender;
  // const state =
  //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //     .state;
  // const district =
  //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //     .district;
  // const pincode =
  //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //     .pincode;
  // const village =
  //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //     .vtcName;
  // // const address =
  // //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
  // const address =
  //   res.data.responseObject1.result.dataFromAadhaar.address
  //     .combinedAddress;
  // const endNum = adhar.slice(-4);
  // const maskNum = endNum.padStart(adhar.length, '*');

  // setuidai({
  //   firstName,
  //   lastName,
  //   fatherName,
  //   gender,
  //   state,
  //   district,
  //   pincode,
  //   village,
  //   address,
  //   // dob,
  //   profilePic,
  // });

  // form.setFieldsValue({
  //   firstName: firstName,
  //   lastName: lastName,
  //   gender: gender,
  //   // aadharNo: maskNum,
  //   fatherName: fatherName,
  //   address: address,
  //   village: village,
  //   district: district,
  //   state: state,
  //   pincode: pincode,
  //   dob: dob3,
  //   age: age,
  // });

  //       setISFetchedFromAadhar(true);

  //       notification.success({ message: 'Fetching Details From UIDAI Server' });
  //     })
  //     .catch(() => {
  //       notification.error({
  //         message: 'UIDAI server issue! inconvenience is regretted',
  //       });
  //       setotpDisable(false);
  //       setDisable(false);
  //     });
  // };
  const Verifyotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/verifyOtp',
        `${process.env.REACT_APP_API_URL}/verifyOtp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ requestId: Request.requestId, otp: otp }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const name = data.data.full_name;
        const parts = name.split(' ');
        const lastName = parts.pop();
        const firstName = parts.join(' ');
        const dob2 = data.data.dob;
        const dob3 = dayjs(dob2, 'YYYY-MM-DD');

        const reverse = dob2.split('-').reverse().join('-');
        // const dob3 = dayjs(reverse, "DD-MM-YYYY");

        var dob = new Date(dob2);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);
        setdate1(reverse);

        // const mobile =
        //   res.data.responseObject1.result.dataFromAadhaar.mobileHash;

        // const age=moment(dob, "YYYYMMDD").fromNow();
        // const fatherName =
        //   res.data.responseObject1.result.dataFromAadhaar.fatherName;
        const profilePic = data.data.profile_image;

        const gender = data.data.gender;
        const state = data.data.address.state;
        const district = data.data.address.district;
        const pincode = data.data.zip;
        const village = data.data.address.po;
        const country = data.data.address.country;
        // const address =
        //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
        // const address =
        //   res.data.responseObject1.result.dataFromAadhaar.address
        //     .combinedAddress;
        const endNum = adhar.slice(-4);
        const maskNum = endNum.padStart(adhar.length, '*');

        setuidai({
          firstName,
          lastName,
          // fatherName,
          gender,
          state,
          district,
          pincode,
          village,
          // address,
          // dob,
          profilePic,
          country,
        });

        form.setFieldsValue({
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          // aadharNo: maskNum,
          // fatherName: fatherName,
          // address: address,
          village: village,
          district: district,
          state: state,
          pincode: pincode,
          dob: dob3,
          age: age,
          country: country,
        });
      } else {
        console.error('Failed to verify OTP');
        alert('Failed to verify OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
      // setOtp('');
    }
  };

  const consentreview = (event) => {
    setconsent(!consent);
  };

  const normfile1 = (e) => {};

  const states = State;
  const citi = {
    'Andhra Pradesh': AndhraPradesh,
    'Andaman and Nicobar Islands': AndhraPradesh,
    'Arunachal Pradesh': ArunachalPradesh,
    Assam: Assam,
    Bihar: Bihar,
    Karnataka: Karnataka,
    Chhattisgarh: Chhattisgarh,
    Chandigarh: Chandigarh,
    Dadra: Dadra,
    Delhi: Delhi,
    Goa: Goa,
    Gujarat: Gujarat,
    Haryana: Haryana,
    HimachalPradesh: HimachalPradesh,
    Jammu: Jammu,
    Jharkhand: Jharkhand,
    Kerala: Kerala,
    Lakshadweep: Lakshadweep,
    MadhyaPradesh: MadhyaPradesh,
    Maharashtra: Maharashtra,
    Manipur: Manipur,
    Meghalaya: Meghalaya,
    Mizoram: Mizoram,
    Nagaland: Nagaland,
    Odisha: Odisha,
    Punjab: Punjab,
    Puducherry: Puducherry,
    Rajasthan: Rajasthan,
    TamilNadu: TamilNadu,
    Telangana: Telangana,
    Tripura: Tripura,
    Uttarakhand: Uttarakhand,
    UttarPradesh: UttarPradesh,
    WestBengal: WestBengal,
  };
  const [selectedState, setSelectedState] = useState('');
  const [selectedstate, setSelectedstate] = useState('');

  //Live Capture
  //Live Capture
  const [visible, setVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    React.useState(false);
  const webcamRef = useRef(null);

  const toggleCamera = () => {
    setVisible(!visible);
    setIsCapturing(true);
  };

  const handleOk = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setVisible(false);
    setIsCapturing(true);
  };

  const handleCancel1 = () => {
    setIsCapturing(false);
    setVisible(false);
  };

  const validateInput = (_, value) => {
    const regex = /^[0-9]+$/; // Regular expression to match only numbers

    if (value && !regex.test(value)) {
      return Promise.reject('Only numbers are allowed');
    }

    if (value && (value.length < 1 || value.length > 6)) {
      return Promise.reject('Length must be between 1 and 6 characters');
    }

    return Promise.resolve();
  };
  const validateage = (_, value) => {
    const age = Number(value);

    if (age < 18 || age > 60) {
      return Promise.reject('Age must be between 18 and 60');
    }

    return Promise.resolve();
  };

  //add aadhar photo and live photo to database
  // const personalDetails.aadharPhoto = {
  //   uidai: {
  //     livePhoto: imageSrc,
  //   },
  //   aadharPhoto: "data:image/png;base64," + uidai.profilePic, // Add the image data here
  //   // Other personal details...
  // };

  const checkAadharUniqueness = async (data) => {
    try {
      setLoader(true);
      const res = await callCheckAadharUniquenessApi(data);
      setLoader(false);

      if (!res.data.isAadhaarAvailable) {
        setDisabledButton({ ...disabledButton, sendOtp: false });
        return true;
        // throw Error('Aadhar already exist.');
      }
      return false;
      //
    } catch (error) {
      return false;
    }
  };

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
  }, []);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        //
        setLoader(true);
        const contractorIdsAndJobOrderNumbers = res.data.map((item) => {
          if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
            return item.addJobDetails.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
              name: item.name,
            }));
          }
          return null;
        });

        const newArr = res.data.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item.id,
              name: item.name,
              jobOrderNumber: jobOrderNumber,
            };
          }
        });

        //

        setSeperatedVariables(newArr);

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        //

        // Get unique contractor IDs
        const uniqueContractorIds = [
          ...new Set(
            flattenedContractorIdsAndJobOrderNumbers.map((item) => item.id)
          ),
        ];

        //

        const filteredContractors =
          flattenedContractorIdsAndJobOrderNumbers.filter(
            (contractor) => contractor.id === userConfig?.supplierId
          );

        //

        const jobOrders = filteredContractors?.map(
          (contractor) => contractor?.jobOrderNumber
        );
        //

        const name = filteredContractors?.map((contractor) => contractor?.name);
        //
        const uniqueNames = [...new Set(name)];
        //
        setContractorName(uniqueNames);
        setJobOrderList(jobOrders);
        setLoader(false);

        // // Get unique contractor IDs
        // const uniqueContractorIds = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.id))];

        // // Get unique job order numbers
        // const uniqueJobOrderNumbers = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.jobOrderNumber))];

        // const uniqueContractorNames = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.name))];

        // // Set state variables with unique IDs
        //
        //
        //
        // setContractorList(uniqueContractorIds);
        // setJobOrderList(uniqueJobOrderNumbers);
        // setContractorNameList(uniqueContractorNames)
        // // setData(res.data);
        // setMaxContractorLimit(res.data.maxContractors)
        // setlln(res.data.lln)

        // Filter job order numbers based on the selected contractor code
        // if (res && res.data) {
        // const filteredJobOrderNumbers = res.data
        //   .flatMap(item => item.addJobDetails)
        //   .filter(details => details?.contractorCode === value)
        //   .map(details => details?.jobOrderNumber)
        //   .filter(number => number !== undefined);
        //
        // // Set the filtered job order numbers to the state variable
        // setJobOrderList(filteredJobOrderNumbers);
        // }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleJobOrderChange = async (value) => {
    setSelectedJobOrder(value);
    //
    const jobId = value;
    const contractorCode = userConfig?.supplierId;

    jobOrderCount({ jobId, contractorCode })
      .then((res) => {
        // Make a request to the backend to get information from MongoDB

        const { maxContractors, jobCount } = res.data;

        // Check if jobCount exceeds the limit
        if (jobCount == maxContractors - 1) {
          notification.error({
            message: 'Limit Reached',
            description: 'Adding one more contractor would exceed the limit.',
          });

          // Set isNextButtonDisabled to true to disable the "Next" button
          //  setIsNextButtonDisabled(false);
        } else {
          // Display a notification if jobCount is greater than maxContractors
          if (jobCount > maxContractors - 1) {
            notification.info({
              message: 'Notice',
              description:
                'The number of contractors is more than the permitted limit.',
            });
            // If the limit is not reached, you might reset the state to enable the button
            setIsSubmitButtonDisabled(true);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  //   const handleContractorNameChange = (value) => {
  //
  //     const id = separatedVariables.find((item)=>{
  //        return item?.name === value
  //     })
  //
  //     // setSelectedContractorCode(id.id)
  //     // setContractorList([id.id]);

  //     // setJobOrderList(id.jobOrderNumber)

  // }

  // const handleContractorCodeChange = (value) => {
  //   setSelectedContractorCode(value);

  // }

  //     setJobOrderList(id.jobOrderNumber);
  //   };

  const handleContractorCodeChange = (value) => {
    setSelectedContractorCode(value);
  };

  // const handleContractorCodeChange = (value) => {
  //   setSelectedContractorCode(value);

  // }

  //

  return (
    <>
      <Form
        layout='vertical'
        onFinish={onFinish}
        initialValues={personalDetails}
        form={form}>
        <Title
          level={4}
          style={{
            textAlign: 'center',

            fontWeight: 'bolder',
          }}>
          Quick Employee Onboarding
        </Title>

        <Collapse style={{ fontWeight: '' }}>
          <Panel header='Aadhaar Details' key='1'>
            <Row>
              <Checkbox onClick={consentreview}>
                I hereby, at my own discretion, voluntarily submit the physical
                copy of my Aadhaar card / physical e-Aadhaar / masked Aadhaar /
                offline electronic Aadhaar / copies of my Aadhaar card in
                physical or electronic form or xml as issued by UIDAI (Aadhaar),
                for the purpose of establishing my identity / age / address
                proof and voluntarily give my consent for the purpose of
                employment-related verification. I understand that I have the
                right to withdraw this consent at any point in time and that I
                have the right to complain to the Data Protection Board of India
                for any breach of my personal identity.
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              {/* <Col
                span={12}
                style={{
                  borderRight: '2px solid black',
                  paddingRight: '26px',
                }}>
                <Form.Item hidden={consent}>
                  <div className='App' style={{ alignItems: 'start' }}>
                    <Button
                      className='glow-on-hover'
                      style={{
                        borderRadius: '10px',
                        type: 'primary',
                        color: 'white',
                        height: '40px',
                        justifyContent: 'end',
                        fontWeight: 'bolder',
                      }}
                      onClick={() => {
                        setStartScan(!startScan);
                      }}
                      type='primary'>
                      {startScan
                        ? 'Scan QR Code Stop '
                        : 'Scan QR Code from Aadhaar '}
                    </Button>

                    {startScan && (
                      <>
                        <QrReader
                          facingMode={selected}
                          delay={2000}
                          onError={handleError}
                          onScan={handleScan}
                          chooseDeviceId={() => selected}
                          style={{ width: '250px' }}
                        />
                      </>
                    )}
                  </div>
                </Form.Item>{' '}
              </Col> */}

              <Col span={12} style={{ paddingLeft: '40px' }}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name='aadharNo'
                      hidden={consent}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!/^[0-9]{12}$/.test(value)) {
                              return Promise.reject('Enter Valid Aadhaar No.');
                              // throw new Error('Enter Valid Aadhar No.');
                              // return 0;
                            }
                            if (
                              value.length === 12 &&
                              !(isFetchedFromAadhar || isAadharChecked)
                            ) {
                              const isUnique = await checkAadharUniqueness(
                                value
                              );

                              if (!isUnique) {
                                return Promise.reject('Aadhaar already exist.');
                              }
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter Aadhaar Number'
                        value={adhar}
                        onChange={adharvalue}
                      />
                    </Form.Item>
                  </Col>
                  {!disabledButton.sendOtp && (
                    <Col span={12}>
                      <Form.Item hidden={consent}>
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          onClick={Sendotp}
                          disabled={disable}
                          type='primary'>
                          Send OTP
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {!disabledButton.verifyOtp && (
                  <Row style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Form.Item
                        name='otp'
                        hidden={consent}
                        rules={[
                          {
                            pattern: /^\d{6}$/,
                            message: 'Six Digit Required',
                          },
                        ]}>
                        <Input
                          size='large'
                          placeholder='Enter OTP'
                          value={otp}
                          onChange={otpvalue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='Verify Otp' hidden={consent}>
                        {/* <div className="App" > */}
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          type='primary'
                          onClick={Verifyotp}
                          disabled={otpdisable}>
                          Verify OTP
                        </Button>
                        {/* </div> */}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Divider />

        <Title level={4} className='qot' style={{ color: '#808000' }}>
          Personal Details
        </Title>
        {/* <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={personalDetails}
        form={form}
      > */}
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='firstName'
              label='First Name'
              rules={[
                { required: true, message: 'Enter First Name ' },
                {
                  pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                {
                  min: 3,
                  message: 'Minimum length must be 3 characters',
                },
                {
                  max: 30,
                  message: 'Maximum length allowed is 30 characters',
                },
              ]}>
              <Input
                size='large'
                disabled={isFetchedFromAadhar}
                // disabled={users.firstName === true ? false : true}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lastName'
              label='Last Name'
              rules={[
                { required: true, message: 'Enter  LastName' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                {
                  min: 3,
                  message: 'Minimum length must be 3 characters',
                },
                {
                  max: 30,
                  message: 'Maximum length allowed is 30 characters',
                },
              ]}>
              <Input size='large' disabled={isFetchedFromAadhar} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='gender'
              label='Gender'
              rules={[{ required: true, message: 'Enter Gender' }]}>
              <Select size='large' disabled={isFetchedFromAadhar}>
                <Option value='male'>Male</Option>
                <Option value='female'>Female</Option>
                <Option value='other'>Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='age'
              label='Age'
              rules={[
                { required: true, message: 'Enter Age' },
                { pattern: /^[0-9]*$/, message: 'Age must be numeric' },
                { validator: validateage },
              ]}>
              <Input size='large' disabled={isFetchedFromAadhar} />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='mobile'
              label='Mobile Number'
              rules={[
                {
                  required: true,
                  message: 'Enter Mobile Number',
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'Enter Valid Mobile No.',
                },
              ]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='alternateMobile'
              label='Alternate Contact Number'
              rules={[
                {
                  required: true,
                  message: 'Enter Alternate Contact Number',
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'Enter Valid Mobile No.',
                },
                // {
                //   min: 10,
                //   max: 10,
                //   message: "Mobile number should be 10 digits",
                // },
              ]}>
              <Input size='large' />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='aadharNo'
              label='Aadhaar Number'
              rules={[
                {
                  required: true,
                  message: 'Aadhaar Number is Required',
                },
                {
                  validator: async (_, value) => {
                    if (!/^[0-9]{12}$/.test(value)) {
                      return Promise.reject('Enter Valid Aadhaar No.');
                      // throw new Error('Enter Valid Aadhar No.');s
                      // return 0;
                    }
                    if (
                      value.length === 12 &&
                      !(isFetchedFromAadhar || isAadharChecked)
                    ) {
                      const isUnique = await checkAadharUniqueness(value);

                      if (!isUnique) {
                        return Promise.reject('Aadhaar already exist.');
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input size='large' disabled={isFetchedFromAadhar} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='fatherName'
              label="Father / Guardian's Name"
              rules={[
                // { required: true, message: "Enter Father/Gardian Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
                {
                  min: 3,
                  message: 'Minimum length must be 3 characters',
                },
                {
                  max: 30,
                  message: 'Maximum length allowed is 30 characters',
                },
              ]}>
              <Input
                size='large'
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              rules={[{ required: true, message: 'Enter DOB' }]}
              name='dob'
              label='DOB (As Per Aadhaar)'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DOB (As Per Aadhaar)'
                format='DD-MM-YYYY'
                disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            span={6}
            // hidden={users.emergencyContactName === true ? false : true}
          >
            <Form.Item
              name='emergencyContactName'
              label='Emergency Contact Name'
              rules={[
                { required: true, message: 'Enter Father/Gardian Name' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
                {
                  min: 3,
                  message: 'Minimum length must be 3 characters',
                },
                {
                  max: 30,
                  message: 'Maximum length allowed is 30 characters',
                },
              ]}>
              <Input size='large' />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            span={6}
            // hidden={users.emergencyContactNo === true ? false : true}
          >
            <Form.Item
              name='emergencyContactNo'
              label='Emergency Contact Number'
              // hidden={true}
              rules={[
                { required: true, message: 'Enter Emergency Contact Number' },
                {
                  min: 10,
                  max: 10,
                  message: 'Mobile number should be 10 digits',
                },
              ]}>
              <Input size='large' />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            span={6}
            hidden={users.idMark === true ? false : true}>
            <Form.Item
              name='idMark'
              label={
                <Space>
                  <span>Id Mark</span>
                  <Tooltip
                    title='Any physical mark on the body such as birth mark'
                    placement='top'>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }>
              <Input size='large' />
            </Form.Item>
          </Col>

          {/* <Col className="gutter-row" span={6}               hidden={users.profilePic === true ? false : true}   
>
            <Form.Item
              label="Profile Photo"
              // name="profilePic"
              valuePropName="fileList"
              getValueFromEvent={normFile}
                       >
              <Upload
                // type="file"
                listType="picture"
                accept="image/*"
                onPreview={handlePreview}
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button
                  icon={<UploadOutlined />}
                  size="large"
                >
                  Upload Photo
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
            <img
              src={"data:image/png;base64," + uidai.profilePic}
              width="60px"
            />
          </Col> */}
          <Col span={6}>
            <Form.Item label='Live Capture' name='livePhoto'>
              <Button size='large' onClick={toggleCamera}>
                {imageSrc ? 'Retake Photo' : 'Capture Photo'}
              </Button>
              {imageSrc && (
                <Image src={imageSrc} alt='Captured photo' preview={true} />
              )}
              <Modal
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel1}
                style={{ width: '150' }}>
                {isCapturing && (
                  <Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    width={200}
                  />
                )}
              </Modal>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='aadharPhoto' label='Aadhaar Photo'>
              <img
                src={'data:image/png;base64,' + uidai.profilePic}
                width='60px'
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider></Divider>
        <Title level={5}>Aadhaar Address </Title>
        <Row gutter={16}>
          <Col
            className='gutter-row'
            span={24}
            // hidden={users.address === true ? false : true}
          >
            <Form.Item
              name='address'
              label='Address'
              rules={[
                { required: true, message: 'Enter Address!' },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 300,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                id='address'
                size='large'
                onChange={onChange}
                disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.address === true ? false : true}
          >
            <Form.Item
              name='village'
              label='Village/Mandal'
              rules={[
                { required: true, message: 'Enter Village/Mandal' },
                // {
                //   pattern: /^[A-Za-z\s]*$/,
                //   message: "Only Alphabets Allowed",
                // },
              ]}>
              <Input
                size='large'
                id='village'
                onChange={onChange}
                disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6} id='state'>
            <Form.Item
              name='state'
              label='State'
              rules={[{ required: true, message: 'Enter State' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  setSelectedState(e);
                }}
                size='large'
                className='ant-select-selector'
                placeholder='Select State'
                disabled={isFetchedFromAadhar}>
                {states.map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6} id='district'>
            <Form.Item
              name='district'
              label='District'
              rules={[{ required: true, message: 'Enter District' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size='large'
                disabled={isFetchedFromAadhar}>
                {citi[selectedState]?.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            span={6}
            // hidden={users.address === true ? false : true}
          >
            <Form.Item
              name='pincode'
              label='Pincode'
              rules={[
                { required: true, message: 'Enter Pincode' },
                {
                  pattern: '^[1-9][0-9]{5}$',
                  message: 'Invalid Pincode',
                },
              ]}>
              <Input size='large' id='pincode' disabled={isFetchedFromAadhar} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='taluka'
              label='Taluka'
              rules={[
                { required: true, message: 'Enter Taluka' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='taluka'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='tehsil'
              label='Tehsil'
              rules={[
                { required: true, message: 'Enter Tehsil' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='tehsil'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='city'
              label='City'
              rules={[
                { required: true, message: 'Enter City' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='city'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='country'
              label='Country'
              rules={[
                { required: true, message: 'Enter Country' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='country'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            className='gutter-row'
            span={24}
            id='homepostalcheck'
            // hidden={users.paddress === true ? false : true}
          >
            <Form.Item>
              <input
                type='checkbox'
                id='same'
                name='same'
                size='large'
                style={{ fontWeight: 'bold' }}
                onClick={addressFunction}
              />
              <label
                htmlFor='same'
                style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                Same As Above
              </label>
            </Form.Item>
          </Col>

          <br />
          <Title
            level={5}
            style={{ marginTop: '30px' }}
            // hidden={users.paddress === true ? false : true}
          >
            Present Address
          </Title>
          <Col
            className='gutter-row'
            span={24}
            // hidden={users.paddress === true ? false : true}
          >
            <Form.Item
              name='paddress'
              label='Address'
              rules={[
                { required: true, message: 'Enter Address' },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 300,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input size='large' id='paddress' name='paddress' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.paddress === true ? false : true}
          >
            <Form.Item
              name='pvillage'
              label='Village/Mandal'
              rules={[
                { required: true, message: 'Enter Village/Mandal' },
                // {
                //   pattern: "^[a-zA-Z]*$",
                //   message: "Only Alphabets Allowed",
                // },
              ]}>
              <Input size='large' id='pvillage' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6} id='pstate'>
            <Form.Item
              name='pstate'
              label='State'
              rules={[{ required: true, message: 'Enter State' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  setSelectedState(e);
                }}
                size='large'
                className='ant-select-selector'
                placeholder='Select State'>
                {states.map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6} id='pdistrict'>
            <Form.Item
              name='pdistrict'
              label='District'
              rules={[{ required: true, message: 'Enter District' }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size='large'>
                {citi[selectedState]?.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.paddress === true ? false : true}
          >
            <Form.Item
              name='ppincode'
              label='Pincode'
              rules={[
                { required: true, message: 'Enter Pincode' },
                // {
                //   pattern: "^[1-9][0-9]{5}$",
                //   message: "Invalid Pincode",
                // },
              ]}>
              <Input size='large' id='ppincode' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='ptaluka'
              label='Taluka'
              rules={[
                { required: true, message: 'Enter Taluka' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='ptaluka'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='ptehsil'
              label='Tehsil'
              rules={[
                { required: true, message: 'Enter Tehsil' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='ptehsil'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='pcity'
              label='City'
              rules={[
                { required: true, message: 'Enter City' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='pcity'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='pcountry'
              label='Country'
              rules={[
                { required: true, message: 'Enter Country' },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabets Allowed',
                },
              ]}>
              <Input
                size='large'
                name='pcountry'
                onChange={onChange}
                // disabled={isFetchedFromAadhar}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider></Divider>
        <Title level={4} style={{ color: '#808000' }}>
          Professional details
        </Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter Location' }]}>
              <Select size='large'>
                {location.Response2?.slice(1).map((option) => (
                  <Option value={option.id}>{option.id}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='empCode'
              label={
                <Space>
                  Workmen Code
                  <span></span>
                  <Tooltip
                    title='Generated by the Principal Employer'
                    placement='top'>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[
                { required: true, message: 'Enter Worker Code' },
                {
                  pattern: /^[a-zA-Z0-9]+$/,
                  message: 'Enter Alpha Numeric Only',
                },
              ]}>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='epnNumber'
              label='EPN Number'
              rules={[{ required: true, message: 'Enter EPN Number' }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.esicno === true ? false : true}>
            <Form.Item
              name='esicno'
              label='ESI Number'
              rules={[
                {
                  pattern:
                    '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$',
                  message: 'Invalid ESIC number',
                },
              ]}>
              <Input size='large' placeholder='Ex: 41-00-123456-000-0001' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.uan === true ? false : true}>
            <Form.Item
              name='uan'
              label='UAN'
              rules={[
                {
                  min: 12,
                  max: 12,
                  message: 'UAN number should be 12 digits',
                },
              ]}>
              <Input size='large' placeholder='Ex: 123456789012' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.pfAccountNo === true ? false : true}>
            <Form.Item
              name='pfAccountNo'
              label='PF Account Number'
              rules={[
                {
                  pattern: '[A-Z]{5}[0-9]{17}',
                  message: 'Invalid PF Account number ',
                },
              ]}>
              <Input size='large' placeholder='Ex: BGBNG24718350000010018' />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <Title level={4} style={{ color: '#808000' }}>
          Job Details
        </Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='employerCode' label='Principal Employer Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig.orgId}
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='contractorCode' label='Contractor Code'>
        <Select
          size='large'
          onChange={handleContractorCodeChange}
          // disabled={true}
          defaultValue={selectedContractorCode}
        >
          {contractorList.map((code) => (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
            <Form.Item name='contractorCode' label='Contractor Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig?.supplierId}
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='contractorName' label='Contractor Name'>
              <Select
                size='large'
                // onChange={handleContractorNameChange}
                // disabled={true}
                value={contractorName}>
                {contractorName.map((code) => (
                  <Select.Option key={code} value={code}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='jobOrderNumber' label='Job Order Number'>
              <Select
                size='large'
                onChange={(value) => handleJobOrderChange(value)}>
                {jobOrderList.map((number) => (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.departmentCode === true ? false : true}>
            <Form.Item name='departmentCode' label='Department Code'>
              <Select
                size='large'
                placeholder='Department Code'
                // disabled={users.departmentCode === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='HR'>HR</Option>
                <Option value='FINANCE'>FINANCE</Option>
                <Option value='ACCOUNTS'>ACCOUNTS</Option>
                <Option value='LEGAL'>LEGAL</Option>
                <Option value='MANUFACTURING'>MANUFACTURING</Option>
                <Option value='DELIVERY'>DELIVERY</Option>
                <Option value='OPERATIONS'>OPERATIONS</Option>
                <Option value='STORES'>STORES</Option>
                <Option value='ADMIN'>ADMIN</Option>
                <Option value='MEDICAL'>MEDICAL</Option>
                <Option value='SECURITY'>SECURITY</Option>
                <Option value='IT'>IT</Option>
                <Option value='PROCUREMENT'>PROCUREMENT</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.sectionCode === true ? false : true}>
            <Form.Item name='sectionCode' label='Section Code'>
              <Select
                size='large'
                placeholder='Section Code'
                // disabled={users.sectionCode === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='HR001'>HR001</Option>
                <Option value='FIN001'>FIN001</Option>
                <Option value='ACC001'>ACC001</Option>
                <Option value='LEG001X'>LEG001X</Option>
                <Option value='MFG001'>MFG001</Option>
                <Option value='DEL001'>DEL001</Option>
                <Option value='OPS001'>OPS001</Option>
                <Option value='STR001'>STR001</Option>
                <Option value='ADM001'>ADM001</Option>
                <Option value='MED001'>MED001</Option>
                <Option value='SEC001'>SEC001</Option>
                <Option value='IT001'>IT001</Option>
                <Option value='PRC001'>PRC001</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            span={6}
            hidden={users.workorderCode === true ? false : true}>
            <Form.Item name='workorderCode' label='Work Order Number'>
              <Select
                size='large'
                placeholder='WorkOrder Code'
                // disabled={users.workorderCode === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='JM-LMG-IT-47839'>JM-LMG-IT-47839</Option>
                <Option value='FT-DRS-OU-97563'>FT-DRS-OU-97563</Option>
                <Option value='AJ-MXV-HG-42371A'>AJ-MXV-HG-42371A</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.itemserviceCode === true ? false : true}>
            <Form.Item name='itemserviceCode' label='Item Service Code'>
              <Select
                size='large'
                placeholder='Item Service Code'
                // disabled={users.itemserviceCode === true ? false : true}
              >
                <Option value=''></Option>
                <Option value='975632'>975632</Option>
                <Option value='991106'>991106</Option>
                <Option value='850219'>850219</Option>{' '}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.employeeType === true ? false : true}>
            <Form.Item
              name='employeeType'
              label='Employee Type'
              hidden={users.employeeType === true ? false : true}>
              <Select size='large' placeholder='Employee Type'>
                <Option value=''></Option>

                <Option value='REGULAR'>REGULAR</Option>
                <Option value='CONTRACT'>CONTRACT</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.labourType === true ? false : true}>
            <Form.Item name='labourType' label='Labour Code'>
              <Select
                size='large'
                placeholder='Labour Code'
                // disabled={users.labourType === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='Monthly'>Monthly</Option>
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.jobCode === true ? false : true}>
            <Form.Item name='jobCode' label='Job Code'>
              <Select
                size='large'
                placeholder='Job Code'
                // disabled={users.jobCode === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='WSC-923'>WSC-923</Option>
                <Option value='ITS-321'>ITS-321</Option>
                <Option value='FIN-003'>FIN-003</Option>
                <Option value='ADM-411'>ADM-411</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.skillCode === true ? false : true}>
            <Form.Item name='skillCode' label='Skill Code'>
              <Select
                size='large'
                placeholder='Skill Code'
                // disabled={users.skillCode === true ? false : true}
              >
                <Option value=''></Option>
                <Option value='Unskilled'>Unskilled</Option>
                <Option value='Semi-skilled'>Semi-skilled</Option>
                <Option value='Highly-skilled'>Highly-skilled</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.contractorReferenceNumber === true ? false : true}>
            <Form.Item
              name='contractorReferenceNumber'
              label={
                <Space>
                  Contractor Reference Number
                  <span></span>
                  <Tooltip
                    title='Generated by Contractor Organisation'
                    placement='top'>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.doj === true ? false : true}
          >
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              // hasFeedback
              // valuePropName="date"
              // getValueFromEvent={normfile1}
            >
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                // disabled={users.doj === true ? false : true}
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.shoeSize === true ? false : true}>
            <Form.Item name='shoeSize' label='Shoe Size'>
              <Select
                size='large'
                placeholder='Shoe Size'
                // disabled={users.shoeSize === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='5'>5 in</Option>
                <Option value='6'>6 in</Option>
                <Option value='7'>7 in</Option>
                <Option value='8'>8 in</Option>
                <Option value='9'>9 in</Option>
                <Option value='10'>10 in</Option>
                <Option value='11'>11 in</Option>
                <Option value='12'>12 in</Option>
              </Select>
            </Form.Item>{' '}
          </Col>

          <Col
            className='gutter-row'
            span={6}
            hidden={users.bloodGroup === true ? false : true}>
            <Form.Item
              name='bloodGroup'
              label='Blood Group'
              // rules={[{ required: true, message: "Enter Employee Code" }]}
              // hasFeedback
            >
              <Select
                size='large'
                placeholder='Blood Group'
                // disabled={users.bloodgroup === true ? false : true}
              >
                <Option value=''></Option>

                <Option value='O+'>O+</Option>
                <Option value='O-'>O-</Option>
                <Option value='A+'>A+</Option>
                <Option value='A-'>A-</Option>
                <Option value='B+'>B+</Option>
                <Option value='B-'>B-</Option>
                <Option value='AB+'>AB+</Option>
                <Option value='AB-'>AB-</Option>
              </Select>
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.academicQualification === true ? false : true}>
            <Form.Item
              name='academicQualification'
              label='Academic Qualification'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.techQualification === true ? false : true}>
            <Form.Item name='techQualification' label='Technical Qualification'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.dl === true ? false : true}>
            <Form.Item name='dl' label='Driving Licence'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.dld === true ? false : true}>
            <Form.Item name='dlExpiry' label='Driving Licence Validity Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
              />
            </Form.Item>{' '}
          </Col>
          <Divider hidden={users.terminationstatus === true ? false : true}>
            Termination Status
          </Divider>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.terminationstatus === true ? false : true}>
            <Form.Item name='dateOfTermination' label='Date Of Termination'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD/MM/YYYY'
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.terminationstatus === true ? false : true}>
            <Form.Item name='terminationReason' label='Reason For Termination'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Divider hidden={users.workexperience === true ? false : true}>
            Work Experience
          </Divider>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.workexperience === true ? false : true}>
            <Form.Item name='totalExperience' label='Total Experience'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.workexperience === true ? false : true}>
            <Form.Item name='previousOrg' label='Previous Organization'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.workexperience === true ? false : true}>
            <Form.Item name='referredBy' label='Refered By'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Divider hidden={users.relativeworking === true ? false : true}>
            Relative Working In Company
          </Divider>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.relativeworking === true ? false : true}>
            <Form.Item name='name' label='Name'>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.relativeworking === true ? false : true}>
            <Form.Item
              name='mobileNo'
              label='Mobile Number'
              rules={[
                {
                  min: 10,
                  max: 10,
                  message: 'Mobile number should be 10 digits',
                },
              ]}>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Divider hidden={users.bankdetails === true ? false : true}>
            Bank Details
          </Divider>
          {/* <Title level={4} hidden={users.bankdetails === true ? false : true} gutter={24}>Bank Details</Title> */}
          <Col
            className='gutter-row'
            span={6}
            hidden={users.bankdetails === true ? false : true}>
            <Form.Item
              name='bankname'
              label='Bank Name'
              rules={[
                // { required: true, message: "Enter Bank Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                {
                  min: 1,
                  max: 100,
                  message: 'Maximum 100 characters',
                },
              ]}
              // hasFeedback
            >
              <Input size='large' />
            </Form.Item>
            {''}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.bankdetails === true ? false : true}
            gutter={24}>
            <Form.Item
              name='AccountNo'
              label='Bank Account Number'
              rules={[
                // { required: true, message: "Enter Bank Account Number" },
                {
                  min: 1,
                  max: 20,
                  message: 'Account number should be less than 20 characters',
                },
              ]}
              // hasFeedback
            >
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.bankdetails === true ? false : true}
            gutter={24}>
            <Form.Item
              name='ifsc'
              label='IFSC Code'
              rules={[
                // { required: true, message: "Enter IFSC Code" },
                {
                  pattern: /^[A-Za-z]{4}\d{7}$/,
                  message: 'Enter Valid IFSC Code',
                },
              ]}
              // hasFeedback
            >
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.bankdetails === true ? false : true}
            gutter={24}>
            <Form.Item
              name='userName'
              label='Name As Per Bank Account'
              rules={[
                // { required: true, message: "Enter Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                {
                  min: 1,
                  max: 100,
                  message: ' Maximum 100 characters allowed',
                },
              ]}
              hasFeedback>
              <Input size='large' />
            </Form.Item>{' '}
          </Col>
        </Row>
        {/* <Col className="gutter-row" span={6}>
            <Form.Item name="medicalcheckupdone" label="Medical Checkup Done">
              <Input
                size="large"
                disabled={users.medicalcheckupdone === true ? false : true}
              />
            </Form.Item>{" "}
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item name="medicalcheckupdate" label="Medical Checkup Date">
            <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="Medical Checkup Date"
                disabled={users.medicaltestdate === true ? false : true}
              />
            </Form.Item>{" "}
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item name="safetytraindone" label="Safety Training Done">
              <Input
                size="large"
                disabled={users.safetytraindone === true ? false : true}
              />
            </Form.Item>{" "}
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item name="safetytraindate" label="Safety Training Date">
            <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="Safety Training Date"
                disabled={users.medicaltestdate === true ? false : true}
              />
            </Form.Item>{" "}
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item name="skillCertification" label="Skill Certificate Done">
              <Input size="large"                 disabled={users.medicaltestdate === true ? false : true}
 />
            </Form.Item>{" "}
          </Col> 
         <Col className="gutter-row" span={6}>
            <Form.Item name="skillCertificationDate" label="Skill Certificate Date">
            <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="Skill Certificate Done"
                disabled={users.medicaltestdate === true ? false : true}
              />
            </Form.Item>{" "}
          </Col> */}
        {/* <Col className="gutter-row" span={6}>
            <Form.Item name="safetytestdate" label="Safety Test date">
            <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="safety Test Date"
                disabled={users.medicaltestdate === true ? false : true}
              />
            </Form.Item>{" "}
          </Col>  */}
        <Divider />
        <Title level={4} style={{ color: '#808000' }}>
          Wage Details
        </Title>
        <Row gutter={16}>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.basic === true ? false : true}
          >
            <Form.Item
              name='basic'
              label='Basic'
              rules={[
                { required: true, message: 'Enter BASIC' },

                { validator: validateInput },
              ]}>
              <Input
                size='large'
                // disabled={users.basic === true ? false : true}
              />
            </Form.Item>{' '}
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.da === true ? false : true}
          >
            <Form.Item
              name='da'
              label='DA'
              rules={[
                { required: true, message: 'Enter BASIC' },

                { validator: validateInput },
              ]}
              hasFeedback>
              <Input size='large' />
            </Form.Item>
          </Col>{' '}
          <Col
            className='gutter-row'
            span={6}
            hidden={users.allowance1 === true ? false : true}>
            <Form.Item
              name='allowance1'
              label='Allowance-1'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.allowance2 === true ? false : true}>
            <Form.Item
              name='allowance2'
              label='Allowance-2'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.hra === true ? false : true}>
            <Form.Item
              name='hra'
              label='HRA'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.conveyance === true ? false : true}>
            <Form.Item
              name='conveyance'
              label='Conveyance'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.specialAllowance === true ? false : true}>
            <Form.Item
              name='specialAllowance'
              label='Special Allowance'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.medicalAllowance === true ? false : true}>
            <Form.Item
              name='medicalAllowance'
              label='Medical Allowance'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.eduAllowance === true ? false : true}>
            <Form.Item
              name='eduAllowance'
              label='Education Allowance'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.travelAllowance === true ? false : true}>
            <Form.Item
              name='travelAllowance'
              label='Leave/Travel Allowance'
              rules={[{ validator: validateInput }]}>
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            hidden={users.ot === true ? false : true}>
            <Form.Item name='ot' label='OT Applicable'>
              <Select size='large' placeholder='OT'>
                <Option value=''></Option>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={users.effectiveDate === true ? false : true}
          >
            <Form.Item
              name='effectiveDate'
              // getValueFromEvent={agecalculated}
              label='Wage Effective Date'
              rules={[{ required: true, message: 'Enter Effective Date' }]}>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Divider />
            <Title level={4} style={{ color: "#808000" }}>
              Upload Document
            </Title>
            <Col xs={24} lg={12}>
              <Form.Item
                name="logoFile"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Upload Document" }]}
              >
                <Upload
                  listType="picture"
                  accept="image/*"
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    name="uploaddocument"
                    style={{
                      width: "300px",
                      height: "40px",
                      fontWeight: "bold",
                    }}
                  >
                    Upload Document
                  </Button>
                </Upload>
              </Form.Item>
            </Col> */}
        <Divider />
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isSubmitButtonDisabled}>
            Submit
          </Button>
        </Form.Item>
      </Form>

      {/* <Divider></Divider>
      <Collapse>
        <Panel header="Edited Things" style={{fontWeight:'bolder'}}>Helloworld</Panel>
      </Collapse>
      <Divider></Divider>
      <Collapse>
        <Panel header="Setting" style={{fontWeight:'bolder'}}></Panel>
      </Collapse>
      <Divider></Divider>
      <Collapse>
        <Panel header="Onboarding" style={{fontWeight:'bolder'}}></Panel>
      </Collapse> */}
    </>
  );
};

export default Quickonboarding;
