import axios from "./axiosInstance";

export const getSupplier = () => {
  return axios.get("/supplier");
};

export const replaceSupplier = (data) => {
  return axios.put("/replaceSupplier", data);
};

export const getSupplierByLocationAndContractValidity = (data) => {
  return axios.get("/supplierByLocationAndContractValidity", { params: data });
};