import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Modal,
  Col,
  Row,
  Select,
  Button,
  Upload,
  Checkbox,
  notification,
  Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AddContractors } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import axios1 from '../../services/axiosInstance';
import { updatecontractor } from '../../services/auth';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { State } from '../../JSON/data';
import { supplierGst, supplierPan } from '../../services/auth';
import UploadDocuments from './UploadDocuments';
import { verifyToken } from '../../utils/jwt';
import * as userService from '../../services/auth'
// import { Editcontractor } from '../services/auth';
// import createOrg from '../../services/organisation';
import {
  userCreate,
  Email,
  contractorCreate,
  contractorUpdate,
  getExcelData,
} from '../../services/auth';
// import { updatesupplier } from '../../../../aadhar-app-api/services/supplier.service';
import { chechUniqueContractorCodeApi } from '../../services/auth';
import { chechUniqueUserIdApi } from '../../services/auth';
import { getWorkOrderDetailsData } from "../../cwfm/services/actions/api/workOrderDetails";

const { Option } = Select;

export default function AddContractor({
  onOk,

  open,
  onCancel,
  isEdit = false,
  initialValues = [],
  locationsData,
}) {
  const formRef = React.useRef();
  const [PfDisabled, setPfDisabled] = React.useState(true);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  const [location, setlocation] = React.useState({});
  const [natureOfWork, setnatureOfWork] = React.useState([]);
  const [selectedNature, setSelectedNature] = useState(null);
  const [masterData, setMasterData] = React.useState([]);
  const [locationIdData, setLocationIdData] = useState('');
  const [documents, setDocuments] = useState(
    initialValues?.uploadDocument || []
  );
  const [rejectReason, setRejectReason] = useState("")
  const [contractorNames, setContractorNames] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [lisence, setLisence] = useState()
  const [contractorCodes, setContractorCodes] = useState([]);

  const [vendorCode, setVendorCode] = useState("");
  const [selectedContractorName, setSelectedContractorName] = useState("");
  console.log('documents61', documents);
  console.log('initial values62', initialValues?.uploadDocument);
  const [allDocuments, setAllDocuments] = useState([]);
  console.log('allDocuments63', allDocuments);
  const [proprietorship, setProprietorship] = useState(false);
  useEffect(() => {
    console.log(locationsData, 'loc');
    console.log(userConfig, 'userconfig');
    setPermanentSameAsLocalAddress(initialValues?.permanentAddressSameCheck);
    setCorrespondingSameAsLocalAddress(
      initialValues?.correspondingAddressSameCheck
    );
    if (initialValues?.proprietorship === 'Yes') {
      setProprietorship(true);
    } else {
      setProprietorship(false);
    }
  }, [initialValues]);

  const { _id } = useParams();

  React.useEffect(() => {
    console.log(initialValues, 'initialValues');
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
    setEmploymentStatus(initialValues?.statusToBeImposed);
    setDocuments(initialValues?.uploadDocument);
    setIsPfApplyDateDisabled(
      initialValues.pfEstablishmentSubCode || initialValues.pfAccountNo
    );
    if (initialValues.pfEstablishmentSubCode || initialValues.pfAccountNo) {
      formRef.current?.setFieldValue('pfApplyDate', '');
    }
  }, [initialValues]);
  async function EmployeeList() {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response?.data?.org?.locations[0]?.name;

    setlocation({ Response2 });
  }
  const navigate = useNavigate();
  const { user, userConfig } = useAuth();
  const isDisabled = userConfig.role == 'approver';

  const getUserInfo = () => {
    userService
      .info()
      .then(async (res) => {
        const decoded = await verifyToken(
          res?.data?.organisation?.productLicenseKey || ''
        )
        setLisence(decoded)
      })
    }

    useEffect(() => {
      if (lisence) {
        console.log(" 44", lisence?.features.includes('lrlist','minimumwage', 'workorderlist', 'scheduleworkmen'));
      }
    }, [lisence]); // This effect runs whenever `lisence` changes

useEffect(() => getUserInfo(), [])

  const states = State;
  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };
  const Esictoggle = () => {
    setEsicDisabled(!EsicDisabled);
  };
  // const onSubmit = () => {
  //   formRef.current?.submit();
  // };

  const validateDocuments = () => {
    let error = [];
    allDocuments?.map((document) => {
      if (document.isRequired) {
        const exists = documents?.some(
          (item) => item.name === document.documentName
        );
        if (!exists) {
          error.push(`${document.documentName} document is required`);
        }
      }
    });
    if (error.length) {
      error.map((err) => {
        notification.error({
          message: err,
        });
      });

      return 0;
    }
    return true;
  };

  const onSubmit = () => {
    if (!validateDocuments()) {
      return;
    }
    if (userConfig.role === 'contractor') {
      //
      //
      formRef.current?.submit();
      if (isEdit && isFormFilled()) {
        navigate('/home/admin');
        if (initialValues.addJobDetails.length === 0) {
          Modal.info({
            title: 'Info',
            content: 'Please add at least one job order details.',
            okButtonProps: { duration: 0 }, // Make sure the modal stays open until the user interacts with it
          });
        }
      }
    } else {
      formRef.current?.submit();
    }
  };

  const isFormFilled = () => {
    const requiredFields = [
      'id',
      'managerName',
      'emailId',
      'userid',
      'password',
      // Add references to other required form fields here
    ];
    // Check if any required field is empty or null
    const isAnyFieldEmpty = requiredFields.some((fieldName) => {
      const fieldValue = formRef.current.getFieldValue(fieldName);
      return !fieldValue || fieldValue.trim() === '';
    });

    return !isAnyFieldEmpty; // Return true if all required fields are filled; otherwise, return false
  };

  const [employmentStatus, setEmploymentStatus] = useState(
    initialValues?.statusToBeImposed
  );
  const handleStatusChange = (value) => {
    setEmploymentStatus(value);
  };

  const [locationName, setLocationName] = React.useState('');

  useEffect(() => {
    const filteredLocation = locationsData?.locations?.find(
      (option) => userConfig?.location === option?.id
    );
    if (filteredLocation) {
      setLocationName(filteredLocation?.name); // Set the initial location name
    }
  }, [locationsData, userConfig]);

  const onFinish = (value) => {
    console.log('values..', value);
    value.proprietorship = proprietorship ? 'Yes' : 'No';
    value.createdInCLMS = initialValues?.createdInCLMS;
    value.uploadDocument = documents;
    value.foreignNumber = value?.foreignNumber ? value?.foreignNumber : initialValues?.foreignNumber;
    value.permanentAddressSameCheck = permanentSameAsLocalAddress;
    value.correspondingAddressSameCheck = correspondingSameAsLocalAddress;
    value.id = initialValues?.createdInCLMS
      ? initialValues?.id
      : initialValues?.userid;
    value.reason = initialValues?.rejectReason || '';
    const supplierId = value?.id;
    // value.status = userConfig.role === 'approver' ? 'Active' : 'Pending';//viresh standard
    if (userConfig.role === 'approver') {
      value.status = 'Active';
    } else if (userConfig.role === 'admin') {
      // Do nothing, maintain the current status
      value.status = value.statusToBeImposed;
    } else {
      value.status = 'Pending';
    }

    // onOk(value);

    if (isEdit) {
      value.Edited = userConfig.role === 'approver' ? 'No' : 'Yes';
      // if (userConfig.role == 'approver') {
      //   Email({
      //     // medicalCheckupDate: values.medicalCheckupDate,
      //     workmenName: value.managerName,
      //     templateId: 'NOTIFICATION_APPTOVAL_CONTARCTOR_AGENCY_PROFILE_VYN',
      //     orgId: userConfig.orgId,
      //     level: null,
      //     role: 'contractor', //userConfig.role
      //     locationId: null,
      //   })
      //     .then((res) => {
      //       notification.success({ message: res.data.message });

      //       //create contractor
      //     })
      //     .catch((err) => {
      //       notification.error({ message: err.response.data.message });
      //     });
      // }
      // if (userConfig.role == 'approver') {
      //   contractorCreate(supplierId)
      //     .then((res) => {
      //       notification.success({ message: res.data.message });
      //     })
      //     .catch((err) => {
      //       console.error('error from create update', err);
      //       notification.error({ message: err.response.data.message });
      //     });
      //   // contractorUpdate(supplierId)
      //   //   .then((res) => {
      //   //
      //   //   })
      //   //   .catch((error) => {
      //   //     console.error('error from create update', error);
      //   //   });
      // }

      // if (userConfig.role == 'contractor') {
      //   //
      //   //

      //   Email({
      //     // medicalCheckupDate: values.medicalCheckupDate,
      //     workmenName: value.managerName,
      //     templateId: 'NOTIFICATION_COMPLETION_CONTARCTOR_AGENCY_PROFILE_VYN',
      //     VYNApplication: 'https://demo.wikiworks.in/login',
      //     orgId: userConfig.orgId,
      //     userId: userConfig.userid,
      //     role: 'ir', //userConfig.role
      //     level: 1,
      //     locationId: 'Delhi',
      //   })
      //     .then((res) => {
      //       notification.success({ message: res.data.message });
      //     })
      //     .catch((err) => {
      //       notification.error({ message: err.response.data.message });
      //     });
      // }
      updatecontractor(value)
        .then((res) => {
          // navigate('/');
          // navigate('/home/admin/organisation');

          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          navigate('/');

          // notification.success({ message: 'Updated Succesfully' });
        });
    } else {
      value.status = 'Pending';
      // Call add API
      AddContractors(value)
        .then((res) => {
          // navigate('/');
          // navigate('/home/admin/organisation');

          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });

      if (userConfig.role !== 'contractor' && userConfig.role !== 'approver') {
        const data = {
          orgId: userConfig.orgId,
          userid: value?.userid,
          emailId: value?.emailId,
          firstName: value?.name,
          password: value?.password,
          role: 'contractor',
          supplierId: value?.userid,
          locationId: value?.location,
          locationName: locationName,
        };
        userCreate(data)
          .then((res) => {
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });
      }
      // Email({
      //   // medicalCheckupDate: values.medicalCheckupDate,
      //   workmenName: value.managerName,
      //   templateId: 'NOTIFICATION_COMPLETION_CONTARCTOR_AGENCY_PROFILE_VYN',
      //   VYNApplication: 'https://demo.wikiworks.in/login',
      //   orgId: userConfig.orgId,
      //   userId: userConfig.userid,
      //   role: 'ir', //userConfig.role
      //   userId: userConfig.userid,
      //   password: userConfig.password,
      //   level: 1,
      //   locationId: 'Delhi',
      // })
      //   .then((res) => {
      //     notification.success({ message: res.data.message });
      //   })
      //   .catch((err) => {
      //     notification.error({ message: err.response.data.message });
      //   });
      if (userConfig.role == 'admin') {
        Email({
          // medicalCheckupDate: values.medicalCheckupDate,
          workmenName: value.managerName,
          templateId: 'NOTIFICATION_CONTRACTOR_AGENCY_CREATION',
          // VYNApplication: 'https://demo.wikiworks.in/login',
          // VYNApplication: process.env.REACT_APP_URL,
          VYNApplication:`${axios1.defaults.baseURL.replace('/api', '')}/login`, 
          orgId: userConfig.orgId,
          level: userConfig.level,
          role: 'contractor',
          password: value.password,
          userId: value.userid,
          locationId: value.location,
          correctRecipientEmail: value.emailId,
        })
          .then((res) => {
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });
      }
    }
    // window.location.reload();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  // const ApproveSubmit = (value) => {
  //  //
  //   updatecontractor()
  //     .then((res) => {
  //       navigate('/');

  //       notification.success({ message: res.data.message });
  //     })
  //     .catch((err) => {
  //       navigate('/');

  //       notification.success({ message: 'Updated Succesfully' });
  //     });
  // };
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const _onCancel = () => {
  //   setIsModalVisible(true);

  //   formRef.current?.resetFields();
  //   onCancel();
  // };

  useEffect(() => {
    getExcel();
  }, []);

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res.data.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Excel data:', error);
      });
  };

  useEffect(() => {
    natureOfWorkInput();
  }, [masterData]);

  const orgId = userConfig.orgId;

  const Reject = (rejectReason) => {
    console.log("inside Reject ")
    const data = {
      reason : rejectReason
    }
    console.log("data.reason ", data?.reason)
  }

  const handleOk = () => {
    Reject(rejectReason);
    Email({
      // medicalCheckupDate: values.medicalCheckupDate,
      // workmenName: value.managerName,
      templateId: 'NOTIFICATION_REJECTION_CONTARCTOR_AGENCY_PROFILE_VYN',
      orgId: userConfig.orgId,
      level: null,
      role: 'contractor', //userConfig.role
      locationId: userConfig.location || null,
      correctRecipientEmail: userConfig.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    // Close the modal
    setIsModalVisible(false);
  };
  const cancelForReject = () => {
    // Close the modal without taking any action
    setIsModalVisible(false);
  };

  const handleReject = () => {
    // Show the popup when the "Reject" button is clicked
    setIsModalVisible(true);
    onCancel();
  };
  const handleCancel1 = () => {
    // Close the modal without showing the popup when the "X" close button or "Cancel" button is clicked
    setIsModalVisible(false);
    formRef.current?.resetFields();
    onCancel();
  };

  //upload
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //Preview
  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => {
    setIsPfApplyDateDisabled(false);

    setPreviewOpen(false);
  };

  const [form] = Form.useForm();
  const [isPfApplyDateDisabled, setIsPfApplyDateDisabled] = useState(false);

  const [permanentSameAsLocalAddress, setPermanentSameAsLocalAddress] =
    useState(initialValues?.permanentAddressSameCheck || false);
  const [correspondingSameAsLocalAddress, setCorrespondingSameAsLocalAddress] =
    useState(initialValues?.correspondingAddressSameCheck || false);
  const handlePfEstablishmentChange = (value) => {
    setIsPfApplyDateDisabled(
      !!value || !!form.getFieldValue('pfEstablishmentSubCode')
    );
  };

  const handleSubCodeChange = (value) => {
    setIsPfApplyDateDisabled(!!value || !!form.getFieldValue('pfAccountNo'));
  };

  function addressFunction(e) {
    setPermanentSameAsLocalAddress(e?.target?.checked);
    const addressCheck = e?.target?.checked;
    addressCheck !== initialValues?.permanentAddressSameCheck
      ? setIsChanged(true)
      : setIsChanged(false);
    if (e.target.checked) {
      form.setFieldsValue({
        paddress1: form.getFieldValue('address1'),
        paddress2: form.getFieldValue('address2'),
        paddress3: form.getFieldValue('address3'),
        pstate: form.getFieldValue('state'),
        pvillage: form.getFieldValue('village'),
        ptaluka: form.getFieldValue('taluka'),
        pdistrict: form.getFieldValue('district'),
        ptehsil: form.getFieldValue('tehsil'),
        pcity: form.getFieldValue('city'),
        pcountry: form.getFieldValue('country'),
        ppincode: form.getFieldValue('pincode'),
      });
    } else {
      form.setFieldsValue({
        paddress1: form.getFieldValue(''),
        paddress2: form.getFieldValue(''),
        paddress3: form.getFieldValue(''),
        pstate: form.getFieldValue(''),
        ptaluka: form.getFieldValue(''),
        ptehsil: form.getFieldValue(''),
        pvillage: form.getFieldValue(''),
        pdistrict: form.getFieldValue(''),
        pcountry: form.getFieldValue(''),

        ppincode: form.getFieldValue(''),
      });
    }
  }

  //----------
  function contractorAddressFunction(e) {
    setCorrespondingSameAsLocalAddress(e?.target?.checked);
    const addressCheck = e?.target?.checked;
    addressCheck !== initialValues?.correspondingAddressSameCheck
      ? setIsChanged(true)
      : setIsChanged(false);
    if (e.target.checked) {
      form.setFieldsValue({
        caddress1: form.getFieldValue('address1'),
        caddress2: form.getFieldValue('address2'),
        caddress3: form.getFieldValue('address3'),
        cstate: form.getFieldValue('state'),
        cvillage: form.getFieldValue('village'),
        ctaluka: form.getFieldValue('taluka'),
        cdistrict: form.getFieldValue('district'),
        ctehsil: form.getFieldValue('tehsil'),
        ccity: form.getFieldValue('city'),
        ccountry: form.getFieldValue('country'),
        cpincode: form.getFieldValue('pincode'),
      });
    } else {
      form.setFieldsValue({
        caddress1: form.getFieldValue(''),
        caddress2: form.getFieldValue(''),
        caddress3: form.getFieldValue(''),
        cstate: form.getFieldValue(''),
        ctaluka: form.getFieldValue(''),
        ctehsil: form.getFieldValue(''),
        cvillage: form.getFieldValue(''),
        cdistrict: form.getFieldValue(''),
        ccountry: form.getFieldValue(''),

        cpincode: form.getFieldValue(''),
      });
    }
  }

  //SAP Vendor Code
  const [contractorCode, setContractorCode] = useState('');
  const [isContractorCodeValid, setIsContractorCodeValid] = useState(false); // State to track contractor code validity
  const [isUserIdValid, setIsUserIdValid] = useState(false);
  const [isContractorCodeChecked, setIsContractorCodeChecked] = useState(false); // State to track if contractor code has been checked
  const [isUserIdChecked, setIsUserIdChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState('');

  const validateContractorCode = async (contractorDetails) => {
    console.log(contractorDetails, 'contractor');
    try {
      // setLoader(true); // Start loader before API call
      const res = await chechUniqueContractorCodeApi(contractorDetails); // Call the API
      console.log(res, 'check contractor');

      // setLoader(false); // Stop loader after API call

      if (res.status === 200) {
        // Check if response status is 200
        if (res.data.contractorCode) {
          setIsContractorCodeValid(true); // Set contractor code validity to true
        } else {
          setIsContractorCodeValid(false); // Set contractor code validity to false
        }
        return true; // Validation successful
      } else {
        setIsContractorCodeValid(false); // Set contractor code validity to false
        return false; // Validation failed
      }
    } catch (error) {
      console.error('Error validating contractor code and user ID:', error);
      // setLoader(false); // Stop loader in case of error
      setIsContractorCodeValid(false); // Set contractor code validity to false
      return false; // Return false on error
    }
  };

  const validateUserId = async (userId) => {
    console.log(userId, 'userId');
    try {
      // setLoader(true); // Start loader before API call
      const res = await chechUniqueUserIdApi(userId); // Call the API
      console.log(res, 'check userId');

      // setLoader(false); // Stop loader after API call

      if (res.status === 200) {
        // Check if response status is 200

        if (res.data.userId) {
          setIsUserIdValid(true); // Set user ID validity to true
        } else {
          setIsUserIdValid(false); // Set user ID validity to false
        }

        return true; // Validation successful
      } else {
        setIsUserIdValid(false); // Set user ID validity to false
        return false; // Validation failed
      }
    } catch (error) {
      console.error('Error validating contractor code and user ID:', error);
      // setLoader(false); // Stop loader in case of error
      setIsUserIdValid(false); // Set user ID validity to false
      return false; // Return false on error
    }
  };

  // Event handler function to update SAP Vendor Code when Contractor Code changes
  const handleContractorCodeChange = (e) => {
    const value = e.target.value.toUpperCase(); // Convert value to uppercase
    setContractorCode(value); // Update state with the new value
  };

  const handleUserIdChange = (e) => {
    const value = e.target.value; // No conversion, just capture input
    setUserId(value); // Update state with the new value
  };

  const LocalAdminGrayOut = userConfig.role === 'admin';

  function natureOfWorkInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Type_of_Contract';
    const dataFromArray = masterData.find(
      (item) => item.sheetName === sheetName
    );

    if (!dataFromArray) {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
      return;
    }

    const natureOfWorkAreas = dataFromArray.dataParsed.map(
      (item) => item['Dropdown for Nature of Work']
    );
    setnatureOfWork(natureOfWorkAreas);
  }

  const handleNatureChange = (value) => {
    setSelectedNature(value);
  };

  const handleLocationChange = (value) => {
    // Find the location id corresponding to the inserted location
    const location = locationsData?.locations?.find(
      (element) => element.name === value
    );
    if (location) {
      setLocationIdData(location.id); // Update the state with the location_id
    } else {
      setLocationIdData(''); // Reset locationId if location is not found
    }
  };

  // const handleGstChange = (event) => {
  //
  //   Promise.all([supplierGst({ gstNo: event.target.value })])
  //     .then((results) => {
  //       notification.success({ message: results[0].data.message });
  //       notification.success({ message: results[1].data.message });
  //       // onNext(values);
  //     })
  //     .catch((err) => {
  //       notification.error({ message: err.response.data.message });
  //     });

  //   // form.setFieldsValue({ currentCadre: date });
  // };
  const handleGstChange = (value) => {
    // Validate GST number pattern
    const gstPattern =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/;
    if (!gstPattern.test(value.target.value)) {
      form.setFields([
        {
          name: 'gstNo',
          errors: ['Enter Valid GST No.'],
        },
      ]);
      return;
    }

    // If GST number pattern is valid, call the API
    supplierGst({ gstNo: value.target.value })
      .then((response) => {
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      });
  };
  const handlePANChange = (value) => {
    // Validate GST number pattern
    const panPattern = new RegExp('^([A-Z]{5}[0-9]{4}[A-Z]{1})$');
    if (!panPattern?.test(value.target.value)) {
      form.setFields([
        {
          name: 'pan',
          errors: ['Enter Valid PAN No.'],
        },
      ]);
      return;
    }

    // If GST number pattern is valid, call the API
    supplierPan({ pan: value.target.value })
      .then((response) => {
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      });
  };

  //Disable update button
  const [isChanged, setIsChanged] = useState(false);

  // Function to handle form changes and check for differences
  const handleFormChange = (changedFields, allFields) => {
    // Get the current form values
    const currentValues = form.getFieldsValue();

    // Check if any current value differs from the corresponding initial value
    const hasChanges = Object.keys(currentValues).some(
      (key) => currentValues[key] !== initialValues[key]
    );

    setIsChanged(hasChanges);
  };
  useEffect(() => {
    const documentLengthChanged =
      documents?.length !== initialValues?.uploadDocument?.length;

    if (documentLengthChanged) {
      console.log('742documents', documents?.length);
      console.log(
        '743initialValues?.uploadDocument',
        initialValues?.uploadDocument?.length
      );
      setIsChanged(true); // Set isChanged to true if document length has changed
    } else {
      setIsChanged(false); // Reset isChanged if no changes
    }
  }, [documents]);


  // const getWorkOrderDetails = async () => {
  //   try {
  //     // const response = await axios.get('/workOrderDetails');
  //     const response = await getWorkOrderDetailsData()

  //     // Access the workOrderDetails from the response
  //     // const workOrderDetails = response.data.result.workOrderDetails;
  //     const res = response.data
  //     setContractorData(res);
  //     return res;
   
  //   } catch (error) {
  //     console.error("Error fetching work order details:", error);
  //   }
  // };

  const getWorkOrderDetails = async () => {
    try {
      let allData = [];
      let page = 1;
      let limit = 100; // Adjust based on your API limit
      let totalPages = 1; // Default to 1
  
      // Fetch the first page to determine total pages
      const response = await getWorkOrderDetailsData(page, limit);
  
      if (response) {
        allData = response.data || [];
        totalPages = response.pagination?.totalPages || 1;
      }
  
      // Fetch remaining pages if totalPages > 1
      while (page < totalPages) {
        page++;
        const nextPageData = await getWorkOrderDetailsData(page, limit);
        if (nextPageData) {
          allData = allData.concat(nextPageData.result?.workOrderDetails || []);
        }
      }
  
      setContractorData(allData);
      return { data: allData, totalPages };
  
    } catch (error) {
      console.error("Error fetching work order details:", error);
      return { data: [], totalPages: 0 };
    }
  };
  
  const handleContractorChange = (value) => {
    // Find the selected contractor's data
    const selectedData = contractorData.find(
      (item) => item.contractorName === value
    );

    // Update the form's 'contractorCode' field with the corresponding vendorCode
    form.setFieldsValue({
      contractorCode: selectedData?.vendorCode,
    });
  };

  useEffect(() => {
    if(lisence)
      {    if (selectedContractorName) {
          // Find the corresponding contractor object from the contractorData
          const matchingContractor = contractorData.find(
            (contractor) => contractor.contractorName === selectedContractorName
          );
     
          if (matchingContractor) {
            // First, set the vendorCode state
            setVendorCode(matchingContractor.vendorCode);
          }
        }}
        if(lisence){
          if (contractorCode) {
            // First, set the vendorCode state
            setVendorCode(contractorCode);
          }
        }
      }, [selectedContractorName, contractorData, contractorCode]);

  useEffect(() => {
    if (vendorCode) {
      // Set the vendorCode in the form after it's updated
      form.setFieldsValue({
        contractorCode: vendorCode,
      });
    }
  }, [vendorCode, form]);

  useEffect(() => {
    getWorkOrderDetails();
  }, []);

  useEffect(() => {
    // const fetchWorkOrderDetails = async () => {
    //   try {
    //     const workOrder = await getWorkOrderDetails();

    //     const activeNames = workOrder.filter((detail) => detail.status === "Active");
       
       
    //     const contractorNames = [...new Set(activeNames.map(
    //       (detail) => detail.contractorName
    //     ))]
       
    //     const contractorCodes = workOrder.map((detail) => detail.vendorCode);

    //     setContractorNames(contractorNames);
    //     setContractorCodes(contractorCodes);

    //   } catch (error) {
    //     console.error("Error fetching work order details:", error);
    //   }
    // };

    // fetchWorkOrderDetails();
  
    const fetchWorkOrderDetails = async () => {
      try {
        let allData = [];
        let page = 1;
        let limit = 100; // Adjust based on API limits
        let totalPages = 1;
    
        // Fetch the first page to get totalPages
        const response = await getWorkOrderDetailsData(page, limit);
        console.log("response", response);
        
    
        if (response) {
          allData = response.data || [];
          totalPages = response.pagination?.totalPages || 1;
        }
    
        // Fetch remaining pages
        while (page < totalPages) {
          page++;
          const nextPageData = await getWorkOrderDetailsData(page, limit);
          if (nextPageData) {
            allData = allData.concat(nextPageData.result?.workOrderDetails || []);
          }
        }
    
        // Filter active work orders
        const activeNames = allData.filter((detail) => detail.status === "Active");
    
        // Get unique contractor names
        const contractorNames = [...new Set(activeNames.map((detail) => detail.contractorName))];
    
        // Get all vendor codes
        const contractorCodes = allData.map((detail) => detail?.vendorCode);
        console.log("contractorCodes", contractorCodes);
        
    
        // Update state
        setContractorNames(contractorNames);
        setContractorCodes(contractorCodes);
    
      } catch (error) {
        console.error("Error fetching work order details:", error);
      }
    };
    
    // Call the function
    fetchWorkOrderDetails();
    
  
  }, []);


  return (
    <>
      <Modal
        title={isEdit ? 'Edit Contractor Agency' : ''}
        open={open}
        onOk={onSubmit}
        maskClosable={false} // Prevent closing on outside click
        // onCancel={_onCancel}
        // okText={isEdit ? 'Save' : 'Add'}
        onCancel={handleCancel1}
        okText={
          isEdit
            ? userConfig.role == 'approver'
              ? 'Approve'
              : 'Update'
            : 'Add'
        }
        okButtonProps={{
          disabled: isEdit && !isChanged, // For disable update
        }}
        cancelText={userConfig.role === 'approver' ? 'Reject' : 'Cancel'}
        // footer={userConfig.role === 'ir' ? null : undefined}
        width={'75%'}
        cancelButtonProps={{
          // ...(userConfig.role === 'contractor'
          //   ? { style: { display: 'none' } }
          //   : {}),
          onClick:
            userConfig.role === 'contractor' ? handleCancel1 : handleReject,
        }}
        // className='custom-modal' // Add a class to the modal for styling
        // cancelButtonProps = {
        //   userConfig.role === 'contractor' ? { style: { display: 'none' } } : {}
        // }
      >
        <Form
          name='contractor'
          layout='vertical'
          onFinish={onFinish}
          // initialValues={initialValues}
          form={form}
          ref={formRef}
          onFieldsChange={handleFormChange} // For disable update
        >
          <Row gutter={24}>
            <Divider>Contractor Agency Detail</Divider>
            <Col span={6}>
              <Form.Item label='Principal Emp Code'>
                <Input
                  value={userConfig.orgId}
                  disabled
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                label='Contractor Name'
                name='name'
                rules={[
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: 'Only Alphabetic Allowed',
                  // },

                  { required: true, message: 'Enter Contractor Name' },
                ]}>
                <Input
                  disabled={isEdit ? true : false}
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col> */}

            <Col span={6}>
              <Form.Item
                label="Contractor Name"
                name="name"
                rules={[{ required: true, message: "Enter Contractor Name" }]}
              >
                  {["lrlist", "minimumwage", "workorderlist", "wageregister", "scheduleworkmen"].some(feature => lisence?.features?.includes(feature)) ? (
                <Select
                  disabled={isEdit} // Conditionally disable if in edit mode
                  style={{ fontWeight: "bold", color: "gray" }}
                  onChange={(value) => {
                    console.log("ValueSElected ", value);
                    setSelectedContractorName(value);
                    form?.setFieldsValue({
                      name: value, // Set the selected contractor name in the form
                    });
                  }}
                >
                  {contractorNames?.map((name, index) => (
                    <Select.Option key={index} value={name}>
                      {name} {/* Display the contractor name */}
                    </Select.Option>
                  ))}
                </Select>
                  ) : (
                    <Input
                    disabled={isEdit === true} // Conditionally disable if in edit mode
                    style={{ fontWeight: "bold", color: "gray" }}
                    onChange={(e) => {
                      setSelectedContractorName(e.target.value);
                      form?.setFieldsValue({
                        name: e.target.value, // Set the entered contractor name in the form
                      });
                    }}
                  />
                    
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contractor Code"
                name="contractorCode"
                // rules={[{ required: true, message: "" }]}
              >
                <Input
                  // value={vendorCode}
                  readOnly = {["lrlist", "minimumwage", "workorderlist", "wageregister", "scheduleworkmen"].some(feature => lisence?.features?.includes(feature))}// If you want to make it read-only
                  style={{ fontWeight: "bold", color: "gray" }}
                />
              </Form.Item>
            </Col>

            {/* {userConfig?.supplierId && (
              <Col span={6}>
                <Form.Item
                  label='Contractor Code'
                  name='id'
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!isEdit && value && value.trim() !== '') {
                          // Ensure the value is not empty or whitespace
                          const isUnique = await validateContractorCode(value);
                          if (!isUnique) {
                            return Promise.reject(
                              'Contractor code already exists.'
                            );
                          }
                          setIsContractorCodeChecked(true); // Set state to true after successful check
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.toUpperCase()}>
                  <Input
                    disabled={isEdit ? true : false}
                    style={{ fontWeight: 'bold', color: 'gray' }}
                    onChange={handleContractorCodeChange} // Call event handler on change
                  />
                </Form.Item>
              </Col>
            )} */}

            <Col span={6}>
              <Form.Item
                label='Manager Name'
                name='managerName'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },

                  { required: true, message: 'Enter Manager Name' },
                ]}>
                <Input
                  disabled={isEdit ? true : false}
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Manager Mobile No.'
                name='mobileNo'
                // rules={[{ required: true, message: 'Enter Mobile No.' }]}
              >
                <Input
                  disabled={isEdit ? true : false}
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col>{' '}
            <Col span={6}>
              <Form.Item
                label='Manager Mail Id'
                name='emailId'
                rules={[{ required: true, message: 'Enter Agency Email Id' }]}>
                <Input
                  disabled={isEdit ? true : false}
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col>{' '}
            {/* <Col span={6}>
              <Form.Item
                label='SAP Vendor Code'
                name='sapVendorCode'
                // rules={[{ required: true, message: 'Enter SAP Vendor Code' }]}
              >
                <Input
                  disabled={isEdit ? true : false}
                  style={{ fontWeight: 'bold', color: 'gray' }}
                />
              </Form.Item>
            </Col> */}
            {userConfig.role !== 'contractor' &&
              userConfig.role !== 'approver' && (
                <>
                  <Col span={6}>
                    <Form.Item
                      label='User Id'
                      name='userid'
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (value && value.trim() !== '') {
                              // Ensure the value is not empty or whitespace
                              const isUnique = await validateUserId(value);
                              if (!isUnique) {
                                return Promise.reject(
                                  'User ID already exists.'
                                );
                              }
                              setIsUserIdChecked(true); // Set state to true after successful check
                            }
                            return Promise.resolve();
                          },
                        },
                        {
                          required: true,
                          message: 'Please enter User Id',
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.toUpperCase()}>
                      <Input
                        autoComplete='off'
                        disabled={isEdit ? true : false}
                        style={{ fontWeight: 'bold', color: 'gray' }}
                        onChange={handleUserIdChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label='Password'
                      name='password'
                      rules={[
                        { required: true, message: 'Enter Password' },
                        {
                          pattern:
                            '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
                          message:
                            'Must contain minimum eight characters, at least one number and one special character',
                        },
                      ]}>
                      <Input.Password
                        maxLength={20}
                        autoComplete='new-password'
                        disabled={isEdit ? true : false}
                        style={{ fontWeight: 'bold', color: 'gray' }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            {/* <Col span={6}>
              <Form.Item label='Location' name='location'>
                <Select disabled={isEdit ? true : false}>
                  
                  {locationsData?.locations
                    ?.slice(1)
                    .filter((option) => userConfig.location === option.id)
                    .map((option, index) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  {locationsData?.locations
                    ?.slice(1)
                    .filter((option) => userConfig.location !== option.id)
                    .map((option, index) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item
                label='Location'
                name='location'
                rules={[{ required: true, message: 'Select Location' }]}>
                {!userConfig.location && (
                  <Select disabled={isEdit ? true : false}>
                    {/* {locationsData?.locations?.slice(1).map((option, index) => (
                    <Option key={index} value={option.id}>
                      {option.name}
                    </Option>
                  ))} */}
                    {locationsData?.locations
                      ?.slice(1)
                      .filter((option) => userConfig.location === option.id)
                      .map((option, index) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    {/* Render all locations if filter condition is false */}
                    {locationsData?.locations
                      ?.slice(1)
                      .filter((option) => userConfig.location !== option.id)
                      .map((option, index) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                  </Select>
                )}
                {userConfig?.location && (
                  <Select size='large'>
                    {locationsData?.locations
                      ?.filter((option) => userConfig?.location === option?.id)
                      .map((option) => (
                        <Option value={userConfig?.location}>
                          {option?.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Contractor Foreign Number' name='foreignNumber'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Owner Name' name='ownerName'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Owner Contact No.' name='ownerContactNo'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Owner Mail Id'
                name='ownerEmailId'
                // rules={[{ required: true, message: 'Enter EmailId' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}> */}
            {/* <Form.Item
                label=' Location'
                name='location'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },

                  // { required: true, message: 'Enter Location' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  onChange={(e) => handleLocationChange(e.target.value)}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item> */}
            {/* </Col> */}
            <Col span={6}>
              <Form.Item
                label='Type Of Contractor'
                name='typeOfContractor'
                // rules={[{ required: true, message: 'Enter Contractor' }]}
              >
                <Select
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }>
                  <Option value='local'>
                    <span
                      style={
                        LocalAdminGrayOut || isDisabled
                          ? { fontWeight: 'bold', color: 'gray' }
                          : {}
                      }>
                      Local
                    </span>
                  </Option>
                  <Option value='foreign'>
                    <span
                      style={
                        LocalAdminGrayOut || isDisabled
                          ? { fontWeight: 'bold', color: 'gray' }
                          : {}
                      }>
                      Foreign
                    </span>
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name='proprietorship'
                // rules={[{ required: true, message: 'Enter Proprietorship' }]}
              >
                <Checkbox
                  size='large'
                  onChange={(e) => setProprietorship(e?.target?.checked)}
                  style={{ paddingTop: '20px' }}
                  disabled={isDisabled || LocalAdminGrayOut}
                  checked={proprietorship}>
                  Proprietorship
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='PAN'
                name='pan'
                rules={[
                  {
                    pattern: '^([A-Z]{5}[0-9]{4}[A-Z]{1})$',
                    message: 'Enter Valid PAN No.',
                  },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                  onChange={handlePANChange}
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item label='Service Tax Number' name='serviceTaxNumber'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item
                label='Nature of Work'
                name='natureOfWork'
                // rules={[{ required: true, message: 'Please select a gate' }]}
              >
                <Select
                  onChange={(value) => handleNatureChange(value)}
                  disabled={isDisabled || LocalAdminGrayOut}>
                  {natureOfWork?.map((area, index) => (
                    <Select.Option key={index} value={area}>
                      <span
                        style={
                          LocalAdminGrayOut || isDisabled
                            ? { fontWeight: 'bold', color: 'gray' }
                            : {}
                        }>
                        {area}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                label='Unit Id'
                name='unitId'
                rules={[
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },

                  { required: true, message: 'Enter Unit Id' },
                ]}
                >
                <Input disabled={isDisabled} />
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item
                label='GST No.'
                name='gstNo'
                rules={[
                  // { required: true, message: 'Enter GST No.' },
                  {
                    pattern:
                      '^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$',
                    message: 'Enter Valid GST No.',
                  },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                  onChange={handleGstChange}
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                label='VAT No.'
                name='vatNo'
                // rules={[{ required: true, message: 'Enter VAT No.' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Divider>Local Address</Divider>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label=' Address1'
                name='address1'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabetic Allowed',
                    // },
                    // { required: true, message: 'Enter Address1' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Address2'
                name='address2'
                // rules={[{ required: true, message: 'Enter Address2' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Address3'
                name='address3'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: "Only Alphabetic Allowed",
                    // },
                    // { required: true, message: 'Enter Address3' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              {/* <Form.Item
                label='State'
                name='state'
                // rules={[{ required: true, message: 'Enter State' }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className='ant-select-selector'
                  disabled={isDisabled || LocalAdminGrayOut}
                  placeholder='Select State'
                  style={{ fontWeight: 'bold', color: 'gray' }}>
                  {states.map((state) => (
                    <Select.Option
                      key={state}
                      value={state}
                      // style={{ fontWeight: 'bold', color: 'gray' }}
                    >
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item
                label='State'
                name='state'
                // rules={[{ required: true, message: 'Enter State' }]}
              >
                <Select
                  showSearch
                  // filterOption={(input, option) =>
                  //   option?.children
                  //     ?.toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  className='ant-select-selector'
                  disabled={isDisabled || LocalAdminGrayOut}
                  placeholder='Select State'
                  style={{ fontWeight: 'bold', color: 'gray' }}>
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                      <span
                        style={
                          LocalAdminGrayOut || isDisabled
                            ? { fontWeight: 'bold', color: 'gray' }
                            : {}
                        }>
                        {state}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Village' name='village'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Taluka'
                name='taluka'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District/City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='District'
                name='district'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Tehsil'
                name='tehsil'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Tehsil' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='City'
                name='city'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Country'
                name='country'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Country' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Pincode'
                name='pincode'
                rules={[
                  {
                    pattern: '^[1-9][0-9]{5}$',
                    message: 'Invalid Pincode',
                  },

                  // { required: true, message: 'Enter Pincode' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Divider>Permanent Address</Divider>
            <Col
              className='gutter-row'
              span={24}
              id='homepostalcheck'
              // hidden={users.paddress === true ? false : true}
            >
              <Form.Item>
                <input
                  type='checkbox'
                  id='permanentSameAsLocalAddress'
                  name='permanentSameAsLocalAddress'
                  size='large'
                  style={{ fontWeight: 'bold' }}
                  onClick={addressFunction}
                  checked={permanentSameAsLocalAddress}
                />
                <label
                  htmlFor='permanentSameAsLocalAddress'
                  style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                  Same As Above
                </label>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Address1'
                name='paddress1'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabetic Allowed',
                    // },
                    // { required: true, message: 'Enter Address1' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Address2'
                name='paddress2'
                // rules={[{ required: true, message: 'Enter Address2' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Address3'
                name='paddress3'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: "Only Alphabetic Allowed",
                    // },
                    // { required: true, message: 'Enter Address3' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='State'
                name='pstate'
                // rules={[{ required: true, message: 'Enter State' }]}
              >
                <Select
                  disabled={isDisabled || LocalAdminGrayOut}
                  // style={
                  //   LocalAdminGrayOut
                  //     ? { fontWeight: 'bold', color: 'gray' }
                  //     : {}
                  // }
                  showSearch
                  // filterOption={(input, option) =>
                  //   option?.children
                  //     ?.toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  // className='ant-select-selector'
                  placeholder='Select State'>
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                      <span
                        style={
                          LocalAdminGrayOut || isDisabled
                            ? { fontWeight: 'bold', color: 'gray' }
                            : {}
                        }>
                        {state}
                      </span>{' '}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Village' name='pvillage'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Taluka'
                name='ptaluka'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District/City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='District'
                name='pdistrict'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Tehsil'
                name='ptehsil'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Tehsil' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='City'
                name='pcity'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Country'
                name='pcountry'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Country' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Pincode'
                name='ppincode'
                rules={[
                  {
                    pattern: '^[1-9][0-9]{5}$',
                    message: 'Invalid Pincode',
                  },

                  // { required: true, message: 'Enter Pincode' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Divider>Contractor Corresponding Local Address</Divider>
            <Col
              className='gutter-row'
              span={24}
              id='homepostalcheck'
              // hidden={users.paddress === true ? false : true}
            >
              <Form.Item>
                <input
                  type='checkbox'
                  id='correspondingSameAsLocalAddress'
                  name='correspondingSameAsLocalAddress'
                  size='large'
                  style={{ fontWeight: 'bold' }}
                  onClick={contractorAddressFunction}
                  checked={correspondingSameAsLocalAddress}
                />
                <label
                  htmlFor='correspondingSameAsLocalAddress'
                  style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                  Same As Local Address
                </label>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Address1'
                name='caddress1'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: 'Only Alphabetic Allowed',
                    // },
                    // { required: true, message: 'Enter Address1' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Address2'
                name='caddress2'
                // rules={[{ required: true, message: 'Enter Address2' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label=' Address3'
                name='caddress3'
                rules={
                  [
                    // {
                    //   pattern: /^[A-Za-z\s]*$/,
                    //   message: "Only Alphabetic Allowed",
                    // },
                    // { required: true, message: 'Enter Address3' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='State'
                name='cstate'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter State' },
                ]}>
                <Select
                  showSearch
                  disabled={isDisabled || LocalAdminGrayOut}
                  // filterOption={(input, option) =>
                  //   option?.children
                  //     ?.toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  className='ant-select-selector'
                  placeholder='Select State'
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }>
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                      <span
                        style={
                          LocalAdminGrayOut || isDisabled
                            ? { fontWeight: 'bold', color: 'gray' }
                            : {}
                        }>
                        {state}
                      </span>{' '}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Village' name='cvillage'>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Taluka'
                name='ctaluka'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District/City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='District'
                name='cdistrict'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter District' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Tehsil'
                name='ctehsil'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Tehsil' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='City'
                name='ccity'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter City' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Country'
                name='ccountry'
                rules={[
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message: 'Only Alphabetic Allowed',
                  },
                  // { required: true, message: 'Enter Country' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Pincode'
                name='cpincode'
                rules={[
                  {
                    pattern: '^[1-9][0-9]{5}$',
                    message: 'Invalid Pincode',
                  },

                  // { required: true, message: 'Enter Pincode' },
                ]}>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Contact No.'
                name='contactNo'
                rules={
                  [
                    // { required: true, message: 'Enter Contact No.' },
                  ]
                }>
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>{' '}
            <Col span={6}>
              <Form.Item
                label='Email Id'
                name='agencyEmailId'
                // rules={[{ required: true, message: 'Enter EmailId' }]}
              >
                <Input
                  disabled={isDisabled || LocalAdminGrayOut}
                  style={
                    LocalAdminGrayOut || isDisabled
                      ? { fontWeight: 'bold', color: 'gray' }
                      : {}
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Divider>Logo and Documents</Divider>
            <Col span={12}>
              <Form.Item
                label='Choose Logo'
                name='logoFile'
                valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  listType='picture'
                  accept='image/*'
                  maxCount={1}
                  beforeUpload={() => false}
                  onPreview={handlePreview}
                  size='large'>
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: '100%' }}
                    disabled={isDisabled || LocalAdminGrayOut}>
                    Choose Logo
                  </Button>
                </Upload>
              </Form.Item>
              <Modal
                open={previewOpen}
                title={previewTitle}
                maskClosable={false} // Prevent closing on outside click
                footer={null}
                onCancel={handleCancel}
                // width={20}
              >
                <img
                  alt='example'
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
            </Col>
            <Col
              //className='gutter-row'
              span={24}
              // hidden={users.profilePic === true ? false : true}
            >
              {initialValues?.location && userConfig?.role != 'admin' && (
                <UploadDocuments
                  personalDetails={{ location: initialValues?.location }}
                  documents={documents}
                  setDocuments={setDocuments}
                  setAllDocuments={setAllDocuments}
                />
              )}
            </Col>
            {/*
            {userConfig.role !== 'contractor' &&
              userConfig.role !== 'approver' && (
                <>
                  <Col span={6}>
                    <Form.Item
                      label='User Id'
                      name='userid'
                      rules={[{ required: true, message: 'Enter User Id' }]}
                      getValueFromEvent={(e) => e.target.value.toUpperCase()}>
                      <Input
                        autoComplete='off'
                        disabled={isEdit ? true : false}
                        style={{ fontWeight: 'bolder', color: 'darkgray' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label='Password'
                      name='password'
                      rules={[
                        { required: true, message: 'Enter Password' },
                        {
                          pattern:
                            '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
                          message:
                            'Must contain minimum eight characters, at least one number and one special character',
                        },
                      ]}>
                      <Input.Password
                        maxLength={20}
                        autoComplete='new-password'
                        disabled={isEdit ? true : false}
                        style={{ fontWeight: 'bolder', color: 'darkgray' }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )} */}
          </Row>
          <Row gutter={24} style={{ justifyContent: 'flex-end' }}>
            {/* {userConfig.role === 'ir' && (
            <>
              <Form.Item style={{ marginLeft: '10px' }}>
                <Button type='primary'>Reject</Button>
              </Form.Item>
              <Form.Item style={{ marginLeft: '10px' }}>
                <Button type='primary' onClick={ApproveSubmit}>
                  Approve
                </Button>
              </Form.Item>
            </>
          )} */}
          </Row>
        </Form>
      </Modal>
      <Modal
        title='Reject Reason'
        visible={userConfig.role == 'approver' ? isModalVisible : null}
        maskClosable={false} // Prevent closing on outside click
        onOk={handleOk}
        onCancel={cancelForReject}>
        <Input
          placeholder='Enter reason for rejection'
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </>
  );
}

