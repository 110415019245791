import { createWorkOrderDetailData, getWorkOrderDetailData, updateWorkOrderDetailDataById, deleteWorkOrderDetailData, fetchWorkOrderDetailData, updateWorkOrderDetailDataByWorkOrderNumber, uploadWorkOrderDetailData, getExcelTemplateData, getDownloadExcel, getSearch } from '../../api/workOrderDetails';

export const createWorkOrderDetailsData = async (data) => {
  const response = await createWorkOrderDetailData(data);
  console.log('value of post data', response);
  //   getWageHistory();
};

export const getWorkOrderDetailsData = async (page, limit) => {
  //Get Data
  try{
    const workOrderData = await getWorkOrderDetailData( page, limit);
  return workOrderData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const updateWorkOrderDetailsData = async (data) => {
    const response = await updateWorkOrderDetailDataById(data);
    console.log('value of post data', response);
    //   getWageHistory();
};

export const deleteWorkOrderDetailsData = async (data) => {
    const response = await deleteWorkOrderDetailData(data);
    const responseData = await response.json();
    console.log('value of post data', response);
    //   getWageHistory();
};

export const fetchWorkOrderDetailsData = async(data)=>{
    try {
        // Call the function to fetch work order details data
        const response = await fetchWorkOrderDetailData(data);
        // Directly return the response data
        return response.data;
      } catch (error) {
        throw new Error('Error fetching work order details data: ' + error.message);
      }
}

export const updateWorkOrderDetailsDataByWorkOrderNumber = async(workOrderNumber, data)=>{
    try {
        if (!data) {
            throw new Error('Data parameter is undefined');
        }
        // Call the function to fetch work order details data
        const response = await updateWorkOrderDetailDataByWorkOrderNumber(workOrderNumber, data);
        // Directly return the response data
        return response;
      } catch (error) {
        // throw new Error('Error fetching work order details data: ' + error.message);
        console.log("error", error)
      }
}

export const uploadWorkOrderDetailsData = async (data) => {
    const response = await uploadWorkOrderDetailData(data);
    return response; // Return response if needed
};

export const getExcelTemplateDetailsData = async () => {
  //Get Data
  try{
      return await getExcelTemplateData();
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};
// getDownloadExcel
export const getDownloadExcelFile = async () => {
  //Get Data
  try{
      return await getDownloadExcel();
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};


export const getSearchData = async (filters) => {

  try {
      const response = await getSearch(filters);
      return response.data; // Return the whole response
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      return { data: [], totalRecords: 0 }; // Return empty data and count
  }
};
