import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

import Header from './Header';
import { getWageRegisterData } from '../../../../../services/auth';
import { getLocalDate } from '../../../../../utils/helperFunction';

const wageRegisterStateMapping = {
  'Karnataka': {
    header: {
      line1: '',
      line2: 'FORM XVII',
      line3: '(Prescribed Under Rule 78(1)(a) Contract Labour Regulation Abolition Rules 1975)',
      line4: '',
    }
  },
  'West Bengal': {},
  'Andhra Pradesh': {},
  'Bihar': {},
  'Goa': {},
  'Gujarat': {
    header: {
      line1: 'Contract Labour (Regulation & Abolition) Gujarat Rules, 1972',
      line2: 'Form - XVII',
      line3: 'See Rule 78 (2) (a)',
      line4: '',
    }
  },
  'Madhya Pradhesh': {},
  'Maharasthra': {},
  'Orisha': {},
  'Pune': {},
  'Rajsthan': {},
  'Tamil Nadu': {},
  'Telangana': {},
}

const WageRegister = (props) => {
  const {
    userConfig,
    contractorList,
    locationList,
    locationwiseContractors,
    selectedReport,
  } = props;

  const [filters, setFilters] = React.useState({
    contractors: null,
    locations: null,
    dateRange: [],
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const today = dayjs();
  const now = new Date();
  const formattedTime = now.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Use 24-hour format
  }).replace(/,/g, ''); // Remove commas

  useEffect(() => {
    const initialFilters = {
      dateRange: [today.subtract(1, 'month').startOf('day'), today],
      locations: null
    }
    setFilters(initialFilters)
  }, [])

  const columns = [
    {
      title: 'Employee Details',
      children: [
        {
          title: 'Sr. No.',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Emp No.',
          dataIndex: 'employeeId',
          key: 'employeeId',
        },
        {
          title: 'Unique Num',
          dataIndex: 'uniqueNum',
          key: 'uniqueNum',
        },
        {
          title: 'Workmen Name',
          dataIndex: 'name',
          key: 'name',
        },
      ],
    },
    {
      title: 'Worker Attribute',
      children: [
        {
          title: 'Skill',
          dataIndex: 'skill',
          key: 'skill',
        },
        {
          title: 'Sub Skill',
          dataIndex: 'subSkill',
          key: 'subSkill',
        },
        {
          title: 'Designation',
          dataIndex: 'designation',
          key: 'designation',
        },
        {
          title: 'PF Num',
          dataIndex: 'pfNum',
          key: 'pfNum',
        },
        {
          title: 'ESI Num',
          dataIndex: 'esiNum',
          key: 'esiNum',
        },
      ],
    },
    {
      title: 'Attendance Records',
      children: [
        {
          title: 'No of days worked',
          dataIndex: 'noOfDaysWorked',
          key: 'noOfDaysWorked',
        },
        {
          title: 'Holiday Days',
          dataIndex: 'holidayDays',
          key: 'holidayDays',
        },
        {
          title: 'OT Hours',
          dataIndex: 'otHours',
          key: 'otHours',
        },
      ],
    },
    {
      title: 'Fixed Wages per Day',
      children: [
        {
          title: 'Basic',
          dataIndex: 'fbasic',
          key: 'fbasic',
        },
        {
          title: 'VDA',
          dataIndex: 'fvda',
          key: 'fvda',
        },
        {
          title: 'Washing Allowance',
          dataIndex: 'fwashingAllowance',
          key: 'fwashingAllowance',
        },
        {
          title: 'HRA',
          dataIndex: 'fhra',
          key: 'fhra',
        },
        {
          title: 'Bonus @ 8.33%',
          dataIndex: 'fbonus',
          key: 'fbonus',
        },
        {
          title: 'LWW @ 4.165%',
          dataIndex: 'flww',
          key: 'flww',
        },
        {
          title: 'Other Allowance',
          dataIndex: 'fotherAllowance',
          key: 'fotherAllowance',
        },
        {
          title: 'Gross',
          dataIndex: 'fgross',
          key: 'fgross',
        },
      ],
    },
    {
      title: 'Earning Wages per Month',
      children: [
        {
          title: 'Basic',
          dataIndex: 'ebasic',
          key: 'ebasic',
        },
        {
          title: 'VDA',
          dataIndex: 'evda',
          key: 'evda',
        },
        {
          title: 'Washing Allowance',
          dataIndex: 'ewashingAllowance',
          key: 'ewashingAllowance',
        },
        {
          title: 'HRA',
          dataIndex: 'ehra',
          key: 'ehra',
        },
        {
          title: 'Bonus @ 8.33%',
          dataIndex: 'ebonus',
          key: 'ebonus',
        },
        {
          title: 'LWW @ 4.165%',
          dataIndex: 'elww',
          key: 'elww',
        },
        {
          title: 'OT Amount',
          dataIndex: 'eotAmount',
          key: 'eotAmount',
        },
        {
          title: 'Other Allowance',
          dataIndex: 'eotherAllowance',
          key: 'eotherAllowance',
        },
        {
          title: 'NHFH Amount',
          dataIndex: 'enhfhAmount',
          key: 'enhfhAmount',
        },
        {
          title: 'Gross Salary',
          dataIndex: 'egross',
          key: 'egross',
        },
      ],
    },
    {
      title: 'Deductions',
      children: [
        {
          title: 'PF @ 12%',
          dataIndex: 'dpf',
          key: 'dpf',
        },
        {
          title: 'ESI @ 0.75%',
          dataIndex: 'desi',
          key: 'desi',
        },
        {
          title: 'Canteen',
          dataIndex: 'dcanteen',
          key: 'dcanteen',
        },
        {
          title: 'PT',
          dataIndex: 'dpt',
          key: 'dpt',
        },
        {
          title: 'LWF',
          dataIndex: 'dlwf',
          key: 'dlwf',
        },
        {
          title: 'Total Deductions',
          dataIndex: 'totalDeductions',
          key: 'totalDeductions',
        },
        {
          title: 'Net Pay',
          dataIndex: 'netPay',
          key: 'netPay',
        },
        {
          title: 'Bank Account Details',
          dataIndex: 'bankAccountDetails',
          key: 'bankAccountDetails',
        },
      ],
    },
    {
      title: 'Signature',
      dataIndex: 'signature',
      key: 'signature',
    },
  ];

  const addNewRow = (worksheet, rowData, index) => {
    const newRow = worksheet.addRow();
    newRow.font = { size: 9, name: 'Arial' };
    newRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    // Function to get Excel-style column letters (A, B, ..., Z, AA, AB, ..., AZ, BA, etc.)
    const getColumnLetter = (colIndex) => {
      let columnLetter = '';
      while (colIndex >= 0) {
        columnLetter = String.fromCharCode((colIndex % 26) + 65) + columnLetter;
        colIndex = Math.floor(colIndex / 26) - 1;
      }
      return columnLetter;
    };

    // Dynamically populate cell values
    rowData.forEach((value, colIndex) => {
      const columnLetter = getColumnLetter(colIndex);
      worksheet.getCell(`${columnLetter}${index}`).value = value;
    });
  };

  const boldfont = { bold: true, size: 10, name: 'Arial' };
  const textAlignment = {
    vertical: 'middle', // Center vertically
    horizontal: 'center', // Center horizontally
    wrapText: true, // Enable text wrapping
  }
  const addHeaderText = (worksheet, yearMonth) => {
    let header = {}
    for (let key in wageRegisterStateMapping) {
      if(selectedReport?.reportName?.includes(key)) {
        header = wageRegisterStateMapping[key]?.header;
        break;
      }
    }

    worksheet.getCell('A1').value = header?.line1;
    worksheet.getCell('A2').value = header?.line2;
    worksheet.getCell('A3').value = header?.line3;
    worksheet.getCell('A4').value = `Wage Register Report for ${yearMonth}`;

    worksheet.getRow(1).font = boldfont;
    worksheet.getRow(2).font = boldfont;
    worksheet.getRow(3).font = boldfont;
    worksheet.getRow(4).font = boldfont;

    worksheet.getCell('A1').alignment = textAlignment;
    worksheet.getCell('A2').alignment = textAlignment;
    worksheet.getCell('A3').alignment = textAlignment;
    worksheet.getCell('A4').alignment = textAlignment;
  }

  const selectedLocation = userConfig?.organisation?.locations?.filter(l => l.id == filters?.locations)[0]

  const downloadCSV = async () => {
    const documents = data?.wageRegisterData;

    // Step 1: Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // header section
    worksheet.mergeCells('A1:AM1');
    worksheet.mergeCells('A2:AM2');
    worksheet.mergeCells('A3:AM3');
    worksheet.mergeCells('A4:AM4');
    const endDate = new Date(filters?.dateRange[1]);
    let yearMonth = endDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    yearMonth = yearMonth.replace(' ', ' - ');
    addHeaderText(worksheet, yearMonth);

    worksheet.mergeCells('A5:D5');
    worksheet.getCell('A5').value = 'Name and Address of Contractor :';
    worksheet.mergeCells('E5:J5');
    worksheet.getCell('E5').value = data?.contractorDetails?.nameAndAddress;
    worksheet.mergeCells('M5:Q5');
    worksheet.getCell('M5').value = 'Name and Address of principal employer:';
    worksheet.mergeCells('R5:AL5');
    worksheet.getCell('R5').value = `${selectedLocation?.name || ''},  ${selectedLocation?.address || ''}`;  // location name and address;
    worksheet.getRow(5).font = boldfont;

    worksheet.mergeCells('A6:D6');
    worksheet.getCell('A6').value = 'Nature of work :';
    worksheet.mergeCells('E6:J6');
    worksheet.getCell('E6').value = data?.contractorDetails?.natureOfWork;
    worksheet.mergeCells('M6:Q6');
    worksheet.getCell('M6').value = 'Wage Period';
    worksheet.mergeCells('R6:AL6');
    worksheet.getCell('R6').value = yearMonth;
    worksheet.getRow(6).font = boldfont;


    worksheet.mergeCells('M7:T7');
    worksheet.getCell('M7').value = 'Fixed Wages Per Day';
    worksheet.getCell('M7').alignment = textAlignment;
    worksheet.mergeCells('U7:AD7');
    worksheet.getCell('U7').value = 'Earning Wages Per Month';
    worksheet.getCell('U7').alignment = textAlignment;
    worksheet.mergeCells('AE7:AI7');
    worksheet.getCell('AE7').value = 'Deductions if any';
    worksheet.getCell('AE7').alignment = textAlignment;
    worksheet.getRow(7).font = boldfont;

    worksheet.getCell('AM5').value = `Run time : ${formattedTime}`;
    worksheet.getCell('AM6').value = `Ran By : ${userConfig?.userid}`;


    const tableHeaders = [
      'Sr. No.',
      'Emp No.',
      'Unique Num',
      'Workmen Name',
      'Skill',
      'Sub Skill',
      'Designation',
      'PF Num',
      'ESI Num',
      'No of days worked',
      'Holiday days',
      'OT Hours',
      'Basic',
      'VDA',
      'Washing_Allowance',
      'HRA',
      'Bonus @ 8.33%',
      'LWW @ 4.165%',
      'Other Allowance',
      'Gross',
      'Basic',
      'VDA',
      'Washing_Allowance',
      'HRA',
      'Bonus @ 8.33%',
      'LWW @ 4.165%',
      'OT Amount',
      'Other Allowance',
      'NHFH Amount',
      'Gross Salary',
      'PF @%12',
      'ESI @0.75%',
      'Canteen',
      'PT',
      'LWF',
      'Total Deductions',
      'Net Pay',
      'Bank Account Details',
      'Signature/Thumb impression of workmen'
    ];

    // Add headers to the worksheet
    worksheet.addRow()
    addNewRow(worksheet, tableHeaders, 8)
    const headerRow = worksheet.getRow(8);
    headerRow.font = { bold: true, size: 9, name: 'Arial' };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };


    // Step 6: Add table data
    documents.forEach((doc, index) => {
      const rowData = [
        doc.key,
        doc.employeeId,
        doc.uniqueNum,
        doc.name,
        doc.skill,
        doc.subSkill,
        doc.designation,
        doc.pfNum,
        doc.esiNum,
        doc.noOfDaysWorked,
        doc.holidayDays,
        doc.otHours,

        doc.fbasic,
        doc.fvda,
        doc.fwashingAllowance,
        doc.fhra,
        doc.fbonus,
        doc.flww,
        doc.fotherAllowance,
        doc.fgross,

        doc.ebasic,
        doc.evda,
        doc.ewashingAllowance,
        doc.ehra,
        doc.ebonus,
        doc.elww,
        doc.eotAmount,
        doc.eotherAllowance,
        doc.enhfhAmount,
        doc.egross,

        doc.dpf,
        doc.desi,
        doc.dcanteen,
        doc.dpt,
        doc.dlwf,

        doc.totalDeductions,
        doc.netPay,
        doc.bankAccountDetails,
        doc.signature,
      ];

      addNewRow(worksheet, rowData, 9 + index)
    });

    // Step 7: Set column widths
    // worksheet.columns = [
    //   { width: 15.33 },
    //   { width: 1.5 },
    //   { width: 3 },
    //   { width: 12 },
    //   { width: 3 },
    //   { width: 4 },
    //   { width: 6.67 },
    //   { width: 2.78 },
    //   { width: 6.00 },
    //   { width: 3.56 },
    //   { width: 13.22 },
    //   { width: 15.33 },
    //   { width: 15.33 },
    //   { width: 15.33 },
    //   { width: 15.33 },
    //   { width: 10.4 },
    //   { width: 0 },
    //   { width: 4.11 },
    //   { width: 6.33 },
    //   { width: 8.22 },
    //   { width: 13.67 },
    //   { width: 14.22 },
    // ];

    // Step 8: Add borders to all cells
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // Step 9: Write the workbook to a file and trigger
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filters?.contractors} - ${selectedReport?.reportName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  const applyFilters = async (filters) => {
    setLoading(true);

    try {
      const { dateRange, locations, contractors } = filters;
      const response = await getWageRegisterData({
        location: locations,
        contractorName: contractors,
        startDate: getLocalDate(dateRange[0]),
        endDate: getLocalDate(dateRange[1])
      });
      const data = response?.data?.data
      setData(data);
    } catch (error) {
      setData({});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        downloadCSV={downloadCSV}
        userConfig={userConfig}
        contractorList={contractorList}
        locationList={locationList}
        applyFilters={applyFilters}
        loading={loading}
        disableDownload={!data?.wageRegisterData?.length || loading}
        locationwiseContractors={locationwiseContractors}
      />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={data?.wageRegisterData}
          scroll={{ x: true }}
          bordered
          title={() => (<></>)}
          pagination={{ pageSize: 50 }}
        />
      </Spin>
    </>
  );
};

export default WageRegister;
