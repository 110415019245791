import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Upload,
  message,
  Form,
  Dropdown,
  Space,
  Typography,
  menuProps,
} from "antd";
import {
  SearchOutlined,
  ReloadOutlined,
  UploadOutlined,
  DownloadOutlined,
  PrinterOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  uploadMinimumDatas,
  getMinimumWageDatas,
} from "./services/actions/api/minimumWage";
import moment from "moment";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import BulkUploadModal from "./SectionHead/BulkUploadModal";
// import "../../App.css";
import { useReactToPrint } from "react-to-print";
import useAuth from '../hooks/useAuth';
 
const columns = [
  {
    title: "Place",
    children: [
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        width: 90
      },
      {
        title: "Unit ID",
        dataIndex: "unitId",
        key: "unitId",
      },
    ],
  },
  {
    title: "Vender details",
    children: [
      {
        title: "Vender name",
        dataIndex: "venderName",
        key: "venderName",
        width: 100
      },
      {
        title: "Vender ID",
        dataIndex: "venderId",
        key: "venderId",
        width: 110
      },
    ],
  },
  {
    title: "Work Attributes",
    children: [
      {
        title: "Trade",
        dataIndex: "trade",
        key: "trade",
        width: 100
      },
      {
        title: "Skill",
        dataIndex: "skill",
        key: "skill",
        width: 100
      },
      {
        title: "Subskill",
        dataIndex: "subskill",
        key: "subskill",
        width: 100
      },
      {
        title: "Wage Type",
        dataIndex: "wageType",
        key: "wageType",
        width: 100
      },
    ],
  },
  {
    title: "Core Compensation",
    children: [
      {
        title: "Basic",
        dataIndex: "basic",
        key: "basic",
      },
      {
        title: "DA",
        dataIndex: "da",
        key: "da",
        width: 100
      },
    ],
  },
  {
    title: "Extra Compensations",
    children: [
      {
        title: "Skill Allowance",
        dataIndex: "skillAllowance",
        key: "skillAllowance",
        width: 100
      },
      {
        title: "Special Allowance",
        dataIndex: "specialAllowance",
        key: "specialAllowance",
        width: 100
      },
      {
        title: "Daily Batta",
        dataIndex: "dailyBatta",
        key: "dailyBatta",
      },
      {
        title: "Shift Allowance",
        dataIndex: "shiftAllowance",
        key: "shiftAllowance",
        width: 100
      },
      {
        title: "Variable Allowance",
        dataIndex: "variableAllowance",
        key: "variableAllowance",
        width: 100
      },
    ],
  },
  {
    title: "Regulatory Compensation",
    children: [
      {
        title: "Statutory Payout on PL",
        dataIndex: "statutoryPayoutOnPf",
        key: "statutoryPayoutOnPf",
        width: 100
      },
      {
        title: "NH & FH @ 12days",
        dataIndex: "nhfh12days",
        key: "nhfh12days",
        width: 100
      },
      {
        title: "Gratutity",
        dataIndex: "gratutity",
        key: "gratutity",
        width: 90
      },
      {
        title: "ESI",
        dataIndex: "esi",
        key: "esi",
      },
    ],
  },
  {
    title: "Duty Compensation",
    children: [
      {
        title: "Fair Wages",
        dataIndex: "fairWages",
        key: "fairWages",
      },
      {
        title: "Leave Wages",
        dataIndex: "leaveWages",
        key: "leaveWages",
      },
      {
        title: "Uniform Allowance",
        dataIndex: "uniformAllowance",
        key: "uniformAllowance",
        width: 100
      },
      {
        title: "Work Allowance",
        dataIndex: "workAllowance",
        key: "workAllowance",
        width: 100
      },
      {
        title: "Relieving Charges",
        dataIndex: "relievingCharges",
        key: "relievingCharges",
        width: 100
      },
      {
        title: "Training",
        dataIndex: "training",
        key: "training",
        width: 90
      },
    ],
  },
  {
    title: "Additional Compensation",
    children: [
      {
        title: "Medical Allowance",
        dataIndex: "medicalAllowance",
        key: "medicalAllowance",
        width: 100
      },
      {
        title: "Washing Allowance",
        dataIndex: "washingAllowance",
        key: "washingAllowance",
        width: 100
      },
      {
        title: "Education Allowance",
        dataIndex: "educationAllowance",
        key: "educationAllowance",
        width: 100
      },
      {
        title: "House Rent Allowance",
        dataIndex: "houseRentAllowance",
        key: "houseRentAllowance",
        width: 100
      },
      {
        title: "Levy",
        dataIndex: "levy",
        key: "levy",
      },
    ],
  }
];
 
const MinimumWage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    location: null,
    unitId: null,
    venderName: null,
    venderId: null,
    trade: null,
    skill: null,
    subskill: null,
    wageType: null,
    basic: null,
    da: null,
  });
  const { userConfig } = useAuth();
  const [pageSize, setPageSize] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const prevDataSourceRef = useRef();
  const componentRef = useRef();

  console.log("userConfig", userConfig);
  
 
  useEffect(() => {
    // if (prevDataSourceRef.current !== dataSource) {
      const fetchData = async () => {
        try {
          const res = await getMinimumWageDatas();
          if (JSON.stringify(res.data?.data?.minWageData) !== JSON.stringify(dataSource)) {
            setDataSource(res.data?.data?.minWageData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
  }, []);
 
  // Filtering logic
  const handleSearch = () => {
    let data = [...dataSource] || [];
 
    if (filters.search) {
      data = data.filter((item) =>
        [
          item.location,
          item.unitId,
          item.venderName,
          item.venderId,
          item.trade,
          item.skill,
          item.subskill,
          item.wageType,
          item.basic,
          item.da,
        ].some((field) =>
          field?.toString().toLowerCase().includes(filters.search.toLowerCase())
        )
      );
    }
    setFilteredData(data);
  };
 
  useEffect(() => {
    // handleSearch();
  }, [filters, dataSource]);
 
  const uploadExcel = async (file) => {
    try {
      if (!file || !(file instanceof Blob)) {
        throw new Error("The provided input is not a valid file.");
      }
 
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
 
        const mappedData = mapSheetDataToModel(sheetData);
        await uploadMinimumDatas(mappedData);
        message.success("File uploaded successfully!");
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error(`Error uploading file: ${error.message}`);
    }
  };
 
  const mapSheetDataToModel = (sheetData) => {
    const headers = sheetData[0];
    const rows = sheetData.slice(1);
 
    return rows.map((row) => {
      return {
        location: row[headers.indexOf("Location Name")],
        unitId: row[headers.indexOf("UNIT ID")],
        venderName: row[headers.indexOf("VENDOR NAME(IN SAP)")],
        venderId: JSON.stringify(row[headers.indexOf("VENDOR ID (SAP ID)")]),
        trade: row[headers.indexOf("JOB CODE")],
        skill: row[headers.indexOf("SKILL CODE")],
        subskill: row[headers.indexOf("Sub Skill")],
        wageType: row[headers.indexOf("WAGE TYPE ")],
        basic: row[headers.indexOf("Basic")],
        da: row[headers.indexOf("DA")],
        skillAllowance: row[headers.indexOf("Skill Allowance")],
        specialAllowance: row[headers.indexOf("Special Allowance")],
        dailyBatta: row[headers.indexOf("Daily Batta")],
        shiftAllowance: row[headers.indexOf("Shift Allowance")],
        variableAllowance: row[headers.indexOf("Variable Allowance")],
        statutoryPayoutOnPf: row[headers.indexOf("Statutory Bonus")],
        nhfh12days: row[headers.indexOf("NH &FH @ 12 Days")],
        gratutity: row[headers.indexOf("Gartuity")],
        esi: row[headers.indexOf("ESI")],
        fairWages: row[headers.indexOf("Fair Wages")],
        leaveWages: row[headers.indexOf("Leave Wages")],
        uniformAllowance: row[headers.indexOf("Uniform Allowance")],
        workAllowance: row[headers.indexOf("Work Allowancwe")],
        relievingCharges: row[headers.indexOf("Relieving Charges")],
        training: row[headers.indexOf("Training")],
        medicalAllowance: row[headers.indexOf("Medical Allowance")],
        washingAllowance: row[headers.indexOf("Washing Allowance")],
        educationAllowance: row[headers.indexOf("Education")],
        houseRentAllowance: row[headers.indexOf("House Rent Allowance")],
        levy: row[headers.indexOf("Levy")],
      };
    });
  };
 
  const handleClose = () => {
    setIsModalVisible(false);
  };
 
  const handleUploadSuccess = (newData) => {
    setDataSource(newData);
  };
 
  const showModal = () => {
    setIsModalVisible(true);
  };
 
  function handlePrint() {
    console.log("printing");
 
    // Create a style element
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @media print {
        .scrollable-content {
            overflow: visible !important;
            overflow-x: visible;
            height: fit-content !important;
        }
      }
   
    `;
 
    // Append the style to the head of the document
    document.head.appendChild(style);
 
    // Trigger the print
    window.print();
 
    // Optionally remove the style after printing
    window.onafterprint = () => {
      document.head.removeChild(style);
    };
  }
 
  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16}}>
        <Col span={6} >
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Minimum Wage
          </span>
        </Col>
        <Col span={12}>
          <Form form={form}>
            <Form.Item name="search" initialValue={filters.search}>
              <Input
                placeholder="Search"
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
                style={{ width: "100%" }}
                suffix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={6}>
          <Button
            type="default"
            icon={<PrinterOutlined />}
            ref={componentRef}
            onClick={handlePrint}
            className="scrollable-content"
            style={{ position: "relative", right: 0 }}
          />
          <Button
            type="primary"
            onClick={showModal}
            style={{ position: "absolute", right: "5px" }}
          >
            Import Details
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredData.length > 0 ? filteredData : dataSource}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true, // Enable the page size changer dropdown
          pageSizeOptions: ["10", "20", "50", "100"], // Options for page sizes
          onShowSizeChange: (current, size) => setPageSize(size), // Update page size on change
        }}
        scroll={{ x: 3000 }}
        sticky
      />
 
      <BulkUploadModal
        visible={isModalVisible}
        isMinWageSheet={true}
        isWorkOrderSheet={false}
        onClose={handleClose}
        onUploadSuccess={handleUploadSuccess}
        uploadExcel={uploadExcel} // Pass the uploadExcel function
      />
    </div>
  );
 
 };
 
export default MinimumWage;