import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Dropdown,
  Menu,
  Typography,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import '../styles.css';

const { RangePicker } = DatePicker;

const ReportHeader = (props) => {
  const {
    filters,
    setFilters,
    downloadPDF,
    downloadCSV,
    userConfig,
    chart,
    contractorList,
    locationList,
  } = props;

  const today = dayjs();

  const [dateOption, setDateOption] = useState('Last Month');
  const [selectedDateRange, setSelectedDateRange] = useState([
    today.subtract(1, 'month').startOf('day'),
    today,
  ]);

  const dateOptions = ['Today', 'Last Week', 'Last Month', 'Custom Range'];

  const handleDateChange = (value) => {
    setDateOption(value);

    let range = [];

    if (value === 'Today') {
      range = [today, today];
    } else if (value === 'Last Week') {
      range = [today.subtract(7, 'day'), today];
    } else if (value === 'Last Month') {
      range = [today.subtract(1, 'month').startOf('day'), today];
    } else {
      range = null;
    }

    setSelectedDateRange(range);
    setFilters((prev) => ({ ...prev, dateRange: range }));
    if(range != null) applyFilters({ ...filters, dateRange: range })
  };

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    setFilters((prev) => ({ ...prev, dateRange: dates }));
    applyFilters({ ...filters, dateRange: dates })
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key='pdf' onClick={downloadPDF}>
        PDF
      </Menu.Item>
      <Menu.Item key='csv' onClick={downloadCSV}>
        CSV
      </Menu.Item>
    </Menu>
  );

  const applyFilters = (filters) => {
    const { contractors, locations, dateRange } = filters;

    const query = {};

    if (contractors?.length > 0) {
      query['jobDetails.contractorName'] = { $in: contractors };
    }

    if (locations?.length > 0) {
      query.locationId = { $in: locations };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] != dateRange[1]) {
      query['jobDetails.doj'] = {
        $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
        $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
      };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] == dateRange[1]) {
      query['jobDetails.doj'] = new Date(
        dateRange[0].format('YYYY-MM-DD')
      ).toISOString();
    }

    console.log('query', query, filters);

    chart.setFilter(query);
  };

  return (
    <div
      style={{
        border: '1px solid #f0f0f0',
        padding: '16px',
        borderRadius: '4px',
      }}>
      <Row gutter={[16, 16]} align='middle'>
        <Col span={24}>
          <Row align='middle'>
            <Col flex='100px'>
              <Typography.Text strong>Date</Typography.Text>
            </Col>
            <Col flex='auto'>
              <Select
                className="custom-select"
                popupClassName="custom-select-dropdown"
                value={dateOption}
                style={{ width: '110px' }}
                onChange={handleDateChange}
                options={dateOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
              />
              <RangePicker
                style={{
                  marginLeft: '16px',
                  maxWidth: '186px',
                  height: '32px',
                }}
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                className='custom-range-picker'
                disabled={dateOption != 'Custom Range'}
              />
              {/* {dateOption !== 'Custom Range' && (
                <Typography.Text style={{ marginLeft: '16px' }}>
                  {dayjs().format('DD-MM-YYYY')}
                </Typography.Text>
              )} */}
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Button
                style={{ marginRight: '5px' }}
                type='primary'
                onClick={() => applyFilters(filters)}>
                Apply
              </Button>
              <Dropdown overlay={downloadMenu} trigger={['click']}>
                <Button icon={<DownloadOutlined />} />
              </Dropdown>
            </Col>
          </Row>
        </Col>

        {contractorList.length > 0 && (
          <Col span={22}>
            <Row align='middle'>
              <Col flex='100px'>
                <Typography.Text strong>Contractors</Typography.Text>
              </Col>
              <Col flex='auto'>
                <Select
                  mode='multiple'
                  placeholder='Select Contractors'
                  style={{ width: '100%' }}
                  defaultValue={contractorList}
                  options={[
                    //{ value: 'All', label: 'All' },
                    ...contractorList?.map((c) => ({ value: c, label: c })),
                  ]}
                  onChange={(value) =>
                    setFilters((prev) => ({ ...prev, contractors: value }))
                  }
                  maxTagCount={2}
                  maxTagPlaceholder={(omittedValues) =>
                    `+${omittedValues.length}`
                  }
                />
              </Col>
            </Row>
          </Col>
        )}

        {locationList.length > 0 && (
          <Col span={22}>
            <Row align='middle'>
              <Col flex='100px'>
                <Typography.Text strong>Locations</Typography.Text>
              </Col>
              <Col flex='auto'>
                <Select
                  mode='multiple'
                  placeholder='Select Locations'
                  style={{ width: '100%' }}
                  defaultValue={locationList}
                  options={[
                    //  { value: 'All', label: 'All' },
                    ...locationList?.map((l) => ({ value: l, label: l })),
                  ]}
                  // options={[
                  //   { value: 'All', label: 'All' },
                  //   { value: 'Location 1', label: 'Location 1' },
                  //   { value: 'Location 2', label: 'Location 2' },
                  // ]}
                  onChange={(value) =>
                    setFilters((prev) => ({ ...prev, locations: value }))
                  }
                  maxTagCount={2}
                  maxTagPlaceholder={(omittedValues) =>
                    `+${omittedValues.length}`
                  }
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ReportHeader;
