import React, {useState, useEffect} from 'react'
import blueCard from '../../../../assets/blueCard.svg'
import yellowCard from '../../../../assets/yellowCard.svg'
import orangeCard from '../../../../assets/orangeCard.svg'
import purpuleCard from '../../../../assets/purpuleCard.svg'
import {ReactComponent as Vendor} from '../../../../assets/Leader.svg'
import {ReactComponent as Compliant} from '../../../../assets/Compliant.svg'
import {ReactComponent as NonCompliant} from '../../../../assets/NonCompliant.svg'
import {ReactComponent as ImpactedEmployes} from '../../../../assets/ImpactedEmployes.svg'
import {Row, Col, Card, Typography} from 'antd'
import { getVendors } from '../../../../vyn/services/auth'




const ComplianceCharts = () => {
      const [chartsData, setChartData] = useState([]);

      useEffect(() => {
              const fetchData = async () => {
                try {
                  const response = await getVendors();
                  if (response) {
                    setChartData(response);
                    console.log("68 ",response )
                  }
                } catch (error) {
                  console.error("Error fetching pass reports:", error);
                }
              };
          
              fetchData();
            }, []);

            const cardData = [
              {
                title: "Total Vendors",
                value: `${chartsData?.data?.data?.totalSuppliers ? chartsData?.data?.data?.totalSuppliers : "0"}`,
                color: "#6C63FF",
                icon: <Vendor/>,
                backgroundImage: `url(${blueCard})`
              },
              {
                title: "Compliant Vendors",
                value: `${chartsData?.data?.data?.compliantCount ? chartsData?.data?.data?.compliantCount : "0"}`,
                color: "#EAAA00",
                icon: <Compliant/>,
                backgroundImage: `url(${yellowCard})`
              },
              {
                title: "Non compliant Vendors",
                value: `${chartsData?.data?.data?.nonCompliantCount ? chartsData?.data?.data?.nonCompliantCount : "0"}`,
                color: "#E4572E",
                icon: <NonCompliant/>
                ,
                backgroundImage: `url(${orangeCard})`
              },
              {
                title: "Impacted employee count",
                value: `${chartsData?.data?.data?.totalSuppliersMatchingLocation ? chartsData?.data?.data?.totalSuppliersMatchingLocation : "0"}`,
                color: "#6C63FF",
                icon: <ImpactedEmployes/>
                ,
                backgroundImage: `url(${purpuleCard})`
              },
            ];
return (
    <>
    <Row gutter={16} style={{ padding: "2px" }}>
          {cardData.map((card, index) => (
            <Col key={index} xs={24} sm={12} md={6}>
              <Card
                style={{
                  position: "relative",
                  borderRadius: "10px",
                  color: "white",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  textAlign: "left",
                  overflow: "hidden",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: card.backgroundImage, // Background for the card
                }}
                bodyStyle={{
                  padding: "20px",
                  height: "117px",
                  width: "300px", // Adjust width as needed
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                {/* Title styled as per Figma */}
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "50px",
                    fontSize: "12px",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "560",
                    lineHeight: "14.52px",
                    color: "white",
                  }}
                >
                  {card.title}
                </div>
    
                {/* Icon and Value section */}
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: "1px",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {/* Move the icon further left */}
                  <div
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "-15px", // Moves icon more to the left
                    paddingRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                ><div>
                  {React.cloneElement(card.icon, {
                    style: { height: "40px", width: "40px" }, // Ensure the icon is properly sized
                  })}
                  </div>
                </div>
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "580",
                      position: "relative",
                      top: "1.1rem",
                      left: "-29px"
                    }}
                  >
                    {card.value}
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
    </>
  );
}

export default ComplianceCharts