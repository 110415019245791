import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Table,
  Button,
  Select,
  Popconfirm,
  Typography,
  Divider,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { orgEmailValidator } from '../../../validators';
import useAuth from '../../hooks/useAuth';
import DocumentFormTable from './DocumentFormTable';
import OrganisationLocationForm from './OrganisationLocationForm';
import { State } from '../../JSON/data';
const { Option } = Select;

export default function OrganisationLocationPanel({
  field,
  index,
  formRef,
  orgInfoData,
  initialValues,
}) {
  const [form] = Form.useForm();
  const name = Form.useWatch('name');

  const { userConfig } = useAuth();

  const states = State;

  /** @type {import("antd/es/table").ColumnsType} */

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            {...field}
            label='Loc Name'
            name={[field.name, 'name']}
            // name="name"
            rules={[
              { required: true, message: 'Enter  Name' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              {
                pattern: /^[A-Za-z\s-]*$/,
                message: 'Only Alphabetic Allowed',
              },
            ]}>
            <Input id='locationName' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            {...field}
            label='Ho Location ID'
            name={[field.name, 'id']}
            rules={[
              { required: true, message: 'Enter Ho Location ID' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              // {
              //   pattern: /^[a-zA-Z0-9]+$/,
              //   message: 'Enter Alpha Numeric Only',
              // },
            ]}>
            {/* <Input addonBefore={`${orgInfoData.id}_ `} /> */}
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item {...field} label='Address' name={[field.name, 'address']}>
            <Input></Input>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item {...field} label='City' name={[field.name, 'city']}>
            <Input></Input>
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6} id='state'>
          <Form.Item
            {...field}
            label='State'
            name={[field.name, 'state']}
            // rules={[{ required: true, message: 'Enter State' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className='ant-select-selector'
              placeholder='Select State'>
              {states.map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item {...field} label='Zip Code' name={[field.name, 'zipCode']}>
            <Input></Input>
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      {/* <DocumentFormTable field={field} /> */}
    </>
  );
}
