import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Table, Space, Typography, Dropdown, Menu } from 'antd';
import { DownOutlined, EyeFilled } from '@ant-design/icons';
import { getAllReports, getDetails } from '../../../services/auth';
import ChartModal from './ChartModal';
import { getSupplier } from '../../../services/supplier';
import PreviewModal from './PreviewModal';

const Reports = () => {
  const { user, userConfig } = useAuth();

  const [reportsData, setReportsData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [permissions, setPermissions] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [locationwiseContractors, setlocationwiseContractors] = useState(null)

  function handleView(record) {
    console.log('Selected Report:', record); // Log the selected report
    setSelectedReport(record);
    if (["Register of Contractors - Form XII",
       "Wage Cum Musterroll", 
       "Register of Contract Workmen employed by Contractor - Form XIII"].includes(record.reportName)
        || record.reportName.includes('Wage Register')
      )
        setPreviewModalVisible(true);
    else
      setModalVisible(true);
  }

  useEffect(() => {
    getReportsData();
    fetchData();
  }, []);

  const getReportsData = async () => {
    const response = await getAllReports();
    setReportsData(
      response?.data?.data?.filter((chart) => chart.type == 'REPORTS')
    );
    getPermissions(userConfig.orgId);
  };

  const groupContractorsByLocation = (suppliers) => {
    return suppliers.reduce((acc, supplier) => {
      const { location, name } = supplier;

      if (!acc[location]) {
        acc[location] = [];
      }

      acc[location].push(name);

      return acc;
    }, {});
  };

  const fetchData = async () => {
    try {
      const res = await getSupplier(userConfig.orgId);
      const list = [];
      const locationId = userConfig.location || userConfig.locationId;

      setlocationwiseContractors(groupContractorsByLocation(res.data?.suppliers))

      if (locationId == null) {
        // case of super admin
        const locationMap = {};
        res.data?.suppliers?.map((c) => {
          list.push(c.name);
          locationMap[c.location] = 1;
        });
        setLocationList(Object.keys(locationMap));
      } else if (locationId) {
        res.data?.suppliers?.map((c) => {
          if (c.location == locationId) {
            list.push(c.name);
          }
        });
        setLocationList([locationId]);
      }
      setContractorList([...new Set(list)]);
    } catch (error) {
      console.error('Error fetching contractor name:', error);
    }
  };

  const getPermissions = (orgId) => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data?.filter(
          (i) => i.selectedRole?.toLowerCase() == userConfig.role.toLowerCase()
        );
        if (filteredData.length > 0) {
          setPermissions(filteredData[0]?.permissions?.Reports_And_Dashboard);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const renderAction = (key) => (
    <Space>
      <Typography onClick={() => handleView(reportsData?.find((item) => item.reportName === key))}>
        <EyeFilled />
      </Typography>
    </Space>
  );

  // Separate reports that contain "Wage Register"
  const wageRegisterReports = reportsData.filter((report) =>
    report.reportName.includes('Wage Register')
  );

  // Create a dropdown menu for "Wage Register" reports
  const dropdownMenu = (
    <Menu>
      {wageRegisterReports.map((report) => (
        <Menu.Item key={report.reportName} onClick={() => handleView(report)}>
          {report.reportName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const columns = [
    {
      title: 'SI. No.',
      dataIndex: 'index',
      width: 100,
      render: (_, __, index) => {
        //if (index === reportsData.length - wageRegisterReports.length) return <span>-</span>;
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Name Of Reports',
      dataIndex: 'reportName',
      key: 'name',
      render: (text, _, index) =>
        index === reportsData.length - wageRegisterReports.length ? (
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Typography.Link>
              Wage Registers <DownOutlined />
            </Typography.Link>
          </Dropdown>
        ) : text,
    },
    {
      title: 'Actions',
      dataIndex: 'reportName',
      render: (key, _, index) =>
        index === reportsData.length - wageRegisterReports.length
          ? null
          : renderAction(key),
    },
  ];

  // const columns = [
  //   {
  //     title: 'SI. No.',
  //     dataIndex: 'index',
  //     width: 100,
  //     render: (_, __, index) => <span>{index + 1}</span>,
  //   },
  //   {
  //     title: 'Name Of Reports',
  //     dataIndex: 'reportName',
  //     key: 'name',
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: 'reportName',
  //     render: (key) => (
  //       <Space>
  //         <Typography
  //           onClick={() =>
  //             handleView(reportsData?.find((item) => item.reportName === key))
  //           }>
  //           <EyeFilled />
  //         </Typography>
  //       </Space>
  //     ),
  //   },
  // ];

  // Filter out individual "Wage Register" reports so only the dropdown appears
  
  const filteredData = reportsData.filter(
    (report) => !report.reportName.includes('Wage Register')
  );
  // Add a single row for the dropdown at the end
  if (wageRegisterReports.length > 0) {
    filteredData.push({ reportName: 'Wage Registers', isDropdown: true });
  }

  return (
    <div>
      <Table dataSource={filteredData} columns={columns} />
      {modalVisible && (
        <ChartModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          selectedReport={selectedReport}
          permissions={permissions}
          userConfig={userConfig}
          contractorList={contractorList}
          locationList={locationList}
        />
      )}
      {previewModalVisible && (
        <PreviewModal
          previewModalVisible={previewModalVisible}
          setPreviewModalVisible={setPreviewModalVisible}
          selectedReport={selectedReport}
          permissions={permissions}
          userConfig={userConfig}
          contractorList={contractorList}
          locationList={locationList}
          locationwiseContractors={locationwiseContractors}
        />
      )}
    </div>
  );
};

export default Reports;
