import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import {
  Button,
  Divider,
  Select,
  Table,
  Row,
  Col,
  Form,
  Collapse,
  Space,
  Typography,
  Upload,
  notification,
  Tooltip,
  List,
  Input,
} from 'antd';
import { Modal, Steps } from 'antd';
import dayjs from 'dayjs';
import {
  getContractorOnboard,
  getCloudInfo,
  esiIteartion,
  sftpExcel,
} from '../services/auth';

import {
  EyeFilled,
  SendOutlined,
  DownloadOutlined,
  AlertTwoTone,
  UploadOutlined,
} from '@ant-design/icons';
import { esi, Email } from '../services/auth';
import { getAllTransaction } from '../../vyn/services/auth';
import axios from '../services/axiosInstance';
import useAuth from '../hooks/useAuth';
import { encrypt } from '../utils/crypto';

const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;
const { Step } = Steps;

function Esireport() {
  const [modalVisible, setModalVisible] = useState(false);
  const [Employee, setEmployee] = useState([]);
  const [Employee1, setEmployee1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = React.useState({});
  const { userConfig } = useAuth();
  const [form] = Form.useForm();
  const [Request, setRequest] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [selectedDropdown1, setSelectedDropdown1] = useState('PDF');
  const [selectedDropdown2, setSelectedDropdown2] = useState(null);
  const [exceptionRecords, setExceptionRecords] = useState(false);
  const [excepEmployeeRecords, setExcepEmployeeRecords] = useState([]);
  const [nomatchRecords, setNoMatchRecords] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [fileExplorerVisible, setFileExplorerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const roleName = userConfig.roleName;
  const [selectedContractors, setSelectedContractors] = useState([]); // State to track selected rows
  const [contractorFilters, setContractorFilters] = useState([]);
  const getPopupContainer = (triggerNode) => triggerNode.parentNode;

  const onKronosFileUpload = (file) => {
    form.setFieldsValue({ kronosFile: file });
  };

  const onPortalFileUpload = (file) => {
    form.setFieldsValue({ portalFile: file });
  };
  const { Item } = Form;
  React.useEffect(() => {
    if (selectedDropdown2 !== null) {
      EmployeeData();
    }
  }, [selectedDropdown2]);

  useEffect(() => {
    if (users.Response1) {
      const filters = users.Response1.map((option) => ({
        text: option.name, // Visible in the filter dropdown
        value: option.name, // Used for filtering logic
      }));
      setContractorFilters(filters); // Set the filters dynamically
    }
  }, [users.Response1]);

  const onFinish = (value) => {
    const { portalFile, supplierId } = value;
    const [supplierIdValue, supplierName] = supplierId.split(',');

    console.log(
      'value payment date',
      dayjs(value.paymentDate).format('YYYY-MM-DD')
    );

    const formData = new FormData();
    formData.append('esiKronos', kronosFile.file);
    formData.append('esiPortal', portalFile.file);
    formData.append('acceptableDifference', value?.acceptableDifference);
    formData.append('month', value?.month);

    formData.append('orgId', userConfig.orgId);
    formData.append('supplierId', supplierIdValue);
    formData.append('supplierName', supplierName);
    formData.append(
      'paymentDate',
      dayjs(value.paymentDate).format('YYYY-MM-DD')
    );

    esi(formData)
      .then((res) => {
        const transactionId1 = res.data.transactionId;
        setRequest({ transactionId1 });
        notification.success({ message: 'ESI Uploaded Succesfully' });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    setModalVisible(true);
  };

  useEffect(() => {
    if (Request.transactionId1) {
      axios
        .get(`/report/transactions/${Request.transactionId1}`)
        .then((res) => {
          setReportData(res.data);

          setEmployee(
            res.data.data.report?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              esiumber: row['ESI No'],
              Egwr: row['Emp Gross Pay as per Wage Reg'],
              Egec: row['Emp Gross Pay as per MC Challan'],
              Diffamount: row['Diff amt in Wages'],
              Esiwr: row['ESI per Wage Reg'],
              Esic: row['ESI per MC Challan'],
              Diffamountcontribution: row['Diff Amount in Contribution'],
              // dueDate: res?.data?.data?.dueDate,
              dueDate: dayjs(res?.data?.data?.dueDate)?.format('DD-MM-YYYY'),

              isCompliant: res?.data?.data?.isCompliant,
              supplierName: res?.data?.data?.supplierName,
              acceptableDifference: res?.data?.data?.acceptableDifference,
              month: res?.data?.data?.month,
            }))
          );
          const data = res.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((row) => ({
                empName1: row['Emp Name'],
                empCode1: row['Emp Code'],
                esiumber1: row['ESI No'],
                Egwr1: row['Emp Gross Pay as per Wage Reg'],
                Egec1: row['Emp Gross Pay as per MC Challan'],
                Diffamount1: row['Diff amt in Wages'],
                Esiwr1: row['ESI per Wage Reg'],
                Esic1: row['ESI per MC Challan'],
                Diffamountcontribution1: row['Diff Amount in Contribution'],
                // dueDate: res?.data?.data?.dueDate,

                dueDate: dayjs(res?.data?.data?.dueDate)?.format('DD-MM-YYYY'),

                isCompliant: res?.data?.data?.isCompliant,
              }))
          );

          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((row) => ({
                empName2: row['Emp Name'],
                empCode2: row['Emp Code'],
                esiumber2: row['ESI No'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
                // Egwr1: row["Emp Gross Pay as per Wage Reg"],
                // Egec1: row["Emp Gross Pay as per MC Challan"],
                // Diffamount1: row["Diff amt in Wages"],
                // Esiwr1: row["ESI per Wage Reg"],
                // Esic1: row["ESI per MC Challan"],
                // Diffamountcontribution1: row["Diff Amount in Contribution"],
              }))
          );

          setTransactionId(res.data.transactionId);
          setModalVisible(true);
        })
        .catch((err) => {});
    }
  }, [Request.transactionId1]);

  React.useEffect(() => {
    EmployeeList();
    ExceptionReport();
    OrganizationInfo();
    CloudInfo();
    GetAllTransactionReport();
  }, []);

  const GetAllTransactionReport = async () => {
    getAllTransaction({ orgId: userConfig?.orgId, type: 'ESI' }).then(
      (response) => {
        console.log('response is 202', response.data.data);
        const employeeData = response?.data?.data || [];

        // Group by contractor and month, and find the highest iteration
        const maxIterationsByContractorMonth = employeeData.reduce(
          (acc, row) => {
            const contractor = row?.supplierName;
            const month = row?.month;
            const key = `${contractor}-${month}`; // Unique key for contractor & month
            const iteration = parseInt(row?.noOfIteration, 10) || 0;

            if (!acc[key] || iteration > acc[key]) {
              acc[key] = iteration;
            }
            return acc;
          },
          {}
        );

        setEmployee1(
          response?.data?.data?.reverse()?.map((row) => ({
            transactionId: row.transactionId,
            timestamp: dayjs(row.timestamp).format('DD-MM-YYYY'),
            kronosFileName: row.kronosFileName,
            portalFileName: row.portalFileName,
            contractorName: row?.supplierName,
            contractorCode: row?.supplierId,
            acceptableDifference: row?.acceptableDifference,
            month: row?.month?.charAt(0)?.toUpperCase() + row?.month?.slice(1),
            issues: `Mismatched Data (${row?.exceptionCount}) \nNo Match Data (${row?.noMatchCount})`,
            transactionStatus:
              row?.exceptionCount > 0 ? 'ESI-MC Pending' : 'Closed',
            lastValidated: dayjs(row?.lastValidated).format(
              'HH:mm, DD-MM-YYYY'
            ),
            noOfIteration: row?.noOfIteration,
            exceptionCount: row?.exceptionCount,
            flag:
              parseInt(row?.noOfIteration, 10) ===
              maxIterationsByContractorMonth[
                `${row?.supplierName}-${row?.month}`
              ]
                ? 'Highest'
                : '',
          }))
        );
      }
    );
  };

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }
  async function ExceptionReport() {}
  const PfClick = () => {
    window.open('https://esic.gov.in/', '_blank');
  };
  const handleModalCancel = () => {
    // Hide the modal
    setModalVisible(false);
    window.location.reload();
  };
  function handleDropdown1Change(value) {
    setSelectedDropdown1(value);
  }
  const downloadFile = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'all',
      transactionId: Request.transactionId1 || transactionId,
      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const downloadFile1 = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'exception',
      // transactionId: Request.transactionId1,
      transactionId: Request.transactionId1 || transactionId,

      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleDropdown2Change(value) {
    const [supplierIdValue, supplierName] = value.split(',');

    const supplierId = value.split(' ')[1];
    setSelectedSupplier(supplierIdValue);
    setSelectedDropdown2(supplierIdValue);
    // ExceptionReport();
    // if (value !== users.Response1[0].id) {
    //   EmployeeData();
    // }
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const FileExplorer = ({ files, onFileSelect, onClose }) => {
    const handleFileChange = (file) => {
      setSelectedFile(file);
    };

    const handleUpload = () => {
      onFileSelect(selectedFile);
      onClose();
    };
    return (
      <Modal
        title='Cloud File Explorer'
        visible={true}
        onCancel={onClose}
        maskClosable={false} // Prevent closing on outside click
        footer={[
          <Button key='back' onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!selectedFile}
            onClick={handleUpload}>
            Upload
          </Button>,
        ]}>
        {/* Display files from prop using Ant Design List */}
        <List
          header={<div>Cloud Files</div>}
          bordered
          dataSource={files}
          renderItem={(file) => (
            <List.Item>
              <a href={file.link} target='_blank' rel='noopener noreferrer'>
                {file.name}
              </a>
            </List.Item>
          )}
        />
        {/* <Upload
          type="file"
          beforeUpload={() => false}
          onChange={(info) => handleFileChange(info.file)}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload> */}
      </Modal>
    );
  };

  const EmployeeData = async () => {
    const querydata = {
      orgId: userConfig.orgId,
      supplierId: selectedDropdown2,
      type: 'ESI',
    };
    const decryptPayload = await encrypt(JSON.stringify(querydata));

    const Response = axios
      .get(`report/transactions?payload=${decryptPayload}`)
      .then((response) => {
        const employeeData = response.data.data;
        // Find the maximum noOfIteration
        const maxIteration = Math.max(
          ...employeeData.map((row) => parseInt(row.noOfIteration, 10))
        );
        setEmployee1(
          response.data.data.map((row) => ({
            transactionId: row.transactionId,
            // timestamp:row.timestamp,
            timestamp: dayjs(row.timestamp).format('DD-MM-YYYY'),

            kronosFileName: row.kronosFileName,
            portalFileName: row.portalFileName,
            contractorName: row?.supplierName,
            acceptableDifference: row?.acceptableDifference,
            month: row?.month?.charAt(0)?.toUpperCase() + row?.month?.slice(1),
            issues: `Mismatched Data (${row?.exceptionCount}) \nNo Match Data (${row?.noMatchCount})`,
            // transactionStatus: 'ESI-MC Pending',
            transactionStatus:
              row?.exceptionCount > 0 ? 'ESI-MC Pending' : 'Closed',
            lastValidated: dayjs(row?.lastValidated).format(
              'HH:mm, DD-MM-YYYY'
            ),
            noOfIteration: row?.noOfIteration,
            exceptionCount: row?.exceptionCount,
            flag:
              parseInt(row.noOfIteration, 10) === maxIteration ? 'Highest' : '',
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleView(id) {
    setTransactionId(id);
    setModalVisible(true);
    setVisible(true);
    setLoading(true);
    axios
      .get(`/report/transactions/${id}`)
      .then((response) => {
        setReportData(response.data);

        setEmployee(
          response.data.data.report?.map((row) => ({
            empName: row['Emp Name'],
            empCode: row['Emp Code'],
            esiumber: row['ESI No'],
            Egwr: row['Emp Gross Pay as per Wage Reg'],
            Egec: row['Emp Gross Pay as per MC Challan'],
            Diffamount: row['Diff amt in Wages'],
            Esiwr: row['ESI per Wage Reg'],
            Esic: row['ESI per MC Challan'],
            Diffamountcontribution: row['Diff Amount in Contribution'],
            dueDate: dayjs(response?.data?.data?.dueDate)?.format('DD-MM-YYYY'),
          }))
        );
        const data = response.data;
        setExceptionRecords(data.data.exceptionCount > 0);
        setExcepEmployeeRecords(
          data.data.report
            ?.filter((record) => record.isExceptionRecord)
            .map((row) => ({
              empName1: row['Emp Name'],
              empCode1: row['Emp Code'],
              esiumber1: row['ESI No'],
              Egwr1: row['Emp Gross Pay as per Wage Reg'],
              Egec1: row['Emp Gross Pay as per MC Challan'],
              Diffamount1: row['Diff amt in Wages'],
              Esiwr1: row['ESI per Wage Reg'],
              Esic1: row['ESI per MC Challan'],
              Diffamountcontribution1: row['Diff Amount in Contribution'],
              dueDate: dayjs(response?.data?.data?.dueDate)?.format(
                'DD-MM-YYYY'
              ),
            }))
        );
        setNoMatchRecords(
          data.data.report
            ?.filter((record) => record.isNoMatch)
            .map((row) => ({
              empName2: row['Emp Name'],
              empCode2: row['Emp Code'],
              esiumber2: row['ESI No'],
              // Egwr1: row["Emp Gross Pay as per Wage Reg"],
              // Egec1: row["Emp Gross Pay as per MC Challan"],
              // Diffamount1: row["Diff amt in Wages"],
              // Esiwr1: row["ESI per Wage Reg"],
              // Esic1: row["ESI per MC Challan"],
              // Diffamountcontribution1: row["Diff Amount in Contribution"],
            }))
        );
        // setData(response.data);
        setLoading(false);
        // setLoading(false);
        // EmployeeData();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  const handleDownload = (download) => {
    console.log('download', download);
    // return;

    // download.preventDefault();
    axios
      .post(
        `/report/download/PDF`,
        {
          reportType: 'exception',
          transactionId: download.transactionId,
          // supplierId: selectedSupplier,
          supplierId: download?.contractorCode,
        },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      });
  };

  const columns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName',
      key: 'empName',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode',
      key: 'empCode',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber',
      key: 'esiumber',
    },
    {
      title: 'Emp Gross Pay as per Wage Reg',
      dataIndex: 'Egwr',
      key: 'Egwr',
    },
    {
      title: "Emp Gross Pay as per MC Challan'",
      dataIndex: 'Egec',
      key: 'Egec',
    },
    {
      title: 'Diff amt in Wages',
      dataIndex: 'Diffamount',
      key: 'Diffamount',
      render: (value) => {
        return (
          <span
            style={{
              color: value > 0 ? 'red' : 'inherit',
              fontWeight: 'bold',
            }}>
            {value}
          </span>
        );
      },
    },
    {
      title: 'ESI per Wage Reg',
      dataIndex: 'Esiwr',
      key: 'Esiwr',
    },
    {
      title: 'ESI per MC Challan',
      dataIndex: 'Esic',
      key: 'Esic',
    },
    {
      title: 'Diff Amount in Contribution',
      dataIndex: 'Diffamountcontribution',
      key: 'Diffamountcontribution',
      render: (value) => {
        return (
          <span
            style={{
              color: value > 0 ? 'red' : 'inherit',
              fontWeight: 'bold',
            }}>
            {value}
          </span>
        );
      },
    },
    {
      title: 'Transaction Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      color: 'red',
    },
    {
      title: 'Remarks',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        const {
          Diffamount,
          Diffamountcontribution,
          dueDate,

          acceptableDifference = 0,
        } = record;
        const transactionDate = dayjs(dueDate); // Parse the due date
        const dueDay = 15; // Threshold for due date
        const withinTimeline = transactionDate.date() <= dueDay; // Check if within timeline

        let remarks = '';

        // Check for "Non-Compliant (Crossing Due Date and Tolerance Limit)"
        // const exceedsTolerance =
        //   Diffamount > acceptableDifference ||
        //   Diffamountcontribution > acceptableDifference;

        // if (exceedsTolerance && !withinTimeline) {
        //   remarks = 'Non-Compliant (Crossing Due Date and Tolerance Limit)';
        // } else if (!exceedsTolerance && !withinTimeline) {
        //   remarks = 'Non-Compliant (Crossing the Due Date)';
        // } else if (!exceedsTolerance && withinTimeline) {
        //   remarks = 'Fully Compliant';
        // } else if (exceedsTolerance && withinTimeline) {
        //   remarks = 'Non-Compliant (Crossing the Tolerance Limit)';
        // } else {
        //   remarks = 'Status Undefined';
        // }

        const exceedsTolerance =
          Diffamount > reportData?.data?.acceptableDifference ||
          Diffamountcontribution > reportData?.data?.acceptableDifference;

        if (
          Diffamount === 0 &&
          Diffamountcontribution === 0 &&
          withinTimeline
        ) {
          remarks = 'Fully Compliant';
        } else if (
          Diffamount <= reportData?.data?.acceptableDifference &&
          Diffamountcontribution <= reportData?.data?.acceptableDifference &&
          withinTimeline
        ) {
          remarks = 'Compliant(Within Tolerance Limit)';
        } else if (
          Diffamount <= contractorDetails?.acceptableDifference &&
          Diffamountcontribution <= contractorDetails?.acceptableDifference &&
          !withinTimeline
        ) {
          remarks = 'Non Compliant(Crossing Due Date))';
        } else if (exceedsTolerance && withinTimeline) {
          remarks = 'Non-Compliant (Crossing the Tolerance Limit)';
        } else if (exceedsTolerance && !withinTimeline) {
          remarks = 'Non-Compliant (Crossing Due Date and Tolerance Limit)';
        } else if (
          Diffamount === 0 &&
          Diffamountcontribution === 0 &&
          !withinTimeline
        ) {
          remarks = 'Non Complaint(Crossing Due Date)';
        } else {
          remarks = 'Status Undefined';
        }

        return <span>{remarks}</span>;
      },
    },
  ];
  const exceptioncolumns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName1',
      key: 'empName1',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode1',
      key: 'empCode1',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber1',
      key: 'esiumber1',
    },
    {
      title: 'Emp Gross Pay as per Wage Reg',
      dataIndex: 'Egwr1',
      key: 'Egwr1',
    },
    {
      title: "Emp Gross Pay as per MC Challan'",
      dataIndex: 'Egec1',
      key: 'Egec1',
    },
    {
      title: 'Diff amt in Wages',
      dataIndex: 'Diffamount1',
      key: 'Diffamount1',
      render: (value) => {
        return (
          <span
            style={{
              color: value > 0 ? 'red' : 'inherit',
              fontWeight: 'bold',
            }}>
            {value}
          </span>
        );
      },
    },

    {
      title: 'ESI per Wage Reg',
      dataIndex: 'Esiwr1',
      key: 'Esiwr1',
    },
    {
      title: 'ESI per MC Challan',
      dataIndex: 'Esic1',
      key: 'Esic1',
    },
    {
      title: 'Diff Amount in Contribution',
      dataIndex: 'Diffamountcontribution1',
      key: 'Diffamountcontribution1',
      render: (value) => {
        return (
          <span
            style={{
              color: value > 0 ? 'red' : 'inherit',
              fontWeight: 'bold',
            }}>
            {value}
          </span>
        );
      },
    },
    {
      title: 'Transaction Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
    },
    {
      title: 'Remarks',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        const {
          Diffamount1,
          Diffamountcontribution1,

          dueDate,
          acceptableDifference = 0,
        } = record;
        const transactionDate = dayjs(dueDate); // Parse the due date
        const dueDay = 15; // Threshold for due date
        const withinTimeline = transactionDate.date() <= dueDay; // Check if within timeline

        let remarks = '';

        // Check for "Non-Compliant (Crossing Due Date and Tolerance Limit)"
        // const exceedsTolerance =
        //   Diffamount1 > acceptableDifference ||
        //   Diffamountcontribution1 > acceptableDifference;

        // if (exceedsTolerance && !withinTimeline) {
        //   remarks = 'Non-Compliant (Crossing Due Date and Tolerance Limit)';
        // } else if (!exceedsTolerance && !withinTimeline) {
        //   // Non-compliant due to due date only
        //   remarks = 'Non-Compliant (Crossing the Due Date)';
        // } else if (!exceedsTolerance && withinTimeline) {
        //   // Fully compliant
        //   remarks = 'Fully Compliant';
        // } else if (exceedsTolerance && withinTimeline) {
        //   // Non-compliant due to tolerance limit only
        //   remarks = 'Non-Compliant (Crossing the Tolerance Limit)';
        // } else {
        //   // General case fallback for undefined states
        //   remarks = 'Status Undefined';
        // }

        const exceedsTolerance =
          Diffamount1 > reportData?.data?.acceptableDifference ||
          Diffamountcontribution1 > reportData?.data?.acceptableDifference;

        if (
          Diffamount1 === 0 &&
          Diffamountcontribution1 === 0 &&
          withinTimeline
        ) {
          remarks = 'Fully Compliant';
        } else if (
          Diffamount1 <= reportData?.data?.acceptableDifference &&
          Diffamountcontribution1 <= reportData?.data?.acceptableDifference &&
          withinTimeline
        ) {
          remarks = 'Compliant(Within Tolerance Limit)';
        } else if (
          Diffamount1 <= contractorDetails?.acceptableDifference &&
          Diffamountcontribution1 <= contractorDetails?.acceptableDifference &&
          !withinTimeline
        ) {
          remarks = 'Non Compliant(Crossing Due Date))';
        } else if (exceedsTolerance && withinTimeline) {
          remarks = 'Non-Compliant (Crossing the Tolerance Limit)';
        } else if (exceedsTolerance && !withinTimeline) {
          remarks = 'Non-Compliant (Crossing Due Date and Tolerance Limit)';
        } else if (
          Diffamount1 === 0 &&
          Diffamountcontribution1 === 0 &&
          !withinTimeline
        ) {
          remarks = 'Non Complaint(Crossing Due Date)';
        } else {
          remarks = 'Status Undefined';
        }

        return <span>{remarks}</span>;
      },
    },
  ];
  const nomatchRecord = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName2',
      key: 'empName2',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode2',
      key: 'empCode2',
    },
    // {
    //   title: 'UAN Number',
    //   dataIndex: 'Uannumber2',
    //   key: 'Uannumber2',
    // },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber',
      key: 'esiumber',
    },
    {
      title: 'Emp Gross Pay as per Wage Reg',
      dataIndex: 'Egwr',
      key: 'Egwr',
    },
    {
      title: "Emp Gross Pay as per MC Challan'",
      dataIndex: 'Egec',
      key: 'Egec',
    },
    {
      title: 'Diff amt in Wages',
      dataIndex: 'Diffamount',
      key: 'Diffamount',
    },
    {
      title: 'ESI per Wage Reg',
      dataIndex: 'Esiwr',
      key: 'Esiwr',
    },
    {
      title: 'ESI per MC Challan',
      dataIndex: 'Esic',
      key: 'Esic',
    },
    {
      title: 'Diff Amount in Contribution',
      dataIndex: 'Diffamountcontribution',
      key: 'Diffamountcontribution',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  //Before working Compliance
  // const column = [

  //   {
  //     title: 'Transaction ID',
  //     dataIndex: 'transactionId',
  //     key: 'transactionId',
  //     editable: true,
  //   },
  //   {
  //     title: 'Date',
  //     dataIndex: 'timestamp',
  //     key: 'timestamp',
  //   },
  //   {
  //     title: 'Wage Register',
  //     dataIndex: 'kronosFileName',
  //     key: 'kronosFileName',
  //   },
  //   {
  //     title: 'Portal File',
  //     dataIndex: 'portalFileName',
  //     key: 'portalFileName',
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: 'transactionId',
  //     render: (_, record) => (
  //       <Space>
  //         <Typography onClick={() => handleDownload(record.transactionId)}>
  //           <DownloadOutlined />
  //         </Typography>
  //         <Typography onClick={() => handleView(record.transactionId)}>
  //           <EyeFilled />
  //         </Typography>
  //       </Space>
  //     ),
  //   },
  // ];

  const [principalEmployername, setprincipalEmployername] = useState(null);
  const [contractorDetails, setContractorDetails] = useState({});

  const handleRowClick = (record) => {
    console.log('Row clicked:', record);
    // Perform actions like view or edit based on `record.transactionId`
    handleView(record.transactionId);
    setContractorDetails({
      contractorName: record.contractorName,
      acceptableDifference: record.acceptableDifference,
      month: record.month,
      noOfIteration: record?.noOfIteration,
      exceptionCount: record?.exceptionCount,
      flag: record?.flag,
      lastValidated: record?.lastValidated,
    });
    setSelectedSupplier(record?.contractorCode);
    setSelectedDropdown2(record?.contractorCode);
  };

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const name = res.data.org.name;
      setprincipalEmployername(res.data.org.name);
    });
  };

  const CloudInfo = async () => {
    getCloudInfo().then((res) => {
      const data = res.data;
      setFiles(data);
    });
  };

  const openGoogleCloudStorageBucket = async () => {
    // Wait for the file upload to finish asynchronously
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Replace 'your_bucket_name' with the actual name of your Google Cloud Storage bucket
    const bucketName = process.env.BUCKET_NAME;

    // Open the bucket in the Google Cloud Storage Browser
    window.open(
      `https://console.cloud.google.com/storage/browser/${bucketName}`,
      '_blank'
    );
    // window.location.href = `https://console.cloud.google.com/storage/browser/${bucketName}`;
  };

  const openFileExplorer = () => {
    setFileExplorerVisible(true);
  };

  const closeFileExplorer = () => {
    setFileExplorerVisible(false);
  };

  const handleFileSelect = (file) => {
    // Handle the selected file (e.g., update state, perform actions)
  };

  //
  const sampleFiles = [
    { id: 1, name: 'File 1.txt' },
    { id: 2, name: 'File 2.jpg' },
    // Add more files as needed
  ];
  const getMonthOptions = () => [
    { text: 'January', value: 'January' },
    { text: 'February', value: 'February' },
    { text: 'March', value: 'March' },
    { text: 'April', value: 'April' },
    { text: 'May', value: 'May' },
    { text: 'June', value: 'June' },
    { text: 'July', value: 'July' },
    { text: 'August', value: 'August' },
    { text: 'September', value: 'September' },
    { text: 'October', value: 'October' },
    { text: 'November', value: 'November' },
    { text: 'December', value: 'December' },
  ];
  const [pfChalanEnable, setPFchalanEnable] = useState(true);

  const PfChalanClick = () => {
    notification.info({
      message: 'Reminder Sent',
      description: 'PF challan reminder has been sent.',
      placement: 'topRight', // You can change the placement as needed
      duration: 3, // Auto-close after 3 seconds
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContractors(selectedRows.map((row) => row.contractorName)); // Save selected contractor names
      setPFchalanEnable(false);
    },
    getCheckboxProps: (record) => ({
      name: record.contractorName, // Customize checkbox name if needed
    }),
  };

  const [lastValidatedDate, setLastValidatedDate] = useState('');

  const [kronosFile, setKronosFile] = useState(null);
  console.log('kronosFile96', kronosFile);

  const getExcelSftpData = async (value) => {
    console.log('value is', value);
    try {
      setLoading(true);

      // Ensure that sftpExcel is configured to return a blob (if using axios)
      const res = await sftpExcel(value, { responseType: 'blob' });
      if (!res || !res.data) {
        throw new Error('No file received');
      }

      // Use the correct MIME type for XLSX files
      const correctMimeType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([res.data], { type: correctMimeType });

      // Attempt to extract the filename from the response headers
      const contentDisposition = res.headers['content-disposition'];
      let fileName = 'Wikiworks 100006 201700025 January 2025.xlsx';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }

      // Create a File object from the Blob with the correct MIME type
      const file = new File([blob], fileName, { type: correctMimeType });

      // Update state so the file is stored and can be used in your Upload component
      setKronosFile({
        file,
        fileList: [
          {
            uid: '-1',
            name: fileName,
            status: 'done',
            url: window.URL.createObjectURL(blob), // For preview if needed
          },
        ],
      });

      notification.success({
        message: 'PF Wage Registered From SFTP Downloaded',
      });

      console.log('File downloaded and stored:', fileName);
    } catch (err) {
      console.error('Error downloading file:', err);
      notification.error({
        message:
          err?.response?.data?.message ||
          'Server unexpectedly closed network connection',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const now = new Date();
    const formattedDate = `${now.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}, ${String(now.getDate()).padStart(2, '0')}-${String(
      now.getMonth() + 1
    ).padStart(2, '0')}-${now.getFullYear()}`; // Manually format DD-MM-YYYY
    setLastValidatedDate(formattedDate);
  }, []);

  const handleNotification = (e, contractorName) => {
    // Fetch supplier details and filter based on contractorName
    axios
      .get('/supplier')
      .then((response) => {
        const suppliers = response.data.suppliers;

        // Filter supplier based on contractorName
        // const filteredSupplier = suppliers.find(
        //   (supplier) => supplier.name == e?.contractorName
        // );

        const filteredSupplier = suppliers.find(
          (supplier) =>
            supplier.name === e?.contractorName ||
            supplier.name === contractorDetails?.contractorName
        );
        console.log('filteredSupplier', filteredSupplier);

        if (!filteredSupplier) {
          notification.error({
            message: 'Error',
            description: 'No matching supplier found!',
          });
          return;
        }

        // Send Email if supplier exists
        Email({
          workmenName: 'Viresh Banagargi',
          templateId: 'COMPLIANCE_REMINDER',
          VYNApplication: process.env.REACT_APP_URL,
          orgId: filteredSupplier.orgId,
          role: 'contractor',
          level: null,
          userId: filteredSupplier?.id,
          password: filteredSupplier?.password,
          correctRecipientEmail: filteredSupplier?.emailId,
          locationId:
            filteredSupplier?.locationId || filteredSupplier?.location,
        })
          .then((res) => {
            notification.success({ message: 'Email sent successfully!' });
          })
          .catch((err) => {
            notification.error({ message: 'Email sending failed!' });
          });

        // Show success notification
        // notification.info({
        //   message: 'Reminder Sent',
        //   description: `PF challan reminder has been sent for ${contractorName}.`,
        //   placement: 'topRight',
        //   duration: 3,
        // });
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error);
        notification.error({
          message: 'Fetch Error',
          description: 'Failed to fetch supplier data.',
        });
      });
  };

  const column = [
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      filters: contractorFilters, // Dynamically populated filters
      onFilter: (value, record) => record.contractorName === value, // Filter logic
      filterSearch: true, // Enables search inside the filter dropdown
    },
    {
      title: 'Wage Register',
      dataIndex: 'kronosFileName',
      key: 'kronosFileName',
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      filters: getMonthOptions(), // Full list of months for filtering
      onFilter: (value, record) => record.month === value, // Filter logic
      filterSearch: true, // Enables search inside the filter dropdown
    },
    {
      title: 'Acceptable Difference(₹)',
      dataIndex: 'acceptableDifference',
      key: 'acceptableDifference',
    },
    {
      title: 'Issues',
      dataIndex: 'issues',
      key: 'issues',
    },
    {
      title: 'Transaction Status',
      dataIndex: 'transactionStatus',
      key: 'transactionStatus',
      filters: [
        {
          text: 'ESI-MC Uploaded',
          value: 'ESI-MC Uploaded',
        },
        {
          text: 'ESI-MC Pending',
          value: 'ESI-MC Pending',
        },
        {
          text: 'Closed',
          value: 'Closed',
        },
      ],
      onFilter: (value, record) => record.transactionStatus == value, // Filter logic

      render: (text) => {
        console.log('text1420', text);
        let statusColor = 'black'; // Default color

        // Check the value of `text` and set the color accordingly
        if (text === 'esiMcUploaded') {
          statusColor = 'red'; // Green for "Uploaded"
        } else if (text === 'ESI-MC Pending') {
          statusColor = 'red'; // Yellow for "Pending"
        } else if (text === 'Closed') {
          statusColor = 'green'; // Blue for "Closed"
        }

        return (
          <span style={{ color: statusColor, fontWeight: 'bold' }}>{text}</span>
        );
      },
    },
    {
      title: 'Last Validated',
      dataIndex: 'lastValidated',
      key: 'lastValidated',
    },
    {
      title: 'No. of Iteration',
      dataIndex: 'noOfIteration',
      key: 'noOfIteration',
    },
    {
      title: 'Action',
      dataIndex: 'transactionId',
      render: (_, record) => (
        <Space>
          <Typography
            onClick={() => handleDownload(record)}
            style={{
              color: '#1677FF',
              cursor: 'pointer',
              fontWeight: '600',
              textDecoration: 'underline',
              backgroundColor: 'white',
            }}>
            <DownloadOutlined />
          </Typography>
          <Typography onClick={() => handleNotification(record)}>
            <AlertTwoTone />
          </Typography>
        </Space>
      ),
    },
  ];
  const [file, setFile] = useState(null); // State to hold the uploaded file

  const updateAndValidate = (value) => {
    console.log('reportData?.data1183', reportData?.data);
    console.log('contractorDetails.', contractorDetails.noOfIteration);
    // const incrementedIteration = (
    //   parseInt(contractorDetails.noOfIteration, 10) + 1
    // ).toString();

    // console.log('contractorDetails.', incrementedIteration.toString()); // 4

    // setContractorDetails({
    //   noOfIteration: incrementedIteration,
    // });

    console.log('file130', file);
    const formData = new FormData();
    // formData.append('epfKronos', kronosFile.file);
    formData.append(
      'acceptableDifference',
      reportData?.data?.acceptableDifference
    );
    formData.append('month', reportData?.data?.month);

    formData.append('esiPortal', file);
    formData.append('orgId', userConfig?.orgId);
    formData.append('supplierId', reportData?.data?.supplierId);
    formData.append('supplierName', reportData?.data?.supplierName);
    const dateString = file?.name?.split('_')[1];

    console.log('formData970', formData);

    // if (!dateString) {
    //   notification.warning({ message: 'Plz upload valid PF_ECR file' });
    // }

    // const date = getDate(dateString?.slice(0, 8));
    // if (!date) {
    //   notification.warning({ message: 'Plz upload valid PF_ECR file' });
    // }
    // formData.append('paymentDate', date);
    esiIteartion(formData)
      .then((res) => {
        const transactionId1 = res?.data?.transactionId;
        setRequest({ transactionId1 });
        notification.success({
          message: 'ESI-MC Iteration Report successfully generated',
        });
        window.location.reload();
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
        notification.success({
          // message: 'PF-ECR Iteration Report successfully generated',
        });
        window.location.reload();
      });
  };
  const getDate = (dateString) => {
    // Extract year, month, and day components
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
  };

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify='space-between' align='middle'>
          <Col>
            <Title
              level={4}
              style={{ color: '#000000', Font: 'inter', fontWeight: '600' }}>
              ESI Compliance Reconciliation
            </Title>
          </Col>

          <Col>
            <span
              onClick={PfClick}
              style={{
                color: '#1677FF',
                cursor: 'pointer',
                fontWeight: '600',
                textDecoration: 'underline',
              }}>
              Go to ESI Portal
            </span>
          </Col>
        </Row>
        <Title level={4}>Validation</Title>

        <Row>
          <Col span={4}>
            <Form.Item
              label='Contractor Name'
              name='supplierId'
              rules={[
                {
                  required: true,
                  message: 'Select Contractor',
                },
              ]}>
              <Select
                style={{ width: '200px' }}
                // onSelect={(value, option) =>
                //   handleDropdown2Change(value, option.label)
                // }
              >
                {users.Response1?.map((option) => (
                  <Option
                    key={option.id}
                    label={option.name}
                    value={`${option._id},${option.name}`}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Acceptable difference(₹)'
              name='acceptableDifference'
              rules={[
                {
                  required: true,
                  message: 'Enter Acceptable difference(₹)',
                },
              ]}>
              <Input style={{ width: '200px' }} placeholder='Eg :4'></Input>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Month'
              name='month'
              rules={[
                {
                  required: true,
                  message: 'Select a month',
                },
              ]}>
              <Select style={{ width: '200px' }} placeholder='Select'>
                {months.map((month, index) => (
                  <Option key={index} value={month.toLowerCase()}>
                    {month}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            {/* <Form.Item
              label='Upload Wage Register'
              name='kronosFile'
              rules={[{ required: true, message: 'Upload Document' }]}>
              <Upload
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                beforeUpload={() => false}
                // fileList={kronosFile ? [kronosFile] : []}
                onChange={(info) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    onKronosFileUpload(info.file);
                  }
                }}>
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    color: '#1677FF',
                    cursor: 'pointer',
                    fontWeight: '600',
                    textDecoration: 'underline',
                  }}></Button>
              </Upload>
            </Form.Item> */}
            <Form.Item
              label='Download from SFTP'
              name='kronosFile'
              labelCol={{ span: 24 }} // Prevents label from being clickable
            >
              <Button
                icon={<DownloadOutlined />}
                onClick={() => getExcelSftpData()}
                style={{
                  color: '#1677FF',
                  cursor: 'pointer',
                  fontWeight: '600',
                  textDecoration: 'underline',
                  marginBottom: 8,
                }}></Button>

              <Upload
                fileList={kronosFile?.fileList || []} // Display the file if available
                beforeUpload={() => false} // Prevent auto-uploading
                openFileDialogOnClick={false} // Disable the file dialog on click
                listType='text'
                showUploadList={{
                  showPreviewIcon: false, // Disable preview
                  showRemoveIcon: true, // Enable remove functionality
                }}
                onRemove={() => setKronosFile(null)}
              />
            </Form.Item>
            {/* <Form.Item
              label='Cloud Upload'
              name='kronosFile'
              rules={[{ required: true, message: 'Upload Document' }]}>
              <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                Upload Wage Register
              </Button>
            </Form.Item> */}
          </Col>
          <Col span={4}>
            {/* Custom label outside Form.Item */}
            <div
              style={{
                // fontWeight: 'bold',
                marginBottom: '8px',
                cursor: 'default', // Prevents it from behaving like a button
              }}
              onClick={(e) => e.stopPropagation()} // Stops click from affecting the Upload component
            >
              Upload ESI-MC file
            </div>

            <Form.Item
              name='portalFile'
              rules={[{ required: true, message: 'Upload Document' }]}
              style={{ marginTop: 0 }}>
              <Upload
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                beforeUpload={() => false}
                onChange={(info) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    onPortalFileUpload(info.file);
                  }
                }}>
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    color: '#1677FF',
                    cursor: 'pointer',
                    fontWeight: '600',
                    textDecoration: 'underline',
                  }}></Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Button
              // onClick={handleCompareButtonClick}
              type='primary'
              style={{ marginRight: '100px', marginTop: '25px' }}
              htmlType='submit'>
              Validate
            </Button>
          </Col>
        </Row>
        {/* <Divider></Divider> */}

        <Row justify='space-between' align='middle'>
          <Col>
            <Title level={4}>History</Title>
          </Col>

          {/* <Col>
            <span
              onClick={PfChalanClick}
              disabled={pfChalanEnable}
              style={{
                color: '#6AA8FF',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}>
              PF Chalan Reminder
            </span>
          </Col> */}
          <Col hidden={true}>
            <span
              onClick={!pfChalanEnable ? PfChalanClick : undefined} // Disable click if `pfChalanEnable` is false
              style={{
                color: pfChalanEnable ? '#6AA8FF' : '#1677FF', // Gray out if disabled
                cursor: pfChalanEnable ? 'not-allowed' : 'pointer', // Show a not-allowed cursor when disabled
                textDecoration: 'underline',
                fontWeight: '600',
              }}>
              ESI Challan Reminder
            </span>
          </Col>
        </Row>

        {fileExplorerVisible && (
          <FileExplorer
            files={files}
            onFileSelect={handleFileSelect}
            onClose={closeFileExplorer}
          />
        )}

        <Modal
          visible={modalVisible}
          onCancel={handleModalCancel}
          maskClosable={false} // Prevent closing on outside click
          centered
          width='90%'
          footer={null}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center the steps
              // alignItems: 'center', // Vertically center the steps
              // height: '100vh', // Optional: Center vertically within the viewport
            }}>
            <Steps
              current={currentStep}
              style={{
                width: '100%', // Make it responsive
                maxWidth: '600px', // Optional: Limit the width for better aesthetics
              }}>
              <Step
                title={`All Employees (${reportData?.data?.allCount})`}
                onClick={() => setCurrentStep(0)}
              />
              <Step
                title={`Mismatch Data (${reportData?.data?.exceptionCount})`}
                onClick={() => setCurrentStep(1)}
              />
              <Step
                title={`No Match (${reportData?.data?.noMatchCount})`}
                onClick={() => setCurrentStep(2)}
              />
            </Steps>
          </div>
          {currentStep === 0 && (
            <div>
              <div>
                <Row
                  gutter={[16, 16]}
                  justify='center'
                  align='middle'
                  style={{
                    textAlign: 'center',
                    padding: '30px',
                    margin: '0 auto',

                    maxWidth: '900px',
                  }}>
                  {/* Contractor Name */}
                  <Col xs={24} sm={12} md={8}>
                    {/* Responsive layout */}
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span style={{ fontWeight: 'normal' }}>
                        Contractor Name :
                      </span>
                      <span style={{ fontWeight: 650 }}>
                        {contractorDetails?.contractorName ||
                          reportData?.data?.supplierName}
                      </span>
                    </div>
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span>Acceptable difference(₹): </span>
                      <span style={{ fontWeight: 600 }}>
                        {contractorDetails.acceptableDifference ||
                          reportData?.data?.acceptableDifference}
                      </span>
                    </div>
                  </Col>

                  {/* Month */}
                  <Col xs={24} sm={12} md={8}>
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span>Month: </span>
                      <span style={{ fontWeight: 600 }}>
                        {contractorDetails.month || reportData?.data?.month}
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row
                  gutter={[8, 8]} // Reduced gutter for less padding
                  align='middle' // Vertically center the content
                  justify='center' // Horizontally center the column content
                  style={{ height: '100%' }} // Ensure row takes full height if needed
                >
                  {/* Last Validated - Full Center Alignment */}
                  <Col span={8}></Col>
                  <Col
                    span={8}
                    style={{
                      textAlign: 'center', // Align the content inside the column
                    }}>
                    <Input
                      value={`Last Validated Date : ${
                        contractorDetails?.lastValidated
                          ? contractorDetails?.lastValidated
                          : lastValidatedDate
                      }`}
                      disabled
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'default',
                        textAlign: 'center', // Ensure the text inside the input is centered
                      }}
                    />
                  </Col>

                  {/* Acceptable Difference and Download Controls */}
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '10px',
                      }}>
                      <Select
                        getPopupContainer={getPopupContainer}
                        style={{
                          fontWeight: 'bolder',
                          color: 'black',
                          width: '80px',
                        }}
                        onChange={handleDropdown1Change}
                        placeholder={
                          <span style={{ color: 'black', fontWeight: 400 }}>
                            PDF
                          </span>
                        }>
                        <Option value='PDF'>PDF</Option>
                        <Option value='Excel'>Excel</Option>
                      </Select>
                      <Button
                        type='primary'
                        style={{
                          color: '#1677FF',
                          cursor: 'pointer',
                          fontWeight: '600',
                          textDecoration: 'underline',
                          backgroundColor: 'white',
                        }}
                        onClick={(evt) => downloadFile(evt)}>
                        <DownloadOutlined /> {/* Apply bold style */}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  dataSource={Employee}
                  pagination={{ pageSize: 10 }}
                />
                {/* <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
                  <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                    <Select
                      onChange={handleDropdown1Change}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '100px',
                        // height: "50px",
                      }}
                      placeholder='PDF'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{ float: 'right', marginLeft: '10px' }}
                      type='primary'
                      onClick={(evt) => downloadFile(evt)}>
                      Download
                    </Button>
                  </Col>
                </Row> */}
              </div>
            </div>
          )}
          {currentStep === 1 && exceptionRecords && (
            <div>
              <div>
                {/* Inputs Row */}
                <Row
                  gutter={[16, 16]}
                  justify='center'
                  align='middle'
                  style={{
                    textAlign: 'center',
                    padding: '30px',
                    margin: '0 auto',

                    maxWidth: '900px',
                  }}>
                  {/* Contractor Name */}
                  <Col xs={24} sm={12} md={8}>
                    {/* Responsive layout */}
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span style={{ fontWeight: 'normal' }}>
                        Contractor Name :
                      </span>
                      <span style={{ fontWeight: 650 }}>
                        {contractorDetails?.contractorName ||
                          reportData?.data?.supplierName}
                      </span>
                    </div>
                  </Col>

                  <Col xs={24} sm={12} md={8}>
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span>Acceptable difference(₹): </span>
                      <span style={{ fontWeight: 600 }}>
                        {contractorDetails.acceptableDifference ||
                          reportData?.data?.acceptableDifference}
                      </span>
                    </div>
                  </Col>

                  {/* Month */}
                  <Col xs={24} sm={12} md={8}>
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#E3F8FF',
                        border: 'none',
                        cursor: 'default',
                        borderRadius: '4px',
                        padding: '8px',
                        color: 'black',
                      }}>
                      <span>Month: </span>
                      <span style={{ fontWeight: 600 }}>
                        {contractorDetails.month || reportData?.data?.month}
                      </span>
                    </div>
                  </Col>
                </Row>
                {/* <Divider style={{ margin: '10px 0' }} />{' '} */}
                {/* Reduced divider margins */}
              </div>
              {/* <Divider style={{ margin: '10px 0' }} />{' '} */}
              {/* Reduced divider margins */}
              <Row gutter={[8, 8]} align='middle'>
                {/* First Column - Start Alignment */}
                <Col
                  span={12}
                  style={{
                    textAlign: 'start', // Align content to the start
                  }}>
                  <Form.Item
                    name='portalFile'
                    rules={[
                      {
                        required: true,
                        message: 'Please upload the PF-ECR file',
                      },
                    ]}>
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center', // Align items vertically centered
                        gap: '10px', // Add some space between buttons
                      }}>
                      <Upload
                        // accept='text/plain'
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        beforeUpload={(file) => {
                          setFile(file);
                          onPortalFileUpload(file);
                          return false;
                        }}
                        maxCount={1}>
                        <Tooltip title='Upload ESI-MC File'>
                          <Button
                            icon={<UploadOutlined />}
                            disabled={
                              !(contractorDetails?.exceptionCount > 0) ||
                              contractorDetails?.flag !== 'Highest'
                            }
                          />
                        </Tooltip>{' '}
                      </Upload>
                      <Button
                        type='primary'
                        onClick={updateAndValidate}
                        disabled={!file}>
                        Validate
                      </Button>
                    </Row>
                  </Form.Item>
                </Col>

                {/* Second Column - End Alignment */}
                <Col
                  span={12}
                  style={{
                    textAlign: 'end', // Align content to the end
                  }}>
                  <Input
                    value={`No.of Iteration:${
                      contractorDetails?.noOfIteration
                        ? contractorDetails?.noOfIteration
                        : '1'
                    }`}
                    disabled
                    style={{
                      fontWeight: 'bold',
                      color: 'gray',
                      textAlign: 'end',
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'default',
                    }}
                  />
                </Col>
              </Row>
              {/* <Divider /> */}
              <div style={{ width: '100%' }}>
                {/* <Divider></Divider> */}

                <Row gutter={[16, 16]}>
                  {/* First Column - Start Alignment */}
                  <Col span={8}>
                    <Form.Item name='upload' valuePropName='fileList'>
                      <Button
                        icon={<SendOutlined />}
                        style={{ color: '#1677FF' }}
                        onClick={handleNotification}>
                        Notify Issues To Vendor
                      </Button>
                    </Form.Item>
                  </Col>

                  {/* Second Column - Center Alignment */}
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Input
                      value={`Last Validated Date : ${
                        contractorDetails?.lastValidated
                          ? contractorDetails?.lastValidated
                          : lastValidatedDate
                      }`}
                      disabled
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'default',
                        textAlign: 'center',
                      }}
                    />
                  </Col>

                  {/* Third Column - Compact Layout */}
                  <Col
                    span={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '10px', // Adds consistent spacing between the Select and Button
                    }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        // width: '150px', // Fixed width for consistent layout
                      }}
                      onChange={handleDropdown1Change}
                      placeholder={
                        <span style={{ color: 'black', fontWeight: 400 }}>
                          PDF
                        </span>
                      }>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{
                        color: '#1677FF',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                      }}
                      onClick={(evt) => downloadFile1(evt)}>
                      <DownloadOutlined />
                    </Button>
                  </Col>
                </Row>

                {/* Table Section */}
                <Table
                  dataSource={excepEmployeeRecords}
                  columns={exceptioncolumns}
                  pagination={{ pageSize: 10 }}
                  scroll={{ x: 1500 }}
                />

                {/* Export and Download Section */}
                {/* <Row
                      gutter={[16, 16]}
                      style={{ justifyContent: 'end', marginTop: '10px' }}>
                      <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                        <Select
                          getPopupContainer={getPopupContainer}
                          style={{
                            fontWeight: 'bolder',
                            color: 'black',
                          }}
                          onChange={handleDropdown1Change}
                          placeholder='Export as'>
                          <Option value='PDF'>PDF</Option>
                          <Option value='Excel'>Excel</Option>
                        </Select>
                        <Button
                          style={{ float: 'right', marginLeft: '10px' }}
                          type='primary'
                          onClick={(evt) => downloadFile1(evt)}>
                          Download
                        </Button>
                      </Col>
                    </Row> */}
              </div>
            </div>
          )}
          {currentStep === 2 && (
            // <div width='100%'>
            //   <Table
            //     dataSource={nomatchRecords}
            //     columns={nomatchRecord}
            //     pagination={{ pageSize: 5 }}
            //   />
            // </div>
            <div style={{ width: '100%' }}>
              <Row
                gutter={[16, 16]}
                justify='center'
                align='middle'
                style={{
                  textAlign: 'center',
                  padding: '30px',
                  margin: '0 auto',

                  maxWidth: '900px',
                }}>
                {/* Contractor Name */}
                <Col xs={24} sm={12} md={8}>
                  {/* Responsive layout */}
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      borderRadius: '4px',
                      padding: '8px',
                      color: 'black',
                    }}>
                    <span style={{ fontWeight: 'normal' }}>
                      Contractor Name :
                    </span>
                    <span style={{ fontWeight: 650 }}>
                      {contractorDetails?.contractorName ||
                        reportData?.data?.supplierName}
                    </span>
                  </div>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      borderRadius: '4px',
                      padding: '8px',
                      color: 'black',
                    }}>
                    <span>Acceptable difference(₹): </span>
                    <span style={{ fontWeight: 600 }}>
                      {contractorDetails.acceptableDifference ||
                        reportData?.data?.acceptableDifference}
                    </span>
                  </div>
                </Col>

                {/* Month */}
                <Col xs={24} sm={12} md={8}>
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#E3F8FF',
                      border: 'none',
                      cursor: 'default',
                      borderRadius: '4px',
                      padding: '8px',
                      color: 'black',
                    }}>
                    <span>Month: </span>
                    <span style={{ fontWeight: 600 }}>
                      {contractorDetails.month || reportData?.data?.month}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[8, 8]} // Reduced gutter for less padding
                align='middle' // Vertically center the content
                justify='center' // Horizontally center the column content
                style={{ height: '100%' }} // Ensure row takes full height if needed
              >
                {/* Last Validated - Full Center Alignment */}
                <Col span={8}></Col>
                <Col
                  span={8}
                  style={{
                    textAlign: 'center', // Align the content inside the column
                  }}>
                  <Input
                    value={`Last Validated Date : ${
                      contractorDetails?.lastValidated
                        ? contractorDetails?.lastValidated
                        : lastValidatedDate
                    }`}
                    disabled
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'default',
                      textAlign: 'center', // Ensure the text inside the input is centered
                    }}
                  />
                </Col>

                {/* Acceptable Difference and Download Controls */}
                <Col span={8} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '80px',
                      }}
                      onChange={handleDropdown1Change}
                      placeholder={
                        <span style={{ color: 'black', fontWeight: 400 }}>
                          PDF
                        </span>
                      }>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      type='primary'
                      style={{
                        color: '#1677FF',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                        backgroundColor: 'white',
                      }}
                      onClick={(evt) => downloadFile(evt)}>
                      <DownloadOutlined /> {/* Apply bold style */}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={nomatchRecords}
                columns={nomatchRecord}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 1500 }}
              />
            </div>
          )}
        </Modal>
      </Form>
      {/* <Divider></Divider> */}

      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        <Table
          bordered
          dataSource={Employee1}
          columns={column}
          // title={Title}
          rowKey='userid'
          rowSelection={{
            type: 'checkbox', // Enables checkbox selection
            ...rowSelection,
            renderCell: () => null, // Hide checkbox for individual rows
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Row click event
          })}
          style={{ width: '100%' }}
        />
      </Space>
    </>
  );
}

export default Esireport;
