import React, { useCallback, useEffect, useState } from 'react'
import { Button, Drawer, Flex, notification, Table } from 'antd';
import { activeForm, copyFormVersion, getFormsList, releaseNewVersion } from '../../api/dynamicForm'
import { useNavigate } from 'react-router-dom';




const ActionButtonComponent = ({ record, formName, handleActiveForm }) => {
    const navigate = useNavigate();

    const isDraft = record?.status == 'draft';
    const isInactive = record?.status == 'inactive';


    if (isDraft || isInactive) {
        return (
            <Flex gap={2}>
                <Button type='primary' onClick={() => handleActiveForm(record?.formId, record?.versionNumber)}>
                    Active
                </Button>
                <Button onClick={() => {
                    navigate(`/home/supervisor/edit/${record?.formId}/v/${record?.versionNumber}`, {
                        state: formName
                    })
                }} type='link' disabled={isInactive}>Edit</Button>
            </Flex>
        )
    }


    return (
        <Flex gap={2}>
            <Button onClick={
                () => {
                    navigate(`/home/supervisor/view/${record?.formId}/v/${record?.versionNumber}`)
                }} type='link' disabled={isInactive}>View</Button>
        </Flex>
    )
}


const FormLists = () => {
    const [forms, setForms] = useState([])
    const [versions, setVersions] = useState([])
    const [open, setOpen] = useState(false);

    console.log("fvo48 ", forms, versions, open)

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const getList = useCallback(async () => {
        try {
            const { data, status } = await getFormsList()
            console.log("data ", data)
            console.log("status ", status)
            if (status == 200) {
                setForms(data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        getList()
    }, [])

    const showVersionInDrawer = (_versions) => {
        if (!versions) return
        setVersions(_versions)
        showDrawer()
    }

    const handleActiveForm = async (formId, version) => {
        try {
            if (!formId || !version) throw new Error("Form ID and version required");
            const response = await activeForm(formId, version)
            if (response?.status == 200) {
                notification.success({
                    message: "Form activated"
                })
                getList();
                onClose();
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || error?.message || "Something went wrong"
            });
        }
    }

    const copyForm = async (formId) => {
        try {
            if (!formId) throw new Error("Form ID  required");
            const response = await copyFormVersion(formId, "Untitled")
            if (response?.status == 200) {
                notification.success({
                    message: "Form Copied!!"
                })
                getList();
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || error?.message || "Something went wrong"
            });
        }
    }

    const createNewVersion = async (formId) => {
        try {
            if (!formId) throw new Error("Form ID  required");
            const response = await releaseNewVersion(formId)
            if (response?.status == 200) {
                notification.success({
                    message: "Form Copied!!"
                })
                getList();
            }
        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || error?.message || "Something went wrong"
            });
        }
    }

    const columns = [
        { title: 'Name', dataIndex: 'formName', key: 'formName' },
        {
            title: 'Active Version',
            dataIndex: 'activeVersion',
            key: 'activeVersion',
            render: (_, { activeVersion }) => activeVersion ?? '-'
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            render: (_, record) => {
                return (<Button onClick={() => showVersionInDrawer({ version: record?.versions, formName: record?.formName })} type='primary'>View Versions</Button>)
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Flex gap={2}>
                    <Button onClick={() => createNewVersion(record?._id)}>New Version</Button>
                    <Button onClick={() => copyForm(record?._id)}>Copy Form</Button>

                </Flex>
            ),
        },
    ];

    const versionColumns = [
        { title: 'Version', dataIndex: 'versionNumber', key: 'versionNumber' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                return (
                    <ActionButtonComponent handleActiveForm={handleActiveForm} record={record} formName={versions?.formName} />

                )
            },
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={forms} />
            <Drawer title="Versions" onClose={onClose} open={open}>
                <Table columns={versionColumns} dataSource={versions?.version} />
            </Drawer>
        </div>
    )
}



export default FormLists