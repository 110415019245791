import React, { useEffect, useState } from 'react';
import {
  Row,
  Divider,
  Collapse,
  Modal,
} from 'antd';
import SchedulesList from './SchedulesList';
import SchedulerHistory from './SchedulerHistory';

const { Panel } = Collapse;

const SchedulerJob = () => {
  const [viewHistory, setViewHistory] = useState(false);

  console.log('viewHistory', viewHistory)
  return(
    <>
      <Divider />
        <Collapse>
          <Panel header='Scheduler Job' style={{ fontWeight: 'bolder' }}>
            <>
              <Divider />
              <Row>
                <SchedulesList setViewHistory={setViewHistory} />
              </Row>
            </>
          </Panel>
        </Collapse>
        <Divider />
        {viewHistory?._id && <Modal
          title={`Schedule History for ${viewHistory?.label}`}
          open={viewHistory?._id ? true : false}
          width="100vw"
          footer={null}
          onCancel={() => {
            setViewHistory(false)
          }}>
          <SchedulerHistory viewHistory={viewHistory} />
        </Modal>}
    </>
  )
}

export default SchedulerJob