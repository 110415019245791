import axios from './../services/axiosInstance';

export const saveFormSchema = async (schema, formId = null) => {
  console.log({ schema });
  if (!Array.isArray(schema?.fields) && schema.fields.length == 0) {
    throw new Error('Fields required');
  }

  if (!schema?.metadata?.name || !schema?.metadata?.version) {
    throw new Error('Form and version required');
  }

  const payload = {
    schema: schema?.fields,
    formName: schema?.metadata?.name,
    versionNumber: schema?.metadata?.version,
    ...(formId && { formId }),
  };

  const response = await axios.post('/form/create', payload);
  console.log('res24 ', response);
  console.log('payload25 ', payload);

  return response;
};
export const getFormsList = async () => {
  const response = await axios.get('/form/schemas');
  console.log('response from form/schema ', response);
  return response;
};

export const getFormByVersion = async (formId, version) => {
  return await axios.get(`/form/schema/${formId}/v/${version}`);
};

export const activeForm = async (formId, versionNumber) => {
  return await axios.post('/form/active', {
    formId,
    versionNumber,
  });
};

export const copyFormVersion = async (formId, formName) => {
  return await axios.post('/form/copy', {
    formId,
    formName,
  });
};

export const releaseNewVersion = async (formId) => {
  return await axios.post('/form/relaseNewVersion', {
    formId,
  });
};

export const submitFormResonse = async (formData) => {
  return await axios.post('/form/save/response', formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set correct header for file uploads
    },
  });
};
