import React, { useEffect, useState } from 'react';
import {
  Modal,
  Switch,
  Table,
  Space,
  Typography,
  DatePicker,
  Row,
  Col,
  Collapse,
} from 'antd';
import dayjs from 'dayjs';
import { verifyToken } from '../utils/jwt';

const { Panel } = Collapse;

const LicenceManagementModal = ({ open, onCancel, onOk, token }) => {
  const formRef = React.useRef();
  const [features, setFeatures] = useState([]);
  const [expiry, setExpiry] = useState('');
  const [isDatePickerValid, setIsDatePickerValid] = useState(true);

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  // Define ALWAYS_ON_FEATURES
  const ALWAYS_ON_FEATURES = [
    'orgsetup',
    'mastersetup',
    'audit',
    'usermanagement',
    'workmendashboard',
    'workmenapproval',
    'contractorapproval',
  ];

  useEffect(() => {
    updateData(token);
  }, [token]);

  const updateData = async (token) => {
    const decoded = await verifyToken(token?.productLicenseKey);

    if (decoded) {
      // Ensure ALWAYS_ON_FEATURES are always included in the features
      const updatedFeatures = [
        ...new Set([...decoded.features, ...ALWAYS_ON_FEATURES]),
      ];
      setFeatures(updatedFeatures);
      setExpiry(decoded.expiry);
    } else {
      // If no decoded data, still include ALWAYS_ON_FEATURES
      setFeatures(ALWAYS_ON_FEATURES);
      setExpiry(false);
    }
  };

  const categories = [
    // Hidden "ALWAYS_ON_FEATURES" category
    {
      title: 'ALWAYS_ON_FEATURES',
      subItems: [
        { name: 'Org Setup', value: 'orgsetup', key: 2 },
        { name: 'Master Setup', value: 'mastersetup', key: 4 },
        { name: 'Audit', value: 'audit', key: 5 },
        { name: 'User Management', value: 'usermanagement', key: 6 },
        { name: 'Workmen Dashboard', value: 'workmendashboard', key: 8 },
        { name: 'Workmen Approval', value: 'workmenapproval', key: 12 },
        { name: 'Contractor Approval', value: 'contractorapproval', key: 15 },
      ],
    },
    // Other visible categories
    {
      title: 'Vendor Management',
      subItems: [
        { name: 'Contractors', value: 'contractors', key: '1' },
        {
          name: 'Contractor Dashboard',
          value: 'contractordashboard',
          key: '18',
        },
        { name: 'Manage Supervisor', value: 'managesupervisor', key: '19' },
      ],
    },
    // Add other categories as needed
    {
      title: 'Contract Workmen Management',
      subItems: [
        { name: 'Onboard Workmen', value: 'onboardworkmen', key: '7' },
        { name: 'Bulk Upload', value: 'bulkupload', key: '10' },
        {
          name: 'Quick Employee Onboarding',
          value: 'quickemployeeonboarding',
          key: '9',
        },
        {
          name: 'Reports For Contractor Workmen',
          value: 'reportsforcontractorworkmen',
          key: '16',
        },
      ],
    },
    {
      title: 'Compliance',
      subItems: [{ name: 'Compliance', value: 'compliance', key: '3' }],
    },
    {
      title: 'Dynamic Form Render',
      subItems: [
        { name: 'Form Builder', value: 'formbuilder', key: '3' },
        { name: 'Form List', value: 'formlist', key: '3' },
      ],
    },
    {
      title: 'Dashboards and Reports',
      subItems: [
        {
          name: 'Reports and Dashboard',
          value: 'reportsanddashboard',
          key: '22',
        },
      ],
    },
    {
      title: 'CWFM',
      subItems: [
        { name: 'Work Order List', value: 'workorderlist', key: '23' },
        { name: 'LR List', value: 'lrlist', key: '24' },
        { name: 'Schedule Workmen', value: 'scheduleworkmen', key: '25' },
        { name: 'Minimum Wage', value: 'minimumwage', key: '26' },
        { name: 'Wage Register', value: 'wageregister', key: '27' },
      ],
    },
    {
      title: 'Regular Workmen management',
      subItems: [
        {
          name: 'Onboard Regular Workmen',
          value: 'onboardregularworkmen',
          key: '17',
        },
        { name: 'Historical Data', value: 'historicaldata', key: '15' },
        { name: 'Bulk Upload', value: 'bulkupload', key: '10' },
        {
          name: 'Regular Workmen Dashboard',
          value: 'regularworkmendashboard',
          key: '14',
        },
        { name: 'IR Dashboard', value: 'irdashboard', key: '17' },
      ],
    },
    {
      title: 'Digital Reports Feedback',
      subItems: [
        {
          name: 'Digital Report Feedback',
          value: 'digitalreportsfeedback',
          key: '7',
        },
      ],
    },
    {
      title: 'Incident Management',
      subItems: [{ name: 'Incidents', value: 'incidentmanagement', key: '8' }],
    },
  ];

  const handleSubItemToggle = (value, checked) => {
    // Prevent disabling ALWAYS_ON_FEATURES
    if (ALWAYS_ON_FEATURES.includes(value)) return;

    const updatedFeatures = checked
      ? [...features, value]
      : features.filter((item) => item !== value);
    setFeatures(updatedFeatures);
  };

  const handleCategoryToggle = (category, checked) => {
    const subItemsValues = category.subItems.map((item) => item.value);
    const updatedFeatures = checked
      ? [...new Set([...features, ...subItemsValues])]
      : features.filter(
          (item) =>
            !subItemsValues.includes(item) || ALWAYS_ON_FEATURES.includes(item)
        );
    setFeatures(updatedFeatures);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'action',
      render: (_, record) => (
        <Space>
          <Switch
            checked={features.includes(record.value)}
            onChange={(checked) => handleSubItemToggle(record.value, checked)}
            disabled={ALWAYS_ON_FEATURES.includes(record.value)} // Disable switch for ALWAYS_ON_FEATURES
          />
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title='Manage Features'
      onCancel={_onCancel}
      onOk={() => {
        if (!expiry) {
          setIsDatePickerValid(false);
          return;
        }
        setIsDatePickerValid(true);
        onOk(features, expiry);
      }}
      open={open}
      width={'50%'}>
      <Collapse accordion>
        {categories
          .filter((category) => category.title !== 'ALWAYS_ON_FEATURES') // Hide "ALWAYS_ON_FEATURES" category
          .map((category) => {
            const allSubItemsEnabled = category.subItems.some((item) =>
              features.includes(item.value)
            );
            return (
              <Panel
                header={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Typography.Text>{category.title}</Typography.Text>
                    <Switch
                      checked={allSubItemsEnabled}
                      onChange={(checked) =>
                        handleCategoryToggle(category, checked)
                      }
                    />
                  </div>
                }
                key={category.title}>
                <Table
                  columns={columns}
                  dataSource={category.subItems}
                  pagination={false}
                  showHeader={false}
                />
              </Panel>
            );
          })}
      </Collapse>

      <Row
        gutter={[16, 0]}
        style={{
          padding: '10px',
        }}>
        <Col span={8}>
          <label htmlFor='datepicker'>Valid Upto:</label>
        </Col>
        <Col span={16}>
          <DatePicker
            id='datepicker'
            style={{
              width: '80%',
              height: '40px',
              marginLeft: '20px',
              borderColor: isDatePickerValid ? '' : 'red',
            }}
            onChange={(e) => {
              setExpiry(new Date(e).getTime());
              setIsDatePickerValid(true);
            }}
            format='DD-MM-YYYY'
            value={expiry ? dayjs(new Date(expiry)) : ''}
          />
          {!isDatePickerValid && (
            <p style={{ color: 'red' }}>Please select a date</p>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default LicenceManagementModal;
