import { Builder, Renderer, useFormStore } from 'form-builder'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getFormByVersion, saveFormSchema } from '../../api/dynamicForm'
import { notification } from 'antd'

const FormEdit = () => {
    const { formId, version } = useParams()

    const { setFields, setMetadata } = useFormStore(state => state)

    const { state: formName } = useLocation()

    useEffect(() => {
        const getForm = async () => {
            try {
                const { data, status } = await getFormByVersion(formId, version)
                if (status == 200) {
                    setMetadata({
                        version,
                        name: formName
                    })
                    setFields(() => data?.schema)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getForm()
    }, [])

    const onSchemaSave = async (schema) => {
        try {
            const response = await saveFormSchema(schema, formId);
            if (response?.status == 200) {
                return notification.success({
                    message: 'Form update successfully!'
                })
            }

        } catch (error) {
            notification.error({
                message: error?.response?.data?.message || error?.message || "Something went wrong"
            });
        }
    }
    return (
        <div>
            <Builder onFormSave={onSchemaSave} />
        </div>
    )
}


export default FormEdit