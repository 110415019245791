// import Axios from './axios';
import axios from '../../../services/axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */

export const uploadMinimumData = async (payload, orgId) => {
    try {
        const response = await axios.post(`/minimum-wage`, payload);
        if (response && response.data) {
            return response.data;
        } else {
            throw new Error("Invalid response received from server");
        }
    } catch (error) {
        throw new Error(`Error uploading work order details data: ${error.message}`);
    }
};

export const getMinimumWageData = () => {
    
    return axios.get('/minimum-wage');
    // return Axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};