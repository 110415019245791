import React, { useState, useEffect } from 'react';
import {
  Button,
  Upload,
  message,
  notification,
  Row,
  Col,
  Form,
  Typography,
  Select,
  Modal,
  Table,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import useAuth from '../hooks/useAuth';
import {
  bulkUploadContractWorkmenDocumentApi,
  uploadBulkDataApi,
  getWorkmenLimit,
} from '../services/auth';

// import XLSX from "xlsx";

import axios1 from '../services/axiosInstance';
import dayjs from 'dayjs';
import { setLoader } from '../actions';
const XLSX = require('xlsx');
const { Title } = Typography;
const { Option } = Select;

const FileUploader = () => {
  const [fileList, setFileList] = useState([]);
  const [fileDirectory, setFileDirectory] = useState([]);
  // const [fileMetadata, setFileMetadata] = useState([]);
  const [location, setLocation] = useState({});
  const [uploaddocumentResponse, setuploaddocumentResponse] = useState([]);
  const [bulkDocumentUploadResponse, setBulkDocumentUploadResponse] = useState(
    []
  );
  const navigate = useNavigate();
  const { user, userConfig } = useAuth();
  const [uploadLogsView, setUploadLogsView] = useState(false);
  const [contractorName, setContractorName] = useState(
    sessionStorage.getItem('contractorName') || ''
  );

  const orgId = userConfig?.orgId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWorkmenLimit(orgId);
        console.log(res.data);

        const matchingItem = res.data.find(
          (item) => item?.id === userConfig?.supplierId
        );
        console.log('matchingItem', matchingItem);
        if (matchingItem) {
          sessionStorage.setItem('contractorName', matchingItem.name);
          setContractorName(matchingItem.name);
        }
      } catch (error) {
        console.error('Error fetching contractor name:', error);
      }
    };

    fetchData();
  }, [orgId, userConfig]);

  useEffect(() => {
    EmployeeList1();
  }, []);

  const EmployeeList1 = async () => {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response.data.org.locations;

    setLocation({ Response2 });
  };

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file
    setFileList(fileList);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = async (info) => {
    const files = await Promise.all(
      info.fileList.map(async (file) => {
        const base64Content = await getBase64(file.originFileObj); // Get Base64 content
        return {
          blob: file.originFileObj, // Original file object
          url: `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${file.name}`, // Constructed GCP path
          mimeType: file.type, // File MIME type
          base64Content, // Add Base64 content
        };
      })
    );
    setFileDirectory(files); // Save the files for the form
  };

  // const formatField = (item, locationId) => {
  //   const dob = dayjs(item['DOB (as per Aadhaar)'], 'DD/MM/YYYY').format(
  //     'YYYY-MM-DDTHH:mm:ss.SSSZ'
  //   );

  //   const dobDate = new Date(
  //     1900,
  //     0,
  //     1 + parseInt(item['DOB (as per Aadhaar)']) - 1
  //   );

  //   // Format the date using dayjs
  //   // const dob = dayjs(dobDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  //   const dojDate = new Date(
  //     1900,
  //     0,
  //     1 + parseInt(item['Date of Joining']) - 1
  //   );

  //   // Format the date using dayjs
  //   const doj1 = dayjs(dojDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

  //   // const doj = dayjs(item['Doj'], 'DD/MM/YYYY').format(
  //   //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
  //   // );
  //   const age = calculateAge(dob);

  //   let workerCode = String(item['Worker Code']) || ''; // Ensure ContractorCode is a string, if it's null or undefined, set it to an empty string
  //   const desiredLength11 = 8;

  //   // Check if ContractorCode length is less than 4
  //   if (workerCode.length < desiredLength11) {
  //     const numZerosToAdd = desiredLength11 - workerCode.length;
  //     // Add leading zeros
  //     workerCode = '0'.repeat(numZerosToAdd) + workerCode;
  //   }
  //   // const gateNumber = item['Gate No.']? item['Gate No.']: !item['Unit Name']? "": item['Unit Name'] === "Hazira East"? "GATE 2": item['Unit Name'] === "Hazira West"? "GATE 5": "";

  //   // console.log("gateNumber", gateNumber);

  //   const personalDetails = {
  //     firstName: item['First Name'],
  //     middleName: item['Middle Name'],
  //     lastName: item['Last Name'],
  //     LongName: item['Long Name'],

  //     gender: item['Gender'],
  //     fatherName: item['Father/Guardian Name'],
  //     // Dob: dayjs(item['Date Of Birth'], 'DD/MM/YYYY').format(
  //     //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
  //     // ),
  //     Dob: dayjs(item['Date Of Birth'], 'DD/MM/YYYY').format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     ),

  //     mobile: String(item['Mobile No.']),
  //     alternateMobile: String(item['Alternate Contact No.']),
  //     age: item['Age'],
  //     aadharNo: item['Aadhar_Number'],
  //     martialStatus: item['Marital Status'],
  //     spousename: item['Spouse Name'],
  //     childrenNo: String(item['Number of Children']),
  //     emergencyContactNo: String(item['Emergency Contact No.']? item['Emergency Contact No.'] : ""),
  //     emergencyContactName: item['Emergency Contact Name'],
  //     idmark: item['ID Mark'],
  //     religion: item['Religion'],
  //     domicile: item['Domicile'],
  //     height: item['Height'],
  //     numberOfYearSpent: item['No. of Years spent in State'],
  //     pan: item['PAN'],
  //     rationcardno: String(item['Ration Card Number']),
  //     paddress1: item['Present Address Line1'],
  //     paddress2: item['Present Address Line2'],
  //     paddress3: item['Present Address Line3'],

  //     pvillage: item['Local Village/Mandal'],
  //     pdistrict: item['Local District'],
  //     ppincode: String(item['Local Pincode']),
  //     // ppincode: item['Pincode'],

  //     pvillage: item['Local Village/Mandal'],
  //     pstate: item['Local State'],
  //     ptehsil: item['Local Tehsil'],
  //     ptaluka: item['Local Taluka'],
  //     pcity: item['Local City'],
  //     pcountry: item['Local Country'],
  //     //--------------
  //     address1: item['Permanent Address Line1'],
  //     address2: item['Permanent Address Line2'],
  //     address3: item['Permanent Address Line3'],

  //     village: item['Permanent Village/Mandal'],
  //     state: item['Permanent State'],
  //     district: item['Permanent District'],
  //     // pincode: item['Pincode'],
  //     pincode: String(item['Permanent Pincode']),

  //     taluka: item['Permanent Taluka'],
  //     tehsil: item['Permanent Tehsil'],
  //     city: item['Permanent City'],
  //     country: item['Permanent Country'],
  //     //Professional Details
  //     empCode: workerCode,
  //     empCode: item['Worker Code'],
  //     epnNumber: item['EPN Code'],
  //     epnRenew: dayjs(item['EPN Renew'], 'DD/MM/YYYY').format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     ),

  //     locationId: item['Location ID'],
  //     hazardousProcessArea: item['Hazardous Process Area'],
  //     technicalDetails: item['Technical Details'],
  //     natureOfWork: item['Type of Contract'],
  //     // gateNumber: String(item['Gate No.']),
  //     // gateNumber: item['Gate No.'],
  //     gateNumber: item['Gate No.']
  //     ? item['Gate No.']
  //     : !item['Unit Name']
  //     ? ""
  //     : item['Unit Name'] === "Hazira East"
  //     ? "GATE 2"
  //     : item['Unit Name'] === "Hazira West"
  //     ? "GATE 5"
  //     : "",

  //     disabilityType: item['Disability Type'],
  //     disabilityPercentage: item['Disability Percentage'],

  //     blockRemark: item['Block Remarks'],
  //     workingArea: item['Working Area'],
  //     policeVerification: item['Police Verification'],

  //     esicno: item['IP_ESIC_Number'],
  //     uan: item['UAN_Number'],
  //     pfAccountNo: item['PF Account No.'],
  //     locationId: locationId,
  //     unitName: item['Unit Name'],
  //     votercardno: item['Voter Card No.'],
  //     handicapped: item['Handicapped?'],
  //     nomineeName: item['Nominee Name'],
  //     nomineeRelationship: item['Nominee Relationship'],
  //     nomineeAge: item['Nominee Age'],
  //     nomineeAadharNo: item['Nominee Aadhaar'],
  //     nomineeContactnumber: item['Nominee Contact Number'],
  //   };

  //   const jobDetails = {
  //     // employerCode: String(item['Principal Employer Code']),
  //     // contractorCode: String(item['Contractor Code']) || userConfig?.supplierId,
  //     contractorCode: userConfig?.supplierId,
  //     contractorName: item['Contractor Name'],
  //     departmentCode: item['Dept Code'],
  //     workingArea: item['Working Area'],
  //     sectionCode: item['Section Code'],

  //     workorderCode: item['Workorder Number'],

  //     itemServiceCode: String(item['Item Service Code']),
  //     labourType: item['Employee Type'],
  //     jobOrderNumber: item['JO Number'],
  //     skillCode: item['Skill Code'],

  //     subSkillCode: String(item['Subskill Code']),
  //     subSkillDate: dayjs(item['Subskill Date'], 'DD/MM/YYYY').format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     ),
  //     workType: item['Work Type'],
  //     designation: item['Worker Type'],
  //     duration: item['Duration'],
  //     workerCategory: item['Worker Category'],
  //     terminationReason: item['Reason for Termination'],
  //     doj: dayjs(item['Date of Joining'], 'DD/MM/YYYY').format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     ),

  //     totalExperience: String(item['Total Experience']),
  //     previousOrg: item['Previous Organization'],
  //     referredBy: item['Refered By'],
  //     name: item['Relative Name'],
  //     mobileNo: item['Relative Mobile Number'],
  //     bankname: item['Bank Name'],
  //     AccountNo: String(item['Bank Account No.']),
  //     ifsc: item['IFSC Code'],
  //     userName: item['Name as per Bank Account'],
  //     bloodGroup: item['Blood Group'],
  //     techQualification: item['Technical Qualification'],
  //     academicQualification: item['Academic Qualification'],
  //     shoeSize: String(item['Shoe Size']),
  //     dl: item['DL No.'] ? String(item['DL No.']) : '',
  //     dlExpiry: item['DL Validity Date']
  //       ? dayjs(item['DL Validity Date'], 'DD/MM/YYYY').format(
  //           'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //         )
  //       : '',
  //     primaryJobSkill: item['Primary Job Skill']
  //       ? item['Primary Job Skill']
  //       : '',
  //     nomineeBankName: item['Nominee Bank Name'],
  //     nomineeAccountNo: item['Nominee Bank Account No.'],
  //     nomineeIfsc: item['Nominee IFSC Code']
  //   };
  //   const wageDetails = {
  //     basic: String(item['Basic']),
  //     da: String(item['DA']),
  //     allowance1: String(item['Allowance1']),
  //     allowance2: String(item['Allowance2']),
  //     hra: String(item['HRA']),
  //     conveyance: String(item['Conveyence']),
  //     specialAllowance: String(item['Special Allowance']),
  //     medicalAllowance: String(item['Medical Allowance']),
  //     eduAllowance: String(item['Education Allowance']),
  //     travelAllowance: String(item['Leave/Travel Allowance']),
  //     ot: item['OT Applicable'],
  //     effectiveDate: dayjs(item['Wage Effective Date'], 'DD/MM/YYYY').format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     ),
  //   };
  //   const uploadDocument = {
  //     annualMedicalCheckupDate: dayjs(
  //       item['Next annual Medical Checkup Date'],
  //       'DD/MM/YYYY'
  //     ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  //   };

  //   const payload = {
  //     personalDetails,
  //     jobDetails,
  //     //wageDetails,
  //     uploadDocument,
  //     locationId,
  //     employementType: 'Contractor',
  //   };
  //   return payload;
  // };

  const formatField = (item, locationId, contractorName, userConfig) => {
    // Skipped records collector
    // const skippedRecords = [];

    // Validate contractor name and contractor code
    const contractorNameFromItem = !item['Contractor Name']
      ? contractorName
      : item['Contractor Name']?.trim();
    // const contractorCodeFromItem = !item['Contractor Code']? userConfig?.supplierId : item['Contractor Code']?.trim();
    const contractorCodeFromItem = !item['Contractor Code']
      ? userConfig?.supplierId
      : typeof item['Contractor Code'] === 'string'
      ? item['Contractor Code'].trim()
      : String(item['Contractor Code']).trim();

    const expectedContractorCode = userConfig?.supplierId;

    if (!contractorNameFromItem || !contractorCodeFromItem) {
      console.warn(
        `Skipping record due to missing contractor details: ` +
          `Contractor Name: ${
            contractorNameFromItem || 'MISSING'
          }, Contractor Code: ${contractorCodeFromItem || 'MISSING'}`
      );
      return null;
    }
    // if(item['Contractor Name'] || item['Contractor Code']){
    //   if (
    //     contractorName !== contractorNameFromItem ||
    //     contractorCodeFromItem !== expectedContractorCode
    //   ) {
    //     console.warn(
    //       `Skipping record because Contractor Name or Contractor Code does not match: ` +
    //         `Expected Name: "${contractorName}", Got Name: "${contractorNameFromItem}"; ` +
    //         `Expected Code: "${expectedContractorCode}", Got Code: "${contractorCodeFromItem}"`
    //     );
    //     skippedRecords.push({
    //       reason: "Contractor Name or Contractor Code mismatch",
    //       item,
    //     });
    //     return { payload: null, skippedRecords }; // Skip this record
    //     // return null; // Skip this record
    //   }
    // }

    const parseExcelDate = (excelDate) => {
      // If the value is a number (Excel date format)
      if (typeof excelDate === 'number') {
        // Excel date system starts from 1900, but Day.js uses Unix epoch (1970)
        const date = dayjs('1899-12-30').add(excelDate, 'days'); // Excel's starting point is Dec 30, 1899
        return date.isValid() ? date : null;
      }

      // If it's already a valid string date (DD-MM-YYYY format)
      if (dayjs(excelDate, 'DD-MM-YYYY', true).isValid()) {
        return dayjs(excelDate, 'DD-MM-YYYY'); // Strict parsing to ensure it's exactly DD-MM-YYYY
      }

      // Return null if neither condition is met
      return null;
    };

    const formatDate = (dateString) => {
      const date = parseExcelDate(dateString);

      if (date && date.isValid()) {
        return date.format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // Desired output format (ISO 8601 with timezone)
      }

      console.warn('Invalid date:', dateString); // In case of invalid data
      return null;
    };

    // const dob = formatDate(item['Date Of Birth']);
    const dob = item['Date Of Birth'] ? dayjs(new Date(item['Date Of Birth'])).local().format('YYYY-MM-DD') :'';

    // const doj = formatDate(item['Date of Joining']);
    const doj = item['Date of Joining'] ? dayjs(new Date(item['Date of Joining'])).local().format('YYYY-MM-DD') :'';

    // Calculate age from DOB
    const age = calculateAge(dob);

    // Ensure Worker Code is padded to the desired length
    let workerCode = !item['Worker Code'] ? '' : String(item['Worker Code']);
    const desiredLength = 8;
    if (workerCode.length < desiredLength) {
      const numZerosToAdd = desiredLength - workerCode.length;
      workerCode = '0'.repeat(numZerosToAdd) + workerCode;
    }

    // Structure personal details
    const personalDetails = {
      firstName: item['First Name'],
      middleName: item['Middle Name'],
      lastName: item['Last Name'],
      LongName: item['Long Name'],
      gender: item['Gender'],
      fatherName: item['Father/Guardian Name'],
      Dob: dob,
      mobile: String(item['Mobile No.']),
      alternateMobile: String(item['Alternate Contact No.']),
      age: age,
      aadharNo: item['Aadhar_Number'],
      martialStatus: item['Marital Status'],
      spousename: item['Spouse Name'],
      emergencyContactNo: String(
        item['Emergency Contact No.'] ? item['Emergency Contact No.'] : ''
      ),
      emergencyContactName: item['Emergency Contact Name'],
      idmark: item['ID Mark'],
      religion: item['Religion'],
      domicile: item['Domicile'],
      height: item['Height'],
      numberOfYearSpent: item['No. of Years spent in State'],
      pan: item['PAN'],
      paddress1: item['Present Address Line1'],
      paddress2: item['Present Address Line2'],
      paddress3: item['Present Address Line3'],

      pvillage: item['Present Village/Mandal'],
      pdistrict: item['Present District'],
      ppincode: String(item['Present Pincode']),
      pvillage: item['Present Village/Mandal'],
      pstate: item['Present State'],
      ptehsil: item['Present Tehsil'],
      ptaluka: item['Present Taluka'],
      pcity: item['Present City'],
      pcountry: item['Present Country'],
      address1: item['Permanent Address Line1'],
      address2: item['Permanent Address Line2'],
      address3: item['Permanent Address Line3'],
      village: item['Permanent Village/Mandal'],
      state: item['Permanent State'],
      district: item['Permanent District'],
      pincode: String(item['Pincode']),
      taluka: item['Permanent Taluka'],
      tehsil: item['Permanent Tehsil'],
      city: item['Permanent City'],
      country: item['Country'],
      empCode:
        workerCode === undefined || workerCode === '00000000' ? '' : workerCode,
      epnNumber: !item['EPN Code'] ? '' : String(item['EPN Code']),
      epnRenew: !item['EPN Renew']
        ? ''
        : dayjs(item['EPN Renew'], 'DD/MM/YYYY').format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
      locationId: item['Location ID'],
      hazardousProcessArea: item['Hazardous Process Area'],
      technicalDetails: item['Technical Details'],
      natureOfWork: item['Type of Contract'],
      gateNumber: item['Gate No.']
        ? item['Gate No.']
        : !item['Unit Name']
        ? ''
        : item['Unit Name'] === 'Hazira East'
        ? 'GATE 2'
        : item['Unit Name'] === 'Hazira West'
        ? 'GATE 5'
        : '',
      disabilityType: item['Disability Type'],
      disabilityPercentage: item['Disability Percentage'],

      blockRemark: item['Block Remarks'],
      workingArea: item['Working Area'],
      policeVerification: item['Police Verification'],
      esicno: item['IP_ESIC_Number'],
      // pf:
      //   item['PF Account No.'] === 'NO DATA' || item['PF Account No.'] === 'NO'
      //     ? true
      //     : false,
      uan: item['UAN_Number'],
      pfAccountNo: item['PF Account No.'],
      locationId: locationId,
      unitName: item['Unit Name'],
      votercardno: item['Voter Card No.'],
      handicapped: item['Handicapped?'],
      nomineeName: item['Nominee Name'],
      nomineeRelationship: item['Nominee Relationship'],
      nomineeAge: item['Nominee Age'],
      nomineeAadharNo: item['Nominee Aadhaar'],
      nomineeContactnumber: item['Nominee Contact Number'],
    };

    // Structure job details
    const jobDetails = {
      contractorCode: contractorCodeFromItem,
      contractorName: contractorName,
      departmentCode: item['Dept Code'],
      workingArea: item['Working Area'],
      sectionCode: item['Section Code'],
      workorderCode: item['Workorder Number'],
      labourType: item['Employee Type'],
      jobOrderNumber: item['JO Number'],
      skillCode: item['Skill Code'],
      workType: item['Work Type'],
      designation: item['Worker Type'],
      duration: item['Duration'],
      workerCategory: item['Worker Category'],
      terminationReason: item['Reason for Termination'],
      doj: doj,
      previousOrg: item['Previous Organization'],
      referredBy: item['Refered By'],
      name: item['Relative Name'],
      mobileNo: item['Relative Mobile Number'],
      bankname: item['Bank Name'],
      AccountNo: String(item['Bank Account No.']),
      ifsc: item['IFSC Code'],
      userName: item['Name as per Bank Account'],
      bloodGroup: item['Blood Group'],
      techQualification: item['Technical Qualification'],
      academicQualification: item['Academic Qualification'],
      dl: item['DL No.'] ? String(item['DL No.']) : '',
      dlExpiry:
        item['DL Validity Date'] == null
          ? ''
          : dayjs(item['DL Validity Date'], 'DD/MM/YYYY').format(
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ),
      primaryJobSkill: item['Primary Job Skill'] || '',
      nomineeBankName: item['Nominee Bank Name'],
      nomineeAccountNo: item['Nominee Bank Account No.'],
      nomineeIfsc: item['Nominee IFSC Code'],
    };

    // Structure wage details
    const wageDetails = {
      basic: !item['Basic'] ? '' : String(item['Basic']),
      da: !item['DA'] ? '' : String(item['DA']),
      allowance1: !item['Allowance1'] ? '' : String(item['Allowance1']),
      allowance2: !item['Allowance2'] ? '' : String(item['Allowance2']),
      hra: !item['HRA'] ? '' : String(item['HRA']),
      conveyance: !item['Conveyance'] ? '' : String(item['Conveyance']),
      specialAllowance: !item['Special Allowance']
        ? ''
        : String(item['Special Allowance']),
      medicalAllowance: !item['Medical Allowance']
        ? ''
        : String(item['Medical Allowance']),
      eduAllowance: !item['Education Allowance']
        ? ''
        : String(item['Education Allowance']),
      travelAllowance: !item['Leave/Travel Allowance']
        ? ''
        : String(item['Leave/Travel Allowance']),
      ot: !item['OT Applicable'] ? '' : item['OT Applicable'],
      effectiveDate: !item['Wage Effective Date']
        ? ''
        : dayjs(item['Wage Effective Date'], 'DD/MM/YYYY').format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
    };
    const uploadDocument = {
      annualMedicalCheckupDate: dayjs(
        item['Next annual Medical Checkup Date'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    };

    const payload = {
      personalDetails,
      jobDetails,
      //wageDetails,
      uploadDocument,
      locationId,
      employementType: 'Contractor',
    };
    return payload;
  };

  const removeDuplicatesAndNullsByFieldsAndStructureData = (
    data,
    fields,
    locationId
  ) => {
    const seen = new Set();
    const uniqueItems = [];
    const duplicateItems = [];

    data.forEach((item) => {
      const values = fields?.map((field) => item[field]);

      // Check if any of the required fields are missing
      // if (!item['Contractor Name'] || !item['Contractor Code']) {
      //   console.warn(
      //     `Skipping record due to missing required fields: Contractor Name: ${item['Contractor Name']}, Contractor Code: ${item['Contractor Code']}`
      //   );
      //   return; // Skip incomplete records
      // }

      // Check if any of the values is null
      if (values.includes(null)) {
        return; // Skip objects with null values
      }

      const key = values.join('|');

      // const formatedValue = formatField(item, locationId);

      const formatedValue = formatField(
        item,
        locationId,
        contractorName,
        userConfig
      );
      // Skip invalid entries returned as null from formatField
      if (!formatedValue) {
        return;
      }

      // Check for duplicates
      if (!seen.has(key)) {
        seen.add(key);
        uniqueItems.push(formatedValue);
      } else {
        duplicateItems.push(formatedValue);
      }
    });

    return { uniqueItems, duplicateItems };
  };
  const calculateAge = (dob) => {
    const currentDate = dayjs(); // Get the current date
    const birthDate = dayjs(dob, 'YYYY-MM-DD'); // Parse the DOB string into a dayjs object

    const age = currentDate.diff(birthDate, 'year'); // Calculate the difference in years

    return age;
  };
  //New one
//   const onFinish = async (value) => {
//     const file = fileList[0];

//     if (!file) {
//       message.error('No file uploaded');
//       return;
//     }

//     const reader = new FileReader();

//     reader.onload = async (e) => {
//       const data = new Uint8Array(e.target.result);

//       const workbook = XLSX.read(data, { type: 'array' });
//       const firstSheetName = workbook.SheetNames[0];
//       const jsonData = XLSX.utils.sheet_to_json(
//         workbook.Sheets[firstSheetName]
//       );

//       jsonData = jsonData.map((row) => ({
//         ...row,
//         Dob: row?.Dob ? dayjs.utc(new Date(row.Dob)).format('YYYY-MM-DD') : null,
//          // Format as needed
// dob: "12333333bhbiui",
//         doj: row?.doj ? dayjs.utc(new Date(row.doj)).format('YYYY-MM-DD') : null, // Format as needed
//       }));

//       console.log("jsonData 707 ", )

//       const fieldsToCheck = ['Aadhar_Number', 'UAN_Number', 'IP_ESIC_Number'];

//       const { uniqueItems, duplicateItems } =
//         removeDuplicatesAndNullsByFieldsAndStructureData(
//           jsonData,
//           fieldsToCheck,
//           value.locationId
//         );

//       setLoader(true);

//       const response = await uploadBulkDataApi(uniqueItems);
//       setLoader(false);

//       console.log("response ", response)
//       // Check if failedData is empty
//       if (response.data.failedData.length === 0) {
//         notification.success({
//           message: 'Employee onboarded Successfully.',
//         });
//         navigate('/home/supervisor/employee');
//         return; // Do not show modal if it's empty
//       }

//       response?.data?.WorkmenData.map(wm => {
//         console.log("wm 725 ", wm)
//         // wm.personalDetails.Dob = dayjs.utc(new Date(wm.personalDetails?.Dob))
//         // wm.personalDetails.dob = dayjs.utc(new Date(wm.personalDetails?.dob))
//       })

//       setuploaddocumentResponse(
//         response.data?.failedData?.map((row) => {
//           return {
//             firstName: row.personalDetails.firstName,
//             lastName: row.personalDetails.lastName,
//             aadharNo:
//               row.aadharDuplicate === true
//                 ? row.personalDetails.aadharNo
//                 : 'NA',
//             // pfAccountNo:
//             //   row.isPfDuplicate === true
//             //     ? row.personalDetails.pfAccountNo
//             //     : 'NA',
//             pfAccountNo: row.personalDetails.pfAccountNo || "NA",
//             esicno: row.personalDetails.esicno,
//             Reason:
//               row.aadharDuplicate === true
//                 ? 'Aadhaar Number is duplicate'
//                 : 'PF/UAN Number is duplicate',
//           };
//         })
//       );
//     };

//     reader.readAsArrayBuffer(file.originFileObj);
//   };


const onFinish = async (value) => {
  const file = fileList[0];

  if (!file) {
    message.error('No file uploaded');
    return;
  }

  const reader = new FileReader();

  reader.onload = async (e) => {
    try {
      const data = new Uint8Array(e.target.result);

      // Read Excel file
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
      console.log('jsonData', jsonData[0]);
      

      // Format Dob and doj fields using dayjs.utc
      jsonData = jsonData.map((row) => {
      console.log('788',row['Date Of Birth'], 
        dayjs(row['Date Of Birth'], 'DD-MM-YYYY').format('YYYY-MM-DD'),
        dayjs(row['Date Of Birth'], 'DD-MM-YYYY').local().format('YYYY-MM-DD')
      )

        return {
          ...row,
          'Date Of Birth': row['Date Of Birth'] &&  dayjs(row['Date Of Birth'], 'DD-MM-YYYY').local().format('YYYY-MM-DD'),
        
          'Date of Joining': row['Date of Joining'] && dayjs(row['Date of Joining'], 'DD-MM-YYYY').local().format('YYYY-MM-DD'),
        };
      });

      console.log('Formatted jsonData:', jsonData);

      const fieldsToCheck = ['Aadhar_Number', 'UAN_Number', 'IP_ESIC_Number'];

      // Process data to remove duplicates and nulls
      const { uniqueItems, duplicateItems } =
        removeDuplicatesAndNullsByFieldsAndStructureData(
          jsonData,
          fieldsToCheck,
          value.locationId
        );

      // Call API with unique items
      setLoader(true);
      const response = await uploadBulkDataApi(uniqueItems);
      setLoader(false);

      // Check if there are no failed data
      if (response.data.failedData.length === 0) {
        notification.success({
          message: 'Employee onboarded Successfully.',
        });
        navigate('/home/supervisor/employee');
        return; // Exit if no failed data
      }

      // Map failed data for UI rendering
      setuploaddocumentResponse(
        response.data?.failedData?.map((row) => ({
          firstName: row.personalDetails.firstName,
          lastName: row.personalDetails.lastName,
          aadharNo:
            row.aadharDuplicate === true
              ? row.personalDetails.aadharNo
              : 'NA',
          pfAccountNo: row.personalDetails.pfAccountNo || 'NA',
          esicno: row.personalDetails.esicno,
          Reason:
            row.aadharDuplicate === true
              ? 'Aadhaar Number is duplicate'
              : 'PF/UAN Number is duplicate',
        }))
      );
    } catch (error) {
      console.error('Error processing file:', error);
      message.error('An error occurred while processing the file. Please try again.');
      setLoader(false);
    }
  };

  // Read file as array buffer
  reader.readAsArrayBuffer(file.originFileObj);
};

  const uploadBulkDocuments = async (values) => {
    bulkUploadContractWorkmenDocumentApi({
      files: fileDirectory,
      location: values.locationId,
    })
      .then((response) => {
        notification.success({
          message: `${fileDirectory.length} files processed`,
        });
        setBulkDocumentUploadResponse(response?.data);
        setUploadLogsView(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Aadhaar Number',
      dataIndex: 'aadharNo',
      key: 'aadharNo',
    },
    {
      title: 'PF Number',
      dataIndex: 'pfAccountNo',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esicno',
    },
    {
      title: 'Reason',
      dataIndex: 'Reason',
    },
  ];

  const handleDownload = async () => {
    try {
      //const response = await fetch('http://localhost:3000/api/download-excel', {
      //const response = await fetch(
      // 'https://vyn.wikiworks.in:443/api/download-excel',
      // {
      const response = await fetch(
        // 'https://test.wikiworks.in:443/api/download-excel',
        `${process.env.REACT_APP_API_URL}/download-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  const handleCancel = () => {
    setuploaddocumentResponse([]);
    navigate('/home/supervisor/employee');
  };

  const handleClose = () => {
    setUploadLogsView(false);
  };

  return (
    <>
      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Bulk Upload
        </Title>
      </Row>
      <Form layout='vertical' onFinish={onFinish}>
        <Row>
          <Col span={6}>
            <Form.Item name='dot' label='Download Template'>
              <Button type='primary' onClick={handleDownload}>
                Download Template
              </Button>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              {/* <Select style={{ width: '60%' }}>
                {location.Response2?.slice(1).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.id}
                  </Option>
                ))}
              </Select> */}
              <Select style={{ width: '60%' }}>
                {/* {location.Response2?.slice(1).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.id}
                  </Option>
                ))} */}
                <Option value={userConfig?.locationId ? userConfig?.locationId: userConfig?.location}>
                  {userConfig?.locationId ? userConfig?.locationId: userConfig?.location}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Upload Bulk Documents' name='bok'>
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false}
                accept='.xlsx'
                rules={[{ required: true, message: 'Select Upload' }]}>
                <Button disabled={fileList.length === 1}>
                  Select Bulk Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={fileList.length === 0}
                htmlType='submit'>
                Upload Bulk Data
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Modal
          title='Failed Data'
          open={uploaddocumentResponse.length}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={
            <Button type='primary' onClick={handleCancel}>
              OK
            </Button>
          }
          maskClosable={true}
          width={1000}>
          <Table
            columns={columns}
            dataSource={uploaddocumentResponse}
            pagination={true}
          />
        </Modal>
      </Form>
      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Bulk Document Upload
        </Title>
      </Row>
      <Form layout='vertical' onFinish={uploadBulkDocuments}>
        <Row>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              <Select style={{ width: '60%' }}>
                <Option value={userConfig?.locationId}>
                  {userConfig?.locationId}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Upload Bulk Documents'
              name='bulkDocuments'
              rules={[{ required: true, message: 'Select folder to upload' }]}>
              <Upload
                multiple
                directory
                showUploadList={false} // Hides default Ant Design file list
                beforeUpload={() => false}
                onChange={handleChange} // Custom replacement handler
              >
                <Button>Upload Folder</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={fileDirectory.length === 0}
                htmlType='submit'>
                Upload Documents
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title='Failed uploads'
        open={uploadLogsView}
        onOk={handleClose}
        onCancel={handleClose}
        footer={
          <Button type='primary' onClick={handleClose}>
            OK
          </Button>
        }
        maskClosable={true}
        width={500}>
        <p>
          <b>Records found: </b>
          {bulkDocumentUploadResponse?.records_found?.length
            ? bulkDocumentUploadResponse?.records_found?.join(', ')
            : 'None'}
        </p>
        <p>
          <b>Records not found: </b>
          {bulkDocumentUploadResponse?.records_not_found?.length
            ? bulkDocumentUploadResponse?.records_not_found?.join(', ')
            : 'None'}
        </p>
      </Modal>
    </>
  );
};

export default FileUploader;
