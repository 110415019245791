import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Form } from 'antd';
import { SearchOutlined, ReloadOutlined, UploadOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
// import { uploadWorkOrderDetailsData, getWorkOrderDetailsData } from '../actions/api/workOrderDetails'
import moment from 'moment';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// import axios from 'axios';
// import BulkUploadModal from './BulkUploadModal'
import { useParams, useHistory, useNavigate, useLocation } from 'react-router-dom';

const { Option } = Select;

// Sample data
const dataSource = [
    {
      key: '1',
      stNo: '1',
      empNo: 'E001',
      uniqueNum: '1234567890',
      workmenName: 'John Doe',
      skill: 'Electrician',
      subskill: 'Wiring',
      trade: 'Trade 1',
      pfNumber: 'PF001',
      esiNumber: 'ESI001',
      daysWorked: '20',
      holidayDays: '2',
      otHours: '5',
      fixedWagesDay: {
        basic: 500,
        vda: 200,
        washingAllowance: 50,
        hra: 100,
        bonus: 41.65,
        lww: 20.83,
        otherAllowance: 30,
        gross: 942.48,
      },
      fixedWagesMonth: {
        basic: 15000,
        vda: 6000,
        washingAllowance: 1500,
        hra: 3000,
        bonus: 1249.5,
        lww: 624.9,
        otherAllowance: 900,
        gross: 27774.4,
      },
      deductions: {
        pf: 1800,
        esi: 112.5,
        canteen: 100,
        pt: 200,
        lwf: 20,
        totalDeductions: 2232.5,
        netPay: 25541.9,
        bankAccount: 'XYZ Bank, A/C: 1234567890',
      },
      signature: '',
    },
    // More rows...
  ];
  
  // Table column definitions
  const columns = [
    {
      title: 'Employee Details',
      children: [
        {
          title: 'St.no',
          dataIndex: 'stNo',
          key: 'stNo',
        },
        {
          title: 'Emp no.',
          dataIndex: 'empNo',
          key: 'empNo',
        },
        {
          title: 'Unique Num',
          dataIndex: 'uniqueNum',
          key: 'uniqueNum',
        },
        {
          title: 'Workmen Name',
          dataIndex: 'workmenName',
          key: 'workmenName',
        },
      ],
    },
    {
      title: 'Worker Attribute',
      children: [
        {
          title: 'Skill',
          dataIndex: 'skill',
          key: 'skill',
        },
        {
          title: 'Subskill',
          dataIndex: 'subskill',
          key: 'subskill',
        },
        {
          title: 'Trade',
          dataIndex: 'trade',
          key: 'trade',
        },
        {
          title: 'PF Number',
          dataIndex: 'pfNumber',
          key: 'pfNumber',
        },
        {
          title: 'ESI Number',
          dataIndex: 'esiNumber',
          key: 'esiNumber',
        },
      ],
    },
    {
      title: 'Attendance Records',
      children: [
        {
          title: 'No. of days worked in month',
          dataIndex: 'daysWorked',
          key: 'daysWorked',
        },
        {
          title: 'Holiday Days',
          dataIndex: 'holidayDays',
          key: 'holidayDays',
        },
        {
          title: 'OT Hours',
          dataIndex: 'otHours',
          key: 'otHours',
        },
      ],
    },
    {
      title: 'Fixed Wages per Day',
      children: [
        {
          title: 'Basic',
          dataIndex: ['fixedWagesDay', 'basic'],
          key: 'fixedWagesDayBasic',
        },
        {
          title: 'VDA',
          dataIndex: ['fixedWagesDay', 'vda'],
          key: 'fixedWagesDayVda',
        },
        {
          title: 'Washing Allowance',
          dataIndex: ['fixedWagesDay', 'washingAllowance'],
          key: 'fixedWagesDayWashingAllowance',
        },
        {
          title: 'HRA',
          dataIndex: ['fixedWagesDay', 'hra'],
          key: 'fixedWagesDayHra',
        },
        {
          title: 'Bonus @8.33%',
          dataIndex: ['fixedWagesDay', 'bonus'],
          key: 'fixedWagesDayBonus',
        },
        {
          title: 'LWW @4.165%',
          dataIndex: ['fixedWagesDay', 'lww'],
          key: 'fixedWagesDayLww',
        },
        {
          title: 'Other Allowance',
          dataIndex: ['fixedWagesDay', 'otherAllowance'],
          key: 'fixedWagesDayOtherAllowance',
        },
        {
          title: 'Gross',
          dataIndex: ['fixedWagesDay', 'gross'],
          key: 'fixedWagesDayGross',
        },
      ],
    },
    {
      title: 'Fixed Wages per Month',
      children: [
        {
          title: 'Basic',
          dataIndex: ['fixedWagesMonth', 'basic'],
          key: 'fixedWagesMonthBasic',
        },
        {
          title: 'VDA',
          dataIndex: ['fixedWagesMonth', 'vda'],
          key: 'fixedWagesMonthVda',
        },
        {
          title: 'Washing Allowance',
          dataIndex: ['fixedWagesMonth', 'washingAllowance'],
          key: 'fixedWagesMonthWashingAllowance',
        },
        {
          title: 'HRA',
          dataIndex: ['fixedWagesMonth', 'hra'],
          key: 'fixedWagesMonthHra',
        },
        {
          title: 'Bonus @8.33%',
          dataIndex: ['fixedWagesMonth', 'bonus'],
          key: 'fixedWagesMonthBonus',
        },
        {
          title: 'LWW @4.165%',
          dataIndex: ['fixedWagesMonth', 'lww'],
          key: 'fixedWagesMonthLww',
        },
        {
          title: 'Other Allowance',
          dataIndex: ['fixedWagesMonth', 'otherAllowance'],
          key: 'fixedWagesMonthOtherAllowance',
        },
        {
          title: 'Gross',
          dataIndex: ['fixedWagesMonth', 'gross'],
          key: 'fixedWagesMonthGross',
        },
      ],
    },
    {
      title: 'Deductions',
      children: [
        {
          title: 'PF @ 12%',
          dataIndex: ['deductions', 'pf'],
          key: 'deductionsPf',
        },
        {
          title: 'ESI @ 0.75%',
          dataIndex: ['deductions', 'esi'],
          key: 'deductionsEsi',
        },
        {
          title: 'Canteen',
          dataIndex: ['deductions', 'canteen'],
          key: 'deductionsCanteen',
        },
        {
          title: 'PT',
          dataIndex: ['deductions', 'pt'],
          key: 'deductionsPt',
        },
        {
          title: 'LWF',
          dataIndex: ['deductions', 'lwf'],
          key: 'deductionsLwf',
        },
        {
          title: 'Total Deductions',
          dataIndex: ['deductions', 'totalDeductions'],
          key: 'deductionsTotal',
        },
        {
          title: 'Net Pay',
          dataIndex: ['deductions', 'netPay'],
          key: 'deductionsNetPay',
        },
        {
          title: 'Bank Account Details',
          dataIndex: ['deductions', 'bankAccount'],
          key: 'deductionsBankAccount',
        },
      ],
    },
    {
      title: 'Signature',
      dataIndex: 'signature',
      key: 'signature',
    },
  ];
  
const WageRegister = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    unit: null,
    status: null,
    sectionCode: null,
    validFrom: null,
    validTo: null,
  });
 

  const [jsonData, setJsonData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();
  const isLRPage = location.pathname.includes('scheduleworkmen');

//   useEffect(() => {
//     getWorkOrderDetailsData()
//       .then(data => {
//         setTableData(data);
//         setFilteredData(data);
//       })
//       .catch(error => {
//         console.error('Error fetching work order details data:', error);
//       });
//   }, []);

  console.log("data", dataSource);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSearch = () => {
    let data = [...tableData];
    console.log("Filters:", filters);
    console.log("Table Data:", tableData);
    // let data = [...tableData];
    // data = tableData;

    // Debugging: Log data to ensure it contains expected values
    console.log("Original Data:", data);
    if (filters.search) {
      console.log("Applying search filter for:", filters.search);
      data = data.filter(item => {
          console.log("Checking item:", item);
          console.log("Search Term:", filters.search);
          console.log("workOrderNumber:", item.workOrderNumber);
          console.log("workOrderType:", item.workOrderType);
          console.log("jobDescription:", item.jobDescription);
          console.log("sectionCode:", item.sectionCode);
          console.log("sectionHead:", item.sectionHead);
          console.log("contractorName:", item.contractorName);
          console.log("unitName:", item.unitName);
          console.log("status:", item.status);
          const match = (
            (typeof item.workOrderNumber === 'string' && item.workOrderNumber.includes(filters.search)) ||
            (typeof item.workOrderType === 'string' && item.workOrderType.includes(filters.search)) ||
            (typeof item.jobDescription === 'string' && item.jobDescription.includes(filters.search)) ||
            (typeof item.sectionCode === 'string' && item.sectionCode.includes(filters.search)) ||
            (typeof item.sectionHead === 'string' && item.sectionHead.includes(filters.search)) ||
            (typeof item.contractorName === 'string' && item.contractorName.includes(filters.search)) ||
            (typeof item.unitName === 'string' && item.unitName.includes(filters.search)) ||
            (typeof item.status === 'string' && item.status.includes(filters.search)) || 
            (typeof item.validFrom === 'string' && item.status.includes(filters.validFrom)) || 
            (typeof item.validTo === 'string' && item.status.includes(filters.validTo)) 
        );
        console.log("Match:", match); // Log the result of the filter condition
        return match;
      });
  }

    if (filters.unit) {
      data = data.filter(item => item.unitName.includes(filters.unit));
    }
    if (filters.status) {
      console.log("filters.status", filters.status);
      data = data.filter(item => item.status.includes(filters.status));
    }
    if (filters.sectionCode) {
      data = data.filter(item => item.sectionCode.includes(filters.sectionCode));
    }
    if (filters.validFrom) {
      console.log("Applying validFrom filter for:", filters.validFrom);
      console.log("data", data);
      data = data.filter(item => 
        moment(item.validFrom, 'YYYY-MM-DD').isSameOrAfter(moment(filters.validFrom, 'DD-MM-YYYY'))
      );
    }
    if (filters.validTo) {
      console.log("Applying validTo filter for:", filters.validTo);
      data = data.filter(item => 
        moment(item.validTo, 'YYYY-MM-DD').isSameOrBefore(moment(filters.validTo, 'DD-MM-YYYY'))
      );
    }

    console.log("Filtered Data:", data);

    setFilteredData(data);
  };

  useEffect(() => {
    handleSearch();
  }, [filters]);

  const handleReset = () => {
    
    setFilters({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
    setFilteredData(tableData);
    
    // form.resetFields(['search', 'unit', 'status', 'sectionCode', 'validFrom', 'validTo']);
    // Manually reset form fields immediately after state update
    form.setFieldsValue({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
  };

  const handlePrinter = () => {
    Window.print();
  }

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <span style={{ fontSize: '18px', fontWeight: 'bold',fontFamily:'Inter' }}>Wage Register</span>
        </Col>
        <Col span={12}>
        <Form form={form}>
            <Form.Item name="search" initialValue={filters.search}>
          <Input
            placeholder="Search"
            onChange={e => setFilters({ ...filters, search: e.target.value })}
            style={{ width: '100%'}}
            suffix={<SearchOutlined />}
          />
          </Form.Item>
          </Form>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
            <Button type="default" 
                icon={<DownloadOutlined />} 
                style={{ borderColor: '#1890ff', color: '#1890ff',  padding: '0 8px', marginRight: '10px'}}
                onClick={showModal}>
            </Button>
            <Button type="default" 
                icon={<PrinterOutlined />} 
                style={{ borderColor: '#1890ff', color: '#1890ff',  padding: '0 8px' }}
                onClick={handlePrinter}>
            </Button>
        </Col>
        <Col span={1} style={{ textAlign: 'right' }}>
        
        </Col>
      </Row>
      
      <Table dataSource={dataSource} columns={columns} bordered scroll={{ x: 'max-content' }} />
     
    </div>
  );
};

export default WageRegister;
