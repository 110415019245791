import { getPersonDetailData, updatePersonDataDetail } from '../../api/personDetails';

export const getPersonDetailsData = async () => {
  //Get Data
  try{
    const personDetailsData = await getPersonDetailData();
    return personDetailsData?.data?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  } 
};

export const updatePersonDataDetails = async (response, schedulStatus, selectedJob) => {
  //Get Data
  try{
    const personDetailsData = await updatePersonDataDetail(response, schedulStatus, selectedJob);
    return personDetailsData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

